// Import Style
import styled, { css, keyframes } from 'styled-components';

// Import Colors
import colors from '../../../Colors/colors';

const slideUp = keyframes`
  from {
    bottom: -200px;
    opacity: 0;
  }
  to {
    bottom: 20px;
    opacity: 1;
  }
`;

export const PopUpContainer = styled.div`
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;

  background-color: ${colors.underlineEffect};
  color: ${colors.headerFooterBackGround};
  
  border-radius: 5px;
  border: 1px solid ${colors.headerFooterBackGround};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  visibility: hidden;
  opacity: 0;

  transition: bottom 0.3s ease-in-out;
  ${(props) =>
    props.show &&
    css`
      visibility: visible;
      animation: ${slideUp} 0.3s forwards;
    `}
`;
