// Import React Libraries
import styled from 'styled-components';

// Import Constants
import { responsiveWidthMobile } from '../../../Constant';

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;

  border-radius: 50%;
  margin-left: 5px;
  background-color: white;
`;

export const TooltipContainer = styled.div`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.alignLeft === true ? '' : 'center'};
  
  padding: 5px;

  width: 280px;
  
  position: absolute;
  z-index: 1;
  bottom: 125%;

  left: 50%;
  transform: translateX(-50%);
  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s;

  background-color: #333;
  color: #fff;

  border-radius: 5px;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
  }

  @media (max-width: ${responsiveWidthMobile}px) {
    position: fixed;
    bottom: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: none;

    &::after {
      content: none;
    }
  }
`;

export const HelpIcon = styled.span`
  cursor: pointer;
  font-weight: bold;
  margin: 0 5px;
`;

export const ToolTipIllustrationContainer = styled.div`
  width: 100px;
  height: 100px;
`;

export const ToolTipIllustration = styled.img`
  width: 100%;
  height: 100%;
`;

export const ToolTipP = styled.p`
  margin: 2px;
  text-align: left;
`;
