// Import Functions
import {
  APIGetAllGear, APIGetAllEquipmentValidationStatus,
  APIGetUserAllPendingValidationGear
} from '../../../../api/Gear/Gear_API';

import {
  APIGetAllAccessories,
  APIGetAllAccessoriesStatus,
  APIUserAllAccessoriesPendingValidation
} from '../../../../api/Gear/Accessory_API';

// Import Constants
import { responsiveWidthTablet } from '../../../Constant';

/* Generates a list containing all the items of a category */
export async function getAllItemSubCategoryList (gearData) {
  /*
  console.log('getAllItemSubCategoryList');
  console.log('gearData : ', gearData);
  */

  // Shelter
  const tarpListAPI = await APIGetAllEquipmentValidationStatus(gearData, true);
  const tentListAPI = await APIGetAllEquipmentValidationStatus(gearData, true);
  // Bedding
  const sleepingBagListAPI = await APIGetAllEquipmentValidationStatus(gearData, true);
  const mattressListAPI = await APIGetAllEquipmentValidationStatus(gearData, true);
  const hammockListAPI = await APIGetAllEquipmentValidationStatus(gearData, true);

  let itemList;

  const functionMap = {
    Shelter: [tarpListAPI, tentListAPI],
    Bedding: [hammockListAPI, sleepingBagListAPI, mattressListAPI]
    // Cooking: [cookingList, stoveList],
    // Portage: [minus30LList, plus30LList]
  };

  if (functionMap[gearData.gearCategory]) {
    itemList = functionMap[gearData.gearCategory];
    // console.log('itemList : ', itemList);
    return itemList;
  } else {
    console.error(`Unknown itemCategory: ${gearData.gearCategory}`);
  }
};

/* Return the subCategory list of items using intermediary functions */
export async function getProductList (gearData, itemChoiceSelection) {
  /*
  console.log('getProductList');
  console.log('gearData : ', gearData);
  */

  const functionMap = {
    Shelter: getItemListShelter,
    Bedding: getItemListBedding,
    Cooking: getItemListCooking,
    Portage: getItemListPortage
  };

  const selectedFunction = functionMap[gearData.Category];
  if (selectedFunction) {
    return await selectedFunction(gearData, itemChoiceSelection);
    // return selectedFunction(gearTypeCategory, gearCategory, gearSubCategory);
  } else {
    console.error(`Unknown Category: ${gearData.Category}`);
  }
};

// Shelter
async function getItemListShelter (gearData, gearChoiceSelection) {
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  /*
  console.log('getItemListShelter');
  console.log('gearData : ', gearData);
  console.log(`gearChoiceSelection : ${gearChoiceSelection}`);
  */

  let shelterListAPI;
  let accessoryListAPI;

  if (gearData.SubCategory !== 'Accessory') {
    if (gearChoiceSelection === 'Validated') {
      shelterListAPI = await APIGetAllEquipmentValidationStatus(gearData, true);
    } else if (gearChoiceSelection === 'PendingValidation') {
      if (currentUserData.userProfile.User_Rank === 'ADMIN') {
        shelterListAPI = await APIGetAllEquipmentValidationStatus(gearData, false);
      } else {
        shelterListAPI = await APIGetUserAllPendingValidationGear(gearData, currentUserData.userProfile.User_ID);
      }
    } else if (gearChoiceSelection === 'All') {
      shelterListAPI = await APIGetAllGear(gearData.TypeCategory, gearData.Category, gearData.SubCategory);
    }
  } else if (gearData.SubCategory === 'Accessory') {
    if (gearChoiceSelection === 'Validated') {
      accessoryListAPI = await APIGetAllAccessoriesStatus(gearData, true);
    } else if (gearChoiceSelection === 'PendingValidation') {
      if (currentUserData.userProfile.User_Rank === 'ADMIN') {
        accessoryListAPI = await APIGetAllAccessoriesStatus(gearData, false);
      } else {
        accessoryListAPI = await APIUserAllAccessoriesPendingValidation(gearData, currentUserData.userProfile.User_ID);
      }
    } else if (gearChoiceSelection === 'All') {
      accessoryListAPI = await APIGetAllAccessories(gearData);
    }
  }

  const functionMap = {
    Tarp: shelterListAPI,
    Tent: shelterListAPI,
    Accessory: accessoryListAPI
  };

  const selectedItemList = functionMap[gearData.SubCategory];
  // console.log('selectedItemList : ', selectedItemList);
  if (selectedItemList) {
    // console.log('selectedItemList');
    // console.log(selectedItemList);
    return selectedItemList;
  } else {
    console.error(`Unknown SubCategory: ${gearData.SubCategory}`);
  }
};

// Bedding
async function getItemListBedding (gearData, gearChoiceSelection) {
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  /*
  console.log('getItemListBedding');
  console.log('gearData : ', gearData);
  console.log(`gearChoiceSelection : ${gearChoiceSelection}`);
  */

  let beddingAPILIst;
  let accessoryListAPI;

  if (gearData.SubCategory !== 'Accessory') {
    if (gearChoiceSelection === 'Validated') {
      beddingAPILIst = await APIGetAllEquipmentValidationStatus(gearData, true);
    } else if (gearChoiceSelection === 'PendingValidation') {
      if (currentUserData.userProfile.User_Rank === 'ADMIN') {
        beddingAPILIst = await APIGetAllEquipmentValidationStatus(gearData, false);
      } else {
        beddingAPILIst = await APIGetUserAllPendingValidationGear(gearData, currentUserData.userProfile.User_ID);
      }
    } else if (gearChoiceSelection === 'All') {
      beddingAPILIst = await APIGetAllGear(gearData.TypeCategory, gearData.Category, gearData.SubCategory);
    }
  } else if (gearData.SubCategory === 'Accessory') {
    if (gearChoiceSelection === 'Validated') {
      accessoryListAPI = await APIGetAllAccessoriesStatus(gearData, true);
    } else if (gearChoiceSelection === 'PendingValidation') {
      if (currentUserData.userProfile.User_Rank === 'ADMIN') {
        accessoryListAPI = await APIGetAllAccessoriesStatus(gearData, false);
      } else {
        accessoryListAPI = await APIUserAllAccessoriesPendingValidation(gearData, currentUserData.userProfile.User_ID);
      }
    } else if (gearChoiceSelection === 'All') {
      accessoryListAPI = await APIGetAllAccessories(gearData);
    }
  }

  const functionMap = {
    Accessory: accessoryListAPI,
    Hammock: beddingAPILIst,
    SleepingBag: beddingAPILIst,
    Mattress: beddingAPILIst
  };

  const selectedItemList = functionMap[gearData.SubCategory];
  if (selectedItemList) {
    // console.log('selectedItemList');
    // console.log(selectedItemList);
    return selectedItemList;
  } else {
    console.error(`Unknown SubCategory: ${gearData.SubCategory}`);
  }
};

// Cooking
function getItemListCooking (gearData) {
  const functionMap = {
    // Cooking: cookingList,
    // Stove: stoveList
  };

  const selectedItemList = functionMap[gearData.gearSubCategory];
  if (selectedItemList) {
    // console.log('selectedItemList');
    // console.log(selectedItemList);
    return selectedItemList;
  } else {
    console.error(`Unknown itemSubCategory: ${gearData.gearSubCategory}`);
  }
};

// Portage
function getItemListPortage (gearData) {
  const functionMap = {
    // Minus30L: minus30LList,
    // Plus30L: plus30LList
  };

  const selectedItemList = functionMap[gearData.gearSubCategory];
  if (selectedItemList) {
    // console.log('selectedItemList');
    // console.log(selectedItemList);
    return selectedItemList;
  } else {
    console.error(`Unknown itemSubCategory: ${gearData.gearSubCategory}`);
  }
};

export function displayPreviewSlider (amountOfPictureAvailable, screenWidth) {
  let previewSliderDisplay;
  if ((amountOfPictureAvailable <= 1) || (screenWidth <= responsiveWidthTablet)) {
    previewSliderDisplay = false;
  } else {
    return true;
  }
  return previewSliderDisplay;
}
