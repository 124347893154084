// Import React Libraries
import React, { useEffect, useState } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// Import PropTypes
import { gearDataPropTypes, gearItemPropTypes } from '../../datas/PropTypes/gearPropTypes';

// Import Context
import { usePreviewSliderContext } from '../../context/PreviewSliderContext';

// Import Components
import PreviewSlider from '../Slider/PreviewSlider/PreviewSlider';

// Import Functions
import { displayPreviewSlider } from '../../utils/Functions/Gear/GatheringGears/gatheringGear_Functions';
import { getGearTypeCategoryDefaultIllustrationFunction } from '../../utils/Functions/Gear/gearDefaultIllustration_Function';

// Import Style
import {
  StyledIcon
} from '../../utils/Style/GlobalStyle';
import {
  ButtonContainer,
  ScrollingButton
} from '../../utils/Style/PreviewSliderStyle';
import {
  PictureWrapper, PictureContainer, PictureDisplayer, ProductPicture,
  PictureSelector
} from '../../utils/Style/ItemDetailStyle';

function ItemDetailPicturesDisplay ({ gearData, item }) {
  /*
  console.log('ItemDetailDisplay');
  console.log('gearData : ', gearData);
  console.log('item : ', item);
  */

  const { pictureToDisplayIndex, setPictureToDisplayIndex } = usePreviewSliderContext();

  const [image, setImage] = useState(
    item.Assets.Illustrations && item.Assets.Illustrations.length > 0
      ? item.Assets.Illustrations[0]
      : getGearTypeCategoryDefaultIllustrationFunction(gearData)
  );

  const [screenWidth, updatescreenWidth] = useState(window.innerWidth);

  const slideLeft = async () => {
    setPictureToDisplayIndex(pictureToDisplayIndex - 1);
  };

  const slideRight = async () => {
    setPictureToDisplayIndex(pictureToDisplayIndex + 1);
  };

  useEffect(() => {
    setPictureToDisplayIndex(0);
    updatescreenWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (pictureToDisplayIndex <= item.Assets.Illustrations.length - 1) {
      // console.log(itemList[index].illustrations[pictureToDisplayIndex].picture);
      // setImage(item.illustrations[pictureToDisplayIndex].picture);
      setImage(item.Assets.Illustrations[pictureToDisplayIndex]);
      // console.log(`pictureToDisplayIndex : ${pictureToDisplayIndex}`);
    }
  }, [pictureToDisplayIndex]);

  return (
    <PictureWrapper id='pictureWrapper'>
      <PictureDisplayer id='pictureDisplayer'>
        <ButtonContainer id='scrollingButtonLeftContainer'>
          {item.Assets.Illustrations.length === 0
            ? <></>
            : <ScrollingButton id='scrollLeftButton' onClick={slideLeft}
              style={{ display: pictureToDisplayIndex === 0 ? 'none' : undefined }}>
                <StyledIcon icon={faChevronLeft} />
              </ScrollingButton>}
        </ButtonContainer>
          <PictureContainer id='pictureContainer'>
            <ProductPicture src={image} alt='picture1' id='itemPicture'/>
          </PictureContainer>
        <ButtonContainer id='scrollingButtonRightContainer'>
          {item.Assets.Illustrations.length === 0
            ? <></>
            : <ScrollingButton id='scrollRightButton' onClick={slideRight}
              style={{ display: pictureToDisplayIndex === item.Assets.Illustrations.length - 1 ? 'none' : undefined }}>
                <StyledIcon icon={faChevronRight} />
              </ScrollingButton>}
        </ButtonContainer>
      </PictureDisplayer>
      {
        displayPreviewSlider(item.Assets.Illustrations.length, screenWidth) === false
          ? <></>
          : <PictureSelector id='pictureSelector'>
              <PreviewSlider pictureList={item.Assets.Illustrations} setImage={setImage}/>
            </PictureSelector>
      }
    </PictureWrapper>
  );
}

ItemDetailPicturesDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearItemPropTypes
};

export default ItemDetailPicturesDisplay;
