// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Asset
import { commingSoonIllustration } from '../../datas/Illustrations/mascot_Ilustrations';

// Import Style
import {
  CommingSoonContainer,
  CommingSoonIllustrationContainer, CommingSoonIllustration
} from '../../utils/Style/js/CommingSoon/CommingSoon';
import { StyledP } from '../../utils/Style/js/General/Font_Style';

// Import Constants

function CommingSoonComponent () {
  const { t } = useTranslation();

  return (
    <CommingSoonContainer>
      <StyledP>
        {`${t('CommingSoon')}`}
      </StyledP>
      <CommingSoonIllustrationContainer>
        <CommingSoonIllustration src={commingSoonIllustration} alt='CommingSoonIllustration'/>
      </CommingSoonIllustrationContainer>
    </CommingSoonContainer>
  );
}

export default CommingSoonComponent;
