// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Import Context
import { useNavContext } from '../../context/NavContext';

// Import Data
import { navigationServiceContentUser, navigationServiceContentAdmin } from '../../datas/Nav/navServiceContent';

// Import compoment
import Dropdown from '../Dropdown/Dropdown';

// Import Function
import { scrollToTop } from '../../utils/Functions/general_Functions';

// Import Style
import { NavLink, StyledUlItems } from '../../utils/Style/js/HeaderNav/NavStyle';

function NavItems () {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { burgerButtonState, setBurgerButtonState } = useNavContext();
  const location = useLocation();
  const [navItems, setNavItems] = useState([]);

  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  useEffect(() => {
    const navigationServiceContent = currentUserData.userProfile.User_Rank === 'ADMIN' ? navigationServiceContentAdmin : navigationServiceContentUser;
    // Perform your operations here and update navItems
    const updatedNavItems = navigationServiceContent.map((item) => {
      const pageLinkPath = `/${i18n.language}/${t(item.pageLink)}`;
      const isTabActive = location.pathname.includes(pageLinkPath);
      const isHomeTabActive = location.pathname === `/${i18n.language}` && item.pageLink === '';
      const isActive = isTabActive || isHomeTabActive;

      /*
      console.log('item.pageLink : ', item.pageLink);
      console.log('location.pathname : ', location.pathname);
      console.log('isTabActive : ', isTabActive);
      console.log('isHomeTabActive : ', isHomeTabActive);
      console.log('isActive : ', isActive);
      console.log('\n\n');
      */

      return {
        ...item,
        pageLinkPath,
        isTabActive,
        isHomeTabActive,
        isActive
      };
    });
    setNavItems(updatedNavItems);
  }, [location.pathname, i18n.language, t, navigate]);

  function handleLinkClick () {
    setBurgerButtonState(false);
    scrollToTop();
  }

  return (
    <div id='itemSideNav'>
      <StyledUlItems burgerButtonState={burgerButtonState} id='productUl'>
        {navItems.map((webService) => (
          webService.dropDown === true
            ? (
            <li key={webService.pageLink}>
            <Dropdown
              dropDownTitle ={webService.pageLinkTitle}
              options={webService.dropDownOptionsLinksRender}
              links={webService.dropDownOptionsLinks}
              handleCloseBurger={() => setBurgerButtonState(false)}
              isActive={ webService.isActive}
            />
          </li>
              )
            : (
          <li key={webService.pageLink} >
            <NavLink
              to={webService.pageLink ? webService.pageLinkPath : `/${i18n.language}`}
              className={webService.isActive ? 'activeUnderline' : ''}
              onClick={handleLinkClick}
            >
              {t(webService.pageLinkTitle)}
            </NavLink>
          </li>
              )
        ))}
        </StyledUlItems>
    </div>

  );
}

export default NavItems;
