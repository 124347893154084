// Import Constants
import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';

export async function APIPostContact (formData) {
  console.log('APIPostContact');
  console.log('formData : ', formData);
  try {
    const response = await fetch(`https://${WebServerIPAddress}/contact/pack-planner`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    });

    const responseBody = await response.json();
    console.log('responseBody : ', responseBody); // Log server response

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    return responseBody;
  } catch (error) {
    if (error.response) {
      console.log('Server error response:', error.response);
      return {
        success: false,
        message: error.message,
        error: error.response
      };
    } else {
      return {
        success: false,
        message: error.message
      };
    }
  }
}
