// Import Datas
import { distanceCharacteristic } from '../../../../datas/Unit/UnitArrays';
import { getUnitUsedForStorage } from '../Unit/unit_Functions';

// Import Functions
import { convertCentimeterToMeter } from './convertDistance_Functions';
import { convertGramToKilogram } from './convertWeight_Functions';

export function convertIfNecessarySI (characteristicName, characteristicValue) {
  let convertedUnit = getUnitUsedForStorage(characteristicName);
  let convertedValue = characteristicValue;

  // Distance
  if (distanceCharacteristic.includes(characteristicName)) {
    if (characteristicValue > 100) {
      // convertToMeter
      convertedValue = convertCentimeterToMeter(characteristicValue);
      convertedUnit = 'm';
    }
  } else {
    switch (characteristicName) {
      // Weight
      case 'Weight':
        if (characteristicValue > 1000) {
          // convertToKilogram
          convertedValue = convertGramToKilogram(characteristicValue);
          convertedUnit = 'kg';
        }
        break;
      case 'Density':
        if (characteristicValue > 1000) {
          // convertToKilogram
          convertedValue = convertGramToKilogram(characteristicValue);
          convertedUnit = 'kg/L';
        }
        break;
    }
  }
  return { convertedUnit, convertedValue };
};
