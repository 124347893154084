// Import Styled
import styled from 'styled-components';

// Import Constants
import { responsiveWidthMobile } from '../../../Constant';

export const GearControlTable = styled.table`
  font-family: sans-serif;
  width: 100%;
  border-spacing: 4px;
  border: 2px solid white;

  &:hover {
    border: 2px solid black;
  }

   @media (max-width: ${responsiveWidthMobile}px){
  }
`;
