// Import Styled
import styled from 'styled-components';

// Import Colors
import colors from '../../../Colors/colors';

export const GearItemFilterTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const GearUserInterractionContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-evenly;
  align-items: center;

  width: 100%;
`;

export const FilterTable = styled.table`
`;

export const FilterTableBody = styled.tbody`
`;

export const FilterTR = styled.tr`
  display: block;
  width: 250px;
`;

export const FilterTH = styled.th`
  display: inline-block;
  border-radius: 3px;
  background: ${colors.characteristicTH};
  height: 21px;
  width: 100%;
  margin: 5px 0;
`;

export const FilterTD = styled.td`
  display: inline-block;
  border-radius: 3px;
  min-width: 160px;
  width: 100%;
`;
