// Import React Libraries
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavIndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  height: 25px;

  padding-left: 25px;
`;

export const NavIndicatorLink = styled(Link)`
  font-family:  sans-serif;
  margin: 0 5px;
`;
