// Import React Libraries
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Import Context
import { useNavContext } from '../../context/NavContext';

// Import compoment
import Dropdown from '../Dropdown/Dropdown';

// Import Functions
import { scrollToTop } from '../../utils/Functions/general_Functions';

// Import Json
import { navigationMenuContent } from '../../datas/Nav/navMenuContent';

// Import Style
import '../../utils/Style/css/NavStyles.css';
import { StyledUlMenu, NavLink } from '../../utils/Style/js/HeaderNav/NavStyle';
import { LanguageMenu } from '../LanguageMenu/languageMenu';

function NavMenu () {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { burgerButtonState, setBurgerButtonState } = useNavContext();
  const location = useLocation();
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    // Perform your operations here and update navItems
    const updatedNavItems = navigationMenuContent.map((item) => {
      const pageLinkPath = `/${i18n.language}/${t(item.pageLink)}`;
      const isTabActive = location.pathname.includes(pageLinkPath) && location.pathname !== `/${i18n.language}`;
      const isHomeTabActive = location.pathname === `/${i18n.language}` && item.pageLink === '';
      /*
      console.log(`location.pathname : ${location.pathname}`);
      console.log(`isTabActive : ${isTabActive}`);
      console.log(`isHomeTabActive : ${isHomeTabActive}`);
      console.log('\n');
      */

      return {
        ...item,
        pageLinkPath,
        isTabActive,
        isHomeTabActive
      };
    });
    setNavItems(updatedNavItems);
  }, [location.pathname, i18n.language, t, navigate]);

  function handleLinkClick () {
    setBurgerButtonState(false);
    scrollToTop();
  }

  return (
    <div id='menuNav'>
      <StyledUlMenu burgerButtonState={burgerButtonState} id='menuUl'>
        {navItems.map((item) => {
          if (item.dropDown) {
            return (
              <li key={item.pageLink}>
                <Dropdown
                  dropDownTitle={item.pageLinkTitle}
                  options={item.dropDownOptions}
                  links={item.dropDownOptionsLinks}
                  handleCloseBurger={() => setBurgerButtonState(false)}
                  isActive={ item.isActive}
                />
              </li>
            );
          } else {
            return (
              <li key={item.pageLink} >
                <NavLink
                  to={item.pageLink ? item.pageLinkPath : `/${i18n.language}`}
                  className={item.pageLinkTitle === 'Home' ? (item.isHomeTabActive ? 'activeUnderline' : '') : (item.isTabActive ? 'activeUnderline' : '')}
                  onClick={handleLinkClick}
                >
                  {t(item.pageLinkTitle)}
                </NavLink>
              </li>
            );
          }
        })}
        <LanguageMenu />
      </StyledUlMenu>
    </div>
  );
}

export default NavMenu;
