import PropTypes from 'prop-types';

export const gearDataPropTypes = PropTypes.objectOf(
  PropTypes.string
);

export const gearCommonCharacteristicPropTypes = PropTypes.shape({
  Brand: PropTypes.string,
  Price: PropTypes.number,
  PriceUnity: PropTypes.string,
  Colors: PropTypes.arrayOf(PropTypes.string),
  Weight: PropTypes.number,
  WeightUnity: PropTypes.string
});

export const deployedDimensionCharacteristicPropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
);

export const compactedDimensionCharacteristicPropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
);

export const specificCharacteristicPropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.string)
  ])
);

export const statisticsPropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
);

export const gearItemPropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
    PropTypes.oneOf([null])
  ])
);
