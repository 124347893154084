// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import ListTableConstructionDisplay from '../../components/List/Tab/Display/listTableConstruction_Display';
import LoadingComponent from '../../components/LoadingComponent/Loading_Component';

// Import Functions
import { APIGetOneUserTypeList } from '../../api/List/List_API';
import { APIgetUserIDFromPseudo } from '../../api/User/User_API';
import { displayEditListPage } from '../../utils/Functions/PathFunctions/listPath_Functions';
import ListInterractionSelector from '../../components/List/Tab/Display/listInteractionSelector';

// Import Constants

function ListDetailDisplayPage ({ userConcerned, listType }) {
  console.log('ListDetailDisplayPage');
  const { userPseudo, listID } = useParams();
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const [listTagDeletion, setListTagDeletion] = useState(false);
  const [listTagEdition, setListTagEdition] = useState(false);

  const [listCharacteristics, setListCharacteristics] = useState(null);
  const [shouldDisplayListEdit, setShouldDisplayListEdit] = useState(false);

  useEffect(() => {
    const fetchListData = async () => {
      setListCharacteristics(null);
      console.log('RESET');
      const userID = userConcerned === 'Me' ? currentUserData.userProfile.User_ID : await APIgetUserIDFromPseudo(userPseudo);
      const listData = await APIGetOneUserTypeList(userID, listID, listType);
      setListCharacteristics(listData);
    };

    fetchListData();
  }, [listTagDeletion, listTagEdition, listType]);

  useEffect(() => {
    console.log('listTagDeletion update');
    setListTagDeletion(false);
  }, [listTagDeletion]);

  useEffect(() => {
    console.log('listTagEdition update');
    setListTagEdition(false);
  }, [listTagEdition]);

  useEffect(() => {
    setShouldDisplayListEdit(displayEditListPage(currentUrl, t, i18n));
  }, [navigate]);

  return (
    <>
      {shouldDisplayListEdit
        ? (
            <Outlet />
          )
        : (
            listCharacteristics !== null
              ? (
                <>
                  <ListInterractionSelector listCharacteristics={listCharacteristics} userConcerned={userConcerned}/>
                  <ListTableConstructionDisplay
                    listCharacteristics={listCharacteristics}
                    listTagDeletion={listTagDeletion}
                    setListTagDeletion={setListTagDeletion}
                    listTagEdition={listTagEdition}
                    setListTagEdition={setListTagEdition}
                  />
                </>
                )
              : (
                <LoadingComponent/>
                )
          )
      }
    </>
  );
}

ListDetailDisplayPage.propTypes = {
  userConcerned: PropTypes.string.isRequired,
  listType: PropTypes.string.isRequired
};

export default ListDetailDisplayPage;
