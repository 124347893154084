// Deployed Shape
// Square
export const deployedSquareShapeChoiceArray = ['Square', 'Rectangle'];

// Triangle
export const deployedTriangleShapeChoiceArray = ['ScaleneTriangle', 'IsoscelesTriangle', 'EquilateralTriangle'];

// Parallelepiped
export const deployedParallelepipedShapeChoiceArray = ['Square', 'Rectangle', 'Hexagon', 'Octogon', 'Trapezium'];

// Circular
export const deployedCircularShapeChoiceArray = ['Circle'];

// Specific
// Tent Awning
export const deployedShapeTentAwning = ['Triangle', 'Trapezium'];
// Tent
export const deployedShapeTent = ['Square', 'Rectangle', 'Hexagonal', 'Octogon', 'Circle'];
// Tarp
export const deployedShapeTarp = ['Square', 'Rectangle', 'Hexagon', 'Triangle'];
// SleepingBeg
export const deployedShapeSleepingBag = ['Rectangle', 'Mummy'];
// Mattress
// Mattress Shape
export const deployedShapeMattress = ['Rectangle', 'Ergonomic'];
