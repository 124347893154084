// Import Assets
// Bedding
import BeddingCategoryIllustration from '../../assets/Illustrations/Equipment/Bedding/Bedding_Category_Illustration.jpg';
// SubCategory
import HammockSubCategoryIllustration from '../../assets/Illustrations/Equipment/Bedding/Hammock/Hammock_Illustration.jpg';
import MattressSubCategoryIllustration from '../../assets/Illustrations/Equipment/Bedding/Mattress/Mattress_Illustration.jpg';
import SleepingBagSubCategoryIllustration from '../../assets/Illustrations/Equipment/Bedding/SleepingBag/SleepingBag_Illustration.jpg';
import BeddingAccessoryCategoryIllustration from '../../assets/Illustrations/Equipment/Bedding/Accessory/BeddingAccessory_Illustration.jpg';
// Accessory
import PegAccessoryCategoryIllustration from '../../assets/Illustrations/Equipment/Shelter/Accessory/Peg_Illustration.jpg';
import RopeAccessoryCategoryIllustration from '../../assets/Illustrations/Equipment/Shelter/Accessory/Rope_Illustration.jpg';

// Shelter
import ShelterCategoryIllustration from '../../assets/Illustrations/Equipment/Shelter/Shelter_Category_Illustration.jpg';
// SubCategory
import TarpSubCategoryIllustration from '../../assets/Illustrations/Equipment/Shelter/Tarp/Tarp_Illustration.jpg';
import TentSubCategoryIllustration from '../../assets/Illustrations/Equipment/Shelter/Tent/Tent_Illustration.jpg';
import ShelterAccessoryCategoryIllustration from '../../assets/Illustrations/Equipment/Shelter/Accessory/ShelterAccessory_Illustration.jpg';
// Accessory
import MosquitoNetAccessoryIllustration from '../../assets/Illustrations/Equipment/Bedding/Accessory/MosquitoNet_Illustration.jpg';

// Cooking
import CookingCategoryIllustration from '../../assets/Illustrations/Equipment/Cooking/Cooking_Illustration.jpg';
// SubCategory
import StoveSubCategoryIllustration from '../../assets/Illustrations/Equipment/Cooking/Stove/Stove_Illustration.jpg';
import CookingSubCategoryIllustraton from '../../assets/Illustrations/Equipment/Cooking/Cooking/Cooking_Illustration.jpg';

// Portage
import PortageCategoryIllustration from '../../assets/Illustrations/Equipment/Portage/Portage_Illustration.jpg';
// SubCategory
import Minus30LSubCategoryIllustration from '../../assets/Illustrations/Equipment/Portage/Minus30L/Minus30L_Illustration.jpg';
import Plus30LSubCategoryIllustration from '../../assets/Illustrations/Equipment/Portage/Plus30L/Plus30L_Illustration.jpg';

// Exports
// Bedding
export const beddingCategoryIllustration = BeddingCategoryIllustration;
// SubCategory
export const hammockSubCategoryIllustration = HammockSubCategoryIllustration;
export const mattressSubCategoryIllustration = MattressSubCategoryIllustration;
export const sleepingBagSubCategoryIllustration = SleepingBagSubCategoryIllustration;
export const beddingAccessoryCategoryIllustration = BeddingAccessoryCategoryIllustration;
// Accessory
export const pegAccessoryCategoryIllustration = PegAccessoryCategoryIllustration;
export const ropeAccessoryCategoryIllustration = RopeAccessoryCategoryIllustration;

// Shelter
export const shelterCategoryIllustration = ShelterCategoryIllustration;
// SubCategory
export const tarpSubCategoryIllustration = TarpSubCategoryIllustration;
export const tentSubCategoryIllustration = TentSubCategoryIllustration;
export const shelterAccessoryCategoryIllustration = ShelterAccessoryCategoryIllustration;
// Accessory
export const mosquitoNetAccessoryIllustration = MosquitoNetAccessoryIllustration;

// Cooking
export const cookingCategoryIllustration = CookingCategoryIllustration;
// SubCategory
export const stoveSubCategoryIllustration = StoveSubCategoryIllustration;
export const cookingSubCategoryIllustration = CookingSubCategoryIllustraton;

// Portage
export const portageCategoryIllustration = PortageCategoryIllustration;
// SubCategory
export const minus30LSubCategoryIllustration = Minus30LSubCategoryIllustration;
export const plus30LSubCategoryIllustration = Plus30LSubCategoryIllustration;
