// Tent
// Amount of person
export const tentPersonChoiceArray = ['1', '2', '3', '4', '5'];

// Number of doors
export const tentDoorChoiceArray = ['1', '2', '3'];

// Type
export const tentTypeChoiceArray = ['Tunnel', 'Dome'];

// Wall
export const tentWallChoiceArray = ['Single', 'Double'];

// Ventilation
export const tentVentilationChoiceArray = ['1', '2', '3', '4'];

// Awning
export const tentAwningChoiceArray = [0, 1, 2];

// IngressProtection
export const tentIngressProtectionChoiceArray = ['IPX0', 'IPX1', 'IPX2', 'IPX3', 'IPX4', 'IPX5', 'IPX6', 'IPX7', 'IPX8'];

// Common Shelter
// Impermeability
export const impermeabilityChoiceArray = [0, 500, 1000, 1500, 2000, 3000, 5000, 8000, 10000, 20000];
/*
impermeability[mm] = [
  0,      # Non imperméable
  500,    # Faiblement imperméable
  1000,   # Moyennement imperméable (convient pour des vêtements légers, abris temporaires)
  1500,   # Bonne imperméabilité (convient pour des vêtements de pluie standards)
  2000,   # Très bonne imperméabilité (vêtements de pluie performants, tentes de camping)
  3000,   # Excellente imperméabilité (conditions météorologiques difficiles)
  5000,   # Très haute imperméabilité (conditions extrêmes)
  10000,  # Ultra haute imperméabilité (environnements très humides et pluvieux)
  20000   # Maximum pratique pour l'imperméabilité des tissus
]
*/

// Breathability
export const breathabilityChoiceArray = [
  0, // Non respirant
  2000, // Faiblement respirant (convient pour des vêtements et équipements de protection basiques)
  5000, // Moyennement respirant (convient pour des vêtements de pluie et de sport)
  10000, // Hautement respirant (convient pour des activités sportives intenses)
  15000, // Très hautement respirant (convient pour des conditions exigeantes, alpinisme, ski de randonnée)
  20000, // Ultra respirant (performances maximales pour activités extrêmes)
  30000 // Respiration optimale (utilisé dans des matériaux techniques de pointe)
];

// Tissu Density
export const tissuDensityChoiceArray = [
  10, // Ultra-léger (utilisé pour des tissus très fins comme les voiles de parapente)
  20, // Très léger (utilisé pour des tissus légers comme ceux des sacs de couchage ultralégers)
  40, // Léger (utilisé pour des vêtements légers et des accessoires de camping)
  70, // Moyen (utilisé pour des vêtements de pluie et des vestes légères)
  100, // Relativement lourd (utilisé pour des vêtements plus robustes, sacs à dos légers)
  190,
  210, // Lourd (utilisé pour des sacs à dos robustes, tentes)
  420, // Très lourd (utilisé pour des sacs de voyage, équipements militaires)
  1000, // Ultra lourd (utilisé pour des tissus très résistants, bâches industrielles)
  1680 // Maximum pratique pour la densité des tissus très résistants
];

// Sewing
export const sewingChoiceArray = ['Iron-on-strip', 'Heat-sealed-strip'];

// Thread Count
export const threadCountChoiceArray = [100, 120, 140, 160, 180, 200, 210, 220, 240, 260, 280, 300, 320, 340, 360, 380, 400, 450, 500, 600, 700, 800];
