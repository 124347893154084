// Import React Libraries
import React, { useEffect, useState } from 'react';

// Import PropTypes
import PropTypes from 'prop-types';
import { listPropTypes } from '../../../../datas/PropTypes/listPropTypes';

// Import Components
import LoadingOverlayComponent from '../../../LoadingComponent/LoadingOverlay_Component';
import ListTagTableDisplay from '../../../ListTag/listTagTableDisplay';
import PackListTagTableDisplay from './PackListTag/PackListTagTableDisplay';

// Import Functions
import { APIGetListTags } from '../../../../api/List/ListTag_API';
import { APIGetPackListTags } from '../../../../api/List/PackListTag_API';

// Import Style
import ListCommonTableDisplay from './Common/ListCommonTable_Display';

// Import Constants

function ListTableConstructionDisplay (
  {
    listCharacteristics,
    listTagDeletion, setListTagDeletion,
    listTagEdition, setListTagEdition
  }) {
  console.log('ListTableConstructionDisplay');

  // APIGetUserListTag
  const [gettingListTagStatus, setGettingListTagStatus] = useState(false);
  const [listTags, setListTags] = useState(null);
  const [packListTags, setPackListTags] = useState(null);

  useEffect(() => {
    async function APICall () {
      try {
        // PackListTags
        if (listCharacteristics.Informations.Type === 'SumList') {
          setPackListTags(null);
          console.log('PackListTags API CALL');
          const packListTagsReturned = await APIGetPackListTags(listCharacteristics._id);
          console.log(`packLisTagsReturned : ${packListTagsReturned}`);
          setPackListTags(packListTagsReturned);
        }
        // ListTags
        setListTags(null);
        const lisTagsReturned = await APIGetListTags(listCharacteristics._id, listCharacteristics.Informations.Type);
        setListTags(lisTagsReturned);
        console.log(`lisTagsReturned : ${lisTagsReturned}`);
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }
    setGettingListTagStatus(true);
    APICall();
    setGettingListTagStatus(false);
  }, [listTagDeletion, listTagEdition]);

  return (
    <>
      <ListCommonTableDisplay listCharacteristics={listCharacteristics}/>
      {
        listCharacteristics.Informations.Type === 'SumList' && packListTags !== null && (
          packListTags.map((packListTag, index) => (
            <PackListTagTableDisplay
              key={`${packListTag.PackList.WebAppID}-${index}`}
              packListTag={packListTag}
              setListTagDeletion={setListTagDeletion}
              setListTagEdition={setListTagEdition}
            />
          ))
        )
      }
      {
        listTags !== null && listTags.map((listTag, index) => (
          <ListTagTableDisplay
            key={`${listTag.List.WebAppID}-${index}`}
            listTag={listTag}
            setListTagDeletion={setListTagDeletion}
            setListTagEdition={setListTagEdition}
          />
        ))
      }
      {
        gettingListTagStatus === true
          ? <LoadingOverlayComponent/>
          : <></>
      }
    </>
  );
}

ListTableConstructionDisplay.propTypes = {
  listCharacteristics: listPropTypes.isRequired,
  listTagDeletion: PropTypes.bool.isRequired,
  setListTagDeletion: PropTypes.func.isRequired,
  listTagEdition: PropTypes.bool.isRequired,
  setListTagEdition: PropTypes.func.isRequired
};

export default ListTableConstructionDisplay;
