// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faCheck, faXmark, faArrowRightArrowLeft } from '@fortawesome/free-solid-svg-icons';

// Import PropTypes
import { gearItemPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import LoadingOverlayComponent from '../../../../LoadingComponent/LoadingOverlay_Component';

// Import Functions
import { APIDeleteOwnerTag, APIGetOwnerTag, APIPostOwnerTag } from '../../../../../api/OwnerTag/OwnerTag_API';

// Import Style
import {
  GearCommonTR,
  GearCommonTD,
  GearCommonIconContainer,
  GearCommonIcon,
  GearCommonButton,
  GearCommonTable
} from '../../../../../utils/Style/js/GearTable/GearCommonTable_Style';
import ColGroup12 from '../../ColGroup12';
import { GearTableBody } from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearOwnerTagTableDisplay ({ item }) {
  const { t } = useTranslation();
  /*
  console.log('GearCommonCharacteristicTableDisplay');
  console.log('gearCommonCharacteristic : ', gearCommonCharacteristic);
  */
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  const [ownerTagStatus, setOwnerTagStatus] = useState(false);

  const ownerTagData = {
    Owner: currentUserData.userProfile.User_ID,
    GearTypeCategory: item.Informations.TypeCategory,
    GearCategory: item.Informations.Category,
    GearSubCategory: item.Informations.SubCategory,
    GearAccessoryCategory: item.Informations.AccessoryCategory,
    MongoDbId: item._id
  };
  // console.log('data : ', ownerTagData);

  const handleOwnerTagManagement = async () => {
    // console.log('handleOwnerTagManagement');
    // console.log(`ownerTagStatus : ${ownerTagStatus}`);
    if (ownerTagStatus === false) {
      try {
        setOwnerTagStatus(null);
        const serverResponse = await APIPostOwnerTag(ownerTagData);
        // console.log('serverResponse : ', serverResponse);
        setOwnerTagStatus(serverResponse);
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    } else if (ownerTagStatus === true) {
      try {
        setOwnerTagStatus(null);
        const serverResponse = await APIDeleteOwnerTag(ownerTagData);
        // console.log('serverResponse : ', serverResponse);
        setOwnerTagStatus(serverResponse);
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    } else {
      // console.error('ownerTagStatus undefined');
    }
  };

  useEffect(() => {
    async function fetchOwnerTag () {
      try {
        setOwnerTagStatus(await APIGetOwnerTag(ownerTagData));
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }

    fetchOwnerTag();
  }, []);

  /*
  useEffect(() => {
    console.log('useEffect');
    console.log('ownerTagStatus : ', ownerTagStatus);
  }, [ownerTagStatus]);
  */

  return (
    <GearCommonTable style={{ margin: '10px 0' }}>
      <ColGroup12/>
      <GearTableBody>
      <GearCommonTR>
        <GearCommonTD colSpan={6}>
          {`${t('Possessed')} :`}
        </GearCommonTD>
        <GearCommonTD colSpan={2}>
          <GearCommonIconContainer style={ownerTagStatus ? { border: '2px solid black' } : { border: '1px solid black' }}>
            <GearCommonIcon
              icon={faCheck}
              style={ownerTagStatus ? { color: 'lightGreen' } : { color: 'grey' }}
            />
          </GearCommonIconContainer>
        </GearCommonTD>
        <GearCommonTD colSpan={2}>
          <GearCommonButton onClick={() => handleOwnerTagManagement()}>
            <GearCommonIcon icon={faArrowRightArrowLeft}/>
          </GearCommonButton>
        </GearCommonTD>
        <GearCommonTD colSpan={2}>
          <GearCommonIconContainer style={ownerTagStatus ? { border: '1px solid black' } : { border: '2px solid black' }}>
            <GearCommonIcon
              icon={faXmark}
              style={ownerTagStatus ? { color: 'grey' } : { color: 'red' }}
            />
          </GearCommonIconContainer>
        </GearCommonTD>
      </GearCommonTR>
      { ownerTagStatus === null
        ? <LoadingOverlayComponent/>
        : <></>
      }
    </GearTableBody>
    </GearCommonTable>
  );
}
GearOwnerTagTableDisplay.propTypes = {
  item: gearItemPropTypes
};

export default GearOwnerTagTableDisplay;
