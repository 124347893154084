// Import Style
import styled from 'styled-components';

export const GearFunctionnalityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const GearFunctionnalityDescriptionSummary = styled.div`
`;

export const GearFunctionnalityContainer = styled.div`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  padding: 5px;

  width: 90%;
  
  position: absolute;
  z-index: 1;
  top: 100%;

  opacity: ${props => (props.show ? 1 : 0)};
  transition: opacity 0.3s;

  background-color: white;

  border-radius: 5px;
  border: 1px solid black;

  text-align: center;

`;
