// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import {
  gearDataPropTypes
} from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';

// Import Functions
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Datas
import { meshSizeChoiceArray, yesNoChoiceArray } from '../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearMosquitoNetEditRow ({ gearData, mosquitoNet, setMosquitoNet, meshSize, setMeshSize }) {
  const { t } = useTranslation();
  return (
    <>
        {mosquitoNet === 'Yes'
          ? (
            <>
              <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
                <GearTH colSpan={6}>{`${t('MosquitoNet')}`}</GearTH>
                <GearTH colSpan={6}>{`${t('MeshSize')} [${getUnit('MeshSize')}]`}</GearTH>
              </GearTR>
              <GearTR id={`gearCommonRowTD-${gearData.gearSubCategory}`}>
                <GearTD colSpan={6}>
                  <GearSelectDropDown
                    options={yesNoChoiceArray}
                    choice={mosquitoNet}
                    setChoice={setMosquitoNet}
                    noChoice='Select'
                  />
                  <input type="hidden" name="MosquitoNet" value={mosquitoNet} />
                </GearTD>
                <GearTD colSpan={6}>
                  <GearSelectDropDown
                  options={meshSizeChoiceArray}
                  choice={meshSize}
                  setChoice={setMeshSize}
                  noChoice='Select'/>
                  <input type="hidden" name="MeshSize" value={meshSize} />
                </GearTD>
              </GearTR>
            </>
            )
          : (
            <>
              <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
                <GearTH colSpan={12}>{`${t('MosquitoNet')}`}</GearTH>
              </GearTR>
              <GearTR id={`gearCommonRowTD-${gearData.gearSubCategory}`}>
                <GearTD colSpan={12}>
                  <GearSelectDropDown options={yesNoChoiceArray} choice={null} setChoice={setMosquitoNet} noChoice='Select'/>
                  <input type="hidden" name="MosquitoNet" value={mosquitoNet} />
                </GearTD>
              </GearTR>
            </>
            )}
    </>
  );
}

GearMosquitoNetEditRow.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  mosquitoNet: PropTypes.string.isRequired,
  setMosquitoNet: PropTypes.func.isRequired,
  meshSize: PropTypes.string.isRequired,
  setMeshSize: PropTypes.func.isRequired
};

export default GearMosquitoNetEditRow;
