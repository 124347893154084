// Import Assets
import {
  tarpSubCategoryIllustration,
  tentSubCategoryIllustration,
  shelterAccessoryCategoryIllustration
} from '../../Illustrations/equipment_Illustrations';

export const shelterSubCategoryList = [
  {
    typeCategory: 'Equipment',
    category: 'Shelter',
    subCategory: 'Tarp',
    text: 'Tarp-title',
    illustration: tarpSubCategoryIllustration
  },
  {
    typeCategory: 'Equipment',
    category: 'Shelter',
    subCategory: 'Tent',
    text: 'Tent-title',
    illustration: tentSubCategoryIllustration
  },
  {
    typeCategory: 'Equipment',
    category: 'Shelter',
    subCategory: 'Accessory',
    text: 'Accessory',
    illustration: shelterAccessoryCategoryIllustration
  }
];
