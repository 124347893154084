// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';
import { listPropTypes } from '../../../../datas/PropTypes/listPropTypes';

// Import Component
import LoadingOverlayComponent from '../../../LoadingComponent/LoadingOverlay_Component';

// Import Functions
import { APIgetUserPseudoFromID } from '../../../../api/User/User_API';
import { APIDeleteList } from '../../../../api/List/List_API';

// Import Style
import { GeneralButton } from '../../../../utils/Style/js/General/Button_Style';
import { ListInterractionSelectorWrapper } from '../../../../utils/Style/js/Lists/ListInterractionSelector_Style';

// Import Constants

function ListInterractionSelector ({ listCharacteristics, userConcerned }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  /*
  console.log('ListInterractionSelector');
  console.log('listCharacteristics : ', listCharacteristics);
  */

  const [pseudoCorrespondingToListCreatorID, setPseudoCorrespondingToListCreatorID] = useState(null);
  const [deletionStatus, setDeletionStatus] = useState(false);

  const userPath = userConcerned === 'Me' ? 'MyLists' : 'All';
  const linkEditPath = `/${i18n.language}/${t('Lists')}/${t(userPath)}/${t(`${listCharacteristics.Informations.Type}s`)}/${listCharacteristics.Informations.WebAppID}/${t('Edition')}`;
  const linkRedirectAfterDeletePath = `/${i18n.language}/${t('Lists')}`;

  useEffect(() => {
    const fetchListCreatorPseudo = async () => {
      const userPseudo = await APIgetUserPseudoFromID(listCharacteristics.Informations.Creator);
      setPseudoCorrespondingToListCreatorID(userPseudo);
    };
    fetchListCreatorPseudo();
  }, []);

  const handleEditionRedirection = () => {
    navigate(linkEditPath);
  };

  const handleListDeletion = async () => {
    const deleteConfirmation = window.confirm(`${t('DeleteConfirmationE')} ${t('List')}`);
    if (deleteConfirmation) {
      try {
        setDeletionStatus(true);
        const serverResponse = await APIDeleteList(listCharacteristics._id, listCharacteristics.Informations.Type);
        if (serverResponse === true) {
          navigate(linkRedirectAfterDeletePath);
        }
        setDeletionStatus(false);
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    }
  };

  return (
    <>
      {
        pseudoCorrespondingToListCreatorID !== null &&
          <ListInterractionSelectorWrapper id='listInterractionSelectorWrapper'>
          <GeneralButton onClick={() => handleEditionRedirection()}>{`${t('Edit')}`}</GeneralButton>
          <GeneralButton onClick={() => handleListDeletion()}>{`${t('Delete')}`}</GeneralButton>
        </ListInterractionSelectorWrapper>
      }
      { deletionStatus &&
        <LoadingOverlayComponent/>
      }
    </>
  );
}

ListInterractionSelector.propTypes = {
  listCharacteristics: listPropTypes.isRequired,
  userConcerned: PropTypes.string.isRequired
};

export default ListInterractionSelector;
