// Import Style
import styled from 'styled-components';

// Import Colors
import colors from '../../../Colors/colors';
import { responsiveWidthTablet, responsiveWidthMobile } from '../../../Constant';

// Import Constants

export const ListUserSelectorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const ListUserPreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  flex: 0 0 calc(23% - 10px);

  border-radius: 15px;
  box-shadow: 5px 5px 10px 5px rgba(0.1, 0, 0.1, 0.2);
  
  height: 200px;
  width: 250px;
  
  margin: 10px 16px;

  cursor: pointer;

  &:hover{
    outline: 2px solid ${colors.marineBlue};
    box-shadow: none;
  }

  @media (max-width: ${responsiveWidthTablet}px){
    flex: 0 0 calc(45% - 10px);
  }
  @media (max-width: ${responsiveWidthMobile}px){
    flex: 1 0 calc(50% - 10px);
  }
`;

export const ListUserPreviewImage = styled.img`
  width: 150px;
  height: 150px;
`;
