// Import React Libraries
import React from 'react';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import ListsTableConstructorAdd from '../../components/List/Tab/Add/ListsTableConstruction_Add';

// Import Style
// import { PageWrapper } from '../../utils/Styles';

function ListAddPage ({ listType }) {
  return (
    <>
      <ListsTableConstructorAdd listType={listType}/>
    </>
  );
}

ListAddPage.propTypes = {
  listType: PropTypes.string.isRequired
};

export default ListAddPage;
