export function displayItemDetailPage (gearData, id, currentUrl, t, i18n) {
  /*
  console.log('displayItemDetailPage');
  console.log('gearData : ', gearData);
  console.log(`id : ${id}`);
  console.log(`currentUrl : ${currentUrl}`);
  */

  const accessoryPath = `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t(gearData.AccessoryCategory)}/${id}`;
  // console.log(`accessoryPath : ${accessoryPath}`);
  const subCategoryPath = `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${id}`;
  // console.log(`subCategoryPath : ${subCategoryPath}`);

  if (gearData.SubCategory === 'Accessory') {
    if (currentUrl === accessoryPath) {
      return true;
    } else {
      return false;
    }
  } else {
    if (currentUrl === subCategoryPath) {
      return true;
    } else {
      return false;
    }
  }
}

export function displayItemEditPage (currentGear) {
  /*
  console.log('displayItemEditPage');
  console.log('currentGear : ', currentGear);
  */

  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  let accessGranted = false;

  if (currentUserData.userProfile.User_Rank === 'ADMIN') {
    accessGranted = true;
  } else if (currentGear.Statistics.AddedBy === currentUserData.userProfile.User_ID && currentGear.Status.Validated === false) {
    accessGranted = true;
  } else {
    accessGranted = false;
  }

  // console.log(`accessGranted : ${accessGranted}`);
  return accessGranted;
};

// Item Selector Page
export function displayItemSelectorPage (gearData, currentUrl, t, i18n) {
  /*
  console.log('displayItemSelectorPage');
  console.log('gearData : ', gearData);
  console.log(`currentUrl : ${currentUrl}`);
  */

  if (gearData.SubCategory === 'Accessory') {
    // console.log(`/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t(gearData.AccessoryCategory)}`);
    if (currentUrl === `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t(gearData.AccessoryCategory)}`) {
      return true;
    } else {
      return false;
    }
  } else {
    if (currentUrl === `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}`) {
      return true;
    } else {
      return false;
    }
  }
}

export function displayOutletSubCategory (gearTypeCategory, itemCategory, currentUrl, t, i18n) {
  // console.log(`itemCategory : ${itemCategory}`);
  // console.log(`currentUrl : ${currentUrl}`);

  if (currentUrl === `/${i18n.language}/${t(gearTypeCategory)}/${t(itemCategory)}`) {
    return true;
  } else {
    return false;
  }
}

export function displayOutletGearTypeCategory (gearTypeCategory, currentUrl, t, i18n) {
  // console.log(`gearTypeCategory : ${gearTypeCategory}`);
  // console.log(`currentUrl : ${currentUrl}`);
  if (currentUrl === `/${i18n.language}/${t(gearTypeCategory)}`) {
    return true;
  } else {
    return false;
  }
}

export function displayAccessoryCategorySelector (gearData, currentUrl, t, i18n) {
  /*
  console.log('displayAccessoryCategorySelector');
  console.log(`currentUrl : ${currentUrl}`);
  console.log('gearData : ', gearData);
  */

  if (currentUrl === `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t('Accessory')}`) {
    return true;
  } else {
    return false;
  }
};
