// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

// Import Functions
import { createLinkArrayFromPathWithoutLang, extractUrlPart } from '../../utils/Functions/PathFunctions/languagePath_Functions';
import { scrollToTop } from '../../utils/Functions/general_Functions';

// Import Styles
import { NavIndicatorContainer, NavIndicatorLink } from '../../utils/Style/js/NavIndicator/NavIndicator_Style';

// Import Constants

function NavIndicator () {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentUrl = location.pathname;
  const urlParts = extractUrlPart(currentUrl);
  const linksArray = createLinkArrayFromPathWithoutLang(currentUrl, i18n, t);

  /*
  console.log('NavIndicator');
  console.log(`currentUrl : ${currentUrl}`);
  console.log(`linksArray : ${linksArray}`);
  */

  return (
    <NavIndicatorContainer id='navIndicator'>
      {linksArray.map((link, index) => (
        <React.Fragment key={link}>
          <NavIndicatorLink to={link} onClick={() => scrollToTop()}>{urlParts[index]}</NavIndicatorLink>
          {index < linksArray.length - 1 && ' / '}
        </React.Fragment>
      ))}

    </NavIndicatorContainer>
  );
}

export default NavIndicator;
