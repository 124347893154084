// Import Style
import styled from 'styled-components';

// Import Constants
import { languageMenuContainerHeight, languageMenuContainerWidth } from '../../../Constants/Language_Constants';
import { responsiveWidthTablet } from '../../../Constant';
// import { StyledUlNav } from './js/NavStyle';

export const LanguageMenuContainer = styled.div`
  width: ${languageMenuContainerWidth}px;
  height: ${languageMenuContainerHeight}px;

  @media (max-width:${responsiveWidthTablet}px) {
    margin-top: 100px;
  }
`;
