// Import React Libraries
import React from 'react';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

// Import Functions
import { scrollToTop } from '../../utils/Functions/general_Functions';

// Import Assets
// import ArrowUp from '../../assets/Functionnal_Icon/arrowUp.png';

// Import Style
import { StyledIcon } from '../../utils/Style/GlobalStyle';
import { StyledFooter, StyledText, StyledButton, FooterSideContainer } from '../../utils/Style/FooterStyle';
import { StyledP } from '../../utils/Style/js/General/Font_Style';

// Import Constants

function Footer () {
  const { t } = useTranslation();
  const currentYear = format(new Date(), 'yyyy');
  const handleGoToTop = () => {
    scrollToTop();
  };

  return (
    <StyledFooter id='footer'>
        <FooterSideContainer/>
        <StyledText>
            <StyledP>{`Copyright © ${currentYear} ${t('AllRightsReserved')}`}</StyledP>
        </StyledText>
        <FooterSideContainer id='scrollToTopButtonContainer'>
          <StyledButton id="scrollToTopButton" onClick={handleGoToTop}>
            <StyledIcon icon={faChevronUp}/>
          </StyledButton>
        </FooterSideContainer>
    </StyledFooter>
  );
}

export default Footer;
