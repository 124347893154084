// Import React Libraries
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { userProfilePropTypes } from '../../../../datas/PropTypes/userPropTypes';
import { errorMessagesProptypes } from '../../../../datas/PropTypes/errorMessagesPropTypes';

// Import Component
import ColGroup12 from '../../../Gear/Tab/ColGroup12';
import { GearSelectDropDown } from '../../../Gear/SelectDropDown/GearSelectDropDown';

// Import Functions
import { defineAvatarRowSpan, userProfileFormValidTest } from '../../../../utils/Functions/User/UserForm_Functions';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../utils/Style/js/Account/UserDataTableDisplay_Style';

import { GearFormInput } from '../../../../utils/Style/js/GearForm/GearForm_Style';
import { DeleteButton, FileInputLabel, ImageContainer, ImageSelectorContainer } from '../../../../utils/Style/js/GearTable/TablePicture_Style';

// Import Constants
import { userRankChoiceArray } from '../../../../datas/User/User_AddEdit_ChoiceArrays';

function UserProfileAdd ({ onSubmit, onError, temporaryUserProfile, errorMessages }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  // Check if there is a user being added
  // Solution if the data persistance is researched
  /*
  let temporaryUserData;
  let temporaryUserProfile = null;
  if (sessionStorage.getItem('temporaryUserData')) {
    temporaryUserData = JSON.parse(sessionStorage.getItem('temporaryUserData'));
    console.log('temporaryUserData : ', temporaryUserData);
    temporaryUserProfile = temporaryUserData.userProfile;
  } */

  const [userRank, setUserRank] = useState('USER');
  const [selectedAvatar, setSelectedAvatar] = useState(null);
  const [userID, setUserID] = useState(temporaryUserProfile !== null ? temporaryUserProfile.User_ID : null);
  const [userPseudo, setUserPseudo] = useState(temporaryUserProfile !== null ? temporaryUserProfile.User_Pseudo : null);
  const [userPassword, setUserPassword] = useState(temporaryUserProfile !== null ? temporaryUserProfile.User_Password : null);

  const [formDataErrorMessages, setFormDataErrorMessages] = useState({
    User_ID: '',
    User_Pseudo: '',
    User_Password: ''
  });

  const [avatarRowSpan, setAvatarRowSpan] = useState(7);

  // console.log('errorMessages : ', errorMessages);

  const handleSubmit = (event) => {
    console.log('handleSubmit UserProfileAdd');
    event.preventDefault();
    const formData = new FormData(event.target);
    formData.append('User_Avatar', selectedAvatar);
    const isUserProfileFormValid = userProfileFormValidTest(formDataErrorMessages, setFormDataErrorMessages, formData, 'Add');
    const data = Object.fromEntries(formData.entries());

    // console.log(data);
    if (isUserProfileFormValid) {
      console.log(`isUserProfileFormValid : ${isUserProfileFormValid}`);
      if (onSubmit) {
        onSubmit(data);
      }
    } else {
      console.log(`isUserProfileFormValid : ${isUserProfileFormValid}`);
      if (onError) {
        onError(formDataErrorMessages);
      }
    }
  };

  useEffect(() => {
    // console.log('formDataErrorMessages : ', formDataErrorMessages);
    setAvatarRowSpan(defineAvatarRowSpan(formDataErrorMessages, errorMessages));
  }, [formDataErrorMessages, errorMessages]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedAvatar(file);
  };

  const deleteImage = (index) => {
    setSelectedAvatar(null);
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <UserDataTable>
        <ColGroup12/>
        <UserDataTableBody>
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('Rank')}`}</UserDataTH>
            <UserDataTH colSpan={6}>{`${t('Avatar')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              <GearSelectDropDown options={userRankChoiceArray} choice={userRank} setChoice={setUserRank} noChoice={`${t('Rank')}`}/>
              <input type="hidden" name="User_Rank" value={userRank} />
            </UserDataTD>
            <UserDataTD colSpan={6} rowSpan={avatarRowSpan}>
              <ImageSelectorContainer>
                <input
                  type="file"
                  accept="image/jpeg, image/jpg"
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: 'none' }} // Hide the default file input
                  id='User_Avatar-FileInput'
                />
                {/* Custom label to trigger file input */}
                {selectedAvatar && (
                  <ImageContainer>
                    <img
                      src={URL.createObjectURL(selectedAvatar)}
                      alt='preview-Avatar'
                      style={{ width: '100px', height: '100px' }}
                    />
                    <DeleteButton onClick={() => deleteImage(selectedAvatar)}>
                      &times;
                    </DeleteButton>
                  </ImageContainer>
                )}
                <FileInputLabel htmlFor='User_Avatar-FileInput'>
                  {selectedAvatar ? selectedAvatar.name : `${t('SelectUserAvatar')}`}
                </FileInputLabel>
              </ImageSelectorContainer>
            </UserDataTD>
          </UserDataTR>
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('Identifier')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              <GearFormInput
                type="text"
                name="User_ID"
                placeholder={`${t('Identifier')}`}
                value= {userID}
                onChange={(e) => setUserID(e.target.value)}
              />
            </UserDataTD>
          </UserDataTR>
          {errorMessages && errorMessages.includes('User_ID')
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('UserIDUnavailable')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.User_ID === 'FormatUserID'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('FormatUserID')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.User_ID === 'MissingUserID'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('MissingUserID')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('Pseudo')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              <GearFormInput
                type="text"
                name="User_Pseudo"
                placeholder={`${t('Pseudo')}`}
                value= {userPseudo}
                onChange={(e) => setUserPseudo(e.target.value)}
              />
            </UserDataTD>
          </UserDataTR>
          {errorMessages && errorMessages.includes('User_Pseudo')
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('UserPseudoUnavailable')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.User_Pseudo === 'FormatUserPseudo'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('FormatUserPseudo')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.User_Pseudo === 'MissingUserPseudo'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('MissingUserPseudo')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('Password')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              <GearFormInput
                type="text"
                name="User_Password"
                placeholder={`${t('Password')}`}
                value= {userPassword}
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </UserDataTD>
          </UserDataTR>
          {formDataErrorMessages.User_Password === 'MissingUserPassword'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('MissingUserPassword')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
        </UserDataTableBody>
      </UserDataTable>
    </form>
  );
}

UserProfileAdd.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  temporaryUserProfile: userProfilePropTypes,
  errorMessages: errorMessagesProptypes
};

export default forwardRef(UserProfileAdd);
