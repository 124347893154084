// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
// import PropTypes from 'prop-types';
import { gearDataPropTypes, gearItemPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../ColGroup12';

// Import Functions
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearCommonTable,
  GearCommonTR,
  GearCommonTD
} from '../../../../../utils/Style/js/GearTable/GearCommonTable_Style';
import { GearTableBody } from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { convertIfNecessarySI } from '../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Constants

function GearCommonCharacteristicTableDisplay ({ gearData, item }) {
  /*
  console.log('GearCommonCharacteristicTableDisplay');
  console.log('item : ', item);
  */
  const { t } = useTranslation();

  return (
    <GearCommonTable id='gearCommonCharacteristicTableDisplay'>
      <ColGroup12/>
      <GearTableBody>
        <GearCommonTR>
          <GearCommonTD colSpan={12}>{item.Informations.Name}</GearCommonTD>
        </GearCommonTR>
        <GearCommonTR>
          <GearCommonTD colSpan={12}>{item.Informations.Brand}</GearCommonTD>
        </GearCommonTR>
        <GearCommonTR>
          <GearCommonTD colSpan={12}>
            {item.Metrics.Price === null ? '' : `${item.Metrics.Price.toFixed(2)} ${getUnit('Currency')}`}
          </GearCommonTD>
        </GearCommonTR>
        <GearCommonTR>
          <GearCommonTD colSpan={12}>
            {item.Metrics.Weight === null ? '' : `${convertIfNecessarySI('Weight', item.Metrics.Weight).convertedValue} ${convertIfNecessarySI('Weight', item.Metrics.Weight).convertedUnit}`}
          </GearCommonTD>
        </GearCommonTR>
        <GearCommonTR>
          {item.Informations.Colors.length === 1 && (
            <GearCommonTD colSpan={12}>{t(item.Informations.Colors[0])}</GearCommonTD>
          )}
          {item.Informations.Colors.length === 2 && (
            <>
              <GearCommonTD colSpan={6}>{t(item.Informations.Colors[0])}</GearCommonTD>
              <GearCommonTD colSpan={6}>{t(item.Informations.Colors[1])}</GearCommonTD>
            </>
          )}
          {item.Informations.Colors.length === 3 && (
            <>
              <GearCommonTD colSpan={4}>{t(item.Informations.Colors[0])}</GearCommonTD>
              <GearCommonTD colSpan={4}>{t(item.Informations.Colors[1])}</GearCommonTD>
              <GearCommonTD colSpan={4}>{t(item.Informations.Colors[2])}</GearCommonTD>
            </>
          )}
        </GearCommonTR>
      </GearTableBody>
    </GearCommonTable>
  );
}
GearCommonCharacteristicTableDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearItemPropTypes
};

export default GearCommonCharacteristicTableDisplay;
