// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
// import PropTypes from 'prop-types';

// Import Functions
import { scrollToTop } from '../../utils/Functions/general_Functions';

// Import Datas
import { crowdIllustration, defaultProfilePicture } from '../../datas/Illustrations/profile_Illustrations';

// Import Style
import { StyledTitleH2 } from '../../utils/Style/GlobalStyle';
import { PictureContainerSelector } from '../../utils/Style/PreviewStyle';
import { ListUserSelectorContainer, ListUserPreviewContainer, ListUserPreviewImage } from '../../utils/Style/js/Lists/ListUserSelector_Styles';

function UserListSelectorComponent () {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  console.log('currentUserData : ', currentUserData);

  const pathUserLists = `/${i18n.language}/${t('Lists')}/${t('MyLists')}/`;
  const pathAllLists = `/${i18n.language}/${t('Lists')}/${t('AllLists')}/`;

  function handleOnClick (path) {
    scrollToTop();
    navigate(path);
  }

  return (
    <ListUserSelectorContainer id='productSubCategorySelectorContainer'>

      <ListUserPreviewContainer id='productPreviewContainer' onClick={() => handleOnClick(pathUserLists)}>
        <>
        <PictureContainerSelector id='pictureContainerSelector'>
          <ListUserPreviewImage
            src={currentUserData.userProfile.User_Avatar ? currentUserData.userProfile.User_Avatar : defaultProfilePicture}
            alt='User_Avatar'
          />
        </PictureContainerSelector>
        <StyledTitleH2>{t('MyLists')}</StyledTitleH2>
        </>
      </ListUserPreviewContainer>

      <ListUserPreviewContainer id='productPreviewContainer' onClick={() => handleOnClick(pathAllLists)}>
        <>
        <PictureContainerSelector id='pictureContainerSelector'>
          <ListUserPreviewImage src={crowdIllustration} alt='Crowd_Avatar'/>
        </PictureContainerSelector>
        <StyledTitleH2>{t('AllListsTitle')}</StyledTitleH2>
        </>
      </ListUserPreviewContainer>

    </ListUserSelectorContainer>
  );
}

export default UserListSelectorComponent;
