// Import React Libraries
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Context

// Import Components
import GearAccessoryCategorySelectorComponent from '../../components/Gear/gearAccessoryCategorySelector_Component';

// Import Functions
import { displayAccessoryCategorySelector } from '../../utils/Functions/PathFunctions/gearPath_Functions';

// Import Constants

function GearAccessoryCategorySelectorPage ({ gearTypeCategory, gearCategory, gearSubCategory }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentUrl = location.pathname;

  const gearData = {
    TypeCategory: gearTypeCategory,
    Category: gearCategory,
    SubCategory: gearSubCategory
  };

  const shouldDisplayGearAccessoryCategorySelectorPage = displayAccessoryCategorySelector(gearData, currentUrl, t, i18n);
  // const referenceDiv = useRef(null);
  /*
  console.log(`currentUrl : ${currentUrl}`);
  console.log(`shouldDisplayPageContainer ! ${shouldDisplayPageContainer}`);
  */

  console.log(`shouldDisplayGearAccessoryCategorySelectorPage : ${shouldDisplayGearAccessoryCategorySelectorPage}`);

  return (
    <>
      {shouldDisplayGearAccessoryCategorySelectorPage
        ? (
            <GearAccessoryCategorySelectorComponent gearData={gearData} />
          )
        : (
            <Outlet/>
          )
      }
    </>
  );
}

GearAccessoryCategorySelectorPage.propTypes = {
  gearTypeCategory: PropTypes.string.isRequired,
  gearCategory: PropTypes.string.isRequired,
  gearSubCategory: PropTypes.string.isRequired
};

export default GearAccessoryCategorySelectorPage;
