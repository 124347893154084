// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Component
import ListPreviewTableDisplay from './Tab/Display/Common/listPreviewTableDisplay';
import LoadingComponent from '../LoadingComponent/Loading_Component';

// Import Functions
import { getAllTypeListsCreatedByOneUser } from '../../api/List/List_API';

// Import Constants
import { singularValues } from '../../utils/Constants/Gear_Constants';

// Import Styles
import { StyledH1, TitleContainer } from '../../utils/Style/js/General/Font_Style';
import { GeneralButton, GeneralButtonContainer } from '../../utils/Style/js/General/Button_Style';

function MyListSelectorComponent ({ listType }) {
  console.log('MyListSelectorComponent');
  console.log(`listType : ${listType}`);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [sumListArray, setSumListArray] = useState(null);
  const [packListArray, setPackListArray] = useState(null);
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  const userConcerned = 'Me';

  useEffect(() => {
    async function fetchData () {
      try {
        if (listType === 'SumList') {
          setSumListArray(null);
          const sumListArray = await getAllTypeListsCreatedByOneUser(currentUserData.userProfile.User_ID, 'SumList');
          setSumListArray(sumListArray);
        }
        if (listType === 'PackList') {
          setPackListArray(null);
          const packListArray = await getAllTypeListsCreatedByOneUser(currentUserData.userProfile.User_ID, 'PackList');
          setPackListArray(packListArray);
        }

        /*
        console.log('sumListArray : ', sumListArray);
        console.log('packListArray : ', packListArray);
        */
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }
    fetchData();
  }, []);

  const handleButtonCLick = (listType) => {
    navigate(`/${i18n.language}/${t('Lists')}/${t(listType)}/${t('Creation')}`);
  };

  return (
    <div>
        { listType === 'SumList' && (
          <>
            <GeneralButtonContainer>
              <GeneralButton onClick={() => handleButtonCLick('SumLists')}>{`${t('CreateASumList')}`}</GeneralButton>
            </GeneralButtonContainer>
            <TitleContainer>
              {sumListArray !== null &&
                <StyledH1>{singularValues.includes(sumListArray.length) ? `${t('SumList')}` : `${t('SumLists')}`}{` : (${sumListArray.length})`}</StyledH1>
              }
            </TitleContainer>
            {sumListArray !== null
              ? (
                  <>
                    <ListPreviewTableDisplay listArray={sumListArray} userConcerned={userConcerned}/>
                  </>
                )
              : (
                  <LoadingComponent/>
                )
            }
          </>
        )}
        {listType === 'PackList' && (
          <>
            <GeneralButtonContainer>
              <GeneralButton onClick={() => handleButtonCLick('PackLists')}>{`${t('CreateAPackList')}`}</GeneralButton>
            </GeneralButtonContainer>
            <TitleContainer>
              {packListArray !== null &&
              <StyledH1>{singularValues.includes(packListArray.length) ? `${t('PackListTitle')}` : `${t('PackListsTitle')}`}{` : (${packListArray.length})`}</StyledH1>
              }
            </TitleContainer>
            {packListArray !== null
              ? (
                  <>
                    <ListPreviewTableDisplay listArray={packListArray} userConcerned={userConcerned}/>
                  </>
                )
              : (
                  <LoadingComponent/>
                )
            }
          </>
        )}
    </div>
  );
}

MyListSelectorComponent.propTypes = {
  userPseudo: PropTypes.string.isRequired,
  listType: PropTypes.string
};

export default MyListSelectorComponent;
