export function scrollToTop () {
  window.scrollTo({
    top: 0,
    behavior: 'auto'
  });
};

export function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
};
