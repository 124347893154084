// Import React Libraries
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';
import DisplayHelpComponent from '../../../../ToolTip/DisplayHelp_Component';

// Import Styles
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { ImageSelectorContainer, ImageContainer, DeleteButton, FileInputLabel } from '../../../../../utils/Style/js/GearTable/TablePicture_Style';

function GearImageUploaderTableEdit ({ gearData, gearName, gearIllustrationArray, onSubmit }, ref) {
  /*
  console.log('GearCommonTableImageUploaderEdit');
  console.log('gearData : ', gearData);
  console.log(`gearName : ${gearName}`);
  */

  const { t } = useTranslation();
  const formRef = useRef();

  const originalGearIllustrationArray = gearIllustrationArray;
  const [selectedImages, setSelectedImages] = useState(gearIllustrationArray);
  // console.log('originalGearIllustrationArray : ', originalGearIllustrationArray);
  // console.log('selectedImages : ', selectedImages);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    // console.log('formData : ', formData);

    // Append the selected images to the form data
    selectedImages.forEach((image, index) => {
      if (image instanceof File) {
        // console.log('instance of file detected');
        formData.append(`image-${index}`, image);
      } else {
        // console.log('url detected');
        // get the index of the image in the originalGearIllustrationArray
        const originalIndex = originalGearIllustrationArray.indexOf(image);
        formData.append(`remainingKey-${index}`, `Public/${gearData.TypeCategory}/${gearData.Category}/${gearData.SubCategory}/${gearName.replace(/\s+/g, '')}/${gearName.replace(/\s+/g, '')}-${originalIndex}`);
      }
    });

    const data = Object.fromEntries(formData.entries());
    if (onSubmit) {
      console.log('data : ', data);
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  const handleImageChange = (event, index) => {
    const files = Array.from(event.target.files);
    const newSelectedImages = [...selectedImages];
    newSelectedImages[index] = files[0]; // Store the selected file at the corresponding index
    setSelectedImages(newSelectedImages);
  };

  const deleteImage = (index) => {
    const newSelectedImages = [...selectedImages];
    // setDeletedKeysIndex([...deletedKeysIndex, index]);
    newSelectedImages.splice(index, 1);
    setSelectedImages(newSelectedImages);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearImageUploaderTableEdit-${gearData.SubCategory}`}>
        <ColGroup12 />
        <GearTableBody>
          <GearTR id={`gearImageUploaderRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>
              {t('Pictures')}
              <DisplayHelpComponent
                text1={`${t('PictureDescription')}`}
              />
            </GearTH>
          </GearTR>
          {selectedImages.length < 6 && (
            <GearTR id={`gearImageUploaderRowTDEdit-${gearData.SubCategory}`}>
              <GearTD colSpan={12}>
                <div>
                  {/* Hidden file input */}
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg"
                    onChange={(e) => handleImageChange(e, selectedImages.length)}
                    style={{ display: 'none' }} // Hide the default file input
                    id="fileInput-new"
                  />
                  {/* Custom label to trigger file input */}
                  <FileInputLabel htmlFor="fileInput-new">
                    {t('AddPicture')}
                  </FileInputLabel>
                </div>
              </GearTD>
            </GearTR>
          )}
          <GearTR id={`gearImageUploaderRowTDEdit-${gearData.SubCategory}`}>
            {selectedImages.slice(0, 3).map((image, index) => (
              <GearTD colSpan={4} key={`${image}-${index}`} id={`gearCommonRow-${gearData.SubCategory}-${index}`}>
                <ImageSelectorContainer>
                  {/* Hidden file input */}
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg"
                    onChange={(e) => handleImageChange(e, index)}
                    style={{ display: 'none' }} // Hide the default file input
                    id={`fileInput-${index}`}
                  />
                  {/* Custom label to trigger file input */}
                  {image && (
                    <ImageContainer>
                      <img
                        src={image instanceof File ? URL.createObjectURL(image) : image}
                        alt={`preview ${index}`}
                        style={{ width: '100px', height: '100px' }}
                      />
                      <DeleteButton onClick={() => deleteImage(index)}>
                        &times;
                      </DeleteButton>
                    </ImageContainer>
                  )}
                  <FileInputLabel htmlFor={`fileInput-${index}`}>
                    { /* console.log(`label : ${gearName.replace(/\s+/g, '')}-${originalGearIllustrationArray.indexOf(image)}`) */ }
                    { image instanceof File ? image.name : `${gearName.replace(/\s+/g, '')}-${originalGearIllustrationArray.indexOf(image)}` }
                  </FileInputLabel>
                </ImageSelectorContainer>
              </GearTD>
            ))}
          </GearTR>
          {selectedImages.length > 3 && (
            <GearTR id={`gearImageUploaderRowTDEdit-${gearData.SubCategory}`}>
              {selectedImages.slice(3).map((image, index) => (
                <GearTD colSpan={4} key={`${image}-${index + 3}`} id={`gearCommonRow-${gearData.SubCategory}-${index}`}>
                  <ImageSelectorContainer>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      accept="image/jpeg, image/jpg"
                      onChange={(e) => handleImageChange(e, index + 3)}
                      style={{ display: 'none' }} // Hide the default file input
                      id={`fileInput-${index + 3}`}
                    />
                    {/* Custom label to trigger file input */}
                    {image && (
                      <ImageContainer>
                        <img
                          src={image instanceof File ? URL.createObjectURL(image) : image}
                          alt={`preview ${index + 3}`}
                          style={{ width: '100px', height: '100px' }}
                        />
                        <DeleteButton onClick={() => deleteImage(index + 3)}>
                          &times;
                        </DeleteButton>
                      </ImageContainer>
                    )}
                    <FileInputLabel htmlFor={`fileInput-${index + 3}`}>
                      {image instanceof File ? image.name : `${gearName.replace(/\s+/g, '')}-${originalGearIllustrationArray.indexOf(image)}`}
                    </FileInputLabel>
                  </ImageSelectorContainer>
                </GearTD>
              ))}
            </GearTR>
          )}
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearImageUploaderTableEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  gearIllustrationArray: PropTypes.arrayOf(PropTypes.string).isRequired,
  gearName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearImageUploaderTableEdit);
