// Import React Libraries
import { format, differenceInDays } from 'date-fns';

export function getCurrentDate () {
  const currentDate = format(new Date(), 'dd/MM/yyyy');
  return currentDate;
};

export function determineIfNew (dateOfAdd) {
  const currentDate = getCurrentDate();

  const difference = differenceInDays(currentDate, dateOfAdd);

  // Determine if the object is new (within the last week)
  return difference <= 7;
};
