// Import React Libraries

// Import Style
import styled from 'styled-components';

// Import Colors
import colors from '../../../Colors/colors';

export const GeneralButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const GeneralButton = styled.button`

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  text-decoration: none;

  margin: 10px 0;

  height: 40px;
  width: 180px;

  border-radius: 10px;

  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;

  color: white;
  background: ${colors.buttonBackGround};
  box-shadow: 0 0 10px ${colors.buttonShadow};

  cursor: pointer;

  &:hover {
    color: ${colors.buttonBackGround};
    background: white;
    box-shadow: 0 0 10px ${colors.buttonShadow};
  }
`;
