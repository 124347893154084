// Import Style
import styled from 'styled-components';

// Import Colors
import colors from '../../../Colors/colors';

export const ListPreviewTable = styled.table`
  font-family: sans-serif; 
  width: 100%;

  margin: 15px 0;

  border-spacing: 4px;
  border-radius: 3px;
  border: 2px solid white;
  box-shadow: 5px 5px 10px 5px rgba(0.1, 0, 0.1, 0.2);
  
  cursor: pointer;
  
  &:hover {
    border: 2px solid ${colors.headerFooterBackGround};
  }
`;
