// Import React Libraries
import React from 'react';

function ColGroup12 () {
  return (
    <colgroup>
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
      <col style={{ width: '8.33%' }} />
    </colgroup>
  );
}

export default ColGroup12;
