// Common choice arrays
export const yesNoChoiceArray = ['Yes', 'No'];

export const leftRightChoiceArray = ['Left', 'Right'];

// Colors
export const colorsChoiceArray = ['Black', 'Grey', 'White', 'Blue', 'Purple', 'Pink', 'Red', 'Orange', 'Yellow', 'Brown', 'Green', 'OliveGreen'];

// Compacted Shape
export const compactedShapeChoiceArray = ['Rectangular', 'Cylindric'];

// Deployed Shape
export const deployedShapeChoiceArray = ['Square', 'Rectangular', 'Hexagonal'];

// Specific
// Shelter / Bedding
export const seasonChoiceArray = ['1', '2', '3', '4'];
export const meshSizeChoiceArray = ['100', '200', '400', '500', '800', '1300', '1500'];

// Shelter / Clothing
// Tissu Materials
export const tissuMaterialsChoiceArray = ['Polyethylene', 'Polyester', 'Canvas', 'Nylon', 'Ripstop', 'PVC', 'Polyurethane', 'Cotton'];

// Shelter / Bedding / Clothing
// UV Protection Factor
export const UPFChoiceArray = ['15', '20', '25', '30', '35', '40', '45', '50', '50+'];

// Shelter / Cooking
// Metal Materials
export const metalMaterialsChoiceArray = ['Aluminum'];
