// Import React Libraries
import React from 'react';

// Import Components
import EquipmentControlTableDisplay from '../../components/Gear/Tab/Display/Control/EquipmentCategoryControlTableDisplay';

// Import Styles

// Import Constants

function EquipementControlPage () {
  return (
    <>
      <EquipmentControlTableDisplay/>
    </>
  );
}

export default EquipementControlPage;
