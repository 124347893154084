// Import Functions
import {
  generateInformationsObjectFromForms,
  generateAssetsObjectFromForms,
  generateStatusObjectFromForms,
  generateMetricsObjectFromForms,
  generateCompactedObjectFromForms,
  generateStatisticsObjectFromForms
} from '../Common/CommonPropertiesFromForms_Functions';

// Import Functions

export function generateHammockObjectFromForms (form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit) {
  console.log('reworkedGenerateHammockObjectFromForms');
  console.log('form1Data : ', form1Data);
  console.log('form2Data : ', form2Data);
  console.log('form3Data : ', form3Data);
  console.log('form4Data : ', form4Data);
  console.log('form5Data : ', form5Data);
  console.log('item : ', item);

  const informationObject = generateInformationsObjectFromForms(form2Data, gearData);
  const assetsObject = generateAssetsObjectFromForms(form1Data);
  const statusObject = generateStatusObjectFromForms(form5Data, item.Status, fromAddOrEdit);
  const metricsObject = generateMetricsObjectFromForms(form2Data, form4Data);
  const compactedObject = generateCompactedObjectFromForms(form4Data);
  const statisticsObject = generateStatisticsObjectFromForms(item.Statistics, fromAddOrEdit);

  return {
    _id: item._id,
    Informations: informationObject,
    Assets: assetsObject,
    Status: statusObject,
    Metrics: metricsObject,
    Compacted: compactedObject,
    Specific: {
      Person: form3Data.Person || null,
      Type: form3Data.Type || null,
      MaxWeight: parseFloat(form3Data.MaxWeight) || null,
      StoragePocket: form3Data.StoragePocket || null,
      Hoop: form3Data.Hoop || null,
      MosquitoNet: form3Data.MosquitoNet || null,
      MeshSize: form3Data.MeshSize || null,
      PocketMattress: form3Data.PocketMattress || null,
      PocketLength: parseFloat(form3Data.PocketLength) || null,
      PocketWidth: parseFloat(form3Data.PocketWidth) || null,
      PocketThickness: parseFloat(form3Data.PocketThickness) || null,
      Materials: form3Data.Materials ? form3Data.Materials.split(',') : [],
      Deployed: {
        Shape: 'Rectangle',
        TotalLength: parseFloat(form3Data.TotalLength) || null,
        Length: parseFloat(form3Data.Length) || null,
        Width: parseFloat(form3Data.Width) || null,
        Surface: parseFloat(form3Data.Surface) || null
      }
    },
    Statistics: statisticsObject
  };
};
