// Import Style
import styled from 'styled-components';

// Import Constants
import { responsiveWidthTablet } from '../../../Constant';

export const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;

  @media (max-width: ${responsiveWidthTablet}px){
    flex-direction: column;
  }
`;

export const ContactIllustration = styled.img`
  width: 100%;
  height: 100%;
`;

export const ContactIllustrationContainer = styled.div`
  margin: 20px 0;
  max-width: 300px;
  max-height: 300px;
`;
