// Import React Libraries
import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Translation index
import './i18n';
import { initI18n } from './utils/Functions/i18n_Functions/i18n_Functions';

// Import Context
import { NavContextProvider } from './context/NavContext';
import { PreviewSliderContextProvider } from './context/PreviewSliderContext';

// Import Component
import AuthenticationPage from './pages/Authentication/Authentication_Page';
import { AuthenticationLayout } from './components/Authentication/AuthenticationLayout';
// import Header from './components/Header/Header';
// import Footer from './components/Footer/Footer';
import Home from './pages/Home/Home';

import AccountSelectionPage from './pages/Account/AccountSelectionPage';
import AccountDisplayPage from './pages/Account/AccountDisplayPage';
import AccountAddPage from './pages/Account/AccountAddPage';
import AccountEditPage from './pages/Account/AccountEditPage';

import GearCategorySelectorPage from './pages/Items/gearCategorySelectorPage';
import ItemSubcategorySelectorPage from './pages/Items/ItemSubCategorySelectorPage';
import GearAccessoryCategorySelectorPage from './pages/Items/gearAccessoryCategorySelectorPage';
import ItemSelectorPage from './pages/Items/ItemSelectorPage';
import ItemDetailPageDisplay from './pages/Items/ItemDetailPageDisplay';

// ADD
import ItemDetailAddPage from './pages/Items/itemDetailAddPage';
import ItemDetailEditPage from './pages/Items/itemDetailEditPage';

// CONTACT
import ContactPage from './pages/Contact/ContactPage';

// LISTS
import ListPage from './pages/Lists/List_Page';
import AllListsPage from './pages/Lists/AllLists_Page';
import ListAddPage from './pages/Lists/ListAdd_Page';
import ListEditPage from './pages/Lists/ListEdit_Page';
import MyListSelectionPage from './pages/Lists/MyListSelection_Page';
import ListDetailDisplayPage from './pages/Lists/ListDetailDisplay_Page';

// COMPARATOR
import ComparatorPage from './pages/Comparator/Comparator_Page';

// Import Style
import styled from 'styled-components';
import ListTypeSelection from './pages/Lists/ListTypeSelection_Page';
import EquipementControlPage from './pages/Items/equipementControlPage';

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: row;  
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

function App () {
  const { t, i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeI18n = async () => {
      console.log('initialize language from index');
      await initI18n(i18n);
      setIsInitialized(true);
    };
    initializeI18n();
  }, [i18n.language]);

  if (!isInitialized) {
    return <>Wait</>;
  }

  return (
    <NavContextProvider>
      <React.StrictMode>
        <PreviewSliderContextProvider>
        <Suspense fallback={<>Wait</>}>
          <Router>
            { /* <Header/> */ }
            <AuthenticationLayout>
            <HomeWrapper>
              <ContentWrapper>
                <Routes>
                  <Route path={'/'} element={<AuthenticationPage />} />
                  <Route path={`/${i18n.language}/`} element={<Home />} />
                  <Route path={`/${i18n.language}/${t('Equipment')}/`} element={<GearCategorySelectorPage TypeCategory='Equipment'/>}>
                    <Route path={`${t('Control')}/`} element={<EquipementControlPage/>} />
                    <Route path={`${t('Shelter')}/`} element={<ItemSubcategorySelectorPage TypeCategory='Equipment' Category='Shelter'/>} >
                      <Route path={`${t('Tarp')}/`} element={<ItemSelectorPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Tarp'/>}>
                        <Route path={`${t('Add')}/`} element={<ItemDetailAddPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Tarp'/>}/>
                        <Route path=":id/" element={<ItemDetailPageDisplay gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Tarp'/>}>
                          <Route path={`${t('Edition')}/`} element={<ItemDetailEditPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Tarp'/>}/>
                        </Route>
                      </Route>
                      <Route path={`${t('Tent')}`} element={<ItemSelectorPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Tent'/>}>
                        <Route path={`${t('Add')}/`} element={<ItemDetailAddPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Tent'/>}/>
                        <Route path=":id" element={<ItemDetailPageDisplay gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Tent'/>}>
                          <Route path={`${t('Edition')}/`} element={<ItemDetailEditPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Tent'/>}/>
                        </Route>
                      </Route>
                      <Route path={`${t('Accessory')}`} element={<GearAccessoryCategorySelectorPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory'/>}>
                        <Route path={`${t('Rope')}`} element={<ItemSelectorPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory' gearAccessoryCategory={'Rope'}/>}>
                          <Route path={`${t('Add')}/`} element={<ItemDetailAddPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory' gearAccessoryCategory={'Rope'}/>}/>
                          <Route path=":id" element={<ItemDetailPageDisplay gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory' gearAccessoryCategory={'Rope'}/>}>
                            <Route path={`${t('Edition')}/`} element={<ItemDetailEditPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory' gearAccessoryCategory={'Rope'}/>}/>
                          </Route>
                        </Route>
                        <Route path={`${t('Peg')}`} element={<ItemSelectorPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory' gearAccessoryCategory={'Peg'}/>}>
                          <Route path={`${t('Add')}/`} element={<ItemDetailAddPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory' gearAccessoryCategory={'Peg'}/>}/>
                          <Route path=":id" element={<ItemDetailPageDisplay gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory' gearAccessoryCategory={'Peg'}/>}>
                            <Route path={`${t('Edition')}/`} element={<ItemDetailEditPage gearTypeCategory='Equipment' gearCategory='Shelter' gearSubCategory='Accessory' gearAccessoryCategory={'Peg'}/>}/>
                          </Route>
                        </Route>
                      </Route>
                    </Route>

                    <Route path={`${t('Bedding')}/`} element={<ItemSubcategorySelectorPage TypeCategory='Equipment' Category='Bedding'/>} >
                      <Route path={`${t('SleepingBag')}/`} element={<ItemSelectorPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='SleepingBag'/>}>
                        <Route path={`${t('Add')}/`} element={<ItemDetailAddPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='SleepingBag'/>}/>
                        <Route path=":id" element={<ItemDetailPageDisplay gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='SleepingBag'/>}>
                          <Route path={`${t('Edition')}/`} element={<ItemDetailEditPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='SleepingBag'/>}/>
                        </Route>
                      </Route>
                      <Route path={`${t('Mattress')}/`} element={<ItemSelectorPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Mattress'/>}>
                        <Route path={`${t('Add')}/`} element={<ItemDetailAddPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Mattress'/>}/>
                        <Route path=":id" element={<ItemDetailPageDisplay gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Mattress'/>}>
                          <Route path={`${t('Edition')}/`} element={<ItemDetailEditPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Mattress'/>}/>
                        </Route>
                      </Route>
                      <Route path={`${t('Hammock')}/`} element={<ItemSelectorPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Hammock'/>}>
                        <Route path={`${t('Add')}/`} element={<ItemDetailAddPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Hammock'/>}/>
                        <Route path=":id" element={<ItemDetailPageDisplay gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Hammock'/>}>
                          <Route path={`${t('Edition')}/`} element={<ItemDetailEditPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Hammock'/>}/>
                        </Route>
                      </Route>
                      <Route path={`${t('Accessory')}/`} element={<GearAccessoryCategorySelectorPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Accessory'/>}>
                        <Route path={`${t('MosquitoNet')}`} element={<ItemSelectorPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Accessory' gearAccessoryCategory={'MosquitoNet'}/>}>
                          <Route path={`${t('Add')}/`} element={<ItemDetailAddPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Accessory' gearAccessoryCategory={'MosquitoNet'}/>}/>
                          <Route path=":id" element={<ItemDetailPageDisplay gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Accessory' gearAccessoryCategory={'MosquitoNet'}/>}>
                            <Route path={`${t('Edition')}/`} element={<ItemDetailEditPage gearTypeCategory='Equipment' gearCategory='Bedding' gearSubCategory='Accessory' gearAccessoryCategory={'MosquitoNet'}/>}/>
                          </Route>
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  <Route path={`/${i18n.language}/${t('Account')}/`} element={<AccountSelectionPage />}>
                    <Route path={`${t('Add')}/`} element={<AccountAddPage />}/>
                    <Route path=":id/" element={<AccountDisplayPage/>}>
                      <Route path={`${t('Edition')}/`} element={<AccountEditPage/>}/>
                    </Route>
                  </Route>

                  <Route path={`/${i18n.language}/${t('Contact')}/`} element={<ContactPage />}/>

                  <Route path={`/${i18n.language}/${t('Lists')}/`} element={<ListPage/>}>
                    <Route path={`${t('AllLists')}/`} element={<AllListsPage/>}/>
                    <Route path={`${t('SumLists')}/${t('Creation')}`} element={<ListAddPage listType={'SumList'}/>}/>
                    <Route path={`${t('PackLists')}/${t('Creation')}`} element={<ListAddPage listType={'PackList'}/>}/>
                    <Route path={`${t('MyLists')}/`} element={<ListTypeSelection userConcerned={'Me'}/>}>
                      <Route path={`${t('SumLists')}/`} element={<MyListSelectionPage listType={'SumList'}/>}>
                        <Route path=":listID" element={<ListDetailDisplayPage userConcerned={'Me'} listType={'SumList'}/>}>
                          <Route path={`${t('Edition')}/`} element={<ListEditPage userConcerned={'Me'} listType={'SumList'}/>}/>
                        </Route>
                      </Route>
                      <Route path={`${t('PackLists')}/`} element={<MyListSelectionPage listType={'PackList'}/>}>
                        <Route path=":listID" element={<ListDetailDisplayPage userConcerned={'Me'} listType={'PackList'}/>}>
                          <Route path={`${t('Edition')}/`} element={<ListEditPage userConcerned={'Me'} listType={'PackList'}/>}/>
                        </Route>
                      </Route>
                    </Route>
                  </Route>

                  <Route path={`/${i18n.language}/${t('Comparator')}/`} element={<ComparatorPage/>}/>

                </Routes>
              </ContentWrapper>
            </HomeWrapper>
            </AuthenticationLayout>
            { /* <Footer/> */ }

        </Router>
        </Suspense>
      </PreviewSliderContextProvider>
    </React.StrictMode>
  </NavContextProvider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
