// Import Style
import styled from 'styled-components';

export const GearItemSelectorTitle = styled.h2`
  font-family: sans-serif;
  font-size: 25px;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
`;
