// Import React Libraries
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import ItemDetailDisplay from '../../components/Gear/ItemDetailDisplay';
import LoadingComponent from '../../components/LoadingComponent/Loading_Component';

// Import Functions
import { displayItemDetailPage } from '../../utils/Functions/PathFunctions/gearPath_Functions';
import { APIGetOneGear } from '../../api/Gear/Gear_API';

// Import Style
// import { PageWrapper } from '../../utils/Styles';

function ItemDetailPageDisplay ({ gearTypeCategory, gearCategory, gearSubCategory, gearAccessoryCategory }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;
  const { id } = useParams();

  const gearData = {
    TypeCategory: gearTypeCategory,
    Category: gearCategory,
    SubCategory: gearSubCategory,
    AccessoryCategory: gearAccessoryCategory
  };

  const shouldDisplayItemDetail = displayItemDetailPage(gearData, id, currentUrl, t, i18n);

  /*
  console.log('itemDetailPage');
  console.log(`id : ${id}`);
  console.log(`shouldDisplayItemDetail : ${shouldDisplayItemDetail}`);
  */

  const [item, setItem] = useState(null);
  useEffect(() => {
    async function fetchData () {
      try {
        setItem(null);
        const fetchedItem = await APIGetOneGear(gearTypeCategory, gearCategory, gearSubCategory, id);
        // console.log('fetchedItem : ', fetchedItem);
        setItem(fetchedItem);

        // console.log('data[itemIndex]', data[itemIndex]);
      } catch (error) {
        if (gearSubCategory !== 'Accessory') {
          navigate(`/${i18n.language}/${t(gearTypeCategory)}/${t(gearCategory)}/${t(gearSubCategory)}`);
        } else {
          navigate(`/${i18n.language}/${t(gearTypeCategory)}/${t(gearCategory)}/${t(gearSubCategory)}/${t(gearAccessoryCategory)}`);
        }
        console.error('Error fetching product :', error);
      }
    }

    fetchData();
  }, [shouldDisplayItemDetail]);

  return (
    <React.Fragment>
      {shouldDisplayItemDetail
        ? (
            item !== null
              ? (
                <ItemDetailDisplay
                  gearData={gearData}
                  item={item}
                />
                )
              : (
                <LoadingComponent/>
                )
          )
        : (
            <Outlet />
          )
      }
  </React.Fragment>
  );
}
ItemDetailPageDisplay.propTypes = {
  gearTypeCategory: PropTypes.string.isRequired,
  gearCategory: PropTypes.string.isRequired,
  gearSubCategory: PropTypes.string.isRequired,
  gearAccessoryCategory: PropTypes.string
};

export default ItemDetailPageDisplay;
//
