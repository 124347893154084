// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';

// Import Datas
import { compactedShapeChoiceArray } from '../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Function
import { calculCylindricVolume, calculRectangularVolume } from '../../../../../utils/Functions/Gear/Calcul/gearCalculVolumeData_Function';
import { calculDensity } from '../../../../../utils/Functions/Gear/Calcul/gearCalculDensityData_Function';
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';

function GearCompactedDimensionTableAdd ({ gearData, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [compactedShape, setCompactedShape] = useState('');

  // Rectangular
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  // Cylindric
  const [diameter, setDiameter] = useState('');

  const [weight, setWeight] = useState('');

  const [volume, setVolume] = useState(0);
  const [density, setDensity] = useState(0);

  useEffect(() => {
    let calculatedVolume;
    if (compactedShape === 'Rectangular') {
      console.log('Retangular');
      calculatedVolume = calculRectangularVolume(length, width, height, getUnit('Distance'), getUnit('Volume'));
    }
    if (compactedShape === 'Cylindric') {
      console.log('Retangular');
      calculatedVolume = calculCylindricVolume(length, diameter, getUnit('Distance'), getUnit('Distance'), getUnit('Volume'));
    }
    calculatedVolume ? setVolume(parseFloat(calculatedVolume.toFixed(1))) : setVolume(0);
  }, [length, width, height, compactedShape, diameter]);

  useEffect(() => {
    const calculatedDensity = calculDensity(volume, weight, getUnit('Volume'), getUnit('Weight'), getUnit('Density'));

    calculatedDensity ? setDensity(parseFloat(calculatedDensity.toFixed(1))) : setDensity(0);
  }, [volume, weight]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    console.log('Reset Values');
    setLength('');
    setWidth('');
    setHeight('');
    setDiameter('');
    setVolume(0);
    setDensity(0);
  }, [compactedShape]);

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearCompactedDimensionTableAdd-${gearData.SubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearCompactedDimensionTHAdd-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('CompactedDimension')}`}</GearTH>
          </GearTR>
            {compactedShape === '' && (
                <>
                  <GearTR id={`gearCompactedDimensionTHAdd-${gearData.SubCategory}`}>
                    <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
                  </GearTR>
                  <GearTR id={`gearCompactedDimensionTDAdd-${gearData.SubCategory}`}>
                    <GearTD colSpan={12}>
                      <GearSelectDropDown options={compactedShapeChoiceArray} choice={null} setChoice={setCompactedShape} noChoice='Shape'/>
                      <input type="hidden" name="CompactedShape" value={compactedShape} />
                    </GearTD>
                  </GearTR>
                </>
            )}
            {compactedShape === 'Cylindric' && (
              <>
                <GearTR id={`gearCompactedDimensionTHAdd-${gearData.SubCategory}`}>
                  <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
                </GearTR>
                <GearTR id={`gearCompactedDimensionTDAdd-${gearData.SubCategory}`}>
                  <GearTD colSpan={12}>
                    <GearSelectDropDown options={compactedShapeChoiceArray} choice={'Cylindric'} setChoice={setCompactedShape} noChoice='Shape'/>
                    <input type="hidden" name="CompactedShape" value={compactedShape} />
                  </GearTD>
                </GearTR>
                <GearTR id={`gearCompactedDimensionTHAdd-${gearData.SubCategory}`}>
                  <GearTH colSpan={6}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
                  <GearTH colSpan={6}>{`${t('Diameter')} Ø [${getUnit('Distance')}]`}</GearTH>
                </GearTR>
                <GearTR id={`gearCompactedDimensionTDAdd-${gearData.SubCategory}`}>
                  <GearTD colSpan={6}><GearFormInput type="number" name="CylindricLength" placeholder={`${t('Length')}`} onChange={(e) => setLength(e.target.value)}/></GearTD>
                  <GearTD colSpan={6}><GearFormInput type="number" name="Diameter" placeholder={`${t('Diameter')}`} onChange={(e) => setDiameter(e.target.value)}/></GearTD>
                </GearTR>
              </>
            )}
            {compactedShape === 'Rectangular' && (
              <>
              <GearTR id={`gearCompactedDimensionTHAdd-${gearData.SubCategory}`}>
                <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
              </GearTR>
              <GearTR id={`gearCompactedDimensionTDAdd-${gearData.SubCategory}`}>
                <GearTD colSpan={12}>
                  <GearSelectDropDown options={compactedShapeChoiceArray} choice={'Rectangular'} setChoice={setCompactedShape} noChoice='Shape'/>
                  <input type="hidden" name="CompactedShape" value={compactedShape} />
                </GearTD>
              </GearTR>
              <GearTR id={`gearCompactedDimensionTHAdd-${gearData.SubCategory}`}>
                <GearTH colSpan={4}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
                <GearTH colSpan={4}>{`${t('Width')} [${getUnit('Distance')}]`}</GearTH>
                <GearTH colSpan={4}>{`${t('Height')} [${getUnit('Distance')}]`}</GearTH>
              </GearTR>
              <GearTR id={`gearCompactedDimensionTDAdd-${gearData.SubCategory}`}>
                <GearTD colSpan={4}><GearFormInput type="number" name="RectangularLength" placeholder={`${t('Length')}`} onChange={(e) => setLength(e.target.value)}/></GearTD>
                <GearTD colSpan={4}><GearFormInput type="number" name="Width" placeholder={`${t('Width')}`} onChange={(e) => setWidth(e.target.value)}/></GearTD>
                <GearTD colSpan={4}><GearFormInput type="number" name="Height" placeholder={`${t('Height')}`} onChange={(e) => setHeight(e.target.value)}/></GearTD>
              </GearTR>
              </>
            )}
          <GearTR id={`gearCompactedDimensionTHAdd-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('Weight')} [${getUnit('Weight')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('Volume')} [${getUnit('Volume')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('Density')} [${getUnit('Density')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearCompactedDimensionTDAdd-${gearData.SubCategory}`}>
            <GearTD colSpan={4}><GearFormInput type="number" name="Weight" placeholder={`${t('Weight')}`} onChange={(e) => setWeight(e.target.value)}/></GearTD>
            <GearTD colSpan={4}>{volume !== 0 ? volume : `${t('Volume')}`}<input type="hidden" name="Volume" value={volume}/></GearTD>
            <GearTD colSpan={4}>{density !== 0 ? density : `${t('Density')}`}<input type="hidden" name="Density" value={density}/></GearTD>
          </GearTR>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearCompactedDimensionTableAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearCompactedDimensionTableAdd);
