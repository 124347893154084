// Import React Libraries
import styled from 'styled-components';

export const UserButtonContainer = styled.table`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 100%;
`;
