// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../datas/PropTypes/gearPropTypes';

// Import Components

// Import Functions
import { scrollToTop } from '../../utils/Functions/general_Functions';
import { getGearTypeCategoryDefaultIllustrationFunction } from '../../utils/Functions/Gear/gearDefaultIllustration_Function';

// Import Style
import {
  ProductSelectorWrapper, ProductSelectorContainer,
  ProductPresentation, ProductDatasContainer, ProductPrice
} from '../../utils/Style/ItemSelectorStyle';

import { ArticlePicturePreviewSelection, PictureContainerSelector } from '../../utils/Style/PreviewStyle';
import { GearItemSelectorTitle } from '../../utils/Style/js/GearItemSelector/GearItemSelector_Style';
import { StyledP } from '../../utils/Style/js/General/Font_Style';

function GearItemSelectorComponent ({ gearData, itemList }) {
  /*
  console.log('GearItemSelectorComponent');
  console.log('gearData :', gearData);
  console.log('itemList :', itemList);
  */

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const handleItemSelection = (webAppID) => {
    console.log('handleItemSelection');
    console.log(`webAppID : ${webAppID}`);

    const itemPath = gearData.SubCategory !== 'Accessory'
      ? `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${webAppID}`
      : `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t(gearData.AccessoryCategory)}/${webAppID}`;

    navigate(itemPath);
    scrollToTop();
  };

  return (
    <ProductSelectorWrapper id='productSelectorWrapper'>
      <ProductSelectorContainer id='productSelectorContainer'>
        {
          itemList.map((item, index) => {
            const gearDataIntermediar = {
              ...gearData,
              AccessoryCategory: item.Informations.AccessoryCategory
            };

            return (
              <ProductPresentation
                key={index}
                id='productPresentation'
                onClick={() => handleItemSelection(item.Informations.WebAppID)}
              >
                <PictureContainerSelector id='pictureContainerSelector'>
                  <ArticlePicturePreviewSelection
                    src={item.Assets.Illustrations && item.Assets.Illustrations.length > 0
                      ? item.Assets.Illustrations[0]
                      : getGearTypeCategoryDefaultIllustrationFunction(gearDataIntermediar)}
                    alt='product'
                  />
                </PictureContainerSelector>
                <ProductDatasContainer id='productDatasContainer'>
                  <GearItemSelectorTitle>{item.Informations.Name}</GearItemSelectorTitle>
                  <StyledP style={{ margin: '5px 0px' }}>{item.Informations.Brand}</StyledP>
                  <ProductPrice>{item.Metrics.Price === null ? '' : `${item.Metrics.Price.toFixed(2)}€`}</ProductPrice>
                </ProductDatasContainer>
              </ProductPresentation>
            );
          })
        }
      </ProductSelectorContainer>
    </ProductSelectorWrapper>
  );
}

GearItemSelectorComponent.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  itemList: PropTypes.array.isRequired
};

export default GearItemSelectorComponent;
