// Import Constants
import { languageNameSpaceArray } from '../../Constants/Language_Constants';
import { translationsJsons } from '../../Constants/Language_TranslationArray';

const loadTranslations = async (lng, namespaces) => {
  const translations = {};
  for (const ns of namespaces) {
    const data = translationsJsons[lng][ns];
    // const response = await fetch(`${getCurrentHost}/i18n/${lng}/${ns}.json`);
    // console.log('response : ', response);
    // const data = await response.json();
    // console.log('data : ', data);
    Object.assign(translations, data);
  }
  return translations;
};

export const initI18n = async (i18n) => {
  const lng = i18n.language;
  // console.log('translationsJsons : ', translationsJsons);
  // console.log('translationsJsons : ', translationsJsons.en.Bedding);
  // console.log('translationsJsons : ', translationsJsons.fr.Bedding);
  // console.log('languageNameSpaceArray : ', languageNameSpaceArray);

  const resources = await loadTranslations(lng, languageNameSpaceArray);
  // console.log('resources : ', resources);

  i18n.addResources(lng, 'translation', resources);
};
