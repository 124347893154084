// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { specificCharacteristicPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Functions
import { getMaterialsArray } from '../../../../../utils/Functions/Gear/materials_Functions';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearMaterialRowDisplay ({ specificCharacteristic, gearName }) {
  const { t } = useTranslation();

  const materialsArray = getMaterialsArray(specificCharacteristic.Materials);
  // console.log(materialsArray);
  return (
    <>
      <GearTR id={`gearCommonRowTH-${gearName}`}>
          {materialsArray.length <= 1
            ? (
              <GearTH colSpan={12}>{`${t('Material')}`}</GearTH>
              )
            : (
              <GearTH colSpan={12}>{`${t('Materials')}`}</GearTH>
              )}
      </GearTR>
      <GearTR id={`gearCommonRowTD-${gearName}`}>
        {materialsArray.length === 0 && (
          <>
          <GearTD colSpan={12}/>
          </>
        )}
        {materialsArray.length === 1 && (
          <>
          <GearTD colSpan={12}>{materialsArray[0]}</GearTD>
          </>
        )}
        {materialsArray.length === 2 && (
          <>
            <GearTD colSpan={6}>{materialsArray[0]}</GearTD>
            <GearTD colSpan={6}>{materialsArray[1]}</GearTD>
          </>
        )}
        {materialsArray.length === 3 && (
          <>
            <GearTD colSpan={4}>{materialsArray[0]}</GearTD>
            <GearTD colSpan={4}>{materialsArray[1]}</GearTD>
            <GearTD colSpan={4}>{materialsArray[2]}</GearTD>
          </>
        )}
      </GearTR>
    </>
  );
}

GearMaterialRowDisplay.propTypes = {
  specificCharacteristic: specificCharacteristicPropTypes,
  gearName: PropTypes.string.isRequired
};

export default GearMaterialRowDisplay;
