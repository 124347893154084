// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import Styles
import { GearFunctionnalityContainer, GearFunctionnalityDescriptionSummary, GearFunctionnalityWrapper } from '../../../utils/Style/js/GearFunctionnalityDescription/GearFunctionnalityDescription_Style';
import { GeneralButton } from '../../../utils/Style/js/General/Button_Style';
import { StyledH1, StyledP, TitleContainer } from '../../../utils/Style/js/General/Font_Style';

// Import Constants

function GearFunctionalityDescription () {
  const { t } = useTranslation();
  const [learnMoreDisplay, setLearnMoreDisplay] = useState(false);

  useEffect(() => {
    console.log(`learnMoreDisplay : ${learnMoreDisplay}`);
  }, [learnMoreDisplay]);

  const handleClick = () => {
    setLearnMoreDisplay(!learnMoreDisplay);
  };

  const handleMouseLeave = () => {
    setLearnMoreDisplay(false);
  };

  return (
    <GearFunctionnalityWrapper>
      <GearFunctionnalityDescriptionSummary id='GearFunctionalityDescriptionWrapper'>
        <GeneralButton onClick={() => handleClick()}>
          {`${t('LearnMore')} ?`}
        </GeneralButton>
      </GearFunctionnalityDescriptionSummary>
      { learnMoreDisplay &&
        <GearFunctionnalityContainer show={learnMoreDisplay} onMouseLeave={() => handleMouseLeave()}>
          <TitleContainer>
            <StyledH1>{`${t('GearDescriptionTitle1')}`}</StyledH1>
          </TitleContainer>
          <StyledP>{`${t('GearDescription1')}`}</StyledP>
          <StyledP>{`${t('GearDescription2')}`}</StyledP>
          <StyledP>{`${t('GearDescription3')}`}</StyledP>
          <StyledP>{`${t('GearDescription4')}`}</StyledP>
        </GearFunctionnalityContainer>
      }
    </GearFunctionnalityWrapper>
  );
}

export default GearFunctionalityDescription;
