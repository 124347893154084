export function generateSumListObjectFromForms (form1Data, form2Data, form3Data, fromAddOrEdit, listToUpdate) {
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  console.log('generateListObjectFromForms');
  console.log('form1Data : ', form1Data);
  console.log('form2Data : ', form2Data);
  console.log('form3Data : ', form3Data);

  let validationStatus;
  if (currentUserData.userProfile.User_Rank !== 'ADMIN') {
    validationStatus = false;
  } else if (currentUserData.userProfile.User_Rank === 'ADMIN') {
    if (form3Data.Status === 'Validated') {
      validationStatus = true;
    } else {
      validationStatus = false;
    }
  }

  return {
    _id: fromAddOrEdit === 'Add' ? null : listToUpdate._id,
    Informations: {
      Creator: form1Data.Creator,
      Name: form1Data.Name,
      WebAppID: form1Data.Name.replace(/\s+/g, ''),
      Type: 'SumList',
      Environment: form2Data.Environment,
      Seasons: form2Data.Seasons || null
    },
    Status: {
      Visibility: form1Data.Visibility || 'Everyone',
      Validated: validationStatus
    },
    Metrics: {
      Price: form1Data.Price || null,
      Weight: form1Data.Weight || null,
      Volume: form1Data.Volume || null,
      Density: form1Data.Density || null
    },
    Statistics: {
      AverageNoteReceived: fromAddOrEdit === 'Add' ? null : listToUpdate.Statistics.AverageNoteReceived,
      TotalVoteReceived: fromAddOrEdit === 'Add' ? null : listToUpdate.Statistics.TotalVoteReceived
    }
  };
}
