// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Component
import ListTypeSelectorComponent from '../../components/List/listTypeSelector_Component';

// Import Functions
import { displayListTypeSelectionPage } from '../../utils/Functions/PathFunctions/listPath_Functions';

// Import Styles
import { StyledH1, TitleContainer } from '../../utils/Style/js/General/Font_Style';

// Import Constants

function ListTypeSelection ({ userConcerned }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  const [shouldDisplayListSelectionPage, setShouldDisplayListSelectionPage] = useState(false);

  useEffect(() => {
    setShouldDisplayListSelectionPage(displayListTypeSelectionPage(currentUrl, t, i18n));
  }, [navigate]);

  return (
    <>
      {shouldDisplayListSelectionPage
        ? (
            <>
              <TitleContainer>
                  <StyledH1>{`${t('Lists')}`}</StyledH1>
              </TitleContainer>
              <ListTypeSelectorComponent userConcerned={userConcerned}/>
            </>
          )
        : (
            <Outlet />
          )
      }
    </>
  );
}

ListTypeSelection.propTypes = {
  userConcerned: PropTypes.string.isRequired
};

export default ListTypeSelection;
