// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes, gearItemPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';
import ColGroup12 from '../../../ColGroup12';
import GearMaterialTableEditRow from '../../Common/GearMaterialTableEdit';
import GearDeployedDimensionTableComponentEdit from '../../Common/GearDeployedDimensionEdit';

// Import Data
import {
  impermeabilityChoiceArray, breathabilityChoiceArray,
  tissuDensityChoiceArray, threadCountChoiceArray,
  sewingChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Shelter/SpecificShelter_ChoiceArrays';
import { UPFChoiceArray } from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Functions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../../utils/Style/js/GearForm/GearForm_Style';

// Import Constants

function GearTarpSpecificTableEdit ({ gearData, item, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();
  // console.log('gearSpecificCharacteristic : ', gearSpecificCharacteristic);

  // DropDown
  const [impermeability, setImpermeability] = useState(item.Specific.Impermeability);
  const [breathability, setBreathability] = useState(item.Specific.Breathability);
  const [upf, setUpf] = useState(item.Specific.Upf);
  const [tissuDensity, setTissuDensity] = useState(item.Specific.TissuDensity);
  const [threadCount, setThreadCount] = useState(item.Specific.ThreadCount);
  const [sewing, setSewing] = useState(item.Specific.Sewing);

  const [materials, setMaterials] = useState(
    [item.Specific.Materials[0], item.Specific.Materials[1], item.Specific.Materials[2]]);

  // Field Entry
  const [attach, setAttach] = useState(item.Specific.Attach);

  const setFirstMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[0] = value;
      return newMaterials;
    });
  };

  const setSecondMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[1] = value;
      return newMaterials;
    });
  };

  const setThirdMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[2] = value;
      return newMaterials;
    });
  };

  const materialsSetter = [setFirstMaterial, setSecondMaterial, setThirdMaterial];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearTarpSpecificTableEdit-${gearData.SubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('Attach')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={12}>
              <GearFormInput
                type="number"
                name="Attach"
                placeholder={attach === null ? `${t('Attach')}` : attach}
                value={attach}
                onChange={(e) => setAttach(e.target.value)}
              />
            </GearTD>
          </GearTR>
          <GearMaterialTableEditRow
            gearData={gearData}
            setMaterials={materialsSetter}
            materials={materials}
          />
          <input type="hidden" name="Materials" value={materials} />
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('TissuDensity')} [${getUnit('TissuDensity')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('ThreadCount')} [${getUnit('ThreadCount')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('Sewing')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={tissuDensityChoiceArray}
                choice={tissuDensity}
                setChoice={setTissuDensity}
                noChoice='TissuDensity'
              />
              <input type="hidden" name="TissuDensity" value={tissuDensity} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={threadCountChoiceArray}
                choice={threadCount}
                setChoice={setThreadCount}
                noChoice='ThreadCount'
              />
              <input type="hidden" name="ThreadCount" value={threadCount} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={sewingChoiceArray}
                choice={sewing}
                setChoice={setSewing}
                noChoice='Sewing'
              />
              <input type="hidden" name="Sewing" value={sewing} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('Impermeability')} [${getUnit('Impermeability')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('Breathability')} [${getUnit('Breathability')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('UPF')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={impermeabilityChoiceArray}
                choice={impermeability}
                setChoice={setImpermeability}
                noChoice='Impermeability'
              />
              <input type="hidden" name="Impermeability" value={impermeability} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={breathabilityChoiceArray}
                choice={breathability}
                setChoice={setBreathability}
                noChoice='Breathability'
              />
              <input type="hidden" name="Breathability" value={breathability} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={UPFChoiceArray}
                choice={upf}
                setChoice={setUpf}
                noChoice='UPF'
              />
              <input type="hidden" name="Upf" value={upf} />
            </GearTD>
          </GearTR>
          <GearDeployedDimensionTableComponentEdit gearData={gearData} specific={item.Specific}/>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearTarpSpecificTableEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearItemPropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearTarpSpecificTableEdit);
