// Import Functions
import {
  generateInformationsObjectFromForms,
  generateAssetsObjectFromForms,
  generateStatusObjectFromForms,
  generateMetricsObjectFromForms,
  generateCompactedObjectFromForms,
  generateStatisticsObjectFromForms
} from '../Common/CommonPropertiesFromForms_Functions';

export function generateSleepingBagObjectFromForms (form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit) {
  console.log('generateTarpObjectFromForms');
  console.log('form1Data : ', form1Data);
  console.log('form2Data : ', form2Data);
  console.log('form3Data : ', form3Data);
  console.log('form4Data : ', form4Data);
  console.log('form5Data : ', form5Data);

  const informationObject = generateInformationsObjectFromForms(form2Data, gearData);
  const assetsObject = generateAssetsObjectFromForms(form1Data);
  const statusObject = generateStatusObjectFromForms(form5Data, item.Status, fromAddOrEdit);
  const metricsObject = generateMetricsObjectFromForms(form2Data, form4Data);
  const compactedObject = generateCompactedObjectFromForms(form4Data);
  const statisticsObject = generateStatisticsObjectFromForms(item.Statistics, fromAddOrEdit);

  return {
    _id: item._id,
    Informations: informationObject,
    Assets: assetsObject,
    Status: statusObject,
    Metrics: metricsObject,
    Compacted: compactedObject,
    Specific: {
      Deployed: {
        Shape: form3Data.Shape || null,
        Length: parseFloat(form3Data.Length) || null,
        WidthShoulder: parseFloat(form3Data.WidthShoulder) || null,
        WidthFoot: parseFloat(form3Data.WidthFoot) || null,
        Width: parseFloat(form3Data.Width) || null,
        Surface: parseFloat(form3Data.Surface) || null
      },
      Hood: form3Data.Hood || null,
      Season: form3Data.Season || null,
      ZipSide: form3Data.ZipSide || null,
      Pairable: form3Data.Pairable || null,
      InsulatorNature: form3Data.InsulatorNature || null,
      InsulatorQuality: form3Data.InsulatorQuality || null,
      Materials: form3Data.Materials ? form3Data.Materials.split(',') : [],
      TemperatureComfort: form3Data.TemperatureComfort || null,
      TemperatureLimit: form3Data.TemperatureLimit || null,
      TemperatureExtrem: form3Data.TemperatureExtrem || null
    },
    Statistics: statisticsObject
  };
}
