import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';

/* COMMON */
// Post Equipment
export async function APIPostGear (data) {
  /*
  console.log('APIpostGear');
  console.log('data:', data);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${data.Informations.TypeCategory}`;

  // Create a FormData object
  const formData = new FormData();

  // Append other data fields to the FormData object

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'Assets.Illustrations') {
      const value = data[key];
      if (typeof value === 'object' && value !== null) {
        // Convert non-file objects to JSON strings
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  }

  // Append the image files to the FormData object
  if (data.Assets.Illustrations && Array.isArray(data.Assets.Illustrations)) {
    data.Assets.Illustrations.forEach((file, index) => {
      formData.append('illustrations', file);
    });
  }

  // console.log('formData : ', formData);

  try {
    const response = await fetch(URLToFetch, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      body: formData
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    return responseBody;
  } catch (error) {
    console.log('error:', error);
  }
};

// Edit Equipment
export async function APIEditGear (data) {
  console.log('APIEditGear');
  console.log('data:', data);

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${data.Informations.TypeCategory}`;

  // Create a FormData object
  const formData = new FormData();

  // Append other data fields to the FormData object

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'Assets.Illustrations') {
      const value = data[key];
      if (typeof value === 'object' && value !== null) {
        // Convert non-file objects to JSON strings
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  }

  // Append the image files to the FormData object
  if (data.Assets.Illustrations && Array.isArray(data.Assets.Illustrations)) {
    data.Assets.Illustrations.forEach((file, index) => {
      formData.append('illustrations', file);
    });
  }

  // console.log('formData : ', formData);

  try {
    const response = await fetch(URLToFetch, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      body: formData
    });

    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    return responseBody;
  } catch (error) {
    console.log('error:', error);
  }
};

// Get one equipment
export async function APIGetOneGear (gearTypeCategory, gearCategory, gearSubCategory, gearId) {
  console.log('APIGetOneGear');
  /*
  console.log(`gearTypeCategory: ${gearTypeCategory}`);
  console.log(`gearCategory: ${gearCategory}`);
  console.log(`gearSubCategory: ${gearSubCategory}`);
  console.log(`gearId: ${gearId}`);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearTypeCategory}/${gearCategory}/${gearSubCategory}/${gearId}`;

  console.log(`URLToFetch : ${URLToFetch}`);

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

// Delete one equipment
export async function APIDeleteGear (gearData, gearId) {
  /*
  console.log('APIDeleteGear');
  console.log('gearData : ', gearData);
  console.log(`gearId: ${gearId}`);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/${gearData.Category}/${gearData.SubCategory}/${gearId}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    return responseBody;
  } catch (error) {
    console.log('error:', error);
  }
};

/* EQUIPMENT */
// Get all equiment of a subCategory
export async function APIGetAllGear (gearTypeCategory, gearCategory, gearSubCategory) {
  /*
  console.log('APIGetAllGear');
  console.log(`gearTypeCategory: ${gearTypeCategory}`);
  console.log(`gearCategory: ${gearCategory}`);
  console.log(`gearSubCategory: ${gearSubCategory}`);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearTypeCategory}/${gearCategory}/${gearSubCategory}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

// Get all the equipment of a subCategory depending on the validation status
export async function APIGetAllEquipmentValidationStatus (gearData, validationStatus) {
  /*
  console.log('APIGetAllEquipmentValidationStatus');
  console.log('gearData : ', gearData);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/GetEquipmentValidationStatus/${gearData.Category}/${gearData.SubCategory}/${validationStatus}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

/* Get All the Pending Validations Gears of one subCategory of one user  */
export async function APIGetUserAllPendingValidationGear (gearData, userID) {
  /*
  console.log('APIGetUserAllPendingValidationGear');
  console.log('gearData : ', gearData);
  console.log(`userID: ${userID}`);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/UserPendingValidation/${gearData.Category}/${gearData.SubCategory}/${userID}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

/* Check if the current user has any pending validation gear in a subCategory */
export async function APICheckIfUserHasPendingValidationGear (gearData, userID) {
  console.log('APICheckIfUserHasPendingValidationGear');

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/UserHavePendingValidation/${gearData.Category}/${gearData.SubCategory}/${userID}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
}

/* Check if there is any pending validation gear in a subCategory */
export async function APICheckIfPendingValidationGear (gearData) {
  console.log('APICheckIfUserHasPendingValidationGear');

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/IsTherePendingValidation/${gearData.Category}/${gearData.SubCategory}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

/* Get the quantity of all pending validation gear by SubCategories and AccessoryCategory */
export async function APIGetAllPendingValidationGearQuantity () {
  console.log('APICheckIfUserHasPendingValidationGear');

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/Equipment/AllPendingValidation`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};
