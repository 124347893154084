// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import GearDeployedDimensionRowAdd from '../../Common/GearDeployedDimensionAdd';

// Import Style
import {
  GearTR, GearTH
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearTentAwningDeployedDimensionRowAdd ({ gearData, awning, awningIdentical }) {
  // console.log('GearAwningDimensionRowAdd');
  // console.log(`awning : ${awning}`);
  const { t } = useTranslation();

  const gearDataAwning = {
    ...gearData,
    gearSubCategory: 'Awning'
  };

  return (
    <>
        {awning === 0 && (
            <>
            </>
        )}
        {(awning === 1 || (awning === 2 && awningIdentical === 'Yes')) && (
          <>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={12}>
              {awning === 1 ? `${t('Awning')} 1` : `${t('Awnings')}`}
              </GearTH>
          </GearTR>
          <GearDeployedDimensionRowAdd gearData={gearDataAwning} nameComplement='Awning1'/>
          </>
        )}
        {(awning === 2 && (awningIdentical === null || awningIdentical === 'No')) && (
          <>
            <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
              <GearTH colSpan={12}>{`${t('Awning')} 1`}</GearTH>
            </GearTR>
            <GearDeployedDimensionRowAdd gearData={gearDataAwning} nameComplement='Awning1'/>
            <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
              <GearTH colSpan={12}>{`${t('Awning')} 2`}</GearTH>
            </GearTR>
          <GearDeployedDimensionRowAdd gearData={gearDataAwning} nameComplement='Awning2'/>
          </>
        )}
    </>
  );
}

GearTentAwningDeployedDimensionRowAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  awning: PropTypes.number.isRequired,
  awningIdentical: PropTypes.string.isRequired
};

export default GearTentAwningDeployedDimensionRowAdd;
