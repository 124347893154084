// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Component
import ContactInformation from '../../components/Contact/contactInformation';
import ContactFormComponent from '../../components/Contact/ContactForm';

// Import Style
import { PageWrapper } from '../../utils/Styles';
import { ContactWrapper } from '../../utils/Style/js/Contact/Contact_Style';
import { StyledH1, TitleContainer } from '../../utils/Style/js/General/Font_Style';

function ContactPage () {
  const { t } = useTranslation();

  return (
    <PageWrapper id='contactPageWrapper'>
      <TitleContainer>
        <StyledH1>{`${t('Contact')}`}</StyledH1>
      </TitleContainer>
      <ContactWrapper id='contactWrapper'>
        <ContactInformation/>
        <ContactFormComponent/>
      </ContactWrapper>
    </PageWrapper>
  );
}

export default ContactPage;
