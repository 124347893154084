// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';

// Import Datas
import { tissuMaterialsChoiceArray } from '../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearMaterialRowAdd ({ gearData, setMaterials }) {
  const { t } = useTranslation();

  return (
    <>
      <GearTR id={`gearMaterialRowTHAdd-${gearData.SubCategory}`}>
        <GearTH colSpan={12}>{`${t('Materials')}`}</GearTH>
      </GearTR>
      <GearTR id={`gearMaterialRowTDAdd-${gearData.SubCategory}`}>
        <GearTD colSpan={4}>
          <GearSelectDropDown options={tissuMaterialsChoiceArray} choice={null} setChoice={setMaterials[0]} noChoice='Material'/>
        </GearTD>
        <GearTD colSpan={4}>
          <GearSelectDropDown options={tissuMaterialsChoiceArray} choice={null} setChoice={setMaterials[1]} noChoice='Material'/>
        </GearTD>
        <GearTD colSpan={4}>
          <GearSelectDropDown options={tissuMaterialsChoiceArray} choice={null} setChoice={setMaterials[2]} noChoice='Material'/>
        </GearTD>
      </GearTR>
    </>
  );
}

GearMaterialRowAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  setMaterials: PropTypes.arrayOf(PropTypes.func).isRequired
};

export default GearMaterialRowAdd;
