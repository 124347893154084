export function generateUserObjectFromForms (form1Data, form2Data, form3Data) {
  /*
  console.log('generateUserObjectFromForms');
  console.log('form1Data : ', form1Data);
  console.log('form2Data : ', form2Data);
  console.log('form3Data : ', form3Data);
  */

  const userAvatar = form1Data.User_Avatar || null;

  return {
    userProfile: {
      User_ID: form1Data.User_ID,
      User_Pseudo: form1Data.User_Pseudo,
      User_Rank: form1Data.User_Rank || 'USER',
      User_Password: form1Data.User_Password || 'mdp',
      User_Avatar: userAvatar
    },
    userGeneralPreference: {
      User_Language: form2Data.User_Language || 'fr',
      Unit_Currency: form2Data.Unit_Currency || 'euro'
    },
    userUnitPreference: {
      Unit_System: form3Data.Unit_System || 'SI'
    }
  };
}
