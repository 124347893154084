// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import ItemDetailEdit from '../../components/Gear/itemDetailEdit';
import LoadingComponent from '../../components/LoadingComponent/Loading_Component';

// Import Function
import { APIGetOneGear } from '../../api/Gear/Gear_API';
import { displayItemEditPage } from '../../utils/Functions/PathFunctions/gearPath_Functions';

// Import Style
// import { PageWrapper } from '../../utils/Styles';

function ItemDetailEditPage ({ gearTypeCategory, gearCategory, gearSubCategory, gearAccessoryCategory }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [item, setItem] = useState(null);
  const [shouldDisplayEditPage, setShouldDisplayEditPage] = useState(true);

  /*
  console.log('itemDetailPage');
  console.log(`gearTypeCategory : ${gearTypeCategory}`);
  console.log(`gearCategory : ${gearCategory}`);
  console.log(`gearSubCategory : ${gearSubCategory}`);
  console.log(`id : ${id}`);
  */
  const gearData = {
    TypeCategory: gearTypeCategory,
    Category: gearCategory,
    SubCategory: gearSubCategory,
    AccessoryCategory: gearAccessoryCategory
  };

  useEffect(() => {
    async function fetchData () {
      try {
        const fetchedItem = await APIGetOneGear(gearTypeCategory, gearCategory, gearSubCategory, id);
        setItem(fetchedItem);
        setShouldDisplayEditPage(displayItemEditPage(fetchedItem));
        // console.log('fetchedItem', fetchedItem);
      } catch (error) {
        console.error('Error fetching product:', error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    // console.log(`shouldDisplayEditPage : ${shouldDisplayEditPage}`);
    if (!shouldDisplayEditPage) {
      if (gearSubCategory !== 'Accessory') {
        navigate(`/${i18n.language}/${t(gearTypeCategory)}/${t(gearCategory)}/${t(gearSubCategory)}/${id}`);
      } else {
        navigate(`/${i18n.language}/${t(gearTypeCategory)}/${t(gearCategory)}/${t(gearSubCategory)}/${t(gearAccessoryCategory)}/${id}`);
      }
    }
  }, [shouldDisplayEditPage, navigate, i18n.language, t]);

  return (
    <>
      {
        item !== null
          ? (
            <ItemDetailEdit
              gearData={gearData}
              gearId={id}
              item={item}
            />
            )
          : (
            <LoadingComponent/>
            )
      }
    </>
  );
}

ItemDetailEditPage.propTypes = {
  gearTypeCategory: PropTypes.string.isRequired,
  gearCategory: PropTypes.string.isRequired,
  gearSubCategory: PropTypes.string.isRequired,
  gearAccessoryCategory: PropTypes.string
};

export default ItemDetailEditPage;
