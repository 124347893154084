// Import React Libraries
import React, { useEffect, useState } from 'react';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

// Import Component
import ColGroup12 from '../Gear/Tab/ColGroup12';
import { GearSelectDropDown } from '../Gear/SelectDropDown/GearSelectDropDown';
import LoadingOverlayComponent from '../LoadingComponent/LoadingOverlay_Component';

// Import Functions
import { contactFormValidTest } from '../../utils/Functions/Contact/ContactForm_Functions';
import { createContactDataObjectFromForm } from '../../utils/Functions/Contact/FillContactObject';
import { APIPostContact } from '../../api/Contact/Contact_API';

// Import Datas
import { contactMessageObjectChoiceArray } from '../../datas/Contact/Contact_ChoiceArrays';
// Import Style
import {
  ContactFormFieldMessage,
  ContactFormButtonContainer,
  ContactFormButtonIconContainer,
  ContactFormButtonIcon,
  ContactFormWrapper
} from '../../utils/Style/js/Contact/ContactForm_Style';
import {
  ContactTable, ContactTableBody,
  ContactTR, ContactTD
} from '../../utils/Style/js/Contact/Contact_Table';
import { GeneralButton } from '../../utils/Style/js/General/Button_Style';

function ContactFormComponent () {
  const { t } = useTranslation();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const [messageObject, setMessageObject] = useState(null);
  const [messageContent, setMessageContent] = useState('');
  const [loaderState, setLoaderState] = useState(false);
  const [messageSentStatus, setMessageSentStatus] = useState(false);
  const [componentKey, setComponentKey] = useState(0);

  const [formDataErrorMessages, setFormDataErrorMessages] = useState({
    messageObject: ''
  });
  console.log('formDataErrorMessages : ', formDataErrorMessages);

  /*
  const clearForm = () => {
    console.log('clearForm');
    setMessageObject(null);
    setMessageContent('');
  };
  */

  const handleSubmit = async (e) => {
    console.log('handleSubmit');
    e.preventDefault();
    const formData = new FormData(e.target);

    const isFormValid = contactFormValidTest(setFormDataErrorMessages, formData);

    // console.log(data);
    if (isFormValid) {
      setLoaderState(true);

      try {
        const contactDataObject = createContactDataObjectFromForm(formData);

        const serverResponse = await APIPostContact(contactDataObject);
        console.log(`serverResponse : ${serverResponse}`);
        setLoaderState(false);

        if (serverResponse.message === 'Message sent successfully') {
        // setTemporaryMessage(null);
          // clearForm();
          setMessageSentStatus(true);
          alert(`${t('MessageSuccessfullySent')}`);
        } else if (serverResponse.error) {
          console.log('server Response Error : ', serverResponse.error.error);
        // setTemporaryMessage(formData);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
        setLoaderState(false);
      }
    }
  };

  useEffect(() => {
    if (messageSentStatus) {
      setMessageObject(null);
      setMessageContent('');
      setMessageSentStatus(false); // Reset status to avoid infinite loop
      setComponentKey(prevKey => prevKey + 1); // Update component key to force re-render
    }
  }, [messageSentStatus]);

  return (
    <ContactFormWrapper key={componentKey}>
      <form onSubmit={handleSubmit} id='contactForm' noValidate>
        <ContactTable>
          <ColGroup12/>
          <ContactTableBody>
            <ContactTR>
              <ContactTD colSpan={6}>
                {currentUserData.userProfile.User_ID}
                <input type="hidden" name="messageIDFrom" value={currentUserData.userProfile.User_ID} />
              </ContactTD>
              <ContactTD colSpan={6}>
                <GearSelectDropDown options={contactMessageObjectChoiceArray} choice={messageObject} setChoice={setMessageObject} noChoice={`${t('SelectMessageObject')}`}/>
                <input type="hidden" name="messageObject" value={messageObject} />
              </ContactTD>
            </ContactTR>
            {formDataErrorMessages.messageObject === 'MissingMessageObject'
              ? (
                <ContactTR>
                  <ContactTD colSpan={6} style={{ color: 'red', border: '0px' }}/>
                  <ContactTD colSpan={6} style={{ color: 'red' }}>{`${t('MissingMessageObject')}`}</ContactTD>
                </ContactTR>
                )
              : <></>
            }
            <ContactTR>
              <ContactTD colSpan={12}>
                <ContactFormFieldMessage
                  id="message"
                  name="messageContent"
                  value={messageContent}
                  onChange={(e) => setMessageContent(e.target.value)}
                  placeholder="Message"
                  required
                  />
              </ContactTD>
            </ContactTR>

          </ContactTableBody>
        </ContactTable>

        <ContactFormButtonContainer>
          <GeneralButton type="submit">
            <ContactFormButtonIconContainer>
              <ContactFormButtonIcon icon={faPaperPlane}/>
            </ContactFormButtonIconContainer>
            {t('Send')}
          </GeneralButton>
        </ContactFormButtonContainer>
      </form>

      {
        loaderState === true
          ? <LoadingOverlayComponent/>
          : <></>
      }

    </ContactFormWrapper>
  );
}

export default ContactFormComponent;
