// Import Functions
import {
  generateInformationsObjectFromForms,
  generateAssetsObjectFromForms,
  generateStatusObjectFromForms,
  generateMetricsObjectFromForms,
  generateCompactedObjectFromForms,
  generateStatisticsObjectFromForms
} from '../Common/CommonPropertiesFromForms_Functions';

export function generateTentObjectFromForms (form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit) {
  console.log('generateTarpObjectFromForms');
  console.log('form1Data : ', form1Data);
  console.log('form2Data : ', form2Data);
  console.log('form3Data : ', form3Data);
  console.log('form4Data : ', form4Data);
  console.log('form5Data : ', form5Data);

  const informationObject = generateInformationsObjectFromForms(form2Data, gearData);
  const assetsObject = generateAssetsObjectFromForms(form1Data);
  const statusObject = generateStatusObjectFromForms(form5Data, item.Status, fromAddOrEdit);
  const metricsObject = generateMetricsObjectFromForms(form2Data, form4Data);
  const compactedObject = generateCompactedObjectFromForms(form4Data);
  const statisticsObject = generateStatisticsObjectFromForms(item.Statistics, fromAddOrEdit);

  return {
    _id: item._id,
    Informations: informationObject,
    Assets: assetsObject,
    Status: statusObject,
    Metrics: metricsObject,
    Compacted: compactedObject,
    Specific: {
      Deployed: {
        Shape: form3Data.Shape || null,
        Length: parseFloat(form3Data.Length) || null,
        Width: parseFloat(form3Data.Width) || null,
        LengthA: parseFloat(form3Data.LengthA) || null,
        LengthB: parseFloat(form3Data.LengthB) || null,
        LengthC: parseFloat(form3Data.LengthC) || null,
        Diameter: parseFloat(form3Data.Diameter) || null,
        Awning1Shape: form3Data.Awning1Shape || null,
        Awning1Length: parseFloat(form3Data.Awning1Length) || null,
        Awning1LengthA: parseFloat(form3Data.Awning1LengthA) || null,
        Awning1LengthB: parseFloat(form3Data.Awning1LengthB) || null,
        Awning1LengthC: parseFloat(form3Data.Awning1LengthC) || null,
        Awning1Width: parseFloat(form3Data.Awning1Width) || null,
        Awning1Surface: parseFloat(form3Data.Awning1Surface) || null,
        Awning2Shape: form3Data.Awning2Shape || null,
        Awning2Length: parseFloat(form3Data.Awning2Length) || null,
        Awning2LengthA: parseFloat(form3Data.Awning2LengthA) || null,
        Awning2LengthB: parseFloat(form3Data.Awning2LengthB) || null,
        Awning2LengthC: parseFloat(form3Data.Awning2LengthC) || null,
        Awning2Width: parseFloat(form3Data.Awning2Width) || null,
        ChamberWidth: parseFloat(form3Data.ChamberWidth) || null,
        ChamberHeight: parseFloat(form3Data.ChamberHeight) || null,
        ChamberSurface: parseFloat(form3Data.ChamberSurface) || null
      },
      Person: form3Data.Person || null,
      Door: form3Data.Door || null,
      SelfSupporting: form3Data.SelfSupporting || null,
      Type: form3Data.Type || null,
      Wall: form3Data.Wall || null,
      Season: form3Data.Season || null,
      Ventilation: form3Data.Ventilation || null,
      Hoop: form3Data.Hoop || null,
      Peg: form3Data.Peg || null,
      GuyWire: form3Data.GuyWire || null,
      MosquitoNet: form3Data.MosquitoNet || null,
      MeshSize: form3Data.MeshSize || null,
      Awning: form3Data.Awning || null,
      AwningIdentical: form3Data.AwningIdentical || null,
      Materials: form3Data.Materials ? form3Data.Materials.split(',') : [],
      Sewing: form3Data.Sewing || null,
      IngressProtection: form3Data.IngressProtection || null,
      ImpermeabilityExtern: parseInt(form3Data.ImpermeabilityExtern) || null,
      ImpermeabilityFloor: parseInt(form3Data.ImpermeabilityFloor) || null,
      Upf: form3Data.Upf || null
    },
    Statistics: statisticsObject
  };
}
