// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Components
import CommingSoonComponent from '../../components/CommingSoon/CommingSoon_Component';

// Import Styles
import { StyledH1, TitleContainer } from '../../utils/Style/js/General/Font_Style';

// Import Constants

function AllListsPage () {
  const { t } = useTranslation();

  return (
    <>
      <TitleContainer>
        <StyledH1>{`${t('Lists')}`}</StyledH1>
      </TitleContainer>
      <CommingSoonComponent/>
    </>
  );
}

export default AllListsPage;
