// Import React Libraries
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import {
  gearDataPropTypes,
  gearCommonCharacteristicPropTypes
} from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';
import GearMosquitoNetEditRow from '../Common/GearMosquitoNetEditRow';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearAccessoryTableEdit ({ gearData, gearSpecificCharacteristic, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  // Accessory specific
  const [mosquitoNet, setMosquitoNet] = useState(gearSpecificCharacteristic.MosquitoNet);
  const [meshSize, setMeshSize] = useState(gearSpecificCharacteristic.MeshSize);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearAccessoryTableEdit-${gearData.AccessoryCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearAccessoryRowTHEdit-${gearData.AccessoryCategory}`}>
            <GearTH colSpan={12}>{`${t('AccessoryCategory')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearAccessoryRowTDEdit-${gearData.AccessoryCategory}`}>
            <GearTD colSpan={12}>
              {`${t(gearData.AccessoryCategory)}`}
            </GearTD>
          </GearTR>
          {
            gearData.AccessoryCategory === 'MosquitoNet' &&
            <GearMosquitoNetEditRow gearData={gearData} mosquitoNet={mosquitoNet} setMosquitoNet={setMosquitoNet} meshSize={meshSize} setMeshSize={setMeshSize}/>
          }
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearAccessoryTableEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  gearSpecificCharacteristic: gearCommonCharacteristicPropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearAccessoryTableEdit);
