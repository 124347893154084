export function displayListPage (currentUrl, t, i18n) {
  if (currentUrl === `/${i18n.language}/${t('Lists')}`) {
    return true;
  } else {
    return false;
  }
};

export function displayListTypeSelectionPage (currentUrl, t, i18n) {
  // console.log('displayListTypeSelectionPage');
  const basePath = `/${i18n.language}/${t('Lists')}/${t('MyLists')}/`;

  if (currentUrl.startsWith(basePath)) {
    const remainingPath = currentUrl.slice(basePath.length);
    const pathSegments = remainingPath.split('/');

    /*
    console.log('pathSegments : ', pathSegments);
    console.log('pathSegments : ', pathSegments[0]);
    */

    if (pathSegments[0] === '') {
      return true;
    }
  }
  return false;
};

export function displayMyListSelectionPage (currentUrl, t, i18n) {
  const basePath = `/${i18n.language}/${t('Lists')}/${t('MyLists')}/`;

  if (currentUrl.startsWith(basePath)) {
    const remainingPath = currentUrl.slice(basePath.length);
    const pathSegments = remainingPath.split('/');

    if ((pathSegments[0] === t('PackLists') || pathSegments[0] === t('SumLists')) && (pathSegments[1] === undefined || pathSegments[1] === '')) {
      return true;
    }
  }
  return false;
};

export function displayEditListPage (currentUrl, t, i18n) {
  // console.log('displayEditListPage');
  const basePath = `/${i18n.language}/${t('Lists')}`;

  if (currentUrl.startsWith(basePath)) {
    const remainingPath = currentUrl.slice(basePath.length);
    const pathSegments = remainingPath.split('/');
    /*
    console.log(`remainingPath : ${remainingPath}`);
    console.log(`pathSegments : ${pathSegments}`);
    console.log(`pathSegments : ${pathSegments[1]}`);
    console.log(`pathSegments : ${pathSegments[2]}`);
    console.log(`pathSegments : ${pathSegments[3]}`);
    console.log(`pathSegments : ${pathSegments[4]}`);
    */

    if (pathSegments[4] === `${t('Edition')}`) {
      return true;
    }
  }
  return false;
};
