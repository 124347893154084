import { getAllTypeListsCreatedByOneUser } from '../../../api/List/List_API';

export async function generateUserPackListsArray () {
  const userPackListsNameArray = [];
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const userPackLists = await getAllTypeListsCreatedByOneUser(currentUserData.userProfile.User_ID, 'PackList');
  // console.log('userPackLists : ', userPackLists);

  for (const element of userPackLists) {
    userPackListsNameArray.push(element.Informations.WebAppID);
  }

  return userPackListsNameArray;
};

export async function generateUserSumListsArray () {
  const userSumListsNameArray = [];
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const userSumLists = await getAllTypeListsCreatedByOneUser(currentUserData.userProfile.User_ID, 'SumList');
  // console.log('userSumLists : ', userSumLists);

  for (const element of userSumLists) {
    userSumListsNameArray.push(element.Informations.WebAppID);
  }

  return userSumListsNameArray;
};

export async function generateUserListsArray () {
  const userSumListsNameArray = await generateUserSumListsArray();
  const userPackListsNameArray = await generateUserPackListsArray();

  return { userSumListsNameArray, userPackListsNameArray };
};
