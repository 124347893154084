// Import React Libraries
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Component
import ColGroup12 from '../../../Gear/Tab/ColGroup12';
import { GearSelectDropDown } from '../../../Gear/SelectDropDown/GearSelectDropDown';

// Import Datas
import { userUnitSystemChoiceArray } from '../../../../datas/User/User_AddEdit_ChoiceArrays';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../utils/Style/js/Account/UserDataTableDisplay_Style';

function UserUnitPreferenceAdd ({ onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [unitSystem, setUnitSystem] = useState('SI');

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <UserDataTable>
        <ColGroup12/>
        <UserDataTableBody>
          <UserDataTR>
            <UserDataTH colSpan={12}>{`${t('DistanceSystem')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={12}>
              <GearSelectDropDown options={userUnitSystemChoiceArray} choice={unitSystem} setChoice={setUnitSystem} noChoice={`${t('Select')}`}/>
              <input type="hidden" name="Unit_System" value={unitSystem} />
            </UserDataTD>
          </UserDataTR>
        </UserDataTableBody>
      </UserDataTable>
    </form>
  );
}

UserUnitPreferenceAdd.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(UserUnitPreferenceAdd);
