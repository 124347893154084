// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faMinus, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';

// Import PropTypes
import PropTypes from 'prop-types';
import { listPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Components
import ColGroup12 from '../../../../Gear/Tab/ColGroup12';
import LoadingOverlayComponent from '../../../../LoadingComponent/LoadingOverlay_Component';

// Import Functions
import { APIDeleteUserPackListTag, APIEditPackListTagQuantity } from '../../../../../api/List/PackListTag_API';
import { convertIfNecessarySI } from '../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Style
import { GearTableBody, GearTR, GearTD, GearTH } from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';
import { ListPreviewTable } from '../../../../../utils/Style/js/Lists/ListPreviewDataTable_Style';
import { GearCommonButton, GearCommonIcon } from '../../../../../utils/Style/js/GearTable/GearCommonTable_Style';

// Import Constants

function PackListTagTableDisplay ({ packListTag, setListTagDeletion, setListTagEdition }) {
  console.log('PackListTagTableDisplay');
  console.log('packListTag : ', packListTag);
  // const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const quantityRowRef = useRef(null);
  const packListTagTableRef = useRef(null);
  const [oldQuantity, setOldQuantity] = useState(packListTag.PackList.Quantity);
  const [newQuantity, setNewQuantity] = useState(packListTag.PackList.Quantity);
  const [updatingQuantityState, setUpdatingQuantityState] = useState(false);

  const handleQuantity = (operator, event) => {
    if (operator === '+') {
      setNewQuantity(newQuantity + 1);
    } else if (operator === '-') {
      setNewQuantity(newQuantity - 1);
    } else {
      setNewQuantity(event.target.value);
    }
  };

  const handleDelete = async () => {
    const deleteConfirmation = window.confirm(`${t('DeleteConfirmationElementFromList')}`);
    if (deleteConfirmation) {
      const serverResponse = await APIDeleteUserPackListTag(packListTag._id);
      if (serverResponse === true) {
        setListTagDeletion(true);
      } else {
        setNewQuantity(oldQuantity);
      }
    } else {
      setNewQuantity(oldQuantity);
    }
  };

  useEffect(() => {
    async function APICallEditQuantity () {
      try {
        // API call
        const serverResponse = await APIEditPackListTagQuantity(data);
        if (serverResponse === true) {
          setOldQuantity(newQuantity);
          setListTagEdition(true);
        } else {
          setNewQuantity(oldQuantity);
        }
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }
    const data = {
      ...packListTag,
      PackList: {
        Quantity: newQuantity
      }
    };
    if (newQuantity !== 0 && newQuantity !== oldQuantity) {
      setUpdatingQuantityState(true);
      APICallEditQuantity(data);
      setUpdatingQuantityState(false);
    } else if (newQuantity <= 0) {
      handleDelete();
    }
  }, [newQuantity]);

  const packListID = packListTag.PackList.WebAppID;
  const pathBackList = `/${i18n.language}/${t('Lists')}/${t('MyLists')}/${t('PackLists')}/${packListID}`;

  const handleRedirectionToGear = (event) => {
    event.stopPropagation();
    if (quantityRowRef.current && packListTagTableRef.current) {
      if (packListTagTableRef.current.contains(event.target) && !quantityRowRef.current.contains(event.target)) {
        console.log('Navigating to:', pathBackList);
        navigate(pathBackList);
        console.log('Navigation triggered');
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleRedirectionToGear);
    return () => {
      document.removeEventListener('click', handleRedirectionToGear);
    };
  }, []);

  return (
    <>
      <ListPreviewTable id={'packListTagTable-preview'} ref={packListTagTableRef} onClick={() => handleRedirectionToGear(event)}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={'listTagRowTD'}>
            <GearTH colSpan={12}>
              {`${t('PackListTitle')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listTagRowTD'}>
            <GearTH colSpan={6}>
              {`${t('Name')}`}
            </GearTH>
            <GearTH colSpan={6}>
              {`${t('Category')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listTagRowTD'}>
            <GearTD colSpan={6}>
              {`${t(packListTag.PackList.Name)}`}
            </GearTD>
            <GearTD colSpan={6}>
              {`${t(packListTag.PackList.Category)}`}
            </GearTD>
          </GearTR>
          <GearTR id={'listTagRowTD'}>
            <GearTH colSpan={10}>
              {`${t('Quantity')}`}
            </GearTH>
            <GearTH colSpan={9}>
              {`${t('Delete')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listTagRowTD-quantity'} ref={quantityRowRef} style={{ cursor: 'default' }}>
            <GearTD colSpan={3}>
              <GearCommonButton onClick={() => handleQuantity('-')}>
                <GearCommonIcon icon={faMinus}/>
              </GearCommonButton>
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput type="number" value={newQuantity} placeholder={newQuantity} onChange={(event) => handleQuantity(null, event)}/>
            </GearTD>
            <GearTD colSpan={3}>
              <GearCommonButton onClick={() => handleQuantity('+')}>
                <GearCommonIcon icon={faPlus}/>
              </GearCommonButton>
            </GearTD>
            <GearTD colSpan={3}>
              <GearCommonButton onClick={() => handleDelete()}>
                <GearCommonIcon icon={faXmark}/>
              </GearCommonButton>
            </GearTD>
          </GearTR>
          <GearTR id={'listTagRowTH-weightVolume'}>
            <GearTH colSpan={6}>
              {packListTag.Metrics.Weight !== null
                ? `${t('Weight')} [${convertIfNecessarySI('Weight', packListTag.Metrics.Weight * newQuantity).convertedUnit}]`
                : `${t('Weight')}`
              }
            </GearTH>
            <GearTH colSpan={6}>
              {packListTag.Metrics.Volume !== null
                ? `${t('Volume')} [${convertIfNecessarySI('Volume', packListTag.Metrics.Volume * newQuantity).convertedUnit}]`
                : `${t('Volume')}`
              }
            </GearTH>
          </GearTR>
          <GearTR id={'listTagRowTD-quantity'}>
            <GearTD colSpan={6}>
              {packListTag.Metrics.Weight !== null
                ? convertIfNecessarySI('Weight', packListTag.Metrics.Weight * newQuantity).convertedValue
                : ''
              }
            </GearTD>
            <GearTD colSpan={6}>
              {packListTag.Metrics.Volume !== null
                ? convertIfNecessarySI('Volume', packListTag.Metrics.Volume * newQuantity).convertedValue
                : ''
              }
            </GearTD>
          </GearTR>
        </GearTableBody>
      </ListPreviewTable>
      {
        updatingQuantityState === true
          ? <LoadingOverlayComponent/>
          : <></>
      }
    </>
  );
}

PackListTagTableDisplay.propTypes = {
  packListTag: listPropTypes.isRequired,
  setListTagDeletion: PropTypes.func.isRequired,
  setListTagEdition: PropTypes.func.isRequired
};

export default PackListTagTableDisplay;
