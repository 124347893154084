// Import Functions
import { userRankGearValidationAccessArray } from '../../../../../datas/User/User_AddEdit_ChoiceArrays';
import { getCurrentDate } from '../Common/dates_Functions';

export function generateInformationsObjectFromForms (form2Data, gearData) {
  console.log('generateInformationsObjectFromForms');
  console.log('form2Data : ', form2Data);
  console.log('gearData : ', gearData);

  return {
    Name: form2Data.Name,
    WebAppID: `${form2Data.Brand.replace(/\s+/g, '')}${form2Data.Name.replace(/\s+/g, '')}`,
    TypeCategory: gearData.TypeCategory,
    Category: gearData.Category,
    SubCategory: gearData.SubCategory,
    AccessoryCategory: gearData.AccessoryCategory,
    Brand: form2Data.Brand || null,
    Colors: [form2Data.Colors0, form2Data.Colors1, form2Data.Colors2].filter(color => color) || []
  };
};

export function generateAssetsObjectFromForms (form1Data) {
  /*
  console.log('generateAssetsObjectFromForms');
  console.log('form1Data : ', form1Data);
  */

  const illustrationsArray = Object.entries(form1Data).map(([key, value]) => {
    if (key.startsWith('image-') || key.startsWith('remainingKey-')) {
      return value; // Return the file object
    }
    return null;
  }).filter(Boolean);
  console.log('illustrationsArray : ', illustrationsArray);

  return {
    Illustrations: illustrationsArray
  };
};

export function generateStatusObjectFromForms (form5Data, statusObject, fromAddOrEdit) {
  console.log('generateStatusObjectFromForms');
  // console.log('form5Data : ', form5Data);
  // console.log(`form5Data.Validated : ${form5Data.Validated}`);
  console.log('statusObject : ', statusObject);
  console.log(`fromAddOrEdit : ${fromAddOrEdit}`);

  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  let validationStatus;
  if (currentUserData.userProfile.User_Rank === 'USER') {
    validationStatus = false;
  } else if (userRankGearValidationAccessArray.includes(currentUserData.userProfile.User_Rank)) {
    if (form5Data.Validated === 'Validated') {
      validationStatus = true;
    } else {
      validationStatus = false;
    }
  }

  return {
    Validated: validationStatus,
    DateOfAdd: fromAddOrEdit === 'Add' ? getCurrentDate() : statusObject.DateOfAdd
  };
};

export function generateMetricsObjectFromForms (form2Data, form4Data) {
  /*
  console.log('generateMetricsObjectFromForms');
  console.log('form2Data : ', form2Data);
  console.log('form4Data : ', form4Data);
  */

  return {
    Price: parseFloat(form2Data.Price) || null,
    Volume: parseFloat(form4Data.Volume) || null,
    Weight: parseFloat(form4Data.Weight) || null,
    Density: parseFloat(form4Data.Density) || null
  };
};

export function generateCompactedObjectFromForms (form4Data) {
  /*
  console.log('generateCompactedObjectFromForms');
  console.log('form4Data : ', form4Data);
  */

  return {
    Shape: form4Data.CompactedShape || null,
    CylindricLength: parseFloat(form4Data.CylindricLength) || null,
    RectangularLength: parseFloat(form4Data.RectangularLength) || null,
    Width: parseFloat(form4Data.Width) || null,
    Height: parseFloat(form4Data.Height) || null,
    Diameter: parseFloat(form4Data.Diameter) || null
  };
};

export function generateStatisticsObjectFromForms (statisticsObject, fromAddOrEdit) {
  /*
  console.log('generateStatisticsObjectFromForms');
  console.log('statisticsObject : ', statisticsObject);
  console.log(`fromAddOrEdit : ${fromAddOrEdit}`);
  */

  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  return {
    AddedBy: fromAddOrEdit === 'Add' ? currentUserData.userProfile.User_ID : statisticsObject.AddedBy,
    AverageNoteReceived: fromAddOrEdit === 'Add' ? null : statisticsObject.IntrinsicValue_AverageNoteReceived,
    TotalVoteReceived: fromAddOrEdit === 'Add' ? null : statisticsObject.IntrinsicValue_TotalVoteReceived
  };
};
