// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';

// Import Datas
import { insulatorNatureChoiceArray, insulatorQualityChoiceArray } from '../../../../../../datas/ChoiceArrays/Gear/Bedding/specificBedding_ChoiceArrays';

// Import Functions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearSleepingBagInsulatorNatureRowAdd ({ gearData, insulatorNature, setInsulatorNature, insulatorQuality, setInsulatorQuality }) {
  const { t } = useTranslation();
  return (
    <>
      {insulatorNature === 'Down'
        ? (
            <>
              <GearTR id={`GearSleepingBagInsulatorNatureRowTHAdd-${gearData.SuCategory}`}>
                <GearTH colSpan={6}>{`${t('InsulatorNature')}`}</GearTH>
                <GearTH colSpan={6}>{`${t('InsulatorQuality')} [${getUnit('InsulatorQuality')}]`}</GearTH>
              </GearTR>
              <GearTR id={`GearSleepingBagInsulatorNatureRowTDAdd-${gearData.SuCategory}`}>
                <GearTD colSpan={6}>
                  <GearSelectDropDown options={insulatorNatureChoiceArray} choice={null} setChoice={setInsulatorNature} noChoice='Select'/>
                  <input type="hidden" name="InsulatorNature" value={insulatorNature} />
                </GearTD>
                <GearTD colSpan={6}>
                  <GearSelectDropDown options={insulatorQualityChoiceArray} choice={null} setChoice={setInsulatorQuality} noChoice='Select'/>
                  <input type="hidden" name="InsulatorQuality" value={insulatorQuality} />
                </GearTD>
              </GearTR>
            </>
          )
        : (
            <>
              <GearTR id={`GearSleepingBagInsulatorNatureRowTHAdd-${gearData.SuCategory}`}>
                <GearTH colSpan={12}>{`${t('InsulatorNature')}`}</GearTH>
              </GearTR>
              <GearTR id={`GearSleepingBagInsulatorNatureRowTDAdd-${gearData.SuCategory}`}>
                <GearTD colSpan={12}>
                  <GearSelectDropDown options={insulatorNatureChoiceArray} choice={null} setChoice={setInsulatorNature} noChoice='Select'/>
                  <input type="hidden" name="InsulatorNature" value={insulatorNature} />
                </GearTD>
              </GearTR>
            </>
          )
      }
    </>
  );
}

GearSleepingBagInsulatorNatureRowAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  insulatorNature: PropTypes.string,
  setInsulatorNature: PropTypes.func.isRequired,
  insulatorQuality: PropTypes.string,
  setInsulatorQuality: PropTypes.func.isRequired
};

export default GearSleepingBagInsulatorNatureRowAdd;
