// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import { userDataPropTypes } from '../../../../datas/PropTypes/userPropTypes';

// Import Components
import UserProfileEdit from './UserProfile_Edit';
import UserGeneralPreferenceEdit from './UserGeneralPreference_Edit';
import UserUnitPreferenceEdit from './UserUnitPreference_Edit';

// Import Functions
import { generateUserObjectFromForms } from '../../../../utils/Functions/User/FillUserObject';
import { APIEditUser } from '../../../../api/User/User_API';
import LoadingComponent from '../../../LoadingComponent/Loading_Component';

// Import Style
import { UserButtonContainer } from '../../../../utils/Style/js/Account/UserTableConstruction_Style';
import { GeneralButton } from '../../../../utils/Style/js/General/Button_Style';
import { StyledH1, TitleContainer } from '../../../../utils/Style/js/General/Font_Style';

function UserTableEdit ({ userData }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  console.log('userData : ', userData);

  const [loaderState, setLoaderState] = useState(false);
  const [temporaryUserData, setTemporaryUserData] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  const formRef1 = useRef();
  const formRef2 = useRef();
  const formRef3 = useRef();

  let form1 = null;
  let form2 = null;
  let form3 = null;

  const handleFormSubmit1 = (data) => {
    form1 = data;
    console.log('Form 1 data:', form1);
  };
  const handleFormError1 = (errors) => {
    console.log('handleFormError1');
    console.log('Form errors 1:', errors);
  };

  const handleFormSubmit2 = (data) => {
    form2 = data;
    console.log('Form 2 data:', form2);
  };

  const handleFormSubmit3 = (data) => {
    form3 = data;
    console.log('Form 2 data:', form3);
  };

  const triggerSubmit = async (e) => {
    e.preventDefault();

    if (formRef1.current) {
      formRef1.current.submit();
    }
    if (formRef2.current) {
      formRef2.current.submit();
    }

    if (formRef3.current) {
      formRef3.current.submit();
    }

    if (form1 !== null && form2 !== null && form3 !== null) {
      setLoaderState(true);
      console.log('enter here');

      const objectFromForms = generateUserObjectFromForms(form1, form2, form3);
      console.log('objectFromForms : ', objectFromForms);

      try {
        const serverResponse = await APIEditUser(objectFromForms);
        // console.log('server Response : ', serverResponse);
        setLoaderState(false);

        if (serverResponse.message === 'User Edited') {
          setTemporaryUserData(null);
          navigate(`/${i18n.language}/${t('Account')}/${form1.User_ID}`);
        } else if (serverResponse.error) {
          console.log('server Response Error : ', serverResponse.error.error);
          setTemporaryUserData(objectFromForms);
          setErrorMessages(serverResponse.error.error);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    } else {
      setErrorMessages([]);
    }
  };

  useEffect(() => {
  }, [temporaryUserData]);

  return (
    <>
      { loaderState
        ? <LoadingComponent/>
        : <>
            <TitleContainer>
              <StyledH1>{`${t('Profile')} :`}</StyledH1>
            </TitleContainer>
            <UserProfileEdit
              ref={formRef1}
              onSubmit={handleFormSubmit1}
              onError={handleFormError1}
              userProfile={userData.userProfile}
              temporaryUserProfile={temporaryUserData !== null ? temporaryUserData.userProfile : null}
              errorMessages={errorMessages}
            />

            <TitleContainer>
              <StyledH1>{`${t('GeneralPreference')} :`}</StyledH1>
            </TitleContainer>
            <UserGeneralPreferenceEdit
              userGeneralPreference={userData.userGeneralPreference}
              ref={formRef2}
              onSubmit={handleFormSubmit2}
            />

            <TitleContainer>
              <StyledH1>{`${t('UnitPreference')} :`}</StyledH1>
            </TitleContainer>
            <UserUnitPreferenceEdit
              userUnitPreference={userData.userUnitPreference}
              ref={formRef3}
              onSubmit={handleFormSubmit3}
            />
            <UserButtonContainer>
              <GeneralButton onClick={triggerSubmit}>{`${t('Validate')}`}</GeneralButton>
            </UserButtonContainer>
          </>
      }
    </>
  );
}

UserTableEdit.propTypes = {
  userData: userDataPropTypes
};

export default UserTableEdit;
