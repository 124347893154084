// Import React Libraries
import styled from 'styled-components';
import colors from '../../../Colors/colors';
import { responsiveWidthMobile } from '../../../Constant';

export const GearTable = styled.table`
  font-family: sans-serif;
  width: 100%;
  border-spacing: 4px;

   @media (max-width: ${responsiveWidthMobile}px){
  }
`;

export const GearTableBody = styled.tbody`

`;

export const GearTR = styled.tr`
`;

export const GearTH = styled.th`
  border-radius: 3px;
  background: ${colors.characteristicTH};
  height: 21px;
`;

export const GearTD = styled.td`
  border-radius: 3px;
  background: ${colors.characteristicTD};
  text-align: center;
  height: 21px;
`;
