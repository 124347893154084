// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Component
import Header from '../Header/Header';
import Footer from '../Footer/Footer';

export const AuthenticationLayout = ({ children }) => {
  const navigate = useNavigate();

  const authToken = sessionStorage.getItem('authenticationToken');
  const [authenticationState, setAuthenticationState] = useState(authToken !== null);
  console.log('AuthenticationLayout');

  // console.log(`authToken : ${authToken}`);
  // console.log('sessionStorage : ', sessionStorage);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem('userData'));

    // console.log('useEffect AuthenticationStorage');
    if (authToken !== null) {
      // console.log('setAuthenticationState : true');
      setAuthenticationState(true);
      const currentPathname = window.location.pathname;
      if (currentPathname === '/') {
        const langCode = userData.userGeneralPreference.User_Language;
        navigate(`/${langCode}`, { replace: true });
      }
    } else if (authToken === null) {
      // console.log('setAuthenticationState : false');
      setAuthenticationState(false);
    }
    // console.log(`authenticationState : ${authenticationState}`);
  }, [sessionStorage.authenticationToken]);

  useEffect(() => {
    // console.log(`redirect useEffect : authenticationState : ${authenticationState}`);
    if (authToken === null) {
      // console.log('REDIRECT');
      navigate('/', { replace: true });
    }
  }, []);

  return (
    <div>
      {authenticationState
        ? (
        <>
          <Header />
          {children}
          <Footer />
        </>
          )
        : <>
        {children}
        </>
      }
    </div>
  );
};
AuthenticationLayout.propTypes = {
  children: PropTypes.node.isRequired
};
