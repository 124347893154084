// Import React Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import Style
import styled from 'styled-components';

export const GearSelectDropDownContainer = styled.div`
  position: relative;
  width: 100%;
  height: 22px;
`;

export const GearDropdownButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background-color: #f5f5f5;
  text-align: center;
  &:focus {
    outline: none;
    border-color: #2684ff;
  }
  &:hover {
    background-color: #eee;
  }
`;

export const GearDropdownMenu = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: auto;
  z-index: 1000;
`;

export const GearDropdownOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  margin: 3px;
  border-radius: 2px;
  &:hover {
    background-color: #eee;
  }
  ${({ selected }) => selected && `
    background-color: #ccc;
  `}
`;

export const GearDropdownIndicator = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  pointer-events: none;
  
  color: #888;
`;

export const GearDropDownIcon = styled(FontAwesomeIcon)`
  width: 10px;
  height: 10px;
`;
