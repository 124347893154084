function isValidName (nameToTest) {
  // console.log(`userIDToTest : ${userIDToTest}`);
  if (nameToTest === '' || nameToTest === null) {
    return ('NameMissing');
  } else {
    // Regular expression pattern for email validation
    const userIDPattern = /^[a-zA-Z0-9\s]+$/;
    // Test the email address against the pattern
    return userIDPattern.test(nameToTest);
  }
};

function isValidBrand (brandToTest) {
  console.log('isValidBrand');
  console.log(`brandToTest : ${brandToTest}`);

  if (brandToTest === '' || brandToTest === null) {
    return ('BrandMissing');
  } else {
    // Regular expression pattern for email validation
    const userIDPattern = /^[a-zA-Z0-9\s]+$/;
    // Test the email address against the pattern
    return userIDPattern.test(brandToTest);
  }
};

function isValidPrice (priceToTest) {
  console.log('isValidPrice');
  console.log(`priceToTest : ${priceToTest}`);

  if (priceToTest !== '') {
    if (priceToTest < 0) {
      return ('PriceNegative');
    } else {
      return true;
    }
  } else {
    return true;
  }
};

export function commonFormValidTest (setFormDataErrorMessages, formData) {
  /*
  console.log('commonFormValidTest');
  console.log('formData : ', formData);
  */
  const isNameValid = isValidName(formData.get('Name'));
  const isBrandValid = isValidBrand(formData.get('Brand'));
  const isPriceValid = isValidPrice(formData.get('Price'));

  console.log(`isNameValid : ${isNameValid}`);
  console.log(`isBrandValid : ${isBrandValid}`);
  console.log(`isPriceValid : ${isPriceValid}`);

  if (isNameValid === true) {
    // console.log('User_ID Valid');
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      Name: ''
    }));
  } else {
    if (isNameValid === 'NameMissing') {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        Name: 'NameMissing'
      }));
    } else if (isNameValid === false) {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        Name: 'NameFormat'
      }));
    }
  }

  if (isBrandValid === true) {
    // console.log('User_ID Valid');
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      Brand: ''
    }));
  } else {
    if (isBrandValid === 'BrandMissing') {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        Brand: 'BrandMissing'
      }));
    } else if (isBrandValid === false) {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        Brand: 'BrandFormat'
      }));
    }
  }

  if (isPriceValid === true) {
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      Price: ''
    }));
  } else {
    if (isPriceValid === 'PriceNegative') {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        Price: 'PriceNegative'
      }));
    }
  }

  if (isNameValid === true && isBrandValid === true && isPriceValid === true) {
    return true;
  } else {
    return false;
  }
};
