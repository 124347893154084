// Import Datas

// Equipment
import { equipmentGearCategoryList } from '../../../datas/Equipment/EquipmentGearCategory_Datas';

// Accessory
import { shelterAccessoryCategoryList } from '../../../datas/Equipment/Shelter/ShelterAccessoryCategory';
import { beddingAccessoryCategoryList } from '../../../datas/Equipment/Bedding/BeddingAccessoryCategory';

export function getGearTypeCategoryList (gearTypeCategory) {
  const functionMap = {
    Equipment: equipmentGearCategoryList
  };

  const selectedItemList = functionMap[gearTypeCategory];
  if (selectedItemList) {
    /*
    console.log('selectedItemList');
    console.log(selectedItemList);
    */
    return selectedItemList;
  } else {
    console.error(`Unknown itemCategory: ${gearTypeCategory}`);
  }
};

export function getGearAccessoryCategoryList (gearData) {
  const functionMap = {
    Equipment: getAccessoryCategoryList(gearData)
  };

  const selectedItemList = functionMap[gearData.TypeCategory];
  if (selectedItemList) {
    /*
    console.log('selectedItemList');
    console.log(selectedItemList);
    */
    return selectedItemList;
  } else {
    console.error(`Unknown Category: ${gearData.TypeCategory}`);
  }
};

export function getAccessoryCategoryList (gearData) {
  const functionMap = {
    Shelter: shelterAccessoryCategoryList,
    Bedding: beddingAccessoryCategoryList
  };

  const selectedItemList = functionMap[gearData.Category];
  if (selectedItemList) {
    /*
    console.log('selectedItemList');
    console.log(selectedItemList);
    */
    return selectedItemList;
  } else {
    console.error(`Unknown Category: ${gearData.Category}`);
  }
};
