// Import React Libraries
import React from 'react';

// Import Components
import AuthenticationForm from '../../components/Authentication/Authentication_Form';

// Import Datas

// Import Style
import { PageWrapper } from '../../utils/Styles';

function AuthenticationPage () {
  return (
    <PageWrapper id='pageWrapperAccount'>
      <AuthenticationForm/>
    </PageWrapper>
  );
}

export default AuthenticationPage;
