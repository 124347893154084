// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import { GearSideBarContainer, GearSideBarSubCategory, GearSideBarSummary, GearSideBarTypeCategory, GearSideBarLink, GearSideBarWrapper } from '../../utils/Style/js/GearSideBar/GearSideBar_Style';

// Import Functions
// import { scrollToTop } from '../../utils/Functions/general_Functions';
import { getGearTypeCategoryList } from '../../utils/Functions/Gear/gearTypeCategory_Functions';
import { getGearSubCategoryList } from '../../utils/Functions/gearSubcategoryFunction';
import {
  getSideBarGearSubCategoryColorFunction,
  getSideBarGearTypeCategoryColorFunction
} from '../../utils/Functions/Gear/gearSideBarGetColor_Function';
import { SideBarArrowContainer, SideBarArrowIcon, SidebarContentContainer, SidebarWrapper } from '../../utils/Style/js/GearSideBar/SideBar_Style';

// Import Style

function GearSideBarSelectorComponent ({ gearTypeCategory, height }) {
  const { i18n, t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const sideBarRef = useRef(null);
  const gearCategoryList = getGearTypeCategoryList(gearTypeCategory);

  /*
  console.log('GearSideBarSelectorComponent');
  console.log(`gearTypeCategory : ${gearTypeCategory}`);
  console.log(`gearTypeCategoryList : ${gearCategoryList}`);
  */

  /*
  function handleOnClick () {
    scrollToTop();
  }
  */

  const handleClickOutside = (event) => {
    if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <SidebarWrapper ref={sideBarRef} isOpen={isOpen} height={height} id='sidebarWrapper'>
      <SidebarContentContainer isOpen={isOpen}>
      <GearSideBarWrapper id='gearSideBarWrapper'>
      {gearCategoryList.map((gearCategory) => {
        const equipmentSubCategoryList = getGearSubCategoryList(gearCategory.gearCategory);
        const gearCategorycolor = getSideBarGearTypeCategoryColorFunction(gearTypeCategory, gearCategory.gearCategory);
        const gearSubCategoryColor = getSideBarGearSubCategoryColorFunction(gearTypeCategory, gearCategory.gearCategory);
        /*
        console.log('gearCategoryList.map');
        console.log('gearCategory : ', gearCategory);
        console.log(`key : ${gearCategory.gearCategory}`);
        */
        return (
        <GearSideBarContainer id={`gearSideBarContainer-${gearCategory}`} key={gearCategory.gearCategory}>
          <GearSideBarSummary>
            <GearSideBarTypeCategory style={{ backgroundColor: `${gearCategorycolor}` }}>
              {t(gearCategory.text)}
            </GearSideBarTypeCategory>
          </GearSideBarSummary>

          { equipmentSubCategoryList.map((gearSubCategory) => (
            <GearSideBarLink
              to={`/${i18n.language}/${t(gearTypeCategory)}/${t(gearCategory.gearCategory)}/${t(gearSubCategory.subCategory)}`}
              key={gearSubCategory.subCategory}
              onClick={() => setIsOpen(!isOpen)}
            >
              <GearSideBarSubCategory gearsubcategorycolor={gearSubCategoryColor}>{t(gearSubCategory.text)}</GearSideBarSubCategory>
            </GearSideBarLink>
          ))}
        </GearSideBarContainer>
        );
      })}
      </GearSideBarWrapper>
      </SidebarContentContainer>
      <SideBarArrowContainer onClick={() => setIsOpen(!isOpen)}>
        <SideBarArrowIcon icon={isOpen ? faChevronLeft : faChevronRight}/>
      </SideBarArrowContainer>
    </SidebarWrapper>
  );
}

GearSideBarSelectorComponent.propTypes = {
  gearTypeCategory: PropTypes.string.isRequired,
  height: PropTypes.number.isRequired
};

export default GearSideBarSelectorComponent;
