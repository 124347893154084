import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';

export async function APIPostOwnerTag (data) {
  // console.log('APIPostOwnerTag');
  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/OwnerTag/`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'POST',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    // console.log('responseBody : ', responseBody);
    if (responseBody.message === 'OwnerTag created') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error:', error);
  }
};

export async function APIGetOwnerTag (data) {
  // console.log('APIGetOwnerTag');
  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/OwnerTag/${data.Owner}/${data.MongoDbId}`;
  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    // console.log('responseBody : ', responseBody);
    if (responseBody.message === 'OwnerTag found') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.error('error:', error);
  }
};

export async function APIDeleteOwnerTag (data) {
  // console.log('APIDeleteOwnerTag');
  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/OwnerTag/${data.Owner}/${data.MongoDbId}`;
  try {
    const response = await fetch(URLToFetch, {
      method: 'DELETE',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    // console.log('responseBody : ', responseBody);
    if (responseBody.message === 'OwnerTag deleted') {
      return false;
    } else {
      return true;
    }
  } catch (error) {
    console.log('error:', error);
  }
};
