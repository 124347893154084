// Import Style
import styled from 'styled-components';

export const CommingSoonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CommingSoonIllustrationContainer = styled.div`
  margin: 20px 0;
  max-width: 300px;
  max-height: 300px;
`;

export const CommingSoonIllustration = styled.img`
  width: 100%;
  height: 100%;
`;
