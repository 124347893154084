// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Component
import CommingSoonComponent from '../../components/CommingSoon/CommingSoon_Component';

// Import Styles
import { DisplayContainer, PageContainer, PageWrapper } from '../../utils/Styles';
import { StyledH1, TitleContainer } from '../../utils/Style/js/General/Font_Style';

// Import Constants

function ComparatorPage () {
  const { t } = useTranslation();
  return (
    <PageWrapper id='ComparatorPageWrapper'>
      <PageContainer>
        <DisplayContainer>
          <TitleContainer>
            <StyledH1>{`${t('Comparator')}`}</StyledH1>
          </TitleContainer>
          <CommingSoonComponent/>
        </DisplayContainer>
      </PageContainer>
    </PageWrapper>
  );
}

export default ComparatorPage;
