// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { specificCharacteristicPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import DisplayHelpComponent from '../../../../ToolTip/DisplayHelp_Component';

// Import Function
import { convertIfNecessarySI } from '../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Illustration
import HexagonalLengthAfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthA_fr.jpg';
import HexagonalLengthAen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthA_en.jpg';
import HexagonalLengthBfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthB_fr.jpg';
import HexagonalLengthBen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthB_en.jpg';
import HexagonalLengthCfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthC_fr.jpg';
import HexagonalLengthCen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthC_en.jpg';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearDeployedDimensionRowDisplay ({ specific, gearName }) {
  /*
  console.log('GearDeployedDimensionRowDisplay');
  console.log(`gearName : ${gearName}`);
  console.log('specific : ', specific);
  */

  const { i18n, t } = useTranslation();

  return (
  <>
    <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
      <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
    </GearTR>
    <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
      <GearTD colSpan={12}>
        {specific.Deployed.Shape !== null ? `${t(specific.Deployed.Shape)}` : ''}
      </GearTD>
    </GearTR>

    {specific.Deployed.Shape === 'Square' && (
      <>
      <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
        <GearTH colSpan={6}>
          {specific.Deployed.Length !== null
            ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.Length).convertedUnit}]`
            : `${t('Length')}`
          }
        </GearTH>
        <GearTH colSpan={6}>
          {specific.Deployed.Surface !== null
            ? `${t('Surface')} [${convertIfNecessarySI('Surface', specific.Deployed.Surface).convertedUnit}]`
            : `${t('Surface')}`
          }
          </GearTH>
      </GearTR>
      <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
        <GearTD colSpan={6}>
          {specific.Deployed.Length !== null ? convertIfNecessarySI('Distance', specific.Deployed.Length).convertedValue : ''}
          </GearTD>
        <GearTD colSpan={6}>
          {specific.Deployed.Surface !== null ? specific.Deployed.Surface : ''}
        </GearTD>
      </GearTR>
      </>
    )}
    {specific.Deployed.Shape === 'Rectangle' && (
      <>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTH colSpan={4}>
            {specific.Deployed.Length !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.Length).convertedUnit}]`
              : `${t('Length')}`
            }
          </GearTH>
          <GearTH colSpan={4}>
            {specific.Deployed.Length !== null
              ? `${t('Width')} [${convertIfNecessarySI('Distance', specific.Deployed.Width).convertedUnit}]`
              : `${t('Width')}`
            }
            </GearTH>
          <GearTH colSpan={4}>
            {specific.Deployed.Surface !== null
              ? `${t('Surface')} [${convertIfNecessarySI('Surface', specific.Deployed.Surface).convertedUnit}]`
              : `${t('Surface')}`
            }
          </GearTH>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTD colSpan={4}>
            {specific.Deployed.Length !== null ? convertIfNecessarySI('Distance', specific.Deployed.Length).convertedValue : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specific.Deployed.Width !== null ? convertIfNecessarySI('Distance', specific.Deployed.Width).convertedValue : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specific.Deployed.Surface !== null ? specific.Deployed.Surface : ''}
          </GearTD>
        </GearTR>
      </>
    )}
    {specific.Deployed.Shape === 'Triangle' && (
      <>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTH colSpan={4}>
            {specific.Deployed.LengthA !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.LengthA).convertedUnit}]`
              : `${t('Length')}`
            }
            <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthAfr : HexagonalLengthAen}/>
          </GearTH>
          <GearTH colSpan={4}>
            {specific.Deployed.LengthB !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.LengthB).convertedUnit}]`
              : `${t('Length')}`
            }
            <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthBfr : HexagonalLengthBen}/>
          </GearTH>
          <GearTH colSpan={4}>
            {specific.Deployed.LengthC !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.LengthC).convertedUnit}]`
              : `${t('Length')}`
            }
            <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthCfr : HexagonalLengthCen}/>
          </GearTH>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTD colSpan={4}>
            {specific.Deployed.LengthA !== null ? convertIfNecessarySI('Distance', specific.Deployed.LengthA).convertedValue : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specific.Deployed.LengthB !== null ? convertIfNecessarySI('Distance', specific.Deployed.LengthB).convertedValue : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specific.Deployed.LengthC !== null ? convertIfNecessarySI('Distance', specific.Deployed.LengthC).convertedValue : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTH colSpan={12}>
            {specific.Deployed.Surface !== null
              ? `${t('Surface')} [${convertIfNecessarySI('Surface', specific.Deployed.Surface).convertedUnit}]`
              : `${t('Surface')}`
            }
            </GearTH>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTD colSpan={12}>
            {specific.Deployed.Surface !== null ? specific.Deployed.Surface : ''}
            </GearTD>
        </GearTR>
      </>
    )}
    {specific.Deployed.Shape === 'Hexagonal' && (
      <>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTH colSpan={4}>
            {specific.Deployed.LengthA !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.LengthA).convertedUnit}]`
              : `${t('Length')}`
            }
            <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthAfr : HexagonalLengthAen}/>
          </GearTH>
          <GearTH colSpan={4}>
            {specific.Deployed.LengthB !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.LengthB).convertedUnit}]`
              : `${t('Length')}`
            }
            <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthBfr : HexagonalLengthBen}/>
          </GearTH>
          <GearTH colSpan={4}>
            {specific.Deployed.LengthC !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.LengthC).convertedUnit}]`
              : `${t('Length')}`
            }
            <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthCfr : HexagonalLengthCen}/>
          </GearTH>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTD colSpan={4}>
            {specific.Deployed.LengthA !== null ? convertIfNecessarySI('Distance', specific.Deployed.LengthA).convertedValue : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specific.Deployed.LengthB !== null ? convertIfNecessarySI('Distance', specific.Deployed.LengthB).convertedValue : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specific.Deployed.LengthC !== null ? convertIfNecessarySI('Distance', specific.Deployed.LengthC).convertedValue : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTH colSpan={12}>
            {specific.Deployed.Surface !== null
              ? `${t('Surface')} [${convertIfNecessarySI('Surface', specific.Deployed.Surface).convertedUnit}]`
              : `${t('Surface')}`
            }
            </GearTH>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTD colSpan={12}>
            {specific.Deployed.Surface !== null ? specific.Deployed.Surface : ''}
            </GearTD>
        </GearTR>
      </>
    )}
    {specific.Deployed.Shape === 'Mummy' && (
      <>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTH colSpan={4}>
            {specific.Deployed.Length !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', specific.Deployed.Length).convertedUnit}]`
              : `${t('Length')}`
            }
          </GearTH>
          <GearTH colSpan={4}>
            {specific.Deployed.WidthShoulder !== null
              ? `${t('WidthShoulder')} [${convertIfNecessarySI('Distance', specific.Deployed.WidthShoulder).convertedUnit}]`
              : `${t('WidthShoulder')}`
            }
          </GearTH>
          <GearTH colSpan={4}>
            {specific.Deployed.WidthFoot !== null
              ? `${t('WidthFoot')} [${convertIfNecessarySI('Distance', specific.Deployed.WidthFoot).convertedUnit}]`
              : `${t('WidthFoot')}`
            }
          </GearTH>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowDisplay-${gearName}`}>
          <GearTD colSpan={4}>
            {specific.Deployed.Length !== null
              ? convertIfNecessarySI('Distance', specific.Deployed.Length).convertedValue
              : ''
            }
          </GearTD>
          <GearTD colSpan={4}>
            {specific.Deployed.WidthShoulder !== null
              ? convertIfNecessarySI('Distance', specific.Deployed.WidthShoulder).convertedValue
              : ''
            }
          </GearTD>
          <GearTD colSpan={4}>
            {specific.Deployed.WidthFoot !== null
              ? convertIfNecessarySI('Distance', specific.Deployed.WidthFoot).convertedValue
              : ''
            }
          </GearTD>
        </GearTR>
      </>
    )}
  </>
  );
}

GearDeployedDimensionRowDisplay.propTypes = {
  specific: specificCharacteristicPropTypes,
  gearName: PropTypes.string.isRequired
};

export default GearDeployedDimensionRowDisplay;
