// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
// import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../datas/PropTypes/gearPropTypes';

// Import Functions
import { scrollToTop } from '../../utils/Functions/general_Functions';
import { getGearAccessoryCategoryList } from '../../utils/Functions/Gear/gearTypeCategory_Functions';

// Import Style
import { StyledTitleH2 } from '../../utils/Style/GlobalStyle';
import { ProductSubCategorySelectorContainer, ProductPreviewContainer } from '../../utils/Style/ItemSubCategorySelectorStyle';
import { PictureContainerSelector, ArticlePicturePreviewSelection } from '../../utils/Style/PreviewStyle';

function GearAccessoryCategorySelectorComponent ({ gearData }) {
  /*
  console.log('GearAccessoryCategorySelectorComponent');
  console.log('gearData : ', gearData);
  */

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const gearAccessoryCategoryList = getGearAccessoryCategoryList(gearData);

  const handleAccessoryCategorySelection = (accessoryCategory) => {
    console.log('handleAccessoryCategorySelection');
    console.log(`accessoryCategory : ${accessoryCategory}`);

    const accessoryCategoryPath = `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t(accessoryCategory)}`;
    navigate(accessoryCategoryPath);
    scrollToTop();
  };

  return (
    <ProductSubCategorySelectorContainer id='productSubCategorySelectorContainer'>
      {
        gearAccessoryCategoryList.map((gear) => (
          <ProductPreviewContainer
            key={gear.Category}
            id='productPreviewContainer'
            onClick={() => handleAccessoryCategorySelection(gear.AccessoryCategory)}
          >
            <PictureContainerSelector id='pictureContainerSelector'>
              <ArticlePicturePreviewSelection src={gear.illustration} alt='picture1'/>
            </PictureContainerSelector>
            <StyledTitleH2>{t(gear.text)}</StyledTitleH2>
          </ProductPreviewContainer>
        ))
      }
    </ProductSubCategorySelectorContainer>
  );
}

GearAccessoryCategorySelectorComponent.propTypes = {
  gearData: gearDataPropTypes.isRequired
};

export default GearAccessoryCategorySelectorComponent;
