// Import Functions
import { storeAuthenticationToken, storeUserData } from '../../utils/Functions/Storage_Functions/SessionStorage_Functions';

// Import Constants
import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';
import { APIgetUser } from './User_API';

export async function authenticationLoginAPI (userIDs) {
  // console.log('authenticationAPI');
  // console.log('userIDs :', userIDs);

  const URLToFetch = `https://${WebServerIPAddress}/User/Login`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userIDs)
    });

    // console.log('response : ', response);
    // console.log('response.statusText : ', response.statusText);

    if (response.statusText === 'OK') {
      console.log('Authentication Successfull');

      const data = await response.json();
      console.log('data : ', data);
      storeAuthenticationToken(data.token);

      const userData = await APIgetUser(userIDs.User_ID);
      console.log('userData : ', userData);
      await storeUserData(userData);

      return true;
    } else {
      // console.log('Authentication Denied');
      return false;
    }

    /*
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    */

    // window.language = window.profile.Language;
    // initializePage();
  } catch (error) {
    console.error('Error during authentication:', error);
  }
};
