// Import Assets
import {
  cookingSubCategoryIllustration,
  stoveSubCategoryIllustration
} from '../../Illustrations/equipment_Illustrations';

export const cookingSubCategoryList = [
  {
    category: 'Cooking',
    subCategory: 'Cooking',
    text: 'Cooking',
    illustration: cookingSubCategoryIllustration
  },
  {
    category: 'Cooking',
    subCategory: 'Stove',
    text: 'Stove',
    illustration: stoveSubCategoryIllustration
  }
];
