// Import Assets
import {
  beddingCategoryIllustration,
  shelterCategoryIllustration
} from '../Illustrations/equipment_Illustrations';

export const equipmentGearCategoryList = [
  {
    gearCategory: 'Shelter',
    text: 'Shelter-title',
    illustration: shelterCategoryIllustration
  },
  {
    gearCategory: 'Bedding',
    text: 'Bedding-title',
    illustration: beddingCategoryIllustration
  }
];
