// Import React Libraries
import React from 'react';
// import { useParams } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes, gearItemPropTypes } from '../../datas/PropTypes/gearPropTypes';

// Import Components
import GearDataTableEdit from './Tab/Edit/GearTableConstructionEdit';

// Import Functions
// import { getItemCorrespondingToId, getProductList } from '../../../../utils/Functions/ItemFunctions';

// Import Style
import {
  ProductDetailWrapper,
  ProductPresentationWrapper,
  ProductInfoWrapper, ProductInfoContainer,
  DescriptionContainer
} from '../../utils/Style/ItemDetailStyle';

function ItemDetailEdit ({ gearData, gearId, item }) {
  /*
  console.log('ItemDetailEdit');
  console.log(`itemTypeCategory : ${gearTypeCategory}`);
  console.log(`itemCategory : ${gearCategory}`);
  console.log(`itemSubCategory : ${gearSubCategory}`);
  console.log(`gearId : ${gearId}`);
  console.log('item : ', item);
  */

  return (
        <ProductDetailWrapper id='productDetailContainer'>
          <ProductPresentationWrapper id='productPresentationWrapper'>
            <ProductInfoWrapper id='productInfoWrapper'>
              <ProductInfoContainer id='productInfoWrapper'>
              </ProductInfoContainer>
            </ProductInfoWrapper>
          </ProductPresentationWrapper>
          <DescriptionContainer id='descriptionContainer'>
            <GearDataTableEdit
              gearData={gearData}
              item={item}
            />
          </DescriptionContainer>
        </ProductDetailWrapper>
  );
}

ItemDetailEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  gearId: PropTypes.string.isRequired,
  item: gearItemPropTypes
};

export default ItemDetailEdit;
