// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import { userDataPropTypes } from '../../../../datas/PropTypes/userPropTypes';

// Import Components
import UserProfileDisplay from './UserProfileTable_Display';
import UserGeneralPreferenceDisplay from './UserGeneralPreference_Display';
import UserUnitPreferenceDisplay from './UserUnitPreference_Display';

// Import Styles
import { UserButtonContainer } from '../../../../utils/Style/js/Account/UserTableConstruction_Style';
import { GeneralButton } from '../../../../utils/Style/js/General/Button_Style';
import { StyledH1, TitleContainer } from '../../../../utils/Style/js/General/Font_Style';

function UserTableDisplay ({ userData }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  console.log('UserTableDisplay');
  console.log('userData : ', userData);

  const handleEditRedirection = (data) => {
    navigate(`/${i18n.language}/${t('Account')}/${userData.userProfile.User_ID}/${t('Edition')}`);
  };
  return (
    <>
      <TitleContainer>
        <StyledH1>{`${t('Profile')} :`}</StyledH1>
      </TitleContainer>
      <UserProfileDisplay userProfile={userData.userProfile}/>

      <TitleContainer>
        <StyledH1>{`${t('GeneralPreference')} :`}</StyledH1>
      </TitleContainer>
      <UserGeneralPreferenceDisplay userGeneralPreference={userData.userGeneralPreference}/>

      <TitleContainer>
        <StyledH1>{`${t('UnitPreference')} :`}</StyledH1>
      </TitleContainer>
      <UserUnitPreferenceDisplay userUnitPreference={userData.userUnitPreference}/>

      <UserButtonContainer>
        <GeneralButton onClick={handleEditRedirection}>{`${t('EditProfile')}`}</GeneralButton>
      </UserButtonContainer>
    </>
  );
}

UserTableDisplay.propTypes = {
  userData: userDataPropTypes
};

export default UserTableDisplay;
