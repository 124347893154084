export const navigationServiceContentUser = [
  {
    pageLinkTitle: 'Equipment',
    pageLink: 'Equipment',
    dropDown: true,
    dropDownOptions: [
      'Shelter', 'Bedding'
    ],
    dropDownOptionsLinks: [
      'Equipment/Shelter', 'Equipment/Bedding'
    ],
    dropDownOptionsLinksRender: [
      'Shelter', 'Bedding'
    ]
  },
  {
    pageLinkTitle: 'Lists',
    pageLink: 'Lists',
    dropDown: false
  },
  {
    pageLinkTitle: 'Comparator',
    pageLink: 'Comparator',
    dropDown: false
  }
];

export const navigationServiceContentAdmin = [
  {
    pageLinkTitle: 'Equipment',
    pageLink: 'Equipment',
    dropDown: true,
    dropDownOptions: [
      'Control', 'Shelter', 'Bedding'
    ],
    dropDownOptionsLinks: [
      'Equipment/Control', 'Equipment/Shelter', 'Equipment/Bedding'
    ],
    dropDownOptionsLinksRender: [
      'Control', 'Shelter', 'Bedding'
    ]
  },
  {
    pageLinkTitle: 'Lists',
    pageLink: 'Lists',
    dropDown: false
  },
  {
    pageLinkTitle: 'Comparator',
    pageLink: 'Comparator',
    dropDown: false
  }
];

/*
  const dropDownTitle3 = 'Cooking';
  const links3 = ['Cooking/Cooking', 'Cooking/Stove'];
  const linksRender3 = ['Cooking', 'Stove'];

  const dropDownTitle4 = 'Portage';
  const links4 = ['Portage/Minus30L', 'Portage/Plus30L'];
  const linksRender4 = ['Minus30L', 'Plus30L'];
  */
