// Import React Libraries
import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Component
import GearItemSelectorComponent from '../../components/Gear/gearItemSelector_Component';
import LoadingComponent from '../../components/LoadingComponent/Loading_Component';
import GearItemFilterTableComponent from '../../components/Gear/Tab/Filters/gearItemSelectorFilter_Component';

// Import Function
import { getProductList } from '../../utils/Functions/Gear/GatheringGears/gatheringGear_Functions';
import { displayItemSelectorPage } from '../../utils/Functions/PathFunctions/gearPath_Functions';

import { APICheckIfUserHasPendingValidationGear, APICheckIfPendingValidationGear } from '../../api/Gear/Gear_API';
import { APICheckIfUserHasPendingValidationAccessories, APICheckIfPendingValidationAccessories } from '../../api/Gear/Accessory_API';

// Import Styles

function ItemSelectorPage ({ gearTypeCategory, gearCategory, gearSubCategory, gearAccessoryCategory }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentUrl = location.pathname;
  const userData = JSON.parse(sessionStorage.getItem('userData'));
  const navigate = useNavigate();

  const gearData = {
    TypeCategory: gearTypeCategory,
    Category: gearCategory,
    SubCategory: gearSubCategory,
    AccessoryCategory: gearAccessoryCategory
  };

  const shouldDisplayItemSelectorPage = displayItemSelectorPage(gearData, currentUrl, t, i18n);

  /*
  console.log('ItemSelectorPage');
  console.log(`currentUrl : ${currentUrl}`);
  console.log(`shouldDisplayItemSelectorPage : ${shouldDisplayItemSelectorPage}`);
  */

  const [itemList, setItemList] = useState([]);
  const [isTherePendingValidationGear, setIsTherePendingValidationGear] = useState(null);
  const [loaderState, setLoadedState] = useState(true);
  const [gearChoiceSelection, setGearChoiceSelection] = useState('Validated');

  useEffect(() => {
    async function fetchData () {
      try {
        setItemList([]);
        const data = await getProductList(gearData, gearChoiceSelection);
        setItemList(data);
        setLoadedState(false);
      } catch (error) {
        console.error('Error fetching product list:', error);
      }
    }

    fetchData();
  }, [shouldDisplayItemSelectorPage, gearChoiceSelection, gearTypeCategory, gearCategory, gearSubCategory, gearAccessoryCategory]);

  useEffect(() => {
    async function checkIfPendingValidation () {
      try {
        setLoadedState(true);
        if (userData.userProfile.User_Rank !== 'ADMIN') {
          if (gearSubCategory === 'Accessory') {
            const serverResult = await APICheckIfUserHasPendingValidationAccessories(gearData, userData.userProfile.User_ID);
            setIsTherePendingValidationGear(serverResult);
          } else {
            const serverResult = await APICheckIfUserHasPendingValidationGear(gearData, userData.userProfile.User_ID);
            if (serverResult === false) {
              setGearChoiceSelection('Validated');
            }
            setIsTherePendingValidationGear(serverResult);
          }
        } else if (userData.userProfile.User_Rank === 'ADMIN') {
          console.log('ADMIN DETECTED');
          console.log('-------------------------------------------------------------');
          if (gearSubCategory === 'Accessory') {
            const serverResult = await APICheckIfPendingValidationAccessories(gearData);
            console.log(`serverResult : ${serverResult}`);
            setIsTherePendingValidationGear(serverResult);
          } else {
            const serverResult = await APICheckIfPendingValidationGear(gearData);
            console.log(`serverResult : ${serverResult}`);
            if (serverResult === false) {
              setGearChoiceSelection('Validated');
            }
            setIsTherePendingValidationGear(serverResult);
          }
        }
        setLoadedState(false);
      } catch (error) {
        console.error('Error checking if pending validation:', error);
      }
    }
    checkIfPendingValidation();
  }, [gearChoiceSelection, gearTypeCategory, gearCategory, gearSubCategory, gearAccessoryCategory, navigate]);
  /*
  console.log(`currentUrl : ${currentUrl}`);
  console.log(`shouldDisplayPageContainer ! ${shouldDisplayPageContainer}`);
  console.log('itemList: ', itemList);
  */

  return (
    <>
      {shouldDisplayItemSelectorPage
        ? (
            <>
              <GearItemFilterTableComponent gearData={gearData} setGearChoiceSelection={setGearChoiceSelection} isTherePendingValidationGear={isTherePendingValidationGear}/>
              {loaderState
                ? <LoadingComponent/>
                : <GearItemSelectorComponent gearData={gearData} itemList={itemList}/>
              }
            </>
          )
        : (
            <Outlet/>
          )
      }
    </>
  );
}

ItemSelectorPage.propTypes = {
  gearTypeCategory: PropTypes.string.isRequired,
  gearCategory: PropTypes.string.isRequired,
  gearSubCategory: PropTypes.string.isRequired,
  gearAccessoryCategory: PropTypes.string
};

export default ItemSelectorPage;
