/* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-/ Store Authentication Token \-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ */
export function storeAuthenticationToken (token) {
  // window.authenticationToken = token;
  sessionStorage.setItem('authenticationToken', token);
  console.log(`Authentication Token Retrieved : ${token}`);
};

/* _-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-/ Store User Profile \-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_ */
export async function storeUserData (userData) {
  // window.userProfile = userProfile;
  sessionStorage.setItem('userData', JSON.stringify(userData));

  console.log('User Data Retrieved : ', userData);
};
/*
// Usefull if the data persistance is researched
export async function storeTemporaryUserData (userData) {
  sessionStorage.setItem('temporaryUserData', JSON.stringify(userData));

  console.log('User Data Retrieved : ', userData);
};

export async function resetTemporaryUserData () {
  sessionStorage.setItem('temporaryUserData', '');

  console.log('Temporary User Data Reseted');
};
*/
