const colorsPalette =
  {
    uranianBlue: '#A3CEF1',
    airSUperiorityBlue: '#6096BA',
    oxfordBlue: '#002440',
    aliceBlue: '#F0F8FF',
    munsellBlue: '#2292A4',
    robbinEggBlue: '#34CED3',

    // SideBar
    // Shelter
    darkCyan: '#238C8C',
    tiffanyBlue: '#6ED8D8',
    // Bedding
    lavender: '#CA88F9',
    thistle: '#EBCDFE',

    // Characteristic
    columniaBlue: '#C9E8F7',
    babyBlue: '#71C2EA'
  };

export default colorsPalette;
