// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';

// Import Datas
import { tissuMaterialsChoiceArray } from '../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearMaterialTableEditRow ({ gearData, setMaterials, materials }) {
  const { t } = useTranslation();
  // console.log('materials : ', materials);

  return (
    <>
      <GearTR id={`gearMaterialTableAddTH-${gearData.gearSubCategory}`}>
        <GearTH colSpan={12}>{`${t('Materials')}`}</GearTH>
      </GearTR>
      <GearTR id={`gearMaterialTableAddTD-${gearData.gearSubCategory}`}>
        <GearTD colSpan={4}>
          <GearSelectDropDown
            options={tissuMaterialsChoiceArray}
            choice={materials[0]}
            setChoice={setMaterials[0]}
            noChoice='Material'
          />
        </GearTD>
        <GearTD colSpan={4}>
          <GearSelectDropDown
            options={tissuMaterialsChoiceArray}
            choice={materials[1]}
            setChoice={setMaterials[1]}
            noChoice='Material'
          />
        </GearTD>
        <GearTD colSpan={4}>
          <GearSelectDropDown
            options={tissuMaterialsChoiceArray}
            choice={materials[2]}
            setChoice={setMaterials[2]}
            noChoice='Material'
          />
        </GearTD>
      </GearTR>
    </>
  );
}

GearMaterialTableEditRow.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  setMaterials: PropTypes.arrayOf(PropTypes.func).isRequired,
  materials: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default GearMaterialTableEditRow;
