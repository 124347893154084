// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { specificCharacteristicPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import GearDeployedDimensionRowDisplay from '../../Common/GearDeployedDimensionRowDisplay';

// Import Style
import {
  GearTR, GearTH
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearTentAwningDeployedDimensionRowDisplay ({ specificCharacteristic, gearName }) {
  /*
  console.log('GearAwningDimensionRowAdd');
  console.log(`gearName : ${gearName}`);
  console.log('specificCharacteristic : ', specificCharacteristic);
  */
  const { t } = useTranslation();

  const awning1DeployedDimension = {
    Deployed: {
      Shape: specificCharacteristic.Deployed.Awning1Shape,
      Length: specificCharacteristic.Deployed.Awning1Length,
      LengthA: specificCharacteristic.Deployed.Awning1LengthA,
      LengthB: specificCharacteristic.Deployed.Awning1LengthB,
      LengthC: specificCharacteristic.Deployed.Awning1LengthC,
      Width: specificCharacteristic.Deployed.Awning1Width,
      Surface: specificCharacteristic.Deployed.Awning1Surface
    }
  };

  const awning2DeployedDimension = {
    Deployed: {
      Shape: specificCharacteristic.Deployed.Awning2Shape,
      Length: specificCharacteristic.Deployed.Awning2Length,
      LengthA: specificCharacteristic.Deployed.Awning2LengthA,
      LengthB: specificCharacteristic.Deployed.Awning2LengthB,
      LengthC: specificCharacteristic.Deployed.Awning2LengthC,
      Width: specificCharacteristic.Deployed.Awning2Width,
      Surface: specificCharacteristic.Deployed.Awning2Surface
    }
  };

  /*
  console.log('AWNINGS');
  console.log('awning1DeployedDimension : ', awning1DeployedDimension);
  console.log('awning2DeployedDimension : ', awning2DeployedDimension);
  */

  return (
    <>
        {specificCharacteristic.Awning === 0 && (
            <>
            </>
        )}
        {(specificCharacteristic.Awning === 1 || (specificCharacteristic.Awning === 2 && specificCharacteristic.AwningIdentical === 'Yes')) && (
          <>
          <GearTR id={`gearCommonRowTH-${gearName}`}>
            <GearTH colSpan={12}>{`${t('Awning')} 1`}</GearTH>
          </GearTR>
          <GearDeployedDimensionRowDisplay specific={awning1DeployedDimension} gearName={gearName}/>
          </>
        )}
        {(specificCharacteristic.Awning === 2 && (specificCharacteristic.AwningIdentical === null || specificCharacteristic.AwningIdentical === 'No')) && (
          <>
            <GearTR id={`gearCommonRowTH-${gearName}`}>
              <GearTH colSpan={12}>{`${t('Awning')} 1`}</GearTH>
            </GearTR>
            <GearDeployedDimensionRowDisplay specific={awning1DeployedDimension} gearName={'Awning'}/>
            <GearTR id={`gearCommonRowTH-${gearName}`}>
              <GearTH colSpan={12}>{`${t('Awning')} 2`}</GearTH>
            </GearTR>
            <GearDeployedDimensionRowDisplay specific={awning2DeployedDimension} gearName={'Awning'}/>
          </>
        )}
    </>
  );
}

GearTentAwningDeployedDimensionRowDisplay.propTypes = {
  gearName: PropTypes.string.isRequired,
  specificCharacteristic: specificCharacteristicPropTypes.isRequired
};

export default GearTentAwningDeployedDimensionRowDisplay;
