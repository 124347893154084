// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import
import PropTypes from 'prop-types';
import {
  gearDataPropTypes,
  specificCharacteristicPropTypes
} from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import GearDeployedDimensionTableComponentEdit from '../../Common/GearDeployedDimensionEdit';

// Import Style
import {
  GearTR, GearTH
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearAwningDeployedDimensionRowEdit ({ gearData, gearSpecificCharacteristic, awning }) {
  // console.log('GearAwningDimensionRowAdd');
  // console.log(`awning : ${awning}`);
  const { t } = useTranslation();

  const gearDataAwning = {
    ...gearData,
    gearSubCategory: 'Awning'
  };

  return (
    <>
        {awning === 0 && (
            <>
            </>
        )}
        {awning === 1 && (
          <>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={12}>{`${t('Awning')} 1`}</GearTH>
          </GearTR>
          <GearDeployedDimensionTableComponentEdit
            gearData={gearDataAwning}
            specific={gearSpecificCharacteristic}
            nameComplement='Awning1'
          />
          </>
        )}
        {awning === 2 && (
          <>
            <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
              <GearTH colSpan={12}>{`${t('Awning')} 1`}</GearTH>
            </GearTR>
            <GearDeployedDimensionTableComponentEdit
              gearData={gearDataAwning}
              specific={gearSpecificCharacteristic}
              nameComplement='Awning1'
            />
            <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
              <GearTH colSpan={12}>{`${t('Awning')} 2`}</GearTH>
            </GearTR>
            <GearDeployedDimensionTableComponentEdit
              gearData={gearDataAwning}
              specific={gearSpecificCharacteristic}
              nameComplement='Awning2'
            />
        </>
        )}
    </>
  );
}

GearAwningDeployedDimensionRowEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  gearSpecificCharacteristic: specificCharacteristicPropTypes.isRequired,
  awning: PropTypes.number.isRequired
};

export default GearAwningDeployedDimensionRowEdit;
