// Impor Constants
import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';

export async function APIPostPackListTag (data) {
  console.log('APIPostPackListTag');
  console.log('data : ', data);

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/PackListTag/`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'POST',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      console.log('responseBody : ', responseBody.message);
      throw error;
    }
    console.log('responseBody : ', responseBody);
    if (responseBody.message === 'PackListTag Created') {
      return true;
    } else if (responseBody.message === 'ListTag already existing') {
      console.log('ListTag already existing');
      return false;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error:', error);
  }
};

export async function APIGetPackListTags (listMongoDBID) {
  /*
  console.log('APIGetPackListTags');
  console.log('listMongoDBID : ', listMongoDBID);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/PackListTag/${listMongoDBID}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Read the response body
    const responseBody = await response.json();
    console.log('responseBody : ', responseBody);

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    console.log('responseBody : ', responseBody);
    return responseBody;
  } catch (error) {
    console.log('error:', error);
  }
};

export async function APIEditPackListTagQuantity (data) {
  // console.log('APIEditListTagQuantity');
  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/PackListTag/`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'PUT',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    console.log('responseBody : ', responseBody);
    if (responseBody.message === 'PackListTag Updated') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error:', error);
  }
};

export async function APIDeleteUserPackListTag (listTagMongoDBID) {
  /*
  console.log('APIDeleteUserListTag');
  console.log(`listTagMongoDBID : ${listTagMongoDBID}`)
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/PackListTag/${listTagMongoDBID}`;
  try {
    const response = await fetch(URLToFetch, {
      method: 'DELETE',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    console.log('responseBody : ', responseBody);
    if (responseBody.message === 'PackListTag deleted') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error:', error);
  }
};
