export function getMaterialsArray (originalMaterialArray) {
  // console.log('originalMaterialArray : ', originalMaterialArray);
  const materialArray = [];
  for (const material of originalMaterialArray) {
    if (material !== '') {
      materialArray.push(material);
    }
  }
  // console.log('materialArray : ', materialArray);
  return materialArray;
};
