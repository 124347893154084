// Import React Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Component
import Logo from './Logo/Logo';
import NavMenu from '../Nav/NavMenu';
import NavItems from '../Nav/NavItems';
import Navbar from '../Nav/Navbar';

// Import Function
import { scrollToTop } from '../../utils/Functions/general_Functions';

// Import Style
import {
  HeaderFirstLineContainer,
  HeaderWrapper,
  LogoDiv
} from '../../utils/Style/js/HeaderNav/HeaderStyle';

function Header () {
  const { i18n } = useTranslation();

  function handleOnClick () {
    scrollToTop();
  }

  return (
    <HeaderWrapper id='headerWrapper'>
      <HeaderFirstLineContainer>
        <LogoDiv id='logoDiv'>
          <Link to={`/${i18n.language}`} onClick={handleOnClick}><Logo/></Link>
        </LogoDiv>
        <NavMenu />
      </HeaderFirstLineContainer>
      <NavItems />
      <Navbar/>
    </HeaderWrapper>
  );
}

export default Header;
