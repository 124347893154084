export function displayAccountSelection (currentUrl, t, i18n) {
  // console.log('displayAccountSelection');
  if (currentUrl === `/${i18n.language}/${t('Account')}`) {
    return true;
  } else {
    return false;
  }
};

export function shouldDisplayAccount (currentUrl, t, i18n) {
  // console.log('shouldDisplayAccount');
  // console.log(`currentUrl : ${currentUrl}`);
  const basePath = `/${i18n.language}/${t('Account')}/`;

  if (currentUrl.startsWith(basePath)) {
    const remainingPath = currentUrl.slice(basePath.length);
    const pathSegments = remainingPath.split('/');
    // console.log(`pathSegments[1] : ${pathSegments[1]}`);
    // pathSegments[0] = parsedUserID
    // pathSegments[1] allow to detect if the url continues atfer parsedUserID
    if (pathSegments[0] !== t('Add') && (pathSegments[1] === undefined || pathSegments[1] === '')) {
      return true;
    }
  }
  return null;
};

/*

if (userRank !== 'ADMIN') {
    if (currentUrl === `/${i18n.language}/${t('Account')}/${userID}`) {
      return true;
    } else {
      return false;
    }
  } else if (userRank === 'ADMIN') {
    if (userID !== t('Add')) {
      if (regex.test(currentUrl) && !currentUrl.endsWith(`/${editPath}`)) {
        console.log('case 1');
        return true;
      }
    } else {
      console.log('case 2');
      return false;
    }
  }
    */
