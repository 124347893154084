// Import React Libraries
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Import Datas

// Import Style
import { PageWrapper } from '../../utils/Styles';
import { displayAccountSelection } from '../../utils/Functions/PathFunctions/accountPath_Functions';
import { GeneralButton } from '../../utils/Style/js/General/Button_Style';
import { AccountActionSelectorWrapper } from '../../utils/Style/js/Account/AccountActionSelector_Style';

function AccountSelectionPage () {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const userData = JSON.parse(sessionStorage.getItem('userData'));
  const shouldDisplayAccountSelectionPage = displayAccountSelection(currentUrl, t, i18n);

  const userDisplayProfilPagePath = `/${i18n.language}/${t('Account')}/${userData.userProfile.User_ID}`;
  // console.log(`shouldDisplayAccountPage : ${shouldDisplayAccountSelectionPage}`);
  useEffect(() => {
    if (shouldDisplayAccountSelectionPage && userData.userProfile.User_Rank !== 'ADMIN') {
      navigate(userDisplayProfilPagePath);
    }
  }, [shouldDisplayAccountSelectionPage, userData, navigate, i18n.language, t]);

  const handleAddUserRedirection = () => {
    const addUserPagePath = `/${i18n.language}/${t('Account')}/${t('Add')}`;
    navigate(addUserPagePath);
  };

  const handleAdminDisplayProfileRedirection = () => {
    navigate(userDisplayProfilPagePath);
  };

  return (
      <PageWrapper id='pageWrapperAccount'>
        {shouldDisplayAccountSelectionPage
          ? (
              <>
                {userData.userProfile.User_Rank === 'ADMIN'
                  ? <AccountActionSelectorWrapper>
                      <GeneralButton onClick={() => handleAdminDisplayProfileRedirection()}>{`${t('MyProfile')}`}</GeneralButton>
                      <GeneralButton onClick={() => handleAddUserRedirection()}>{`${t('AddUser')}`}</GeneralButton>
                    </AccountActionSelectorWrapper>
                  : <></>
                }
              </>
            )
          : (
              <Outlet/>
            )}
      </PageWrapper>
  );
}

export default AccountSelectionPage;
