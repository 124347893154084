// Import React Libraries
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { errorMessagesProptypes } from '../../../../../datas/PropTypes/errorMessagesPropTypes';
import { listPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Component
import ColGroup12 from '../../../../Gear/Tab/ColGroup12';
import { GearSelectDropDown } from '../../../../Gear/SelectDropDown/GearSelectDropDown';

// Import Datas
import { listVisibilityChoiceArray } from '../../../../../datas/ChoiceArrays/Lists/lists_ChoiceArrays';

// Import Functions
import { listCommonFormValidTest } from '../../../../../utils/Functions/Lists/FillDataObject/ListFormCheck_Functions';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../../utils/Style/js/Account/UserDataTableDisplay_Style';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';
import DisplayHelpComponent from '../../../../ToolTip/DisplayHelp_Component';
import { APIgetUserPseudoFromID } from '../../../../../api/User/User_API';

// Import Constants

function ListCommonTableEdit ({ listCharacteristics, onSubmit, onError, temporaryListData, errorMessages }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [listName, setListName] = useState(temporaryListData !== null ? temporaryListData.Informations.Name : listCharacteristics.Informations.Name);
  const listType = temporaryListData !== null ? temporaryListData.Informations.Type : listCharacteristics.Informations.Type;
  const [listVisibility, setListVisibility] = useState(temporaryListData !== null ? temporaryListData.Status.Visibility : listCharacteristics.Status.Visibility);
  const [creatorPseudo, setCreatorPseudo] = useState(null);

  const [formDataErrorMessages, setFormDataErrorMessages] = useState({
    ListName: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const isListFormValid = listCommonFormValidTest(setFormDataErrorMessages, formData);
    console.log('isListFormValid : ');
    console.log(isListFormValid);

    if (isListFormValid) {
      const data = Object.fromEntries(formData.entries());
      console.log(`isListFormValid : ${isListFormValid}`);
      if (onSubmit) {
        onSubmit(data);
      }
    } else {
      console.log(`isListFormValid : ${isListFormValid}`);
      if (onError) {
        onError(formDataErrorMessages);
      }
    }
  };

  useEffect(() => {
    async function getCreatorPseudo () {
      try {
        const creatorPseudo = await APIgetUserPseudoFromID(listCharacteristics.Informations.Creator);
        setCreatorPseudo(creatorPseudo);
      } catch (error) {
        console.error('Error getting creator pseudp :', error);
      }
    }
    getCreatorPseudo();
  }, []);

  useEffect(() => {
    console.log('formDataErrorMessages : ', formDataErrorMessages);
  }, [formDataErrorMessages, errorMessages]);

  useEffect(() => {
    console.log('useEffect');
    console.log('temporaryListData : ', temporaryListData);
  }, [temporaryListData]);

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <UserDataTable>
        <ColGroup12/>
        <UserDataTableBody>
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('ListCreator')}`}</UserDataTH>
            <UserDataTH colSpan={6}>
              {`${t('ListName')}`}
              <DisplayHelpComponent
                text1={`${t('NameListDescription1')}`}
                text2={`${t('NameListDescription2')}`}
              />
            </UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              {creatorPseudo}
              <input type="hidden" name="Creator" value={listCharacteristics.Informations.Creator} />
            </UserDataTD>
            <UserDataTD colSpan={6}>
              <GearFormInput
                type="text"
                name="Name"
                placeholder={`${t('Name')}`}
                value= {listName}
                onChange={(e) => setListName(e.target.value)}
              />
            </UserDataTD>
          </UserDataTR>
          {errorMessages && errorMessages.includes('ListName')
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6}/>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('ListNameUnavailable')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.ListName === 'FormatListName'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6}/>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('FormatListName')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.ListName === 'MissingListName'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6}/>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('MissingListName')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          <UserDataTR>
            <UserDataTH colSpan={6}>
              {`${t('Type')}`}
            </UserDataTH>
            <UserDataTH colSpan={6}>
              {`${t('ListVisibility')}`}
              <DisplayHelpComponent
                text1={`${t('VisibilityListWarning')}`}
              />
            </UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              {`${t(`${listCharacteristics.Informations.Type}Title`)}`}
              <input type="hidden" name="Type" value={listType} />
            </UserDataTD>
            <UserDataTD colSpan={6}>
              <GearSelectDropDown options={listVisibilityChoiceArray} choice={listVisibility} setChoice={setListVisibility} noChoice={`${t('Select')}`}/>
              <input type="hidden" name="ListVisibility" value={listVisibility} />
            </UserDataTD>
          </UserDataTR>
          <input type="hidden" name="Price" value={listCharacteristics.Metrics.Price} />
          <input type="hidden" name="Weight" value={listCharacteristics.Metrics.Weight} />
          <input type="hidden" name="Volume" value={listCharacteristics.Metrics.Volume} />
          <input type="hidden" name="Density" value={listCharacteristics.Metrics.Density} />
        </UserDataTableBody>
      </UserDataTable>
    </form>
  );
}

ListCommonTableEdit.propTypes = {
  listCharacteristics: listPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  temporaryListData: listPropTypes,
  errorMessages: errorMessagesProptypes
};

export default forwardRef(ListCommonTableEdit);
