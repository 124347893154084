// Import Datas
import { contactMessageObjectChoiceArray } from '../../../datas/Contact/Contact_ChoiceArrays';

function isValidMessageObject (messageObject) {
  console.log(`messageObject : ${messageObject}`);
  return contactMessageObjectChoiceArray.includes(messageObject);
}

export function contactFormValidTest (setFormDataErrorMessages, formData) {
  console.log('formData : ', formData);
  const isMessageObjectValid = isValidMessageObject(formData.get('messageObject'));

  if (isMessageObjectValid === true) {
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      messageObject: ''
    }));
  } else {
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      messageObject: 'MissingMessageObject'
    }));
  }

  // if (isEmailValid === true && isPhoneValid === true && isNameValid === true) {
  if (isMessageObjectValid === true) {
    return true;
  } else {
    return false;
  }
}
