// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';

// Import Functions
import { getGearAccessoryCategoryList } from '../../../../../utils/Functions/Gear/gearTypeCategory_Functions';

// Import Style
import {
  GearTableBody,
  GearTR, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

import { GearControlTable } from '../../../../../utils/Style/js/GearTable/GearControlTable_Style';

// Import Constants

function EquipmentAccessoryCategoryControlTableDisplay ({ gearData, PendingValidationQuantity }) {
  /*
  console.log('EquipmentControlRowDisplay');
  console.log(`PendingValidationQuantity : ${Category}`);
  console.log('PendingValidationQuantity :', PendingValidationQuantity);
  */
  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  const accessoryCategoryList = getGearAccessoryCategoryList(gearData);

  const redirectToSubCategory = (accessoryCategory) => {
    const subCategoryPath = `/${i18n.language}/${t(`${gearData.TypeCategory}`)}/${t(`${gearData.Category}`)}/${t(`${gearData.SubCategory}`)}/${t(`${accessoryCategory}`)}`;
    navigate(subCategoryPath);
  };

  return (
    <>
        {
          accessoryCategoryList.map((AccessoryCategory) => (
            <React.Fragment key={`${AccessoryCategory}`}>
              <GearControlTable id={'equipmentControlTableDisplay'}>
                <ColGroup12/>
                <GearTableBody>
                  <GearTR key={`${AccessoryCategory.AccessoryCategory}`} onClick={() => redirectToSubCategory(AccessoryCategory.AccessoryCategory)}>
                    <GearTD
                      colSpan={6}
                      style={ PendingValidationQuantity[gearData.Category][gearData.SubCategory] > 0 ? { backgroundColor: 'red' } : {}}
                    >
                      {`${t(AccessoryCategory.AccessoryCategory)}`}
                    </GearTD>
                    <GearTD
                      colSpan={6}
                      style={ PendingValidationQuantity[gearData.Category][gearData.SubCategory] > 0 ? { backgroundColor: 'red' } : {}}
                    >
                      { PendingValidationQuantity[gearData.Category][gearData.SubCategory][AccessoryCategory.AccessoryCategory] }
                    </GearTD>
                  </GearTR>
                </GearTableBody>
              </GearControlTable>
            </React.Fragment>
          ))
        }
    </>
  );
}

EquipmentAccessoryCategoryControlTableDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  PendingValidationQuantity: PropTypes.string.isRequired
};

export default EquipmentAccessoryCategoryControlTableDisplay;
