// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';
import GearSleepingBagInsulatorNatureRowAdd from './GearSleepingBagInsulatorNatureRowAdd';
import GearMaterialRowAdd from '../../Common/GearMaterialRowAdd';
import GearDeployedDimensionRowAdd from '../../Common/GearDeployedDimensionAdd';
import DisplayHelpComponent from '../../../../../ToolTip/DisplayHelp_Component';

// Import Funtions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Datas
import {
  yesNoChoiceArray, leftRightChoiceArray,
  seasonChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Style
import { GearFormInput } from '../../../../../../utils/Style/js/GearForm/GearForm_Style';
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

function GearSleepingBagSpecificTableAdd ({ gearData, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [hood, setHood] = useState(null);
  const [zipSide, setZipSide] = useState(null);
  const [pairable, setPairable] = useState(null);
  const [season, setSeason] = useState(null);
  const [insulatorNature, setInsulatorNature] = useState(null);
  const [insulatorQuality, setInsulatorQuality] = useState(null);

  const [materials, setMaterials] = useState([]);

  const setFirstMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[0] = value;
      return newMaterials;
    });
  };

  const setSecondMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[1] = value;
      return newMaterials;
    });
  };

  const setThirdMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[2] = value;
      return newMaterials;
    });
  };

  const materialsSetter = [setFirstMaterial, setSecondMaterial, setThirdMaterial];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearTarpSpecificTable-${gearData.gearSubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearTarpSpecificRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={6}>{`${t('Hood')}`}</GearTH>
            <GearTH colSpan={6}>
              {`${t('Season')}`}
              <DisplayHelpComponent
                text1={`${t('SeasonDescription1')}`}
                text2={`${t('SeasonDescription2')}`}
                text3={`${t('SeasonDescription3')}`}
                text4={`${t('SeasonDescription4')}`}
                alignLeft={true}
              />
            </GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTD-${gearData.gearSubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={yesNoChoiceArray} choice={null} setChoice={setHood} noChoice='Select'/>
              <input type="hidden" name="Hood" value={hood} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={seasonChoiceArray} choice={null} setChoice={setSeason} noChoice='Select'/>
              <input type="hidden" name="Season" value={season} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={6}>{`${t('ZipSide')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('Pairable')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTD-${gearData.gearSubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={leftRightChoiceArray} choice={null} setChoice={setZipSide} noChoice='Select'/>
              <input type="hidden" name="ZipSide" value={zipSide} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={yesNoChoiceArray} choice={null} setChoice={setPairable} noChoice='Select'/>
              <input type="hidden" name="Pairable" value={pairable} />
            </GearTD>
          </GearTR>
          <GearSleepingBagInsulatorNatureRowAdd
            gearData={gearData}
            insulatorNature={insulatorNature}
            setInsulatorNature={setInsulatorNature}
            insulatorQuality={insulatorQuality}
            setInsulatorQuality={setInsulatorQuality}
          />
          <GearMaterialRowAdd gearData={gearData} setMaterials={materialsSetter}/>
          <input type="hidden" name="Materials" value={materials} />
          <GearTR id={`gearTarpSpecificRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={12}>{`${t('Temperature')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={4}>{`${t('TemperatureComfort')} [${getUnit('Temperature')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('TemperatureLimit')} [${getUnit('Temperature')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('TemperatureExtrem')} [${getUnit('Temperature')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTD-${gearData.gearSubCategory}`}>
            <GearTD colSpan={4}>
              <GearFormInput type="number" name="TemperatureComfort" placeholder={`${t('TemperatureComfort')}`}/>
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput type="number" name="TemperatureLimit" placeholder={`${t('TemperatureLimit')}`}/>
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput type="number" name="TemperatureExtrem" placeholder={`${t('TemperatureExtrem')}`}/>
            </GearTD>
          </GearTR>
          <GearDeployedDimensionRowAdd gearData={gearData} />
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearSleepingBagSpecificTableAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearSleepingBagSpecificTableAdd);
