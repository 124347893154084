// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { faMinus, faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';

// Import PropTypes
import PropTypes from 'prop-types';
import { listPropTypes } from '../../datas/PropTypes/listPropTypes';

// Import Components
import ColGroup12 from '../Gear/Tab/ColGroup12';
import LoadingOverlayComponent from '../LoadingComponent/LoadingOverlay_Component';

// Import Functions
import { APIDeleteUserListTag, APIEditListTagQuantity } from '../../api/List/ListTag_API';
import { convertIfNecessarySI } from '../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Style
import { GearTableBody, GearTR, GearTD, GearTH } from '../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../utils/Style/js/GearForm/GearForm_Style';
import { ListPreviewTable } from '../../utils/Style/js/Lists/ListPreviewDataTable_Style';
import { GearCommonButton, GearCommonIcon } from '../../utils/Style/js/GearTable/GearCommonTable_Style';

// Import Constants

function ListTagTableDisplay ({ listTag, setListTagDeletion, setListTagEdition }) {
  console.log('ListTagTableDisplay');
  // Actualiser données de la liste -> poids/volume ->actualiser l'affichage
  // Implementer edition de la liste
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const quantityRowRef = useRef(null);
  const listTagTableRef = useRef(null);
  const [oldQuantity, setOldQuantity] = useState(listTag.Gear.Quantity);
  const [newQuantity, setNewQuantity] = useState(listTag.Gear.Quantity);
  const [updatingQuantityState, setUpdatingQuantityState] = useState(false);

  const handleQuantity = (operator, event) => {
    if (operator === '+') {
      setNewQuantity(newQuantity + 1);
    } else if (operator === '-') {
      setNewQuantity(newQuantity - 1);
    } else {
      setNewQuantity(event.target.value);
    }
  };

  const handleDelete = async () => {
    const deleteConfirmation = window.confirm(`${t('DeleteConfirmationElementFromList')}`);
    if (deleteConfirmation) {
      const serverResponse = await APIDeleteUserListTag(listTag._id);
      if (serverResponse === true) {
        setListTagDeletion(true);
      } else {
        setNewQuantity(oldQuantity);
      }
    } else {
      setNewQuantity(oldQuantity);
    }
  };

  useEffect(() => {
    async function APICallEditQuantity () {
      try {
        // API call
        const serverResponse = await APIEditListTagQuantity(data);
        if (serverResponse === true) {
          setOldQuantity(newQuantity);
          setListTagEdition(true);
        } else {
          setNewQuantity(oldQuantity);
        }
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }
    const data = {
      ...listTag,
      Gear: {
        Quantity: newQuantity
      }
    };
    if (newQuantity !== 0 && newQuantity !== oldQuantity) {
      setUpdatingQuantityState(true);
      APICallEditQuantity(data);
      setUpdatingQuantityState(false);
    } else if (newQuantity <= 0) {
      handleDelete();
    }
  }, [newQuantity]);

  const pathBackToItem = `/${i18n.language}/${t(listTag.Gear.TypeCategory)}/${t(listTag.Gear.Category)}/${t(listTag.Gear.SubCategory)}/${listTag.Gear.WebAppID}`;

  const handleRedirectionToGear = (event) => {
    if (quantityRowRef.current && listTagTableRef.current) {
      if (listTagTableRef.current.contains(event.target) && !quantityRowRef.current.contains(event.target)) {
        navigate(pathBackToItem);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleRedirectionToGear);
    return () => {
      document.removeEventListener('click', handleRedirectionToGear);
    };
  }, []);

  return (
    <>
      <ListPreviewTable id={'listTagTable-preview'} ref={listTagTableRef} onClick={() => handleRedirectionToGear(event)}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={'listTagRowTD'}>
            <GearTH colSpan={4}>
              {`${t('TypeCategory')}`}
            </GearTH>
            <GearTH colSpan={4}>
              {`${t('Category')}`}
            </GearTH>
            <GearTH colSpan={4}>
              {`${t('SubCategory')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listTagRowTD'}>
            <GearTD colSpan={4}>
              {`${t(listTag.Gear.TypeCategory)}`}
            </GearTD>
            <GearTD colSpan={4}>
              {`${t(listTag.Gear.Category)}`}
            </GearTD>
            <GearTD colSpan={4}>
              {`${t(listTag.Gear.SubCategory)}`}
            </GearTD>
          </GearTR>
          <GearTR id={'listTagRowTD'}>
            <GearTH colSpan={3}>
              {`${t('Brand')}`}
            </GearTH>
            <GearTH colSpan={9}>
              {`${t('Name')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listTagRowTD'}>
            <GearTD colSpan={3}>
              {listTag.Gear.Brand}
            </GearTD>
            <GearTD colSpan={9}>
              {listTag.Gear.Name}
            </GearTD>
          </GearTR>
          <GearTR id={'listTagRowTD'}>
            <GearTH colSpan={10}>
              {`${t('Quantity')}`}
            </GearTH>
            <GearTH colSpan={9}>
              {`${t('Delete')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listTagRowTD-quantity'} ref={quantityRowRef} style={{ cursor: 'default' }}>
            <GearTD colSpan={3}>
              <GearCommonButton onClick={() => handleQuantity('-')}>
                <GearCommonIcon icon={faMinus}/>
              </GearCommonButton>
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput type="number" value={newQuantity} placeholder={newQuantity} onChange={(event) => handleQuantity(null, event)}/>
            </GearTD>
            <GearTD colSpan={3}>
              <GearCommonButton onClick={() => handleQuantity('+')}>
                <GearCommonIcon icon={faPlus}/>
              </GearCommonButton>
            </GearTD>
            <GearTD colSpan={3}>
              <GearCommonButton onClick={() => handleDelete()}>
                <GearCommonIcon icon={faXmark}/>
              </GearCommonButton>
            </GearTD>
          </GearTR>
          <GearTR id={'listTagRowTH-weightVolume'}>
            <GearTH colSpan={6}>
              {listTag.Metrics.Weight !== null
                ? `${t('Weight')} [${convertIfNecessarySI('Weight', listTag.Metrics.Weight * newQuantity).convertedUnit}]`
                : `${t('Weight')}`
              }
            </GearTH>
            <GearTH colSpan={6}>
              {listTag.Metrics.Volume !== null
                ? `${t('Volume')} [${convertIfNecessarySI('Volume', listTag.Metrics.Volume * newQuantity).convertedUnit}]`
                : `${t('Volume')}`
              }
            </GearTH>
          </GearTR>
          <GearTR id={'listTagRowTD-quantity'}>
            <GearTD colSpan={6}>
              {listTag.Metrics.Weight !== null
                ? convertIfNecessarySI('Weight', listTag.Metrics.Weight * newQuantity).convertedValue
                : ''
              }
            </GearTD>
            <GearTD colSpan={6}>
              {listTag.Metrics.Volume !== null
                ? convertIfNecessarySI('Volume', listTag.Metrics.Volume * newQuantity).convertedValue
                : ''
              }
            </GearTD>
          </GearTR>
        </GearTableBody>
      </ListPreviewTable>
      {
        updatingQuantityState === true
          ? <LoadingOverlayComponent/>
          : <></>
      }
    </>
  );
}

ListTagTableDisplay.propTypes = {
  listTag: listPropTypes.isRequired,
  setListTagDeletion: PropTypes.func.isRequired,
  setListTagEdition: PropTypes.func.isRequired
};

export default ListTagTableDisplay;
