// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import {
  gearDataPropTypes,
  specificCharacteristicPropTypes
} from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Datas
import { singularValues } from '../../../../../../utils/Constants/Gear_Constants';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearTentAwningAmountRowDisplay ({ gearData, specificCharacteristic }) {
  // console.log('GearAwningDimensionRowAdd');
  // console.log(`awning : ${awning}`);
  const { t } = useTranslation();

  return (
    <>
        {singularValues.includes(specificCharacteristic.Awning) && (
            <>
            <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
              <GearTH colSpan={12}>
                {singularValues.includes(specificCharacteristic.Awning) ? `${t('Awning')}` : `${t('Awning(s)')}`}
              </GearTH>
            </GearTR>
            <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
              <GearTD colSpan={12}>
                {specificCharacteristic.Awning !== null ? specificCharacteristic.Awning : ''}
              </GearTD>
            </GearTR>
            </>
        )}
        {specificCharacteristic.Awning === 2 && (
          <>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={6}>{`${t('Awnings')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('AwningIdenticals')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTD colSpan={6}>
              {specificCharacteristic.Awning !== null ? specificCharacteristic.Awning : ''}
            </GearTD>
            <GearTD colSpan={6}>
              {specificCharacteristic.AwningIdentical !== null ? `${t(specificCharacteristic.AwningIdentical)}` : ''}
            </GearTD>
          </GearTR>
          </>
        )}
    </>
  );
}

GearTentAwningAmountRowDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  specificCharacteristic: specificCharacteristicPropTypes
};

export default GearTentAwningAmountRowDisplay;
