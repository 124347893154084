// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';
import EquipmentAccessoryCategoryControlTableDisplay from './EquipmentAccessoryCategoryControlTableDisplay';

// Import Functions
import { getGearSubCategoryList } from '../../../../../utils/Functions/gearSubcategoryFunction';

// Import Style
import { GearControlTable } from '../../../../../utils/Style/js/GearTable/GearControlTable_Style';
import {
  GearTableBody,
  GearTR, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function EquipmentSubCategoryControlTableDisplay ({ gearData, PendingValidationQuantity }) {
  /*
  console.log('EquipmentControlRowDisplay');
  console.log('gearData :', gearData);
  console.log('PendingValidationQuantity :', PendingValidationQuantity);
  */

  const navigate = useNavigate();

  const { i18n, t } = useTranslation();

  const subCategoryList = getGearSubCategoryList(gearData.Category);

  const redirectToSubCategory = (subCategory) => {
    const subCategoryPath = `/${i18n.language}/${t('Equipment')}/${t(`${gearData.Category}`)}/${t(`${subCategory}`)}`;
    navigate(subCategoryPath);
  };

  return (
    <>
        {
          subCategoryList.map((SubCategory, index) => {
            gearData = {
              ...gearData,
              SubCategory: SubCategory.subCategory
            };
            return (
              <React.Fragment key={`${SubCategory}-${index}`}>
                { SubCategory.subCategory !== 'Accessory'
                  ? <>
                      <GearControlTable id={'equipmentControlTableDisplay'}>
                        <ColGroup12/>
                        <GearTableBody>
                          <GearTR key={`${SubCategory.subCategory.text}`} onClick={() => redirectToSubCategory(SubCategory.subCategory)}>
                            <GearTD
                              colSpan={6}
                              style={ PendingValidationQuantity[gearData.Category][SubCategory.subCategory] > 0 ? { backgroundColor: 'red' } : {}}
                            >
                              {`${t(SubCategory.text)}`}
                            </GearTD>
                            <GearTD
                              colSpan={6}
                              style={ PendingValidationQuantity[gearData.Category][SubCategory.subCategory] > 0 ? { backgroundColor: 'red' } : {}}
                            >
                              {
                                PendingValidationQuantity[gearData.Category][SubCategory.subCategory]
                              }
                            </GearTD>
                          </GearTR>
                        </GearTableBody>
                      </GearControlTable>
                    </>
                  : <>
                      <EquipmentAccessoryCategoryControlTableDisplay gearData={gearData} PendingValidationQuantity={PendingValidationQuantity}/>
                    </>
                }

              </React.Fragment>
            );
          })
        }
    </>
  );
}

EquipmentSubCategoryControlTableDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  PendingValidationQuantity: PropTypes.string.isRequired
};

export default EquipmentSubCategoryControlTableDisplay;
