// Import React Libraries
import React
  from 'react';

// Import PropTypes
// import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../datas/PropTypes/gearPropTypes';

// Import Components
import GearDataTableAdd from './Tab/Add/GearTableConstructionAdd';

// Import Style
import {
  ProductDetailWrapper,
  ProductPresentationWrapper,
  ProductInfoWrapper, ProductInfoContainer,
  DescriptionContainer
} from '../../utils/Style/ItemDetailStyle';

function ItemDetailAdd ({ gearData }) {
  // console.log('ItemDetailAdd');
  // console.log('gearData : ', gearData);

  return (
    <ProductDetailWrapper id='productDetailContainer'>
      <ProductPresentationWrapper id='productPresentationWrapper'>
        <ProductInfoWrapper id='productInfoWrapper'>
          <ProductInfoContainer id='productInfoWrapper'>
          </ProductInfoContainer>
        </ProductInfoWrapper>
      </ProductPresentationWrapper>
      <DescriptionContainer id='descriptionContainer'>
        <GearDataTableAdd gearData={gearData}/>
      </DescriptionContainer>
    </ProductDetailWrapper>
  );
}

ItemDetailAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired
};

export default ItemDetailAdd;
