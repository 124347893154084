// Import React Libraries
import React from 'react';

// Import Components
import UserTableAdd from '../../components/Account/Tab/Add/UserTableConstruction_Add';
// Import Datas

// Import Style
import { PageWrapper } from '../../utils/Styles';

function AccountAddPage () {
  return (
      <PageWrapper id='pageWrapperAccountAdd'>
        <UserTableAdd/>
      </PageWrapper>
  );
}

export default AccountAddPage;
