// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes, specificCharacteristicPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import GearMosquitoNetRow from '../../Common/GearMosquitoNetRowDisplay';
import GearMaterialRowDisplay from '../../Common/GearMaterialRowDisplay';
import GearDeployedDimensionRowDisplay from '../../Common/GearDeployedDimensionRowDisplay';
import GearTentAwningDeployedDimensionRowDisplay from './GearTentAwningDeployedDimensionRowDisplay';

// Import Functions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants
import { singularValues } from '../../../../../../utils/Constants/Gear_Constants';
import DisplayHelpComponent from '../../../../../ToolTip/DisplayHelp_Component';
import GearTentAwningAmountRowDisplay from './GearTentAwningAmountRowDisplay';
import { convertIfNecessarySI } from '../../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

function GearTentSpecificTableDisplay ({ gearData, specificCharacteristic, gearName }) {
  /*
  console.log('GearTentSpecificTableDisplay');
  console.log('gearData : ', gearData);
  */

  const { t } = useTranslation();
  return (
    <GearTable id={`gearCommonTable-${gearName}`}>
      <ColGroup12/>
      <GearTableBody>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.Person) ? `${t('Person')}` : `${t('Persons')}`}
          </GearTH>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.Door) ? `${t('Door')}` : `${t('Doors')}`}
          </GearTH>
          <GearTH colSpan={4}>
            {specificCharacteristic.Deployed.Surface !== null
              ? `${t('Height')} [${convertIfNecessarySI('Distance', specificCharacteristic.Deployed.Height).convertedUnit}]`
              : `${t('Height')}`
            }
          </GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTD-${gearName}`}>
          <GearTD colSpan={4}>
            {specificCharacteristic.Person !== null ? specificCharacteristic.Person : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.Door !== null ? specificCharacteristic.Door : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.Deployed.ChamberHeight !== null ? convertIfNecessarySI('Distance', specificCharacteristic.Deployed.ChamberHeight).convertedValue : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={4}>
            {`${t('SelfSupporting')}`}
          </GearTH>
          <GearTH colSpan={4}>
            {`${t('Type')}`}
          </GearTH>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.Wall) ? `${t('Wall')}` : `${t('Walls')}`}
          </GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTD-${gearName}`}>
          <GearTD colSpan={4}>
            {specificCharacteristic.SelfSupporting !== null ? `${t(specificCharacteristic.SelfSupporting)}` : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.Type !== null ? specificCharacteristic.Type : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.Wall !== null ? specificCharacteristic.Wall : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.Season) ? `${t('Season')}` : `${t('Seasons')}`}
            <DisplayHelpComponent
              text1={`${t('SeasonDescription1')}`}
              text2={`${t('SeasonDescription2')}`}
              text3={`${t('SeasonDescription3')}`}
              text4={`${t('SeasonDescription4')}`}
              alignLeft={true}
            />
          </GearTH>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.Ventilation) ? `${t('Ventilation')}` : `${t('Ventilations')}`}
          </GearTH>
          <GearTH colSpan={4}>
            {`${t('FPU')}`}
            <DisplayHelpComponent text1={`${t('UVProtectionFactor')}`}/>
          </GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTD colSpan={4}>
            {specificCharacteristic.Season !== null ? specificCharacteristic.Season : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.Ventilation !== null ? specificCharacteristic.Ventilation : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.Upf !== null ? specificCharacteristic.Upf : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.Hoop) ? `${t('Hoop')}` : `${t('Hoops')}`}
          </GearTH>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.Peg) ? `${t('Peg')}` : `${t('Pegs')}`}
          </GearTH>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.GuyWire) ? `${t('GuyWire')}` : `${t('GuyWires')}`}
          </GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTD colSpan={4}>
            {specificCharacteristic.Hoop !== null ? specificCharacteristic.Hoop : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.Peg !== null ? specificCharacteristic.Peg : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.GuyWire !== null ? specificCharacteristic.GuyWire : ''}
          </GearTD>
        </GearTR>
        <GearMosquitoNetRow specificCharacteristic={specificCharacteristic} gearName={gearName}/>
        <GearTentAwningAmountRowDisplay gearData={gearData} specificCharacteristic={specificCharacteristic}/>
        <GearMaterialRowDisplay specificCharacteristic={specificCharacteristic} gearName={gearName}/>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={6}>{`${t('Sewing')}`}</GearTH>
          <GearTH colSpan={6}>{`${t('IngressProtection')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTD-${gearName}`}>
          <GearTD colSpan={6}>
            {specificCharacteristic.Sewing !== null ? specificCharacteristic.Sewing : ''}
          </GearTD>
          <GearTD colSpan={6}>
            {specificCharacteristic.IngressProtection !== null ? specificCharacteristic.IngressProtection : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={6}>{`${t('ImpermeabilityExtern')} [${getUnit('Impermeability')}]`}</GearTH>
          <GearTH colSpan={6}>{`${t('ImpermeabilityFloor')} [${getUnit('Impermeability')}]`}</GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTD-${gearName}`}>
          <GearTD colSpan={6}>{specificCharacteristic.ImpermeabilityExtern}</GearTD>
          <GearTD colSpan={6}>{specificCharacteristic.ImpermeabilityFloor}</GearTD>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={12}>{`${t('DeployedDimension')}`}</GearTH>
        </GearTR>
        <GearDeployedDimensionRowDisplay specific={specificCharacteristic} gearName={gearName}/>
        <GearTentAwningDeployedDimensionRowDisplay specificCharacteristic={specificCharacteristic} gearName={gearName}/>
      </GearTableBody>
    </GearTable>
  );
}

GearTentSpecificTableDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  specificCharacteristic: specificCharacteristicPropTypes,
  gearName: PropTypes.string.isRequired
};

export default GearTentSpecificTableDisplay;
