// Import React Libraries
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { userUnitPreferencePropTypes } from '../../../../datas/PropTypes/userPropTypes';

// Import Component
import ColGroup12 from '../../../Gear/Tab/ColGroup12';
import { GearSelectDropDown } from '../../../Gear/SelectDropDown/GearSelectDropDown';

// Import Datas
import {
  userUnitSystemChoiceArray
} from '../../../../datas/User/User_AddEdit_ChoiceArrays';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../utils/Style/js/Account/UserDataTableDisplay_Style';

function UserUnitPreferenceEdit ({ userUnitPreference, onSubmit }, ref) {
  const { t } = useTranslation();

  const [unitSystem, setUnitSystem] = useState(userUnitPreference.Unit_System);

  const formRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const data = Object.fromEntries(formData.entries());

    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <UserDataTable>
      <ColGroup12/>
      <UserDataTableBody>
        <UserDataTR>
          <UserDataTH colSpan={12}>{`${t('UnitSystem')}`}</UserDataTH>
        </UserDataTR>
        <UserDataTR>
          <UserDataTD colSpan={12}>
            <GearSelectDropDown options={userUnitSystemChoiceArray} choice={unitSystem} setChoice={setUnitSystem} noChoice='Select'/>
            <input type="hidden" name="Unit_Distance" value={unitSystem} />
          </UserDataTD>
        </UserDataTR>
      </UserDataTableBody>
    </UserDataTable>
  </form>
  );
}

UserUnitPreferenceEdit.propTypes = {
  userUnitPreference: userUnitPreferencePropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(UserUnitPreferenceEdit);
