// Import Constants
import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';

/* <!---_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-/ Get One \-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_-_--> */
export async function APIgetUser (UserID) {
  // console.log('getUser :');
  // console.log(`User to GET : ${UserID}`);

  const URLToFetch = `https://${WebServerIPAddress}/User/GetUser/${UserID}`;

  const authToken = sessionStorage.getItem('authenticationToken');
  // console.log(`token : ${authToken}`);

  const response = await fetch(URLToFetch, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });

  const UserData = await response.json();
  console.log('UserData : ', UserData);

  return UserData;
};

export async function APIgetUserIDFromPseudo (userPseudo) {
  // console.log('APIgetUserIDFromPseudo :');

  const URLToFetch = `https://${WebServerIPAddress}/User/UserIDFromPseudo/${userPseudo}`;

  const authToken = sessionStorage.getItem('authenticationToken');
  // console.log(`token : ${authToken}`);

  const response = await fetch(URLToFetch, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });

  const userID = await response.json();
  // console.log('userID : ', userID);

  return userID.User_ID;
};

export async function APIgetUserPseudoFromID (userID) {
  // console.log('APIgetUserIDFromPseudo :');

  const URLToFetch = `https://${WebServerIPAddress}/User/UserPseudoFromID/${userID}`;

  const authToken = sessionStorage.getItem('authenticationToken');
  // console.log(`token : ${authToken}`);

  const response = await fetch(URLToFetch, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${authToken}`
    }
  });

  const userPseudo = await response.json();
  // console.log('userID : ', userID);

  return userPseudo.User_Pseudo;
};

export async function APICreateUser (data) {
  // console.log('APICreateUser');
  // console.log('data:', data);

  const URLToFetch = `https://${WebServerIPAddress}/User/CreateUser`;

  const authToken = sessionStorage.getItem('authenticationToken');
  // console.log(`token : ${authToken}`);

  // Create a FormData object
  const formData = new FormData();

  // Append other data fields to the FormData object

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'User_Avatar') {
      const value = data[key];
      if (typeof value === 'object' && value !== null) {
        // Convert non-file objects to JSON strings
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  }

  // Append the image file to the FormData object
  if (data.userProfile.User_Avatar) {
    // console.log('Append User_Avatar');
    // console.log('data.userGeneralPreference.User_Avatar : ', data.userProfile.User_Avatar);
    formData.append('User_Avatar', data.userProfile.User_Avatar);
  }
  // console.log('formData : ', formData);

  try {
    const response = await fetch(URLToFetch, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      body: formData
    });

    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    return responseBody;
  } catch (error) {
    // console.log('Catch Error');
    // console.log('error:', error);
    if (error.response) {
      console.log('Server error response:', error.response);
      return {
        success: false,
        message: error.message,
        error: error.response
      };
    } else {
      return {
        success: false,
        message: error.message
      };
    }
  }
};

export async function APIEditUser (data) {
  // console.log('APIEditUser');
  // console.log('data:', data);

  const URLToFetch = `https://${WebServerIPAddress}/User/EditUser`;

  const authToken = sessionStorage.getItem('authenticationToken');
  // console.log(`token : ${authToken}`);

  // Create a FormData object
  const formData = new FormData();

  // Append other data fields to the FormData object

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key) && key !== 'User_Avatar') {
      const value = data[key];
      if (typeof value === 'object' && value !== null) {
        // Convert non-file objects to JSON strings
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
  }

  // Append the image file to the FormData object
  if (data.userProfile.User_Avatar) {
    // console.log('Append User_Avatar');
    // console.log('data.userGeneralPreference.User_Avatar : ', data.userProfile.User_Avatar);
    formData.append('User_Avatar', data.userProfile.User_Avatar);
  }
  // console.log('formData : ', formData);

  try {
    const response = await fetch(URLToFetch, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`
      },
      body: formData
    });

    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    return responseBody;
  } catch (error) {
    // console.log('Catch Error');
    // console.log('error:', error);
    if (error.response) {
      console.log('Server error response:', error.response);
      return {
        success: false,
        message: error.message,
        error: error.response
      };
    } else {
      return {
        success: false,
        message: error.message
      };
    }
  }
};
