// Import Assets
import {
  minus30LSubCategoryIllustration,
  plus30LSubCategoryIllustration
} from '../../Illustrations/equipment_Illustrations';

export const portageSubCategoryList = [
  {
    category: 'Portage',
    subCategory: 'Minus30L',
    text: 'Minus 30L',
    illustration: minus30LSubCategoryIllustration
  },
  {
    category: 'Portage',
    subCategory: 'Plus30L',
    text: 'Plus 30L',
    illustration: plus30LSubCategoryIllustration
  }
];
