// Import Constants
import { LANGUAGES } from '../../Constants/Language_Constants';

export function translatePaths (links, t) {
  // console.log('translatePaths');
  // console.log(`links: ${links}`);
  const translatedLinksArray = links.map((link) => {
    // Split each link into parts, translate each part, and then rejoin them.
    return link.split('/').map(t).join('/');
  });
  // console.log(`links: ${translatedLinksArray}`);
  return translatedLinksArray;
};

export function translatePath (path, t) {
  // console.log(`path: ${path}`);
  const pathParts = path.split('/');
  const translatedPath = pathParts.length > 1 ? pathParts.map(t).join('/') : t(path);

  // console.log(`translatePath: ${translatePath}`);
  return translatedPath;
}

export function getLanguageCodeArray () {
  return LANGUAGES.map(language => language.code);
};

export function pathWithoutLang (pathname) {
  const languageCodeArray = getLanguageCodeArray();
  // console.log(`languageCodeArray: ${languageCodeArray}`);

  // Find the first segment of the pathname
  const firstSegment = pathname.split('/')[1];
  // console.log(`firstSegment: ${firstSegment}`);

  // Check if the first segment is a language code
  if (languageCodeArray.includes(firstSegment)) {
    // Handle cases where the pathname is just the language code
    if (pathname === `/${firstSegment}`) {
      return '';
    }
    // Remove the language code from the pathname
    const pathnameWithoutLang = pathname.substring(pathname.indexOf('/', 1) + 1);
    // console.log(`pathnameWithoutLang: ${pathnameWithoutLang}`);
    return pathnameWithoutLang;
  }

  // If no language code is found, return the original pathname
  return pathname;
};

export function extractUrlPart (currentUrl) {
  // Split the URL into parts
  currentUrl = pathWithoutLang(currentUrl);
  const urlParts = currentUrl.split('/').filter(part => part);

  return urlParts;
};

export function createLinkArrayFromPathWithoutLang (currentUrl, i18n, t) {
  // const linksArray = [`/${i18n.language}/`];
  const linksArray = [];
  const currentUrlWithoutLang = pathWithoutLang(currentUrl);

  // Split the URL into parts
  const urlParts = currentUrlWithoutLang.split('/').filter(part => part);

  // Iteratively build the URL segments and push to the linksArray
  let cumulativePath = `/${i18n.language}`;
  urlParts.forEach(part => {
    cumulativePath += `/${part}`;
    linksArray.push(cumulativePath);
  });

  return linksArray;
};
