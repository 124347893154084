// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearItemPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';

// Import Functions
import { convertIfNecessarySI } from '../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearCompactedDimensionTableDisplay ({ item, gearName }) {
  const { t } = useTranslation();
  return (
    <GearTable id={`gearCompactedDimensionTableDisplay-${gearName}`}>
      <ColGroup12/>
      <GearTableBody>
        <GearTR id={`gearCompactedDimensionRowTHDisplay-${gearName}`}>
          <GearTH colSpan={12}>{`${t('CompactedDimension')}`}</GearTH>
        </GearTR>
        {item.Compacted.Shape && (
          <>
              {item.Compacted.Shape === 'Cylindric' && (
                <>
                <GearTR id={`gearCompactedDimensionRowTHDisplay-${gearName}`}>
                    <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
                  </GearTR>
                  <GearTR id={`gearCompactedDimensionRowTDDisplay-${gearName}`}>
                    <GearTD colSpan={12}>
                      {item.Compacted.Shape !== null ? `${t(item.Compacted.Shape)}` : ''}
                    </GearTD>
                  </GearTR>
                  <GearTR id={`gearCompactedDimensionRowTHDisplay-${gearName}`}>
                    <GearTH colSpan={6}>
                      {item.Compacted.CylindricLength !== null
                        ? `${t('Length')} [${convertIfNecessarySI('Distance', item.Compacted.CylindricLength).convertedUnit}]`
                        : `${t('Length')}`
                      }
                    </GearTH>
                    <GearTH colSpan={6}>
                      {item.Compacted.Diameter !== null
                        ? `${t('Diameter')} [${convertIfNecessarySI('Distance', item.Compacted.Diameter).convertedUnit}]`
                        : `${t('Diameter')}`
                      }
                    </GearTH>
                  </GearTR>
                  <GearTR id={`gearCompactedDimensionRowTDDisplay-${gearName}`}>
                    <GearTD colSpan={6}>
                      {item.Compacted.CylindricLength !== null ? item.Compacted.CylindricLength : ''}
                    </GearTD>
                    <GearTD colSpan={6}>
                      {item.Compacted.Diameter !== null ? item.Compacted.Diameter : ''}
                    </GearTD>
                  </GearTR>
                </>
              )}
              {item.Compacted.Shape === 'Rectangular' && (
                <>
                  <GearTR id={`gearCompactedDimensionRowTHDisplay-${gearName}`}>
                    <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
                  </GearTR>
                  <GearTR id={`gearCompactedDimensionRowTDDisplay-${gearName}`}>
                    <GearTD colSpan={12}>
                      {t(item.Compacted.Shape) || ''}
                    </GearTD>
                  </GearTR>
                  <GearTR id={`gearCompactedDimensionRowTHDisplay-${gearName}`}>
                    <GearTH colSpan={4}>
                      {item.Compacted.RectangularLength !== null
                        ? `${t('Length')} [${convertIfNecessarySI('Distance', item.Compacted.RectangularLength).convertedUnit}]`
                        : `${t('Length')}`
                      }
                    </GearTH>
                    <GearTH colSpan={4}>
                      {item.Compacted.Width !== null
                        ? `${t('Width')} [${convertIfNecessarySI('Distance', item.Compacted.Width).convertedUnit}]`
                        : `${t('Width')}`
                      }
                    </GearTH>
                    <GearTH colSpan={4}>
                      {item.Compacted.Height !== null
                        ? `${t('Height')} [${convertIfNecessarySI('Distance', item.Compacted.Height).convertedUnit}]`
                        : `${t('Height')}`
                      }
                    </GearTH>
                  </GearTR>
                  <GearTR id={`gearCompactedDimensionRowTDDisplay-${gearName}`}>
                    <GearTD colSpan={4}>
                      {item.Compacted.RectangularLength !== null
                        ? convertIfNecessarySI('Distance', item.Compacted.RectangularLength).convertedValue
                        : ''
                      }
                    </GearTD>
                    <GearTD colSpan={4}>
                      {item.Compacted.Width !== null
                        ? convertIfNecessarySI('Distance', item.Compacted.Width).convertedValue
                        : ''
                      }
                    </GearTD>
                    <GearTD colSpan={4}>
                      {item.Compacted.Height !== null
                        ? convertIfNecessarySI('Distance', item.Compacted.Height).convertedValue
                        : ''
                      }
                    </GearTD>
                  </GearTR>
                </>
              )}
          </>
        )}
        <GearTR id={`gearCompactedDimensionRowTHDisplay-${gearName}`}>
          <GearTH colSpan={4}>
            {item.Metrics.Weight !== null
              ? `${t('Weight')} [${convertIfNecessarySI('Weight', item.Metrics.Weight).convertedUnit}]`
              : `${t('Weight')}`
            }
          </GearTH>
          <GearTH colSpan={4}>
            {item.Metrics.Volume !== null
              ? `${t('Volume')} [${convertIfNecessarySI('Volume', item.Metrics.Volume).convertedUnit}]`
              : `${t('Volume')}`
            }
          </GearTH>
          <GearTH colSpan={4}>
            {item.Metrics.Density !== null
              ? `${t('Density')} [${convertIfNecessarySI('Density', item.Metrics.Density).convertedUnit}]`
              : `${t('Density')}`
            }
          </GearTH>
        </GearTR>
        <GearTR id={`gearCompactedDimensionRowTDDisplay-${gearName}`}>
          <GearTD colSpan={4}>
            {item.Metrics.Weight !== null
              ? convertIfNecessarySI('Weight', item.Metrics.Weight).convertedValue
              : ''
            }
          </GearTD>
          <GearTD colSpan={4}>
            {item.Metrics.Volume !== null
              ? convertIfNecessarySI('Volume', item.Metrics.Volume).convertedValue
              : ''
            }
          </GearTD>
          <GearTD colSpan={4}>
            {item.Metrics.Density !== null
              ? convertIfNecessarySI('Density', item.Metrics.Density).convertedValue
              : ''
            }
          </GearTD>
        </GearTR>
      </GearTableBody>
    </GearTable>
  );
}

GearCompactedDimensionTableDisplay.propTypes = {
  item: gearItemPropTypes.isRequired,
  gearName: PropTypes.string.isRequired
};

export default GearCompactedDimensionTableDisplay;
