// Import React Libraries
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import Functions
import {
  pathWithoutLang,
  translatePath
} from '../../utils/Functions/PathFunctions/languagePath_Functions';

// Import Constants
import { LANGUAGES, flagHeight, flagWidth } from '../../utils/Constants/Language_Constants';

// Import Style
import { LanguageMenuContainer } from '../../utils/Style/js/LanguageMenu/LanguageMenuStyle';
import { initI18n } from '../../utils/Functions/i18n_Functions/i18n_Functions';

export const LanguageMenu = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const onChangeLang = (selectedOption) => {
    const langCode = selectedOption.value;

    // Change the language
    i18n.changeLanguage(langCode);
    console.log('langCode : ', langCode);
  };

  const handleClick = (event) => {
    // Prevent opening and closing on the same event on desktop
    event.preventDefault();
    setMenuIsOpen(current => !current); // Toggle based on previous state
  };

  const handleTouchEnd = (event) => {
    // Prevent closing menu on touch if it's already open
    if (!menuIsOpen) {
      event.stopPropagation(); // Only stop propagation if menu is not open
      setMenuIsOpen(true);
    }
  };

  useEffect(() => {
    // console.log(`i18n.language : ${i18n.language}`);
    const initializeI18n = async () => {
      // console.log('call initI18n from Language menu');
      await initI18n(i18n);
      // console.log('Initialisation done');
      // console.log('languageMenu : language loading completed');
      // Get the current pathname
      const currentPathname = window.location.pathname;
      const currentPathnameWithoutLang = pathWithoutLang(currentPathname);

      // console.log(`currentPathname : ${currentPathname}`);
      // console.log(`currentPathnameWithoutLang : ${currentPathnameWithoutLang}`);

      const newPathname = `${i18n.language}/${translatePath(currentPathnameWithoutLang, t)}`;
      // console.log(`newPathname : ${newPathname}`);

      // console.log('navigate');
      // Navigate to the new path
      navigate(newPathname, { replace: true });
      setMenuIsOpen(false); // Close the menu after selection
    };

    initializeI18n();
  }, [i18n.language]);

  // Create an array of custom options with images
  const customOptions = LANGUAGES.map(({ code, label, flag }) => ({
    value: code,
    label: (
      <div key={code} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <img src={flag} alt={label} style={{ height: `${flagHeight}px`, width: `${flagWidth}px` }} /><span style={{ color: 'black' }}>{label}</span>
      </div>
    )
  }));

  const customSelectRender = LANGUAGES.map(({ code, label, flag }) => ({
    value: code,
    label: (
      <div key={code} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={flag} alt={label} style={{ height: `${flagHeight}px`, width: `${flagWidth}px` }} />
      </div>
    )
  }));

  const selectedLang = customSelectRender.find((option) => option.value === i18n.language);

  return (
    <LanguageMenuContainer id='languageMenuContainer' onMouseDown={handleClick} onTouchEnd={handleTouchEnd}>
      <Select
        options={customOptions}

        value={selectedLang}
        onChange={onChangeLang}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        menuIsOpen={menuIsOpen}
        menuShouldBlockScroll={true}
        isSearchable={false}
      />
    </LanguageMenuContainer>
  );
};
