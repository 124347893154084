// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import ListCommonTableAdd from './Common/ListCommonTableAdd';
import PackListSpecificTableAdd from './PackList/PackListSpecificTable_Add';
import SumListSpecificTableAdd from './SumList/SumListSpecificTable_Add';
import ValidationDataTableAdd from '../../../Gear/Tab/Add/ValidationDataTableAdd';
import LoadingComponent from '../../../LoadingComponent/Loading_Component';

// Import Functions
import { generateSumListObjectFromForms } from '../../../../utils/Functions/Lists/FillDataObject/GenerateSumListObjectFromForms_Function';
import { generatePackListObjectFromForms } from '../../../../utils/Functions/Lists/FillDataObject/GeneratePackListObjectFromForms_Function';
import { APIPostTypeList } from '../../../../api/List/List_API';

// Import Styles
import { GeneralButtonContainer, GeneralButton } from '../../../../utils/Style/js/General/Button_Style';
import { StyledH1, TitleContainer } from '../../../../utils/Style/js/General/Font_Style';

function ListsTableConstructorAdd ({ listType }) {
  const { i18n, t } = useTranslation();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const navigate = useNavigate();

  const [loaderState, setLoaderState] = useState(false);
  const [temporaryListData, setTemporaryListData] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  console.log(temporaryListData);

  const formRef1 = useRef();
  const formRef2 = useRef();
  const formRef3 = useRef();

  let form1 = null;
  let form2 = null;
  let form3 = null;

  const handleFormSubmit1 = (data) => {
    form1 = data;
    console.log('Form 1 data:', form1);
    // Handle form 1 submission here
  };
  const handleFormError1 = (errors) => {
  };

  const handleFormSubmit2 = (data) => {
    form2 = data;
    console.log('Form 2 data:', form2);
    // Handle form 2 submission here
  };
  const handleFormError2 = (errors) => {
  };

  const handleFormSubmit3 = (data) => {
    form3 = data;
    console.log('Form 3 data:', form3);
    // Handle form 2 submission here
  };

  const triggerSubmit = async (e) => {
    e.preventDefault();

    if (formRef1.current) {
      formRef1.current.submit();
    }
    if (formRef2.current) {
      formRef2.current.submit();
    }
    if (formRef3.current) {
      formRef3.current.submit();
    }

    // if (form1 !== null && form2 !== null && form3 !== null) {
    if (form1 !== null && form2 !== null) {
      setLoaderState(true);

      const objectFromForms = listType === 'PackList'
        ? generatePackListObjectFromForms(form1, form2, form3, 'Add')
        : generateSumListObjectFromForms(form1, form2, form3, 'Add');
      console.log('objectFromForms : ', objectFromForms);

      try {
        const serverResponse = await APIPostTypeList(objectFromForms);
        // console.log('server Response : ', serverResponse);
        setLoaderState(false);

        if (serverResponse.message === 'List Created') {
          setTemporaryListData(null);
          navigate(`/${i18n.language}/${t('Lists')}`);
        } else if (serverResponse.error) {
          console.log('server Response Error : ', serverResponse.error.error);
          setTemporaryListData(objectFromForms);
          setErrorMessages(serverResponse.error.error);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    } else {
      setErrorMessages([]);
    }
  };

  useEffect(() => {
  }, [] /* [temporaryUserData] */);

  return (
    <>
      {
        loaderState
          ? <LoadingComponent/>
          : <>
              <TitleContainer>
                <StyledH1>
                  {listType === 'PackList' ? `${t('PackListTitle')}` : `${t('SumListTitle')}`}
                </StyledH1>
              </TitleContainer>
              <ListCommonTableAdd
                ref={formRef1}
                onSubmit={handleFormSubmit1}
                onError={handleFormError1}
                temporaryListData={temporaryListData}
                errorMessages={errorMessages}
                listType={listType}
              />
              {listType === 'PackList' &&
                (
                  <PackListSpecificTableAdd
                  ref={formRef2}
                  onSubmit={handleFormSubmit2}
                  onError={handleFormError2}
                  temporaryListData={temporaryListData}
                  />
                )
              }
              {listType === 'SumList' &&
                (
                  <SumListSpecificTableAdd
                  ref={formRef2}
                  onSubmit={handleFormSubmit2}
                  onError={handleFormError2}
                  temporaryListData={temporaryListData}
                  />
                )
              }
              {currentUserData.userProfile.User_Rank === 'ADMIN'
                ? <ValidationDataTableAdd
                    ref={formRef3}
                    onSubmit={handleFormSubmit3}
                  />
                : <></>
            }
              <GeneralButtonContainer>
                <GeneralButton onClick={triggerSubmit}>{`${t('Create')}`}</GeneralButton>
              </GeneralButtonContainer>
            </>
      }

    </>
  );
}

ListsTableConstructorAdd.propTypes = {
  listType: PropTypes.string.isRequired
};

export default ListsTableConstructorAdd;
