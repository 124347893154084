// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { listPropTypes } from '../../../../datas/PropTypes/listPropTypes';

// Import Components
import ListCommonTableEdit from './Common/ListCommonTable_Edit';
import PackListSpecificTableEdit from './PackList/PackListSpecificTableEdit';
import ValidationDataTableAdd from '../../../Gear/Tab/Add/ValidationDataTableAdd';
// import UserGeneralPreferenceAdd from './UserGeneralPreference_Add';
// import UserUnitPreferenceAdd from './UserUnitPreference_Add';
import LoadingComponent from '../../../LoadingComponent/Loading_Component';

// Import Functions
import { generatePackListObjectFromForms } from '../../../../utils/Functions/Lists/FillDataObject/GeneratePackListObjectFromForms_Function';
import { generateSumListObjectFromForms } from '../../../../utils/Functions/Lists/FillDataObject/GenerateSumListObjectFromForms_Function';
import { APIEditList } from '../../../../api/List/List_API';
// Usefull if the data persistance is researched
// import { resetTemporaryUserData, storeTemporaryUserData } from '../../../../utils/Functions/Storage_Functions/SessionStorage_Functions';

// Import Styles
import { GeneralButtonContainer, GeneralButton } from '../../../../utils/Style/js/General/Button_Style';

function ListsTableConstructorEdit ({ listCharacteristics }) {
  const { i18n, t } = useTranslation();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const navigate = useNavigate();

  const [loaderState, setLoaderState] = useState(false);
  const [temporaryListData, setTemporaryListData] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  console.log(temporaryListData);

  const formRef1 = useRef();
  const formRef2 = useRef();
  const formRef3 = useRef();

  let form1 = null;
  let form2 = null;
  let form3 = null;

  const handleFormSubmit1 = (data) => {
    form1 = data;
    console.log('Form 1 data:', form1);
    // Handle form 1 submission here
  };
  const handleFormError1 = (errors) => {
  };

  const handleFormSubmit2 = (data) => {
    form2 = data;
    console.log('Form 2 data:', form2);
    // Handle form 2 submission here
  };
  const handleFormError2 = (errors) => {
  };

  const handleFormSubmit3 = (data) => {
    form3 = data;
    console.log('Form 3 data:', form3);
    // Handle form 2 submission here
  };

  const triggerSubmit = async (e) => {
    e.preventDefault();

    if (formRef1.current) {
      formRef1.current.submit();
    }
    if (formRef2.current) {
      formRef2.current.submit();
    }
    if (formRef3.current) {
      formRef3.current.submit();
    }

    // if (form1 !== null && form2 !== null && form3 !== null) {
    if (form1 !== null && form2 !== null) {
      setLoaderState(true);

      const objectFromForms = listCharacteristics.Informations.Type === 'PackList' ? generatePackListObjectFromForms(form1, form2, form3, 'Edit', listCharacteristics) : generateSumListObjectFromForms(form1, form2, form3, 'Edit', listCharacteristics);
      console.log('objectFromForms : ', objectFromForms);

      try {
        const serverResponse = await APIEditList(objectFromForms);
        // console.log('server Response : ', serverResponse);
        setLoaderState(false);

        if (serverResponse.message === 'List Edited') {
          setTemporaryListData(null);
          navigate(`/${i18n.language}/${t('Lists')}`);
        } else if (serverResponse.error) {
          console.log('server Response Error : ', serverResponse.error.error);
          setTemporaryListData(objectFromForms);
          setErrorMessages(serverResponse.error.error);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    } else {
      setErrorMessages([]);
    }
  };

  useEffect(() => {
  }, [] /* [temporaryUserData] */);

  return (
    <>
      {
        loaderState
          ? <LoadingComponent/>
          : <>
              <h1>{`${t('List')} EDIT`}</h1>
              <ListCommonTableEdit
                listCharacteristics={listCharacteristics}
                ref={formRef1}
                onSubmit={handleFormSubmit1}
                onError={handleFormError1}
                temporaryListData={temporaryListData}
                errorMessages={errorMessages}
              />
              {
                listCharacteristics.Informations.Type === 'PackList' &&
                <PackListSpecificTableEdit
                  listCharacteristics={listCharacteristics}
                  ref={formRef2}
                  onSubmit={handleFormSubmit2}
                  onError={handleFormError2}
                  temporaryListData={temporaryListData}
                  errorMessages={errorMessages}
                />
              }
              {currentUserData.userProfile.User_Rank === 'ADMIN'
                ? <ValidationDataTableAdd
                    ref={formRef3}
                    onSubmit={handleFormSubmit3}
                  />
                : <></>
            }
              <GeneralButtonContainer>
                <GeneralButton onClick={triggerSubmit}>{`${t('Validate')}`}</GeneralButton>
              </GeneralButtonContainer>
            </>
      }

    </>
  );
}

ListsTableConstructorEdit.propTypes = {
  listCharacteristics: listPropTypes.isRequired
};

export default ListsTableConstructorEdit;
