// Import React Libraries
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { userGeneralPreferencePropTypes } from '../../../../datas/PropTypes/userPropTypes';

// Import Component
import ColGroup12 from '../../../Gear/Tab/ColGroup12';
import { GearSelectDropDown } from '../../../Gear/SelectDropDown/GearSelectDropDown';

// Import Datas
import { userCurrencyChoiceArray, userLanguageChoiceArray } from '../../../../datas/User/User_AddEdit_ChoiceArrays';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../utils/Style/js/Account/UserDataTableDisplay_Style';

// Import Constants

function UserGeneralPreferenceEdit ({ userGeneralPreference, onSubmit }, ref) {
  const { t } = useTranslation();

  const [userLanguage, setUserLanguage] = useState(userGeneralPreference.User_Language);
  const [unitCurrency, setUnitCurrency] = useState(userGeneralPreference.Unit_Currency);

  const formRef = useRef();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const data = Object.fromEntries(formData.entries());

    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <UserDataTable>
        <ColGroup12/>
        <UserDataTableBody>
          <UserDataTR>
            <UserDataTH colSpan={12}>{`${t('Language')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={12}>
              <GearSelectDropDown options={userLanguageChoiceArray} choice={userLanguage} setChoice={setUserLanguage} noChoice='Language'/>
              <input type="hidden" name="User_Language" value={userLanguage} />
            </UserDataTD>
          </UserDataTR>
          <UserDataTR>
            <UserDataTH colSpan={12}>{`${t('UnitCurrency')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={12}>
              <GearSelectDropDown options={userCurrencyChoiceArray} choice={unitCurrency} setChoice={setUnitCurrency} noChoice='Currency'/>
              <input type="hidden" name="Unit_Currency" value={unitCurrency} />
            </UserDataTD>
          </UserDataTR>
        </UserDataTableBody>
      </UserDataTable>
    </form>
  );
}

UserGeneralPreferenceEdit.propTypes = {
  userGeneralPreference: userGeneralPreferencePropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(UserGeneralPreferenceEdit);
