// Import React Libraries
import React from 'react';

// Import PropTypes
import { gearDataPropTypes, gearItemPropTypes } from '../../datas/PropTypes/gearPropTypes';

// Import Context

// Import Components
import ItemDetailPicturesDisplay from './ItemDetailPicturesDisplay';
import GearTableConstructionDisplay from './Tab/Display/GearTableConstructionDisplay';
import GearCommonCharacteristicTableDisplay from './Tab/Display/Common/GearCommonCharacteristicTableDisplay';
import GearOwnerTagTableDisplay from './Tab/Display/Common/GearCommonOwnerTagTable_Display';
import GearAddToListTagTableDisplay from './Tab/Display/Common/GearAddToListTableDisplay';

// Import Functions

// Import Style
import {
  ProductDetailWrapper,
  ProductPresentationWrapper,
  ProductInfoWrapper, ProductInfoContainer,
  DescriptionContainer
} from '../../utils/Style/ItemDetailStyle';

function ItemDetailDisplay ({ gearData, item }) {
  /*
  console.log('ItemDetailDisplay');
  console.log('gearData : ', gearData);
  console.log('item : ', item);
  */

  return (
    <ProductDetailWrapper id='productDetailContainer'>
      <ProductPresentationWrapper key={item.Informations.Name} id='productPresentationWrapper'>
        <ItemDetailPicturesDisplay
          gearData={gearData}
          item={item}
        />
        <ProductInfoWrapper id='productInfoWrapper'>
          <ProductInfoContainer id='productInfoContainer'>
            <GearCommonCharacteristicTableDisplay
              gearData={gearData}
              item={item}
            />
            {item.Status.Validated === true &&
            <>
              <GearOwnerTagTableDisplay
                item={item}
              />
              <GearAddToListTagTableDisplay
                gearData={gearData}
                item={item}
              />
            </>
            }
          </ProductInfoContainer>
        </ProductInfoWrapper>
      </ProductPresentationWrapper>
      <DescriptionContainer id='descriptionContainer'>
        <GearTableConstructionDisplay gearData={gearData} gearItem={item}/>
      </DescriptionContainer>
    </ProductDetailWrapper>
  );
}

ItemDetailDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearItemPropTypes
};

export default ItemDetailDisplay;
