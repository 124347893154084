// Import Assets
import { mosquitoNetAccessoryIllustration } from '../../Illustrations/equipment_Illustrations';

export const beddingAccessoryCategoryList = [
  {
    AccessoryCategory: 'MosquitoNet',
    text: 'MosquitoNet-title',
    illustration: mosquitoNetAccessoryIllustration
  }
];
