// Equipment Shelter
import TarpDefaultIllustration from '../../assets/Illustrations/Equipment/Shelter/Tarp/Tarp_Default_Illustration.jpg';
import TentDefaultIllustration from '../../assets/Illustrations/Equipment/Shelter/Tent/Tent_Default_Illustration.jpg';
// Accessory
import MastDefaultIllustration from '../../assets/Illustrations/Equipment/Shelter/Accessory/Mast_Default_Illustration.jpg';
import PegDefaultIllustration from '../../assets/Illustrations/Equipment/Shelter/Accessory/Peg_Default_Illustration.jpg';
import RopeDefaultIllustration from '../../assets/Illustrations/Equipment/Shelter/Accessory/Rope_Default_Illustration.jpg';
import StripDefaultIllustration from '../../assets/Illustrations/Equipment/Shelter/Accessory/Strip_Default_Illustration.jpg';

// Equipment Bedding
import SleepingBagDefaultIllustration from '../../assets/Illustrations/Equipment/Bedding/SleepingBag/SleepingBag_Default_Illustration.jpg';
import MattressDefaulIllustation from '../../assets/Illustrations/Equipment/Bedding/Mattress/Mattress_Default_Illustration.jpg';
import HammockDefaulIllustation from '../../assets/Illustrations/Equipment/Bedding/Hammock/Hammock_Default_Illustration.jpg';
// Accessory
import MosquitoNetDefaultIllustration from '../../assets/Illustrations/Equipment/Bedding/Accessory/MosquitoNet_Default_Illustration.jpg';

// Equipment Shelter
export const tarpDefaultIllustration = TarpDefaultIllustration;
export const tentDefaultIllustration = TentDefaultIllustration;
// Accessory
export const mastDefaultIllustration = MastDefaultIllustration;
export const pegDefaultIllustration = PegDefaultIllustration;
export const ropeDefaultIllustration = RopeDefaultIllustration;
export const stripDefaultIllustration = StripDefaultIllustration;

// Equipment Bedding
export const matrressDefaultIllustration = MattressDefaulIllustation;
export const hammockDefaulIllustation = HammockDefaulIllustation;
export const sleepingBagDefaultIllustration = SleepingBagDefaultIllustration;
// Accessory
export const mosquitoNetDefaultIllustration = MosquitoNetDefaultIllustration;
