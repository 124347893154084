// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Context

// Import Components
import NavIndicator from '../../components/NavIndicator/NavIndicator';
import GearSideBarSelectorComponent from '../../components/Gear/gearSideBarSelector_Component';
import GearCategorySelectorComponent from '../../components/Gear/gearCategorySelector_Component';

// Import Functions
import { displayOutletGearTypeCategory } from '../../utils/Functions/PathFunctions/gearPath_Functions';

// Import Style
import { PageWrapper, PageContainer, DisplayContainer } from '../../utils/Styles';

// Import Constants
import { footerHeight, headerHeight, verticalMargin } from '../../utils/Constant';

function GearCategorySelectorPage ({ TypeCategory }) {
  /*
  console.log('GearCategorySelectorPage');
  console.log(`TypeCategory : ${TypeCategory}`);
  */
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentUrl = location.pathname;

  const shouldDisplayGearTypeCategorySelectorPage = displayOutletGearTypeCategory(TypeCategory, currentUrl, t, i18n);
  const [sidebarHeight, setSidebarHeight] = useState(0);
  const referenceDiv = useRef(null);

  const gearData = {
    TypeCategory
  };

  /*
  console.log(`currentUrl : ${currentUrl}`);
  console.log(`shouldDisplayPageContainer ! ${shouldDisplayPageContainer}`);
  */

  const updateSidebarHeight = (tresholdReached) => {
    const scrollFromTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollFromTopMax = documentHeight - windowHeight;

    if (referenceDiv.current) {
      const rect = referenceDiv.current.getBoundingClientRect();

      if (rect.height > (window.innerHeight - (headerHeight * 2 + verticalMargin * 2 + footerHeight))) {
        if (tresholdReached === true) {
          setSidebarHeight(window.innerHeight - (headerHeight * 2 + footerHeight) + (scrollFromTopMax - scrollFromTop));
        } else {
          setSidebarHeight(window.innerHeight - (headerHeight * 2));
        }
      } else if (rect.height <= (window.innerHeight - (headerHeight * 2 + footerHeight))) {
        setSidebarHeight(window.innerHeight - (headerHeight * 2 + footerHeight));
      }
      // setSidebarHeight(rect.height);
    }
  };

  const handleScroll = () => {
    const scrollFromTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    // console.log(`document.documentElement.scrollHeight : ${document.documentElement.scrollHeight}`);
    // console.log(`window.document.documentElement.scrollTop : ${document.documentElement.scrollTop}`);
    const scrollFromTopMax = documentHeight - windowHeight;
    // console.log(`scrollFromTop : ${scrollFromTop}`);
    // console.log(`scrollFromTopMax : ${scrollFromTopMax}`);

    if (scrollFromTop >= scrollFromTopMax - footerHeight) {
      updateSidebarHeight(true);
    } else {
      updateSidebarHeight(false);
    }
  };

  useEffect(() => {
    // Initial height update
    updateSidebarHeight();

    // Update height on scroll
    window.addEventListener('scroll', handleScroll);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Call updateSidebarHeight when the URL changes
    updateSidebarHeight();
  }, [location]);

  return (
    <PageWrapper id='pageWrapperSubCategorySelectorPage' ref={referenceDiv}>
      <PageContainer>
        <GearSideBarSelectorComponent gearTypeCategory={TypeCategory} height={sidebarHeight}/>
        <DisplayContainer>
          <NavIndicator/>
            {shouldDisplayGearTypeCategorySelectorPage
              ? (
                  <GearCategorySelectorComponent gearData={gearData} />
                )
              : (
                  <Outlet/>
                )
            }
        </DisplayContainer>
      </PageContainer>
    </PageWrapper>
  );
}

GearCategorySelectorPage.propTypes = {
  TypeCategory: PropTypes.string.isRequired
};

export default GearCategorySelectorPage;
