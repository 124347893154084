// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import { compactedDimensionCharacteristicPropTypes, gearDataPropTypes, gearItemPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../ColGroup12';
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';
import LoadingOverlayComponent from '../../../../LoadingComponent/LoadingOverlay_Component';

// Import Functions
import { generateUserListsArray } from '../../../../../utils/Functions/Lists/generateListsArray_Functions';
import { APIPostListTag } from '../../../../../api/List/ListTag_API';

// Import Style
import {
  GearCommonTR,
  GearCommonTD,
  GearCommonTable
} from '../../../../../utils/Style/js/GearTable/GearCommonTable_Style';
import { GeneralButton, GeneralButtonContainer } from '../../../../../utils/Style/js/General/Button_Style';
import { GearTableBody } from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import PopUp from '../../../../PopUp/PopUpComponent';

// Import Constants

function GearAddToListTagTableDisplay ({ gearData, item }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  /*
  console.log('GearCommonCharacteristicTableDisplay');
  console.log('gearCommonCharacteristic : ', gearCommonCharacteristic);
  */
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  const [creatingTagStatus, setCreatingTagStatus] = useState(false);
  const [sumListAssociated, setSumListAssociated] = useState(null);
  const [packListAssociated, setPackListAssociated] = useState(null);
  const [userSumListsArray, setSumUserListsArray] = useState(null);
  const [userPackListsArray, setPackUserListsArray] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [currentListAssociated, setCurrentListAssociated] = useState(null);

  useEffect(() => {
    async function gatherUserLists () {
      try {
        const userListsNameObject = await generateUserListsArray();
        setSumUserListsArray(userListsNameObject.userSumListsNameArray);
        setPackUserListsArray(userListsNameObject.userPackListsNameArray);
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }

    gatherUserLists();
  }, []);

  const listTagData = {
    Gear: {
      Name: item.Informations.Name,
      WebAppID: item.Informations.WebAppID,
      MongoDBID: item._id,
      Brand: item.Informations.Brand,
      TypeCategory: item.Informations.TypeCategory,
      Category: item.Informations.Category,
      SubCategory: item.Informations.SubCategory,
      AccessoryCategory: item.Informations.AccessoryCategory
    },
    List: {
      Owner: currentUserData.userProfile.User_ID
    },
    Metrics: {
      Weight: item.Metrics.Weight,
      Volume: item.Metrics.Volume
    }
  };

  useEffect(() => {
    async function APICall () {
      try {
        const listTagDataToUpload = {
          ...listTagData,
          List: {
            ...listTagData.List,
            Type: 'SumList',
            WebAppID: sumListAssociated
          }
        };
        // console.log('listTagDataToUpload : ', listTagDataToUpload);
        const listTagServerResult = await APIPostListTag(listTagDataToUpload);
        // console.log(`lisTagServerResult : ${listTagServerResult}`);
        if (listTagServerResult === true) {
          setCurrentListAssociated(sumListAssociated);
          triggerPopup();
        }
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }

    if (sumListAssociated !== null) {
      // console.log(`sumListAssociated : ${sumListAssociated}`);
      setCreatingTagStatus(true);
      APICall();
      setCreatingTagStatus(false);
    }
  }, [sumListAssociated]);

  useEffect(() => {
    async function APICall () {
      try {
        const listTagDataToUpload = {
          ...listTagData,
          List: {
            ...listTagData.List,
            Type: 'PackList',
            WebAppID: packListAssociated
          }
        };
        // console.log('listTagDataToUpload : ', listTagDataToUpload);
        const listTagServerResult = await APIPostListTag(listTagDataToUpload);
        // console.log(`lisTagServerResult : ${listTagServerResult}`);
        if (listTagServerResult === true) {
          setCurrentListAssociated(packListAssociated);
          triggerPopup();
        }
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }

    if (packListAssociated !== null) {
      // console.log(`packListAssociated : ${packListAssociated}`);
      setCreatingTagStatus(true);
      APICall();
      setCreatingTagStatus(false);
    }
  }, [packListAssociated]);

  const handleButtonCLick = (listType) => {
    if (listType === 'SumList') {
      navigate(`/${i18n.language}/${t('Lists')}/${t('SumLists')}/${t('Creation')}`);
    } else if (listType === 'PackList') {
      navigate(`/${i18n.language}/${t('Lists')}/${t('PackLists')}/${t('Creation')}`);
    } else {
      console.error(`Unknow listType : ${listType}`);
    }
  };

  const triggerPopup = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000); // Hide after 3 seconds
  };

  return (
    <>
      <GearCommonTable id='gearAddToListTagTableDisplay'>
        <ColGroup12/>
        <GearTableBody>
          {
            (userSumListsArray !== null && userPackListsArray !== null) && (
              (userSumListsArray.length > 0 || userPackListsArray.length > 0) &&
              <GearCommonTR>
                <GearCommonTD colSpan={12}>
                  {t('AddToAList')} :
                </GearCommonTD>
              </GearCommonTR>
            )
          }
          {
            userPackListsArray !== null && (
              <>
                <GearCommonTR>
                  <GearCommonTD colSpan={12}>
                    {userPackListsArray.length > 1 ? `${t('PackListsTitle')} :` : `${t('PackListTitle')} :`}
                  </GearCommonTD>
                </GearCommonTR>
                { userPackListsArray.length > 0
                  ? <GearCommonTR>
                        <GearCommonTD colSpan={12}>
                          <GearSelectDropDown options={userPackListsArray} choice={null} setChoice={setPackListAssociated} noChoice={`${t('SelectAList')}`}/>
                        </GearCommonTD>
                      </GearCommonTR>
                  : <GearCommonTR>
                      <GearCommonTD colSpan={12}>
                        <GeneralButtonContainer>
                          <GeneralButton onClick={() => handleButtonCLick('PackList')}>{`${t('CreateAPackList')}`}</GeneralButton>
                        </GeneralButtonContainer>
                      </GearCommonTD>
                    </GearCommonTR>
                }
              </>
            )
          }
          {
            userSumListsArray !== null && (
              <>
                <GearCommonTR>
                  <GearCommonTD colSpan={12}>
                    {userSumListsArray.length > 1 ? `${t('SumListsTitle')} :` : `${t('SumListTitle')} :`}
                  </GearCommonTD>
                </GearCommonTR>
                {userSumListsArray.length > 0
                  ? <>
                      <GearCommonTR>
                        <GearCommonTD colSpan={12}>
                          <GearSelectDropDown options={userSumListsArray} choice={null} setChoice={setSumListAssociated} noChoice={`${t('SelectAList')}`}/>
                        </GearCommonTD>
                      </GearCommonTR>
                    </>
                  : <GearCommonTR>
                      <GearCommonTD colSpan={12}>
                        <GeneralButtonContainer>
                          <GeneralButton onClick={() => handleButtonCLick('SumList')}>{`${t('CreateASumList')}`}</GeneralButton>
                        </GeneralButtonContainer>
                      </GearCommonTD>
                    </GearCommonTR>
                }
              </>
            )
          }
        </GearTableBody>
      </GearCommonTable>
      {
        creatingTagStatus === true
          ? <LoadingOverlayComponent/>
          : <></>
      }

      <PopUp show={showPopup} message={`${item.Informations.Name} ${t('SuccessfullyAddedTo')} ${currentListAssociated}`} />
      </>
  );
}
GearAddToListTagTableDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  compactedDimensionCharacteristic: compactedDimensionCharacteristicPropTypes,
  item: gearItemPropTypes
};

export default GearAddToListTagTableDisplay;
