// Import React Libraries
import styled from 'styled-components';

export const AuthenticationInput = styled.input`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-align: center;
  background-color: white;

  border-radius: 3px;
`;
