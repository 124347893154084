// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';

// Import PropType
import PropTypes from 'prop-types';

// Import Context
import { useNavContext } from '../../context/NavContext';

// Import Components
import NavMenu from './NavMenu';
import NavItems from './NavItems';
// import { LanguageMenu } from '../LanguageMenu/languageMenu';

// Import Style
import { MenuWrapper, BurgerButton } from '../../utils/Style/js/HeaderNav/NavStyle';

// Import Constants
import { responsiveWidthTablet } from '../../utils/Constant';

export const sideNavPropsType = PropTypes.shape({
  open: PropTypes.bool.isRequired,
  handleCloseBurger: PropTypes.func.isRequired
});

function Burger () {
  const { burgerButtonState, setBurgerButtonState } = useNavContext();
  const burgerRef = useRef(null);
  const [currentLayout, setCurrentLayout] = useState(window.innerWidth >= responsiveWidthTablet ? 'laptop' : 'responsive');

  useEffect(() => {
    // Function to handle clicks outside the component
    function handleClickOutside (event) {
      /*
      console.log('burgerRef.current');
      console.log(burgerRef.current);
      console.log('!burgerRef.current.contains(event.target)');
      console.log(!burgerRef.current.contains(event.target));
      console.log('event.target');
      console.log(event.target);
      */

      if (burgerRef.current && !burgerRef.current.contains(event.target)) {
        setBurgerButtonState(false);
      }
    }

    // Add event listener when the component mounts
    document.addEventListener('click', handleClickOutside);

    // Remove the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setCurrentLayout(screenWidth >= responsiveWidthTablet ? 'laptop' : 'responsive');
    };

    // Initial setup
    handleResize();

    // Add event listener to update on window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <MenuWrapper ref={burgerRef} id='menuWrapper'>
        <BurgerButton burgerButtonState={burgerButtonState} onClick={() => setBurgerButtonState(!burgerButtonState)} id='burgerButton'>
            <div />
            <div />
            <div />
        </BurgerButton>
        {currentLayout === 'laptop'
          ? <></>
          : <>
              <NavMenu/>
              <NavItems />
            </>
      }
    </MenuWrapper>
  );
}

export default Burger;
