// Import Functions
import { getUnit } from '../Unit/unit_Functions';
import { convertCentimeterToMeter } from '../UnitConversion/convertDistance_Functions';

export function calculSurfaceDependingOnShape (length, width, lengthA, lengthB, lengthC, diameter, deployedShape) {
  let calculatedSurface = 0;
  switch (deployedShape) {
    case 'Square':
      calculatedSurface = parseFloat(calculSquareSurface(length, length, getUnit('Distance'), getUnit('Surface')).toFixed(1));
      break;
    case 'Rectangle':
      calculatedSurface = parseFloat(calculSquareSurface(length, width, getUnit('Distance'), getUnit('Surface')).toFixed(1));
      break;
    case 'Circle':
      calculatedSurface = parseFloat(calculCircleSurface(diameter, getUnit('Distance'), getUnit('Surface')).toFixed(1));
      break;
    case 'Triangle':
      calculatedSurface = parseFloat(calculTriangularSurface(lengthA, lengthB, lengthC, getUnit('Distance'), getUnit('Surface')).toFixed(1));
      break;
    case 'Trapezium':
      calculatedSurface = parseFloat(calculTrapeziumSurface(lengthA, lengthB, lengthC, getUnit('Distance'), getUnit('Surface')).toFixed(1));
      break;
    case 'Hexagon':
      calculatedSurface = parseFloat(calculHexagonalSurface(lengthA, lengthB, lengthC, getUnit('Distance'), getUnit('Surface')).toFixed(1));
      break;
    default:
      console.error(`Ìnvalid deployedShape : ${deployedShape}`);
  }
  return calculatedSurface;
}

export function calculSquareSurface (length, width, distanceUnity, surfaceUnity) {
  /*
  console.log('calculSquareSurface');
  console.log(`length : ${length}`);
  console.log(`width : ${width}`);
  console.log(`distanceUnity : ${distanceUnity}`);
  console.log(`surfaceUnity : ${surfaceUnity}`);
  */

  if (surfaceUnity === 'm²') {
    let lengthInMeters;
    let widthInMeters;
    if (distanceUnity === 'cm') {
      lengthInMeters = convertCentimeterToMeter(length);
      widthInMeters = convertCentimeterToMeter(width);
    }
    const surfaceInSquareMeters = lengthInMeters * widthInMeters;
    // console.log(`surfaceInSquareMeters : ${surfaceInSquareMeters}`);
    return surfaceInSquareMeters;
  }
};

export function calculTriangularSurface (lengthA, lengthB, lengthC, distanceUnity, surfaceUnity) {
  /*
  console.log('calculTriangularSurface');
  console.log(`lengthA : ${lengthA}`);
  console.log(`lengthB : ${lengthB}`);
  console.log(`lengthC : ${lengthC}`);
  */

  if (lengthA !== null && lengthB !== null && lengthC !== null) {
    if (surfaceUnity === 'm²') {
      let lengthAInMeters;
      let lengthBInMeters;
      let lengthCInMeters;

      if (distanceUnity === 'cm') {
        lengthAInMeters = convertCentimeterToMeter(lengthA);
        lengthBInMeters = convertCentimeterToMeter(lengthB);
        lengthCInMeters = convertCentimeterToMeter(lengthC);
      }

      // Calculate the semi-perimeter
      const semiPerimeter = (lengthAInMeters + lengthBInMeters + lengthCInMeters) / 2;

      // Calculate the area using Heron's formula
      const surfaceInSquareMeters = Math.sqrt(semiPerimeter * (semiPerimeter - lengthAInMeters) * (semiPerimeter - lengthBInMeters) * (semiPerimeter - lengthCInMeters));
      return surfaceInSquareMeters;
    }
  } else {
    return 0;
  }
};

export function calculCircleSurface (diameter, distanceUnity, surfaceUnity) {
  /*
  console.log('calculSquareSurface');
  console.log(`length : ${length}`);
  console.log(`width : ${width}`);
  console.log(`distanceUnity : ${distanceUnity}`);
  console.log(`surfaceUnity : ${surfaceUnity}`);
  */

  if (surfaceUnity === 'm²') {
    let diameterInMeters;
    if (distanceUnity === 'cm') {
      diameterInMeters = convertCentimeterToMeter(diameter);
    }
    const surfaceInSquareMeters = ((diameterInMeters / 2) * (diameterInMeters / 2)) * Math.PI;
    console.log(`surfaceInSquareMeters : ${surfaceInSquareMeters}`);
    return surfaceInSquareMeters;
  }
};

export function calculTrapeziumSurface (lengthA, lengthB, lengthC, distanceUnity, surfaceUnity) {
  if (surfaceUnity === 'm²') {
    let lengthAInMeters;
    let lengthBInMeters;
    let lengthCInMeters;

    if (distanceUnity === 'cm') {
      lengthAInMeters = convertCentimeterToMeter(lengthA);
      lengthBInMeters = convertCentimeterToMeter(lengthB);
      lengthCInMeters = convertCentimeterToMeter(lengthC);
    }
    // Base triangle :
    const halfDifference = (lengthAInMeters - lengthBInMeters) / 2;
    // Calcul triangle height :
    const height = Math.sqrt(lengthCInMeters * lengthCInMeters - halfDifference * halfDifference);
    const surfaceTrapezeInSquareMeters = ((lengthAInMeters + lengthBInMeters) / 2) * height;
    // console.log(`surfaceInSquareMeters : ${surfaceInSquareMeters}`);
    return surfaceTrapezeInSquareMeters;
  }
};

export function calculHexagonalSurface (lengthA, lengthB, lengthC, distanceUnity, surfaceUnity) {
  if (surfaceUnity === 'm²') {
    let lengthAInMeters;
    let lengthBInMeters;
    let lengthCInMeters;

    if (distanceUnity === 'cm') {
      lengthAInMeters = convertCentimeterToMeter(lengthA);
      lengthBInMeters = convertCentimeterToMeter(lengthB);
      lengthCInMeters = convertCentimeterToMeter(lengthC);
    }
    // Base triangle :
    const halfDifference = (lengthAInMeters - lengthBInMeters) / 2;
    // Calcul triangle height :
    const height = Math.sqrt(lengthCInMeters * lengthCInMeters - halfDifference * halfDifference);
    const surfaceTrapezeInSquareMeters = ((lengthAInMeters + lengthBInMeters) / 2) * height;
    const surfaceInSquareMeters = surfaceTrapezeInSquareMeters * 2;
    // console.log(`surfaceInSquareMeters : ${surfaceInSquareMeters}`);
    return surfaceInSquareMeters;
  }
};
