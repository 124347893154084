// Import Functions
import { getLanguageCodeArray } from '../../utils/Functions/PathFunctions/languagePath_Functions';

// Ranks
export const userRankChoiceArray = ['HELPER', 'USER'];

// Gear Validation Access
export const userRankGearValidationAccessArray = ['ADMIN', 'HELPER'];

// Languages
export const userLanguageChoiceArray = getLanguageCodeArray();

// Currency
export const userCurrencyChoiceArray = ['euro'];

// Unit System
export const userUnitSystemChoiceArray = ['SI'];
