// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { userProfilePropTypes } from '../../../../datas/PropTypes/userPropTypes';

// Import Component
import ColGroup12 from '../../../Gear/Tab/ColGroup12';

// Import Datas
import { defaultProfilePicture } from '../../../../datas/Illustrations/profile_Illustrations';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD,
  UserAvatarContainer, UserAvatar
} from '../../../../utils/Style/js/Account/UserDataTableDisplay_Style';

// Import Constants

function UserProfileDisplay ({ userProfile }) {
  const { t } = useTranslation();

  // console.log('UserProfileDisplay');
  // console.log('userProfile : ', userProfile);

  const userAvatar = userProfile.User_Avatar ? userProfile.User_Avatar : defaultProfilePicture;
  return (
    <UserDataTable>
      <ColGroup12/>
      <UserDataTableBody>
        <UserDataTR>
          <UserDataTH colSpan={6}>{`${t('Rank')}`}</UserDataTH>
          <UserDataTH colSpan={6}>{`${t('Avatar')}`}</UserDataTH>
        </UserDataTR>
        <UserDataTR>
          <UserDataTD colSpan={6}>{`${t(userProfile.User_Rank)}`}</UserDataTD>
          <UserDataTD colSpan={6} rowSpan={7}>
            <UserAvatarContainer>
              <UserAvatar
                src={userAvatar}
                alt='User_Avatar'
              />
            </UserAvatarContainer>
          </UserDataTD>
        </UserDataTR>
        <UserDataTR>
          <UserDataTH colSpan={6}>{`${t('Identifier')}`}</UserDataTH>
        </UserDataTR>
        <UserDataTR>
          <UserDataTD colSpan={6}>{userProfile.User_ID}</UserDataTD>
        </UserDataTR>
        <UserDataTR>
          <UserDataTH colSpan={6}>{`${t('Pseudo')}`}</UserDataTH>
        </UserDataTR>
        <UserDataTR>
          <UserDataTD colSpan={6}>{userProfile.User_Pseudo}</UserDataTD>
        </UserDataTR>
        <UserDataTR>
          <UserDataTH colSpan={6}>{`${t('Password')}`}</UserDataTH>
        </UserDataTR>
        <UserDataTR>
          <UserDataTD colSpan={6}>●●●●●</UserDataTD>
        </UserDataTR>
      </UserDataTableBody>
    </UserDataTable>
  );
}

UserProfileDisplay.propTypes = {
  userProfile: userProfilePropTypes
};

export default UserProfileDisplay;
