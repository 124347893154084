// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { userUnitPreferencePropTypes } from '../../../../datas/PropTypes/userPropTypes';

// Import Component
import ColGroup12 from '../../../Gear/Tab/ColGroup12';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../utils/Style/js/Account/UserDataTableDisplay_Style';

function UserUnitPreferenceDisplay ({ userUnitPreference }) {
  const { t } = useTranslation();
  return (
        <UserDataTable>
        <ColGroup12/>
        <UserDataTableBody>
          <UserDataTR>
            <UserDataTH colSpan={12}>{`${t('UnitSystem')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={12}>{`${t(userUnitPreference.Unit_System)}`}</UserDataTD>
          </UserDataTR>
        </UserDataTableBody>
      </UserDataTable>
  );
}

UserUnitPreferenceDisplay.propTypes = {
  userUnitPreference: userUnitPreferencePropTypes
};

export default UserUnitPreferenceDisplay;
