// Import Style
import styled from 'styled-components';
import { commonHeaderFooter } from '../../../Styles';

// Import Constants
import { headerHeight, responsiveWidthTablet, zIndexHeader } from '../../../Constant';
import colors from '../../../Colors/colors';

const sideMargin = 20;

export const HeaderWrapper = styled.div`
  ${commonHeaderFooter};
  display: flex;
  flex-direction: column;

  justify-content: center;

  position: fixed;
  top: 0;

  height: ${headerHeight * 2}px;
  
  color: white;
  background: ${colors.headerFooterBackGround};

  z-index: ${zIndexHeader};

  box-sizing: border-box;

  @media (max-width: ${responsiveWidthTablet}px){
    height: ${headerHeight}px;
  }
`;

export const HeaderFirstLineContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${sideMargin}px;
`;

export const LogoDiv = styled.div`
  
`;

export const NavBarDiv = styled.div`
  width: 100%;
`;
