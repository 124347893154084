// Import React Libraries
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { arrayOfListPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Components
import ColGroup12 from '../../../../Gear/Tab/ColGroup12';

// Import Functions
import { scrollToTop } from '../../../../../utils/Functions/general_Functions';
import { convertIfNecessarySI } from '../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Style
import { GearTableBody, GearTR, GearTD, GearTH } from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { ListPreviewTable } from '../../../../../utils/Style/js/Lists/ListPreviewDataTable_Style';

// Import Constants

function ListPreviewTableDisplay ({ listArray, userConcerned }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  async function handleOnClick (list) {
    scrollToTop();
    const userPath = userConcerned === 'Me' ? 'MyLists' : 'All';
    const path = `/${i18n.language}/${t('Lists')}/${t(userPath)}/${t(`${list.Informations.Type}s`)}/${list.Informations.WebAppID}`;
    navigate(path);
  }

  return (
    <>
      {listArray.map((list) => (
        <ListPreviewTable id={'listTable-preview'} key={list.listName} onClick={() => handleOnClick(list)}>
          <ColGroup12/>
          <GearTableBody>
            <GearTR id={'listRowTH-listName'}>
              <GearTH colSpan={6}>
                {`${t('Name')}`}
              </GearTH>
              <GearTH colSpan={3}>
                {list.Metrics.Weight !== null
                  ? `${t('Weight')} [${convertIfNecessarySI('Weight', list.Metrics.Weight).convertedUnit}]`
                  : `${t('Weight')}`
                }
              </GearTH>
              <GearTH colSpan={3}>
                {list.Metrics.Volume !== null
                  ? `${t('Volume')} [${convertIfNecessarySI('Volume', list.Metrics.Volume).convertedUnit}]`
                  : `${t('Volume')}`
                }
              </GearTH>
            </GearTR>
            <GearTR id={'listRowTH-listName'}>
              <GearTD colSpan={6}>
                {list.Informations.Name}
              </GearTD>
              <GearTD colSpan={3}>
                {list.Metrics.Weight !== null
                  ? convertIfNecessarySI('Weight', list.Metrics.Weight).convertedValue
                  : ''
                }
              </GearTD>
              <GearTD colSpan={3}>
                {list.Metrics.Volume !== null
                  ? convertIfNecessarySI('Volume', list.Metrics.Volume).convertedValue
                  : ''
                }
              </GearTD>
            </GearTR>
          </GearTableBody>
        </ListPreviewTable>
      ))}

    </>
  );
}

ListPreviewTableDisplay.propTypes = {
  listArray: arrayOfListPropTypes.isRequired,
  userConcerned: PropTypes.string.isRequired
};

export default ListPreviewTableDisplay;
