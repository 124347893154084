// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Functions
import { scrollToTop } from '../../utils/Functions/general_Functions';

// Import Assets
import { packListIllustration, sumListIllustration } from '../../datas/Illustrations/list_Illustrations';

// Import Style
import { StyledTitleH2 } from '../../utils/Style/GlobalStyle';
import { PictureContainerSelector } from '../../utils/Style/PreviewStyle';
import { ListUserSelectorContainer, ListUserPreviewContainer, ListUserPreviewImage } from '../../utils/Style/js/Lists/ListUserSelector_Styles';

function ListTypeSelectorComponent ({ userConcerned }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  console.log('currentUserData : ', currentUserData);

  const userPath = userConcerned === 'Me' ? 'MyLists' : 'All';
  const pathPackLists = `/${i18n.language}/${t('Lists')}/${t(userPath)}/${t('PackLists')}`;
  const pathSumLists = `/${i18n.language}/${t('Lists')}/${t(userPath)}/${t('SumLists')}`;

  function handleOnClick (path) {
    scrollToTop();
    navigate(path);
  }

  return (
    <ListUserSelectorContainer id='productSubCategorySelectorContainer'>

      <ListUserPreviewContainer id='productPreviewContainer' onClick={() => handleOnClick(pathPackLists)}>
        <>
        <PictureContainerSelector id='pictureContainerSelector'>
          <ListUserPreviewImage
            src={packListIllustration}
            alt='User_Avatar'
          />
        </PictureContainerSelector>
        <StyledTitleH2>{t('PackListsTitle')}</StyledTitleH2>
        </>
      </ListUserPreviewContainer>

      <ListUserPreviewContainer id='productPreviewContainer' onClick={() => handleOnClick(pathSumLists)}>
        <>
        <PictureContainerSelector id='pictureContainerSelector'>
          <ListUserPreviewImage src={sumListIllustration} alt='GlobalList_Illustration'/>
        </PictureContainerSelector>
        <StyledTitleH2>{t('SumListsTitle')}</StyledTitleH2>
        </>
      </ListUserPreviewContainer>

    </ListUserSelectorContainer>
  );
}

ListTypeSelectorComponent.propTypes = {
  userConcerned: PropTypes.string.isRequired
};

export default ListTypeSelectorComponent;
