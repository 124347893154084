// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes, gearItemPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';
import GearSleepingBagInsulatorRowEdit from './GearSleepingBagInsulatorRowEdit';
import GearMaterialTableEditRow from '../../Common/GearMaterialTableEdit';
import GearDeployedDimensionTableComponentEdit from '../../Common/GearDeployedDimensionEdit';
import DisplayHelpComponent from '../../../../../ToolTip/DisplayHelp_Component';

// Import Funtions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';
import { getShapeArray } from '../../../../../../utils/Functions/Gear/GatherShapes/gatherShapes_Functions';

// Import Datas
import {
  yesNoChoiceArray, leftRightChoiceArray,
  seasonChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Style
import { GearFormInput } from '../../../../../../utils/Style/js/GearForm/GearForm_Style';
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

function GearSleepingBagSpecificTableEdit ({ gearData, item, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  // DropDown
  const [deployedShape, setDeployedShape] = useState(item.Specific.Deployed.Shape);
  const [hood, setHood] = useState(item.Specific.Hood);
  const [zipSide, setZipSide] = useState(item.Specific.ZipSide);
  const [pairable, setPairable] = useState(item.Specific.Pairable);
  const [season, setSeason] = useState(item.Specific.Season);
  const [insulatorNature, setInsulatorNature] = useState(item.Specific.InsulatorNature);
  const [insulatorQuality, setInsulatorQuality] = useState(item.Specific.InsulatorQuality);
  // Field Entry
  const [temperatureComfort, setTemperatureComfort] = useState(item.Specific.TemperatureComfort);
  const [temperatureLimit, setTemperatureLimit] = useState(item.Specific.TemperatureLimit);
  const [temperatureExtrem, setTemperatureExtrem] = useState(item.Specific.TemperatureExtrem);

  const [materials, setMaterials] = useState(
    [item.Specific.Materials[0], item.Specific.Materials[1], item.Specific.Materials[2]]);

  const setFirstMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[0] = value;
      return newMaterials;
    });
  };

  const setSecondMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[1] = value;
      return newMaterials;
    });
  };

  const setThirdMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[2] = value;
      return newMaterials;
    });
  };

  const materialsSetter = [setFirstMaterial, setSecondMaterial, setThirdMaterial];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearTarpSpecificTableEdit-${gearData.SubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('Shape')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Hood')}`}</GearTH>
            <GearTH colSpan={4}>
              {`${t('Season')}`}
              <DisplayHelpComponent
                text1={`${t('SeasonDescription1')}`}
                text2={`${t('SeasonDescription2')}`}
                text3={`${t('SeasonDescription3')}`}
                text4={`${t('SeasonDescription4')}`}
                alignLeft={true}
              />
            </GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={getShapeArray('SleepingBag')} choice={deployedShape} setChoice={setDeployedShape} noChoice='Select'/>
              <input type="hidden" name="DeployedShape" value={deployedShape} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={yesNoChoiceArray} choice={hood} setChoice={setHood} noChoice='Select'/>
              <input type="hidden" name="Hood" value={hood} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={seasonChoiceArray} choice={season} setChoice={setSeason} noChoice='Select'/>
              <input type="hidden" name="Season" value={season} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={6}>{`${t('ZipSide')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('Pairable')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={leftRightChoiceArray} choice={zipSide} setChoice={setZipSide} noChoice='Select'/>
              <input type="hidden" name="ZipSide" value={zipSide} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={yesNoChoiceArray} choice={pairable} setChoice={setPairable} noChoice='Select'/>
              <input type="hidden" name="Pairable" value={pairable} />
            </GearTD>
          </GearTR>
          <GearSleepingBagInsulatorRowEdit
            gearData={gearData}
            insulatorNature={insulatorNature}
            setInsulatorNature={setInsulatorNature}
            insulatorQuality={insulatorQuality}
            setInsulatorQuality={setInsulatorQuality}
          />
          <GearMaterialTableEditRow
            gearData={gearData}
            setMaterials={materialsSetter}
            materials={materials}
          />
          <input type="hidden" name="Materials" value={materials} />
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('Temperature')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('TemperatureComfort')} [${getUnit('Temperature')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('TemperatureLimit')} [${getUnit('Temperature')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('TemperatureExtrem')} [${getUnit('Temperature')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearFormInput
              type="number"
              name="TemperatureComfort"
              placeholder={temperatureComfort === null ? `${t('TemperatureComfort')}` : temperatureComfort}
              value={temperatureComfort}
              onChange={(e) => setTemperatureComfort(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
              type="number"
              name="TemperatureLimit"
              placeholder={temperatureLimit === null ? `${t('TemperatureLimit')}` : temperatureLimit}
              value={temperatureLimit}
              onChange={(e) => setTemperatureLimit(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
              type="number"
              name="TemperatureExtrem"
              placeholder={temperatureExtrem === null ? `${t('TemperatureExtrem')}` : temperatureExtrem}
              value={temperatureExtrem}
              onChange={(e) => setTemperatureExtrem(e.target.value)}
              />
            </GearTD>
          </GearTR>
          <GearDeployedDimensionTableComponentEdit gearData={gearData} specific={item.Specific}/>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearSleepingBagSpecificTableEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearItemPropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearSleepingBagSpecificTableEdit);
