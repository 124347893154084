// Import Datas
import { distanceCharacteristic } from '../../../../datas/Unit/UnitArrays';

export function getUnitUsedForStorage (characteristicName) {
  let defaultUnit;

  if (distanceCharacteristic.includes(characteristicName)) {
    defaultUnit = 'cm';
  } else {
    switch (characteristicName) {
      case 'Weight':
        defaultUnit = 'g';
        break;
      case 'Surface':
        defaultUnit = 'm²';
        break;
      case 'Volume':
        defaultUnit = 'L';
        break;
      case 'Density':
        defaultUnit = 'g/L';
        break;
    }
  }
  return defaultUnit;
};

// TO IMPROVE
export function getUserCurrency () {
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  // console.log('currentUserData : ', currentUserData);
  return currentUserData.userGeneralPreference.Unit_Currency;
};

export function getUserUnitSystem () {
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  // console.log('currentUserData : ', currentUserData);
  return currentUserData.userUnitPreference.Unit_System;
};

export function getUserUnitPreference (characteristicName) {
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  const currentUserPreferenceUnit = currentUserData.userUnitPreference;

  let userPreferredUnit;

  if (currentUserPreferenceUnit.Unit_System === 'SI') {
    getUnitUsedForStorage(characteristicName);
  }
  // console.log(`userPreferedUnit : ${userPreferredUnit}`);
  return userPreferredUnit;
};

// TH
export function getUnit (characteristicName) {
  let unit;
  switch (characteristicName) {
    case 'Impermeability':
      unit = 'mm';
      break;
    case 'Breathability':
      unit = 'g/m²/24h';
      break;
    case 'TissuDensity':
      unit = 'D';
      break;
    case 'ThreadCount':
      unit = 'T';
      break;
    case 'MeshSize':
      unit = 'Holes/Inch²';
      break;
    case 'Temperature':
      unit = '°C';
      break;
    case 'InsulatorQuality':
      unit = 'Cuin';
      break;
    case 'Currency':
      unit = '€';
      break;
    default:
      if (getUserUnitSystem() === 'SI') {
        unit = getUnitUsedForStorage(characteristicName);
      }
  }
  return unit;
};

// TD
export function convertToUserUnit (characteristicName, charactesticValue) {
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  const currentUserPreferenceUnit = currentUserData.userUnitPreference;

  if (currentUserPreferenceUnit[characteristicName] !== getUnitUsedForStorage(characteristicName)) {
    console.log('Conversion recquired');
  } else {
    // No conversion recquired
    return charactesticValue;
  }
};
