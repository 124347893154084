// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes, gearItemPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';
import GearMosquitoNetEditRow from '../../Common/GearMosquitoNetEditRow';
import GearPocketMattressRowEdit from './GearPocketMattressSpecificRowEdit';
import GearMaterialTableEditRow from '../../Common/GearMaterialTableEdit';

// Import Funtions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Datas
import {
  yesNoChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';
import { hammockPersonChoiceArray, hammockTypeChoiceArray } from '../../../../../../datas/ChoiceArrays/Gear/Bedding/specificBedding_ChoiceArrays';

// Import Style
import { GearFormInput } from '../../../../../../utils/Style/js/GearForm/GearForm_Style';
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

function GearHammockSpecificTableEdit ({ gearData, item, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  // DropDowns
  const [person, setPerson] = useState(item.Specific.Person);
  const [storagePocket, setStoragePocket] = useState(item.Specific.StoragePocket);
  const [type, setType] = useState(item.Specific.Type);
  const [hoop, setHoop] = useState(item.Specific.Hoop);
  const [mosquitoNet, setMosquitoNet] = useState(item.Specific.MosquitoNet);
  const [meshSize, setMeshSize] = useState(item.Specific.MeshSize);

  const [pocketMattress, setPocketMattress] = useState(item.Specific.PocketMattress);
  // Entry Fields
  const [maxWeight, setMaxWeight] = useState(item.Specific.MaxWeight);
  const [pocketLength, setPocketLength] = useState(item.Specific.PocketLength);
  const [pocketWidth, setPocketWidth] = useState(item.Specific.PocketWidth);
  const [pocketThickness, setPocketThickness] = useState(item.Specific.PocketThickness);

  const [totalLength, setTotalLength] = useState(item.Specific.Deployed.TotalLength);

  const [materials, setMaterials] = useState(
    [item.Specific.Materials[0], item.Specific.Materials[1], item.Specific.Materials[2]]);

  const setFirstMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[0] = value;
      return newMaterials;
    });
  };

  const setSecondMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[1] = value;
      return newMaterials;
    });
  };

  const setThirdMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[2] = value;
      return newMaterials;
    });
  };

  const materialsSetter = [setFirstMaterial, setSecondMaterial, setThirdMaterial];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearHammockSpecificTableEdit-${gearData.SubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearHammockSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearHammockSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('Person')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Type')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('MaxWeight')} [${getUnit('Weight')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearHammockSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={hammockPersonChoiceArray} choice={person} setChoice={setPerson} noChoice='Select'/>
              <input type="hidden" name="Person" value={person} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={hammockTypeChoiceArray} choice={type} setChoice={setType} noChoice='Select'/>
              <input type="hidden" name="Type" value={type} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
              type="number"
              name="MaxWeight"
              placeholder={maxWeight === null ? `${t('Weight')}` : maxWeight}
              value={maxWeight}
              onChange={(e) => setMaxWeight(e.target.value)}
              />
            </GearTD>
          </GearTR>
          <GearTR id={`gearHammockSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={6}>{`${t('StoragePocket')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('Hoops')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearHammockSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={yesNoChoiceArray} choice={storagePocket} setChoice={setStoragePocket} noChoice='Select'/>
              <input type="hidden" name="StoragePocket" value={storagePocket} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={yesNoChoiceArray} choice={hoop} setChoice={setHoop} noChoice='Select'/>
              <input type="hidden" name="Hoop" value={hoop} />
            </GearTD>
          </GearTR>
          <GearMosquitoNetEditRow gearData={gearData} mosquitoNet={mosquitoNet} setMosquitoNet={setMosquitoNet} meshSize={meshSize} setMeshSize={setMeshSize}/>
          <GearPocketMattressRowEdit
            gearData={gearData}
            pocketMattres={pocketMattress}
            setPocketMattress={setPocketMattress}
            pocketLength={pocketLength}
            setPocketLength={setPocketLength}
            pocketWidth={pocketWidth}
            setPocketWidth={setPocketWidth}
            pocketThickness={pocketThickness}
            setPocketThickness={setPocketThickness}
          />
          <GearMaterialTableEditRow gearData={gearData} materials={materials} setMaterials={materialsSetter}/>
          <input type="hidden" name="Materials" value={materials} />
          <GearTR id={`gearHammockSpecificRowTHEdit-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('TotalLength')} [${getUnit('Distance')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearHammockSpecificRowTDEdit-${gearData.SubCategory}`}>
            <GearTD colSpan={12}>
              <GearFormInput
                type="number"
                name="TotalLength"
                placeholder={totalLength === null ? `${t('TotalLength')}` : totalLength}
                value={totalLength}
                onChange={(e) => setTotalLength(e.target.value)}
              />
            </GearTD>
          </GearTR>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearHammockSpecificTableEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearItemPropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearHammockSpecificTableEdit);
