// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import Components
import ColGroup12 from '../../ColGroup12';
import EquipmentSubCategoryControlTableDisplay from './EquipmentSubCategoryControlTableDisplay';

// Import Datas
import { navigationServiceContentUser } from '../../../../../datas/Nav/navServiceContent';

// Import Style
import {
  GearTable, GearTableBody,
  GearTH, GearTR
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { APIGetAllPendingValidationGearQuantity } from '../../../../../api/Gear/Gear_API';
import LoadingComponent from '../../../../LoadingComponent/Loading_Component';

// Import Constants

function EquipmentCategoryControlTableDisplay () {
  /*
  console.log('EquipmentControlTableDisplay');
  */
  const { t } = useTranslation();
  const [pendingValidationQuantity, setPendingValidationQuantity] = useState(null);

  useEffect(() => {
    async function gatherPendingValidationGearQuantity () {
      try {
        const serverResponse = await APIGetAllPendingValidationGearQuantity();
        console.log('serverResponse : ', serverResponse);
        setPendingValidationQuantity(serverResponse);
      } catch (error) {
        console.error('Error getting pendingValidationGearQuantity :', error);
      }
    }

    gatherPendingValidationGearQuantity();
  }, []);

  return (
    <>
    {
    pendingValidationQuantity === null
      ? <LoadingComponent/>
      : <>
          {
            navigationServiceContentUser[0].dropDownOptions.map((Category, index) => {
              const gearData = {
                TypeCategory: 'Equipment',
                Category
              };
              return (
                <React.Fragment key={`${Category}-${index}`}>
                  <GearTable id={'equipmentControlTableDisplay'}>
                    <ColGroup12/>
                    <GearTableBody>
                      <GearTR id={`equipmentControlRowTDDisplay-${Category}`}>
                        <GearTH colSpan={6}>{`${t(Category)}`}</GearTH>
                        <GearTH colSpan={6}>{`${t('PendingValidation')}`}</GearTH>
                      </GearTR>
                          </GearTableBody>
                  </GearTable>
                  <EquipmentSubCategoryControlTableDisplay
                    key={Category}
                    gearData={gearData}
                    PendingValidationQuantity={pendingValidationQuantity}
                  />
                </React.Fragment>
              );
            })
          }
        </>
    }

    </>
  );
}

export default EquipmentCategoryControlTableDisplay;
