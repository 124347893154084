// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import Components

// Import Assets
import { contactIllustration } from '../../datas/Illustrations/mascot_Ilustrations';

// Import Style
import { ContactIllustration, ContactIllustrationContainer } from '../../utils/Style/js/Contact/Contact_Style';
import { ContactInformationContainer } from '../../utils/Style/js/Contact/ContactInformation_Style';
import { StyledP } from '../../utils/Style/js/General/Font_Style';

function ContactInformation () {
  const { t } = useTranslation();

  return (
      <ContactInformationContainer id='contactInformationContainer'>
        <StyledP>{t('ContactUs')}</StyledP>
        <ContactIllustrationContainer>
          <ContactIllustration src={contactIllustration} alt="PIC"/>
        </ContactIllustrationContainer>
      </ContactInformationContainer>
  );
}

export default ContactInformation;
