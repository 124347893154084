// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { specificCharacteristicPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Functions
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearMosquitoNetRow ({ specificCharacteristic, gearName }) {
  const { t } = useTranslation();
  return (
    <>
        {specificCharacteristic.MosquitoNet === 'Yes'
          ? (
            <>
              <GearTR id={`gearCommonRowTH-${gearName}`}>
                <GearTH colSpan={6}>{`${t('MosquitoNet')}`}</GearTH>
                <GearTH colSpan={6}>{`${t('MeshSize')} [${getUnit('MeshSize')}]`}</GearTH>
              </GearTR>
              <GearTR id={`gearCommonRowTD-${gearName}`}>
                <GearTD colSpan={6}>
                  {specificCharacteristic.MosquitoNet !== null ? `${t(specificCharacteristic.MosquitoNet)}` : ''}
                </GearTD>
                <GearTD colSpan={6}>
                  {specificCharacteristic.MeshSize !== null ? specificCharacteristic.MeshSize : ''}
                  </GearTD>
              </GearTR>
            </>
            )
          : (
        <>
              <GearTR id={`gearCommonRowTH-${gearName}`}>
                <GearTH colSpan={12}>{`${t('MosquitoNet')}`}</GearTH>
              </GearTR>
              <GearTR id={`gearCommonRowTD-${gearName}`}>
                <GearTD colSpan={12}>
                  {specificCharacteristic.MosquitoNet !== null ? `${t(specificCharacteristic.MosquitoNet)}` : ''}
                  </GearTD>
              </GearTR>
            </>
            )}
    </>
  );
}

GearMosquitoNetRow.propTypes = {
  specificCharacteristic: specificCharacteristicPropTypes,
  gearName: PropTypes.string.isRequiredn
};

export default GearMosquitoNetRow;
