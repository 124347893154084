// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { userGeneralPreferencePropTypes } from '../../../../datas/PropTypes/userPropTypes';

// Import Component
import ColGroup12 from '../../../Gear/Tab/ColGroup12';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../utils/Style/js/Account/UserDataTableDisplay_Style';

// Import Constants

function UserGeneralPreferenceDisplay ({ userGeneralPreference }) {
  const { t } = useTranslation();
  return (
    <UserDataTable>
      <ColGroup12/>
      <UserDataTableBody>
        <UserDataTR>
          <UserDataTH colSpan={12}>{`${t('Language')}`}</UserDataTH>
        </UserDataTR>
        <UserDataTR>
          <UserDataTD colSpan={12}>{`${t(userGeneralPreference.User_Language)}`}</UserDataTD>
        </UserDataTR>
        <UserDataTR>
          <UserDataTH colSpan={12}>{`${t('Currency')}`}</UserDataTH>
        </UserDataTR>
        <UserDataTR>
          <UserDataTD colSpan={12}>{`${t(userGeneralPreference.Unit_Currency)}`}</UserDataTD>
        </UserDataTR>
      </UserDataTableBody>
    </UserDataTable>
  );
}

UserGeneralPreferenceDisplay.propTypes = {
  userGeneralPreference: userGeneralPreferencePropTypes
};

export default UserGeneralPreferenceDisplay;
