// Import React Libraries
import i18n from 'i18next';
// import i18nBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// Import Constants
import { webSiteDevUrl, webSiteProdUrl } from './utils/Constants/Language_Constants';

export const getCurrentHost =
  process.env.NODE_ENV === 'development'
    ? webSiteDevUrl
    : webSiteProdUrl;

console.log(`process.env.NODE_ENV : ${process.env.NODE_ENV}`);
console.log(`getCurrentHost : ${getCurrentHost}`);

const userData = JSON.parse(sessionStorage.getItem('userData'));
console.log('userData : ', userData);
// lng: userData ? userData.userGeneralPreference.User_Language : 'fr',

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'fr',
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
