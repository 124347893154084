// Import React Libraries
import React from 'react';

// Import Components
import ServiceSelectorGrid from '../../components/LandingPage/ServiceSelectorGrid';

// Import Function
// import { whatsNewParser } from '../../utils/Functions/noveltyFunctions';

// Import Style
import { PageWrapper } from '../../utils/Styles';

function Home () {
  // const newArticleList = whatsNewParser();
  // const titleWrapper = 'Our Novelty';
  // const gearTypeCategory = 'Equipment';
  // console.log('newArticleList : ', newArticleList);

  return (
      <PageWrapper id='pageWrapperHome'>
        <ServiceSelectorGrid/>
      </PageWrapper>
  );
}

export default Home;
