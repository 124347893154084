// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';

// Import Functions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Datas
import { yesNoChoiceArray } from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../../utils/Style/js/GearForm/GearForm_Style';

// Import Constants

function GearPocketMattressRowAdd
({ gearData, pocketMattres, setPocketMattress, pocketLength, setPocketLength, pocketWidth, setPocketWidth, pocketThickness, setPocketThickness }) {
  const { t } = useTranslation();
  return (
    <>
        {pocketMattres === 'Yes'
          ? (
            <>
              <GearTR id={`GearPocketMattressRowTHAdd-${gearData.SubCategory}`}>
                <GearTH colSpan={12}>{`${t('PocketMattress')}`}</GearTH>
              </GearTR>
              <GearTR id={`GearPocketMattressRowTDAdd-${gearData.SubCategory}`}>
                <GearTD colSpan={12}>
                  <GearSelectDropDown options={yesNoChoiceArray} choice={null} setChoice={setPocketMattress} noChoice='Select'/>
                  <input type="hidden" name="PocketMattress" value={pocketMattres} />
                </GearTD>
              </GearTR>
              <GearTR id={`GearPocketMattressRowTHAdd-${gearData.SubCategory}`}>
                <GearTH colSpan={12}>{`${t('PocketMattressDimension')}`}</GearTH>
              </GearTR>
              <GearTR id={`GearPocketMattressRowTHAdd-${gearData.SubCategory}`}>
                <GearTH colSpan={4}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
                <GearTH colSpan={4}>{`${t('Width')} [${getUnit('Distance')}]`}</GearTH>
                <GearTH colSpan={4}>{`${t('Thickness')} [${getUnit('Distance')}]`}</GearTH>
              </GearTR>
              <GearTR id={`GearPocketMattressRowTDAdd-${gearData.SubCategory}`}>
                <GearTD colSpan={4}>
                  <GearFormInput
                    type="number"
                    name="PocketLength"
                    placeholder={`${t('Length')}`}
                    onChange={(e) => setPocketLength(e.target.value)}
                  />
                </GearTD>
                <GearTD colSpan={4}>
                  <GearFormInput
                    type="number"
                    name="PocketWidth"
                    placeholder={`${t('Width')}`}
                    onChange={(e) => setPocketWidth(e.target.value)}
                  />
                </GearTD>
                <GearTD colSpan={4}>
                  <GearFormInput
                    type="number"
                    name="PocketThickness"
                    placeholder={`${t('Thickness')}`}
                    onChange={(e) => setPocketThickness(e.target.value)}
                  />
                </GearTD>
              </GearTR>
            </>
            )
          : (
            <>
              <GearTR id={`GearPocketMattressRowTHAdd-${gearData.SubCategory}`}>
                <GearTH colSpan={12}>{`${t('PocketMattress')}`}</GearTH>
              </GearTR>
              <GearTR id={`GearPocketMattressRowTDAdd-${gearData.SubCategory}`}>
              <GearTD colSpan={12}>
                  <GearSelectDropDown options={yesNoChoiceArray} choice={null} setChoice={setPocketMattress} noChoice='Select'/>
                  <input type="hidden" name="PocketMattress" value={pocketMattres} />
                </GearTD>
              </GearTR>
            </>
            )}
    </>
  );
}

GearPocketMattressRowAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  pocketMattres: PropTypes.string,
  setPocketMattress: PropTypes.func.isRequired,
  pocketLength: PropTypes.string,
  setPocketLength: PropTypes.func.isRequired,
  pocketWidth: PropTypes.string,
  setPocketWidth: PropTypes.func.isRequired,
  pocketThickness: PropTypes.string,
  setPocketThickness: PropTypes.func.isRequired
};

export default GearPocketMattressRowAdd;
