// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import MyListSelectorComponent from '../../components/List/myListSelector_Component';

// Import Functions
import { displayMyListSelectionPage } from '../../utils/Functions/PathFunctions/listPath_Functions';

// Import Constants

function MyListSelectionPage ({ listType }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  const [shouldDisplayListSelectionPage, setShouldDisplayListSelectionPage] = useState(false);

  useEffect(() => {
    const displayResult = displayMyListSelectionPage(currentUrl, t, i18n);
    console.log(`displayResult : ${displayResult}`);
    setShouldDisplayListSelectionPage(displayResult);
  }, [navigate]);

  return (
    <>
      {shouldDisplayListSelectionPage
        ? (
            <MyListSelectorComponent listType={listType}/>
          )
        : (
            <Outlet/>
          )
      }
    </>
  );
}

MyListSelectionPage.propTypes = {
  listType: PropTypes.string.isRequired
};

export default MyListSelectionPage;
