// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearCommonCharacteristicPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../ColGroup12';
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';
import GearCommonErrorRow from '../../Add/Common/GearCommonErrorRow';

// Import Datas
import { colorsChoiceArray } from '../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Functions
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';
import { commonFormValidTest } from '../../../../../utils/Functions/Gear/FillDataObject/Common/GearFormCheck_Functions';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';

function GearCommonTableComponentEdit ({ item, onSubmit, onError }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [name, setName] = useState(item.Informations.Name);
  const [color0, setColor0] = useState(item.Informations.Colors[0]);
  const [color1, setColor1] = useState(item.Informations.Colors[1]);
  const [color2, setColor2] = useState(item.Informations.Colors[2]);

  const [formDataErrorMessages, setFormDataErrorMessages] = useState({
    Name: '',
    Brand: '',
    Price: ''
  });

  // Handle value edition
  const [brand, setBrand] = useState(
    item.Informations.Brand === null ? null : item.Informations.Brand
  );

  const [price, setPrice] = useState(
    item.Metrics.Price === null ? null : item.Metrics.Price
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const isCommonFormValid = commonFormValidTest(setFormDataErrorMessages, formData);
    console.log('isCommonFormValid : ');
    console.log(isCommonFormValid);

    if (isCommonFormValid) {
      const data = Object.fromEntries(formData.entries());
      console.log(`isCommonFormValid : ${isCommonFormValid}`);
      if (onSubmit) {
        onSubmit(data);
      }
    } else {
      console.log(`isCommonFormValid : ${isCommonFormValid}`);
      if (onError) {
        onError(formDataErrorMessages);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearCommonTable-${item.Informations.TypeCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearCommonRowTH-${item.Informations.TypeCategory}`}>
            <GearTH colSpan={4}>{t('Name')}</GearTH>
            <GearTH colSpan={4}>{t('Brand')}</GearTH>
            <GearTH colSpan={4}>{`${t('Price')} [${getUnit('Currency')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${item.Informations.TypeCategory}`}>
            <GearTD colSpan={4}>
              <GearFormInput
                type="text"
                name="Name"
                placeholder={name === null ? `${t('Name')}` : name}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
                type="text"
                name="Brand"
                placeholder={brand === null ? `${t('Brand')}` : brand}
                value={brand}
                onChange={(e) => setBrand(e.target.value)}
              />
              { /* To have the placeholder not considered when typing in field for edit remove the value from the input above and a hidden input */ }
              { /* <input type="hidden" name="Brand" value={brand} /> */}
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
                type="number"
                name="Price"
                placeholder={price === null ? `${t('Price')}` : price}
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </GearTD>
          </GearTR>
          <GearCommonErrorRow formDataErrorMessages={formDataErrorMessages} />
          <GearTR id={`gearCommonRowTH-${item.Informations.TypeCategory}`}>
            <GearTH colSpan={12}>{t('Colors')}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTD-${item.Informations.TypeCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={colorsChoiceArray} choice={color0} setChoice={setColor0} noChoice='Color'/>
              <input type="hidden" name="Colors0" value={color0} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={colorsChoiceArray} choice={color1} setChoice={setColor1} noChoice='Color'/>
              <input type="hidden" name="Colors1" value={color1} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={colorsChoiceArray} choice={color2} setChoice={setColor2} noChoice='Color'/>
              <input type="hidden" name="Colors2" value={color2} />
            </GearTD>
          </GearTR>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearCommonTableComponentEdit.propTypes = {
  item: gearCommonCharacteristicPropTypes,
  gearName: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

// Wrap the component with forwardRef
export default forwardRef(GearCommonTableComponentEdit);
