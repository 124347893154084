// Import React Libraries
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';
import DisplayHelpComponent from '../../../../../ToolTip/DisplayHelp_Component';
import GearMaterialRowAdd from '../../Common/GearMaterialRowAdd';
import GearMosquitoNetRowAdd from '../../Common/GeaMosquitoNetRowAdd';
import GearTentAwningAmountRowAdd from './GearTentAwningAmountRowAdd';
import GearTentAwningDeployedDimensionRowAdd from './GearTentAwningDeployedDimensionRowAdd';
import GearDeployedDimensionRowAdd from '../../Common/GearDeployedDimensionAdd';

// Import Datas
import {
  tentPersonChoiceArray, tentDoorChoiceArray,
  tentTypeChoiceArray, tentWallChoiceArray,
  tentVentilationChoiceArray,
  tentIngressProtectionChoiceArray,
  impermeabilityChoiceArray,
  sewingChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Shelter/SpecificShelter_ChoiceArrays';
import {
  yesNoChoiceArray,
  UPFChoiceArray,
  seasonChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Functions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearTentSpecificTableAddComponent ({ gearData, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [person, setPerson] = useState(null);
  const [door, setDoor] = useState(null);
  const [selfSupporting, setSelfSupporting] = useState(null);
  const [type, setType] = useState(null);
  const [wall, setWall] = useState(null);
  const [season, setSeason] = useState(null);
  const [ventilation, setVentilation] = useState(null);
  const [upf, setUpf] = useState(null);
  const [hoop, setHoop] = useState(null);
  const [peg, setPeg] = useState(null);
  const [guyWire, setGuyWire] = useState(null);
  const [mosquitoNet, setMosquitoNet] = useState(null);
  const [meshSize, setMeshSize] = useState(null);
  const [awning, setAwning] = useState(null);
  const [awningIdentical, setAwningIdentical] = useState(null);
  const [sewing, setSewing] = useState(null);
  const [ingressProtection, setIngressProtection] = useState(null);
  const [externallWallImpermeability, setExternallWallImpermeability] = useState(null);
  const [floorImpermeability, setFloorImpermeability] = useState(null);

  const [materials, setMaterials] = useState([]);

  const setFirstMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[0] = value;
      return newMaterials;
    });
  };

  const setSecondMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[1] = value;
      return newMaterials;
    });
  };

  const setThirdMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[2] = value;
      return newMaterials;
    });
  };

  const materialsSetter = [setFirstMaterial, setSecondMaterial, setThirdMaterial];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (awning === null || awning === 0 || awning === 1) {
      setAwningIdentical(null);
    }
  }, [awning]);

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearTentSpecificTableAdd-${gearData.SubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearTentSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={6}>{`${t('Person(s)')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('Door(s)')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={tentPersonChoiceArray} choice={null} setChoice={setPerson} noChoice='Select'/>
              <input type="hidden" name="Person" value={person} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={tentDoorChoiceArray} choice={null} setChoice={setDoor} noChoice='Select'/>
              <input type="hidden" name="Door" value={door} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('SelfSupporting')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Type')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Wall(s)')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={yesNoChoiceArray} choice={null} setChoice={setSelfSupporting} noChoice='Select'/>
              <input type="hidden" name="SelfSupporting" value={selfSupporting} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={tentTypeChoiceArray} choice={null} setChoice={setType} noChoice='Select'/>
              <input type="hidden" name="Type" value={type} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={tentWallChoiceArray} choice={null} setChoice={setWall} noChoice='Select'/>
              <input type="hidden" name="Wall" value={wall} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>
              {`${t('Season(s)')}`}
              <DisplayHelpComponent
                text1={`${t('SeasonDescription1')}`}
                text2={`${t('SeasonDescription2')}`}
                text3={`${t('SeasonDescription3')}`}
                text4={`${t('SeasonDescription4')}`}
                alignLeft={true}
              />
            </GearTH>
            <GearTH colSpan={4}>{`${t('Ventilation(s)')}`}</GearTH>
            <GearTH colSpan={4}>
              {`${t('UPF')}`}
              <DisplayHelpComponent text1={`${t('UVProtectionFactor')}`}/>
            </GearTH>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={seasonChoiceArray} choice={null} setChoice={setSeason} noChoice='Select'/>
              <input type="hidden" name="Season" value={season} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={tentVentilationChoiceArray} choice={null} setChoice={setVentilation} noChoice='Select'/>
              <input type="hidden" name="Ventilation" value={ventilation} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={UPFChoiceArray} choice={null} setChoice={setUpf} noChoice='Select'/>
              <input type="hidden" name="Upf" value={upf} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('Hoop(s)')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Peg(s)')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('GuyWire(s)')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={tentVentilationChoiceArray} choice={null} setChoice={setHoop} noChoice='Select'/>
              <input type="hidden" name="Hoop" value={hoop} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={tentVentilationChoiceArray} choice={null} setChoice={setPeg} noChoice='Select'/>
              <input type="hidden" name="Peg" value={peg} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={tentVentilationChoiceArray} choice={null} setChoice={setGuyWire} noChoice='Select'/>
              <input type="hidden" name="GuyWire" value={guyWire} />
            </GearTD>
          </GearTR>
          <GearMosquitoNetRowAdd
            gearData={gearData}
            mosquitoNet={mosquitoNet}
            setMosquitoNet={setMosquitoNet}
            meshSize={meshSize}
            setMeshSize={setMeshSize}
          />
          <GearTentAwningAmountRowAdd
            gearData={gearData}
            awning={awning}
            setAwning={setAwning}
            awningIdentical={awningIdentical}
            setAwningIdentical={setAwningIdentical}
          />
          <GearMaterialRowAdd gearData={gearData} setMaterials={materialsSetter}/>
          <input type="hidden" name="Materials" value={materials} />
          <GearTR id={`gearTentSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={6}>{`${t('Sewing')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('IngressProtection')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={sewingChoiceArray} choice={null} setChoice={setSewing} noChoice='Select'/>
              <input type="hidden" name="Sewing" value={sewing} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={tentIngressProtectionChoiceArray} choice={null} setChoice={setIngressProtection} noChoice='Select'/>
              <input type="hidden" name="IngressProtection" value={ingressProtection} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={6}>{`${t('ImpermeabilityExtern')} [${getUnit('Impermeability')}]`}</GearTH>
            <GearTH colSpan={6}>{`${t('ImpermeabilityFloor')} [${getUnit('Impermeability')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearTentSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={impermeabilityChoiceArray} choice={null} setChoice={setExternallWallImpermeability} noChoice='Select'/>
              <input type="hidden" name="ImpermeabilityExtern" value={externallWallImpermeability} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={impermeabilityChoiceArray} choice={null} setChoice={setFloorImpermeability} noChoice='Select'/>
              <input type="hidden" name="ImpermeabilityFloor" value={floorImpermeability} />
            </GearTD>
          </GearTR>
          <GearDeployedDimensionRowAdd gearData={gearData}/>
          <GearTentAwningDeployedDimensionRowAdd gearData={gearData} awning={awning} awningIdentical={awningIdentical}/>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearTentSpecificTableAddComponent.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearTentSpecificTableAddComponent);
