function isValidUserID (userIDToTest) {
  // console.log(`userIDToTest : ${userIDToTest}`);
  if (userIDToTest === '' || userIDToTest === null) {
    return ('MissingUserID');
  } else {
    // Regular expression pattern for email validation
    const userIDPattern = /^[a-zA-Z0-9]+$/;
    // Test the email address against the pattern
    return userIDPattern.test(userIDToTest);
  }
}

function isValidUserPseudo (userPseudoToTest) {
  // console.log(`userIDToTest : ${userPseudoToTest}`);
  if (userPseudoToTest === '' || userPseudoToTest === null) {
    return ('MissingUserPseudo');
  } else {
    // Regular expression pattern for email validation
    const userIDPattern = /^[a-zA-Z0-9]+$/;
    // Test the email address against the pattern
    return userIDPattern.test(userPseudoToTest);
  }
}

function isValidUserPassword (userPasswordToTest) {
  // console.log(`userPasswordToTest : ${userPasswordToTest}`);
  if (userPasswordToTest === '' || userPasswordToTest === null) {
    return ('MissingUserPassword');
  } else {
    return true;
  }
}

export function userProfileFormValidTest (formDataErrorMessages, setFormDataErrorMessages, formData, fromAddOrEdit) {
  // const isPhoneValid = isValidPhoneNumber(formData.phone);
  // console.log('formData : ', formData);
  // console.log('formDataErrorMessages : ', formDataErrorMessages);
  // console.log(`formData.User_ID : ${formData.get('User_ID')}`);
  const isUserIDValid = isValidUserID(formData.get('User_ID'));
  const isUserPseudoValid = isValidUserPseudo(formData.get('User_Pseudo'));
  const isUserPasswordValid = isValidUserPassword(formData.get('User_Password'));

  // console.log(`isUserIDValid : ${isUserIDValid}`);

  if (isUserIDValid === true) {
    // console.log('User_ID Valid');
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      User_ID: ''
    }));
  } else {
    if (isUserIDValid === 'MissingUserID') {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        User_ID: 'MissingUserID'
      }));
    } else if (isUserIDValid === false) {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        User_ID: 'FormatUserID'
      }));
    }
  }

  if (isUserPseudoValid === true) {
    // console.log('User_Pseudo Valid');
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      User_Pseudo: ''
    }));
  } else {
    if (isUserPseudoValid === 'MissingUserPseudo') {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        User_Pseudo: 'MissingUserPseudo'
      }));
    } else if (isUserPseudoValid === false) {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        User_Pseudo: 'FormatUserPseudo'
      }));
    }
  }

  if (fromAddOrEdit === 'Add') {
    if (isUserPasswordValid === true) {
      // console.log('User_Password Valid');
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        User_Password: ''
      }));
    } else {
      if (isUserPasswordValid === 'MissingUserPassword') {
        setFormDataErrorMessages(prevState => ({
          ...prevState,
          User_Password: 'MissingUserPassword'
        }));
      }
    }
  }

  // console.log('formDataErrorMessages : ', formDataErrorMessages);

  // if (isEmailValid === true && isPhoneValid === true && isNameValid === true) {
  if (isUserIDValid === true && isUserPseudoValid === true) {
    return true;
  } else {
    return false;
  }
};

export function defineAvatarRowSpan (formDataErrorMessages, errorMessages) {
  let AvatarRowSpan = 7;
  if (formDataErrorMessages.User_ID !== '' || errorMessages.includes('User_ID')) {
    AvatarRowSpan += 1;
  }
  if (formDataErrorMessages.User_Pseudo !== '' || errorMessages.includes('User_Pseudo')) {
    AvatarRowSpan += 1;
  }
  if (formDataErrorMessages.User_Password !== '') {
    AvatarRowSpan += 1;
  }
  return AvatarRowSpan;
};
