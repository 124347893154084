// Import React Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import Styled
import styled from 'styled-components';

// Import Constants
import { responsiveWidthMobile } from '../../../Constant';

// Import Colors
import colors from '../../../Colors/colors';

export const GearCommonTable = styled.table`
  font-family: sans-serif;

  width: 100%;
  
  border-spacing: 5px 15px;
  border: 1px solid ${colors.headerFooterBackGround};
  border-radius: 5px;

  @media (max-width: ${responsiveWidthMobile}px){
    width: 100%;
  }
`;

export const GearCommonTR = styled.tr`
  border: 1px solid black;
`;

export const GearCommonTH = styled.th`
  border-radius: 3px;
  background: ${colors.characteristicTH};
`;

export const GearCommonTD = styled.td`
  border-radius: 3px;
  text-align: center;
  height: 20px;
  width: 100%;
  padding: 0px;
`;

export const GearCommonIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
  border: 2px solid black;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const GearCommonIcon = styled(FontAwesomeIcon)`

`;

export const GearCommonButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  width: 100%;

  background: ${colors.buttonBackGround};
  color: white;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    color: ${colors.buttonBackGround};
    background: white;
  }
`;
