// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../ColGroup12';
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';

// Import Datas
import { colorsChoiceArray } from '../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Functions
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { commonFormValidTest } from '../../../../../utils/Functions/Gear/FillDataObject/Common/GearFormCheck_Functions';
import GearCommonErrorRow from './GearCommonErrorRow';

function GearCommonDataTableAdd ({ gearData, onSubmit, onError, temporaryGearObject }, ref) {
  console.log('GearCommonDataTableAdd');
  console.log('gearData : ', gearData);
  console.log('temporaryGearObject : ', temporaryGearObject);

  const { t } = useTranslation();
  const formRef = useRef();

  const [color0, setColor0] = useState('');
  const [color1, setColor1] = useState('');
  const [color2, setColor2] = useState('');

  const [formDataErrorMessages, setFormDataErrorMessages] = useState({
    Name: '',
    Brand: '',
    Price: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const isCommonFormValid = commonFormValidTest(setFormDataErrorMessages, formData);
    console.log(`isCommonFormValid : ${isCommonFormValid}`);

    if (isCommonFormValid) {
      const data = Object.fromEntries(formData.entries());
      if (onSubmit) {
        onSubmit(data);
      }
    } else {
      if (onError) {
        onError(formDataErrorMessages);
      }
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearCommonTable-${gearData.TypeCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearCommonRowTH-${gearData.TypeCategory}`}>
            <GearTH colSpan={4}>{t('Name')}</GearTH>
            <GearTH colSpan={4}>{t('Brand')}</GearTH>
            <GearTH colSpan={4}>{`${t('Price')} [${getUnit('Currency')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.TypeCategory}`}>
            <GearTD colSpan={4}>
            <GearFormInput type="text" name="Name" placeholder={`${t('Name')}`}/>
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput type="text" name="Brand" placeholder={`${t('Brand')}`}/>
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput type="number" name="Price" placeholder={`${t('Price')}`}/>
            </GearTD>
          </GearTR>
          <GearCommonErrorRow formDataErrorMessages={formDataErrorMessages} />
          <GearTR id={`gearCommonRowTH-${gearData.TypeCategory}`}>
            <GearTH colSpan={12}>{t('Colors')}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTD-${gearData.TypeCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={colorsChoiceArray} choice={null} setChoice={setColor0} noChoice='Color'/>
              <input type="hidden" name="Colors0" value={color0} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={colorsChoiceArray} choice={null} setChoice={setColor1} noChoice='Color'/>
              <input type="hidden" name="Colors1" value={color1} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={colorsChoiceArray} choice={null} setChoice={setColor2} noChoice='Color'/>
              <input type="hidden" name="Colors2" value={color2} />
            </GearTD>
          </GearTR>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearCommonDataTableAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  temporaryGearObject: gearDataPropTypes,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired
};

// Wrap the component with forwardRef
export default forwardRef(GearCommonDataTableAdd);
