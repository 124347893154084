// Import React Libraries
import styled from 'styled-components';
import colors from '../../../Colors/colors';

export const AuthenticationTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

export const AuthenticationTable = styled.table`
  border-spacing: 4px;

  width: 250px;

  font-family: sans-serif;

`;

export const AuthenticationTableBody = styled.tbody`

`;

export const AuthenticationTR = styled.tr`
`;

export const AuthenticationTH = styled.th`
  border-radius: 3px;
  background: ${colors.characteristicTH};
  height: 30px;
`;

export const AuthenticationTD = styled.td`
  border-radius: 3px;
  background: white;
  text-align: center;
  height: 30px;
`;
