// Import Style
import styled from 'styled-components';

// Import Constants
import { responsiveWidthTablet } from '../../../Constant';

// Import Colors
import colors from '../../../Colors/colors';

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`;

export const StyledH1 = styled.h1`
  font-family: sans-serif;
  font-style: italic;

  margin: 20px 0;

  text-align: center;

  color: ${colors.headerFooterBackGround};
  display: inline-block;
  width: fit-content;
  border-bottom: 2px solid ${colors.titleUnderline};
  padding-bottom: 5px;

  @media (max-width: ${responsiveWidthTablet}px){
    
  }
`;

export const StyledP = styled.p`
  font-family: sans-serif;
  font-style: 15px;
  margin: 5px;
`;
