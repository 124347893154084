export const navigationMenuContent = [
  {
    pageLinkTitle: 'Home',
    pageLink: '',
    dropDown: false
  },
  {
    pageLinkTitle: 'Contact',
    pageLink: 'Contact',
    dropDown: false
  },
  {
    pageLinkTitle: 'Account',
    pageLink: 'Account',
    dropDown: false
  }
];
