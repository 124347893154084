// Import React Libraries
import React from 'react';

// Import Style
import { LoadingOverlayContainer, Spinner } from '../../utils/Style/js/LoadingComponent/LoadingComponent_Styles';

const LoadingOverlayComponent = () => {
  return (
    <LoadingOverlayContainer>
      <Spinner />
    </LoadingOverlayContainer>
  );
};

export default LoadingOverlayComponent;
