// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';
import GearMaterialRowAdd from '../../Common/GearMaterialRowAdd';

import GearDeployedDimensionRowAdd from '../../Common/GearDeployedDimensionAdd';

// Import Funtions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Datas
import {
  impermeabilityChoiceArray, breathabilityChoiceArray,
  tissuDensityChoiceArray, threadCountChoiceArray,
  sewingChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Shelter/SpecificShelter_ChoiceArrays';
import { UPFChoiceArray } from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../../utils/Style/js/GearForm/GearForm_Style';

function GearTarpSpecificTableAddComponent ({ gearData, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [impermeability, setImpermeability] = useState(null);
  const [breathability, setBreathability] = useState(null);
  const [upf, setUpf] = useState(null);
  const [tissuDensity, setTissuDensity] = useState(null);
  const [threadCount, setThreadCount] = useState(null);
  const [sewing, setSewing] = useState(null);

  const [materials, setMaterials] = useState([]);

  const setFirstMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[0] = value;
      return newMaterials;
    });
  };

  const setSecondMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[1] = value;
      return newMaterials;
    });
  };

  const setThirdMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[2] = value;
      return newMaterials;
    });
  };

  const materialsSetter = [setFirstMaterial, setSecondMaterial, setThirdMaterial];

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearTarpSpecificTable-${gearData.SubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearTarpSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('Attach')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={12}><GearFormInput type="number" name="Attach" placeholder={`${t('Attach')}`}/></GearTD>
          </GearTR>
          <GearMaterialRowAdd gearData={gearData} setMaterials={materialsSetter}/>
          <input type="hidden" name="Materials" value={materials} />
          <GearTR id={`gearTarpSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('TissuDensity')} [${getUnit('TissuDensity')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('ThreadCount')} [${getUnit('ThreadCount')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('Sewing')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={tissuDensityChoiceArray} choice={null} setChoice={setTissuDensity} noChoice='TissuDensity'/>
              <input type="hidden" name="TissuDensity" value={tissuDensity} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={threadCountChoiceArray} choice={null} setChoice={setThreadCount} noChoice='ThreadCount'/>
              <input type="hidden" name="ThreadCount" value={threadCount} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={sewingChoiceArray} choice={null} setChoice={setSewing} noChoice='Sewing'/>
              <input type="hidden" name="Sewing" value={sewing} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTH-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('Impermeability')} [${getUnit('Impermeability')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('Breathability')} [${getUnit('Breathability')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('UPF')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearTarpSpecificRowTD-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={impermeabilityChoiceArray} choice={null} setChoice={setImpermeability} noChoice='Impermeability'/>
              <input type="hidden" name="Impermeability" value={impermeability} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={breathabilityChoiceArray} choice={null} setChoice={setBreathability} noChoice='Breathability'/>
              <input type="hidden" name="Breathability" value={breathability} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown options={UPFChoiceArray} choice={null} setChoice={setUpf} noChoice='UPF'/>
              <input type="hidden" name="Upf" value={upf} />
            </GearTD>
          </GearTR>
          <GearDeployedDimensionRowAdd gearData={gearData}/>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearTarpSpecificTableAddComponent.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearTarpSpecificTableAddComponent);
