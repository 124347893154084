import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';

// Create List
export async function APIPostTypeList (data) {
  /*
  console.log('APIPostTypeList');
  console.log('data:', data);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/List/`;

  // console.log('formData : ', formData);

  try {
    const response = await fetch(URLToFetch, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    return responseBody;
  } catch (error) {
    if (error.response) {
      console.log('Server error response:', error.response);
      return {
        success: false,
        message: error.message,
        error: error.response
      };
    } else {
      return {
        success: false,
        message: error.message
      };
    }
  }
};

// Get all type lists created by one user
export async function getAllTypeListsCreatedByOneUser (userID, listType) {
  /*
  console.log('getAllTypeListsCreatedByOneUser');
  console.log(`userID: ${userID}`);
  console.log(`listType: ${listType}`);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/List/TypeListCreatedBy/${userID}/${listType}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

// Get one list
export async function APIGetOneUserTypeList (userID, listID, listType) {
  /*
  console.log('APIGetOneUserTypeList');
  console.log(`userID: ${userID}`);
  console.log(`listID: ${listID}`);
  console.log(`listType: ${listType}`);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/List/UserTypeList/${userID}/${listID}/${listType}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData[0];
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

// Edit List
export async function APIEditList (data) {
  /*
  console.log('APIEditList');
  console.log('data:', data);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/List/`;

  // console.log('formData : ', formData);

  try {
    const response = await fetch(URLToFetch, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    return responseBody;
  } catch (error) {
    if (error.response) {
      console.log('Server error response:', error.response);
      return {
        success: false,
        message: error.message,
        error: error.response
      };
    } else {
      return {
        success: false,
        message: error.message
      };
    }
  }
};

export async function APIDeleteList (listMongoDbID, listType) {
  console.log('APIDeleteList');
  console.log(`listMongoDbID : ${listMongoDbID}`);
  console.log(`listType : ${listType}`);

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/List/${listMongoDbID}/${listType}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Read the response body
    const responseBody = await response.json();
    console.log('responseBody : ', responseBody);

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    } else {
      if (responseBody.message === 'List Deleted') {
        return true;
      }
    }
  } catch (error) {
    console.log('error:', error);
  }
};
