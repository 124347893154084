// Import React Libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Assets
import {
  equipmentServiceIllustration,
  listServiceIllustration,
  comparatorServiceIllustration
} from '../../datas/Illustrations/service_Illustrations';

// Import Style
import {
  GridWrapper, GridContainer,
  GridOne, GridTwo, GridThree,
  OverLay, CategoryImg, Description
} from '../../utils/Style/js/LandingPage/ServiceSelectorGridStyle';

function ServiceSelectorGrid () {
  const { t, i18n } = useTranslation();
  return (
    <GridWrapper id='gridWrapper'>
      <GridContainer id='gridContainer'>
        <GridOne id='gridOne'>
          <Link to={`/${i18n.language}/${t('Equipment')}`}>
            <OverLay id="img1"></OverLay>
            <CategoryImg src={equipmentServiceIllustration} alt="img1" />
            <Description className="main-description">{t('Equipment')}</Description>
          </Link>
        </GridOne>
        <GridTwo id='gridTwo'>
          <Link to={`/${i18n.language}/${t('Lists')}`}>
            <OverLay id="img2"></OverLay>
            <CategoryImg src={listServiceIllustration} alt="img2" />
            <Description className="main-description">{t('Lists')}</Description>
          </Link>
        </GridTwo>
        <GridThree id='gridThree'>
          <Link to={`/${i18n.language}/${t('Comparator')}`}>
            <OverLay id="img3"></OverLay>
            <CategoryImg src={comparatorServiceIllustration} alt="img3" />
            <Description className="main-description">{t('Comparator')}</Description>
          </Link>
        </GridThree>
      </GridContainer>
    </GridWrapper>
  );
}

export default ServiceSelectorGrid;
