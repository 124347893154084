// Import React Libraries
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import Style
import styled from 'styled-components';

// Import Constants
import { headerHeight, responsiveWidthTablet } from '../../../Constant';

// Import Colors
import colors from '../../../Colors/colors';

// height: ${window.innerHeight - (headerHeight * 2 + footerHeight)}px;

export const SidebarWrapper = styled.div`
  display: flex;
  position: fixed;
  top: ${headerHeight * 2}px;
  left: 0;
  
  height: ${props => props.height}px;
  transition: width 0.3s;
  width: ${props => (props.isOpen ? '210px' : '20px')};

  z-index: 2;

  @media (max-width: ${responsiveWidthTablet}px){
    top: ${headerHeight}px;
    height: ${props => props.height + headerHeight}px;
  }
`;

export const SideBarArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  background-color: ${colors.headerFooterBackGround};
  color: white;
  cursor: pointer;
`;

// Icon
export const SideBarArrowIcon = styled(FontAwesomeIcon)`
  width: 15px;
  height: 25px;
  color: white;
`;

export const SidebarContentContainer = styled.div`
  width: ${props => (props.isOpen ? '200px' : '0')};
  overflow: hidden;
  transition: width 0.3s;
  background-color: white;
`;
