// Import React Libraries
import React from 'react';

// Import Style
import { LoadingContainer, Spinner } from '../../utils/Style/js/LoadingComponent/LoadingComponent_Styles';

const LoadingComponent = () => {
  return (
    <LoadingContainer>
      <Spinner />
    </LoadingContainer>
  );
};

export default LoadingComponent;
