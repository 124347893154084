// Import React Libraries
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../datas/PropTypes/gearPropTypes';

// Import Component
import { GearSelectDropDown } from '../../SelectDropDown/GearSelectDropDown';
import GearFunctionalityDescription from '../../DescriptionComponent/GearFunctionalityDescription';

// Import Datas
import { gearValidationChoiceArray } from '../../../../datas/ChoiceArrays/Gear/gearStatistics_ChoiceArrays';

// Import Style
import {
  GearItemFilterTableContainer,
  FilterTable, FilterTableBody,
  FilterTR, FilterTD,
  GearUserInterractionContainer,
  FilterTH
} from '../../../../utils/Style/js/FilterTable/FilterTable_Style';
import { GeneralButton } from '../../../../utils/Style/js/General/Button_Style';

// Import Constants

function GearItemFilterTableComponent ({ gearData, setGearChoiceSelection, isTherePendingValidationGear }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const linkPath = gearData.SubCategory !== 'Accessory'
    ? `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t('Add')}`
    : `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t(gearData.AccessoryCategory)}/${t('Add')}`;

  const handleGearAddRedirection = () => {
    navigate(linkPath);
  };

  useEffect(() => {
  }, [isTherePendingValidationGear]);

  const buttonText = gearData.SubCategory !== 'Accessory'
    ? gearData.SubCategory
    : gearData.AccessoryCategory;

  return (
    <GearItemFilterTableContainer id={`GearItemFilterTableContainer-${gearData.gearSubCategory}`}>
      <GearUserInterractionContainer>
        <GeneralButton onClick={() => handleGearAddRedirection()}>{`${t('ProposeA')} ${t(buttonText)}`}</GeneralButton>
        <GearFunctionalityDescription/>
      </GearUserInterractionContainer>
          {
            isTherePendingValidationGear &&
            <FilterTable>
              <FilterTableBody>
                <FilterTR>
                  <FilterTH colSpan={12}>
                  {`${t('SeeEquipment')} :`}
                  </FilterTH>
                </FilterTR>
                <FilterTR>
                  <FilterTD colSpan={12}>
                    <GearSelectDropDown options={gearValidationChoiceArray} choice={'Validated'} setChoice={setGearChoiceSelection} noChoice='StatusSelection'/>
                  </FilterTD>
                </FilterTR>
              </FilterTableBody>
            </FilterTable>
          }
    </GearItemFilterTableContainer>
  );
}

GearItemFilterTableComponent.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  setGearChoiceSelection: PropTypes.func.isRequired,
  isTherePendingValidationGear: PropTypes.bool
};

export default GearItemFilterTableComponent;
