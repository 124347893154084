// Import Style
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { commonWriting } from '../../GlobalStyle';

export const GearSideBarWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  width: 100%;

  border: 1px solid black;
`;

export const GearSideBarContainer = styled.details`
  display: flex;
  flex-direction: column;
  list-style: none;

  margin: 0;
  padding: 0;
  border: none;
`;

export const GearSideBarSummary = styled.summary`
  ${commonWriting};
  list-style: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;


  &::marker {
    display: none;
  }
`;

export const GearSideBarTypeCategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
`;

export const GearSideBarSubCategory = styled.div`
  ${commonWriting};
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  height: 30px;

  &:hover {
    background-color: ${props => props.gearsubcategorycolor};
  }
  border-bottom: 1px solid blue;
`;

// Link
export const GearSideBarLink = styled(Link)`
  display: flex;
  justify-content: center;
  text-decoration: none;
  display: inline-block;
  color: black;

  width: 100%;
`;
