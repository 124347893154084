// Import React Libraries
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

// Import Proptypes
import PropTypes from 'prop-types';

// Import Style
import {
  GearSelectDropDownContainer,
  GearDropdownIndicator, GearDropdownButton, GearDropDownIcon,
  GearDropdownMenu, GearDropdownOption
} from '../../../utils/Style/js/GearForm/GearFormDropDown_Style';

export const GearSelectDropDown = ({ options, choice, setChoice, noChoice }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(choice || '');
  const dropdownRef = useRef(null);

  /*
  console.log('GearSelectDropDown');
  console.log(`options : ${options}`);
  console.log(`choice : ${choice}`);
  console.log(`setChoice : ${setChoice}`);
  console.log(`noChoice : ${noChoice}`);
  */

  useEffect(() => {
    if (choice) {
      // console.log('choice action');
      setSelectedOption(choice);
    }
  }, [choice]);

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    setChoice(value);
    setIsOpen(false);
  };

  const handleButtonClick = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <GearSelectDropDownContainer ref={dropdownRef}>
      <GearDropdownButton onClick={handleButtonClick}>
        {selectedOption ? t(selectedOption) : t(noChoice)}
        <GearDropdownIndicator><GearDropDownIcon icon={faChevronDown}/></GearDropdownIndicator>
      </GearDropdownButton>
      {isOpen && (
        <GearDropdownMenu>
          <GearDropdownOption onClick={() => handleOptionClick('')} selected={!selectedOption}>
            {t(noChoice)}
          </GearDropdownOption>
          {options.map((option, index) => (
            <GearDropdownOption
              key={index}
              onClick={() => handleOptionClick(option)}
              selected={selectedOption === option}
            >
              {t(option)}
            </GearDropdownOption>
          ))}
        </GearDropdownMenu>
      )}
    </GearSelectDropDownContainer>
  );
};

GearSelectDropDown.propTypes = {
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number)
  ]).isRequired,
  choice: PropTypes.string,
  setChoice: PropTypes.func.isRequired,
  noChoice: PropTypes.string.isRequired
};
