// Import React Libraries
import React, { forwardRef } from 'react';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../datas/PropTypes/gearPropTypes';

// Import Component

// Specific Table
// Accessory
import GearAccessoryTableAdd from './Accessory/GearAccessoryTableAdd';
// Shelter
import GearTarpSpecificTableAddComponent from './Shelter/Tarp/GearTarpSpecificTableAdd';
import GearTentSpecificTableAddComponent from './Shelter/Tent/GearTentSpecificTableAdd';
// Bedding
import GearSleepingBagSpecificTableAdd from './Bedding/SleepingBag/GearSleepingBagSpecificTableAdd';
import GearMattressSpecificTableAdd from './Bedding/Mattress/GearMattressSpecificTableAdd';
import GearHammockSpecificTableAdd from './Bedding/Hammock/GearHammockSpecificTableAdd';

// Bedding

function GearDataTableSpecificHandlerAdd ({ gearData, onSubmit }, ref) {
  return (
    <>
      { /* Accessory */ }
      {gearData.SubCategory === 'Accessory' && (
        <GearAccessoryTableAdd
          ref={ref}
          gearData={gearData}
          onSubmit={onSubmit}
        />
      )}
      { /* Shelter */ }
      {gearData.SubCategory === 'Tarp' && (
        <GearTarpSpecificTableAddComponent
          ref={ref}
          gearData={gearData}
          onSubmit={onSubmit}
        />
      )}
      {gearData.SubCategory === 'Tent' && (
        <GearTentSpecificTableAddComponent
          ref={ref}
          gearData={gearData}
          onSubmit={onSubmit}
        />
      )}
      { /* Bedding */ }
      {gearData.SubCategory === 'SleepingBag' && (
        <GearSleepingBagSpecificTableAdd
          ref={ref}
          gearData={gearData}
          onSubmit={onSubmit}
        />
      )}
      {gearData.SubCategory === 'Mattress' && (
        <GearMattressSpecificTableAdd
          ref={ref}
          gearData={gearData}
          onSubmit={onSubmit}
        />
      )}
      {gearData.SubCategory === 'Hammock' && (
        <GearHammockSpecificTableAdd
          ref={ref}
          gearData={gearData}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}

GearDataTableSpecificHandlerAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearDataTableSpecificHandlerAdd);
