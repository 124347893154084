// Import React Libraries
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { userProfilePropTypes } from '../../../../datas/PropTypes/userPropTypes';
import { errorMessagesProptypes } from '../../../../datas/PropTypes/errorMessagesPropTypes';

// Import Component
import ColGroup12 from '../../../Gear/Tab/ColGroup12';
import { GearFormInput } from '../../../../utils/Style/js/GearForm/GearForm_Style';
import { GearSelectDropDown } from '../../../Gear/SelectDropDown/GearSelectDropDown';

// Import Functions
import { defineAvatarRowSpan, userProfileFormValidTest } from '../../../../utils/Functions/User/UserForm_Functions';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../utils/Style/js/Account/UserDataTableDisplay_Style';
import { DeleteButton, FileInputLabel, ImageContainer, ImageSelectorContainer } from '../../../../utils/Style/js/GearTable/TablePicture_Style';

// Import Constants
import { userRankChoiceArray } from '../../../../datas/User/User_AddEdit_ChoiceArrays';

// Import Constants

function UserProfileEdit ({ onSubmit, onError, userProfile, temporaryUserProfile, errorMessages }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();
  console.log('userRankChoiceArray : ', userRankChoiceArray);

  console.log('temporaryUserProfile : ', temporaryUserProfile);

  const [userRank, setUserRank] = useState(temporaryUserProfile ? temporaryUserProfile.User_Rank : userProfile.User_Rank);
  const [userPseudo, setUserPseudo] = useState(temporaryUserProfile ? temporaryUserProfile.User_Pseudo : userProfile.User_Pseudo);
  const [userPassword, setUserPassword] = useState(temporaryUserProfile ? temporaryUserProfile.User_Password : userProfile.User_Password);
  const [selectedAvatar, setSelectedAvatar] = useState(temporaryUserProfile ? temporaryUserProfile.User_Avatar : userProfile.User_Avatar);

  const [formDataErrorMessages, setFormDataErrorMessages] = useState({
    User_ID: '',
    User_Pseudo: '',
    User_Password: ''
  });

  const [avatarRowSpan, setAvatarRowSpan] = useState(7);

  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedAvatar(file);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    console.log('selectedAvatar : ', selectedAvatar);
    if (selectedAvatar instanceof File) {
      console.log('instance of file detected');
      // Add or edit avatar
      formData.append('User_Avatar', selectedAvatar);
    } else if (selectedAvatar === null || selectedAvatar === undefined) {
      // Delete avatar
      formData.append('User_Avatar', null);
    } else {
      // Keep avatar
      console.log('url detected');
      formData.append('User_Avatar', `Public/User/${userProfile.User_ID}-Avatar`);
    }

    const isUserProfileFormValid = userProfileFormValidTest(formDataErrorMessages, setFormDataErrorMessages, formData, 'Edit');
    const data = Object.fromEntries(formData.entries());

    // console.log(data);
    if (isUserProfileFormValid) {
      console.log(`isUserProfileFormValid : ${isUserProfileFormValid}`);
      if (onSubmit) {
        onSubmit(data);
      }
    } else {
      console.log(`isUserProfileFormValid : ${isUserProfileFormValid}`);
      if (onError) {
        onError(formDataErrorMessages);
      }
    }
  };

  useEffect(() => {
    // console.log('formDataErrorMessages : ', formDataErrorMessages);
    setAvatarRowSpan(defineAvatarRowSpan(formDataErrorMessages, errorMessages));
  }, [formDataErrorMessages, errorMessages]);

  const deleteImage = (index) => {
    setSelectedAvatar(null);
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <UserDataTable>
        <ColGroup12/>
        <UserDataTableBody>
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('Rank')}`}</UserDataTH>
            <UserDataTH colSpan={6}>{`${t('Avatar')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            {
              currentUserData.userProfile.User_Rank === 'ADMIN'
                ? <UserDataTD colSpan={6}>
                  <GearSelectDropDown options={userRankChoiceArray} choice={userRank} setChoice={setUserRank} noChoice='Select'/>
                  <input type="hidden" name="User_Rank" value={userRank} />
                </UserDataTD>
                : <UserDataTD colSpan={6}>{userProfile.User_Rank}</UserDataTD>
            }
            <UserDataTD colSpan={6} rowSpan={avatarRowSpan}>
              <ImageSelectorContainer>
                {/* Hidden file input */}
                <input
                  type="file"
                  accept="image/jpeg, image/jpg"
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: 'none' }} // Hide the default file input
                  id={'User_Avatar-FileInput'}
                />
                {/* Custom label to trigger file input */}
                {selectedAvatar && (
                  <ImageContainer>
                    <img
                      src={selectedAvatar instanceof File ? URL.createObjectURL(selectedAvatar) : selectedAvatar}
                      alt='User_Avatar'
                      style={{ width: '100px', height: '100px' }}
                    />
                    <DeleteButton onClick={() => deleteImage(selectedAvatar)}>
                      &times;
                    </DeleteButton>
                  </ImageContainer>
                )}
                <FileInputLabel htmlFor={'User_Avatar-FileInput'}>
                  { selectedAvatar instanceof File ? 'User_Avatar' : `${t('SelectUserAvatar')}` }
                </FileInputLabel>
              </ImageSelectorContainer>
            </UserDataTD>
          </UserDataTR>
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('Identifier')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              {userProfile.User_ID}
              <input type="hidden" name="User_ID" value={userProfile.User_ID} />
            </UserDataTD>
          </UserDataTR>
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('Pseudo')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              <GearFormInput
                type="text"
                name="User_Pseudo"
                placeholder={userPseudo}
                value={userPseudo}
                onChange={(e) => setUserPseudo(e.target.value)}
              />
            </UserDataTD>
          </UserDataTR>
          {errorMessages && errorMessages.includes('User_Pseudo')
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('UserPseudoUnavailable')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.User_Pseudo === 'FormatUserPseudo'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('FormatUserPseudo')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.User_Pseudo === 'MissingUserPseudo'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('MissingUserPseudo')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('Password')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              <GearFormInput
                type="password"
                name="User_Password"
                placeholder='●●●●●'
                onChange={(e) => setUserPassword(e.target.value)}
              />
              <input type="hidden" name="User_Password" value={userPassword} />
            </UserDataTD>
          </UserDataTR>
          {formDataErrorMessages.User_Password === 'MissingUserPassword'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('MissingUserPassword')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
        </UserDataTableBody>
      </UserDataTable>
    </form>
  );
}

UserProfileEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  userProfile: userProfilePropTypes,
  temporaryUserProfile: userProfilePropTypes,
  errorMessages: errorMessagesProptypes
};

export default forwardRef(UserProfileEdit);
