// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { listPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Components

// Import Style
import { GearTR, GearTH, GearTD } from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function SumListSpecificRowDisplay ({ listCharacteristics }) {
  /*
  console.log('SumListSpecificRowDisplay');
  console.log('listCharacteristics : ', listCharacteristics);
  */

  const { t } = useTranslation();

  return (
    <>
      <GearTR id={`SumListSpecificRowTHDisplay-${listCharacteristics.Informations.Name}`}>
        <GearTH colSpan={6}>
          {`${t('Environment')}`}
        </GearTH>
        <GearTH colSpan={6}>
          {`${t('Seasons')}`}
        </GearTH>
      </GearTR>
      <GearTR id={`SumListSpecificRowTDDisplay-${listCharacteristics.Informations.Name}`}>
        <GearTD colSpan={6}>
          {`${t(listCharacteristics.Informations.Environment)}`}
        </GearTD>
        <GearTD colSpan={6}>
          {listCharacteristics.Informations.Seasons !== null
            ? listCharacteristics.Informations.Seasons
            : ''
          }
        </GearTD>
      </GearTR>
    </>
  );
}

SumListSpecificRowDisplay.propTypes = {
  listCharacteristics: listPropTypes.isRequired
};

export default SumListSpecificRowDisplay;
