// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { listPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Component
import PopUp from '../../../../PopUp/PopUpComponent';
import { GearSelectDropDown } from '../../../../Gear/SelectDropDown/GearSelectDropDown';
import LoadingOverlayComponent from '../../../../LoadingComponent/LoadingOverlay_Component';

// Import Functions
import { generateUserSumListsArray } from '../../../../../utils/Functions/Lists/generateListsArray_Functions';
import { APIPostPackListTag } from '../../../../../api/List/PackListTag_API';

// Import Style
import { GearTR, GearTH, GearTD } from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function PackListAddToSumListRowDisplay ({ list }) {
  const { t } = useTranslation();
  /*
  console.log('PackListAddToSumListTableDisplay');
  console.log('list : ', list);
  */
  const [creatingTagStatus, setCreatingTagStatus] = useState(false);
  const [sumListAssociated, setSumListAssociated] = useState(null);
  const [userSumListsArray, setSumUserListsArray] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    async function gatherUserLists () {
      try {
        setSumUserListsArray(await generateUserSumListsArray());
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }

    gatherUserLists();
  }, []);

  const packListTagData = {
    PackList: {
      Creator: list.Informations.Creator,
      Name: list.Informations.Name,
      WebAppID: list.Informations.WebAppID,
      MongoDBID: list._id,
      Category: list.Informations.Category
    },
    SumList: {
      WebAppID: sumListAssociated
    },
    Metrics: {
      Weight: list.Metrics.Weight,
      Volume: list.Metrics.Volume,
      Price: list.Metrics.Price
    }
  };

  useEffect(() => {
    async function APICall () {
      try {
        const lisTagServerResult = await APIPostPackListTag(packListTagData);
        console.log(`lisTagServerResult : ${lisTagServerResult}`);
        if (lisTagServerResult === true) {
          console.log('Validation OK trigger PopUp');
          triggerPopup();
        }
      } catch (error) {
        console.error('Error fetching lists :', error);
      }
    }

    if (sumListAssociated !== '') {
      setCreatingTagStatus(true);
      APICall();
      setCreatingTagStatus(false);
    }
  }, [sumListAssociated]);

  const triggerPopup = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 3000); // Hide after 3 seconds
  };

  return (
    <>
      <GearTR>
        <GearTH colSpan={12}>
          {`${t('AddToASumList')} :`}
        </GearTH>
      </GearTR>
      {
        userSumListsArray !== null && (
          userSumListsArray.length > 0 &&
          <GearTR>
            <GearTD colSpan={12}>
              <GearSelectDropDown options={userSumListsArray} choice={null} setChoice={setSumListAssociated} noChoice={`${t('SelectAList')}`}/>
            </GearTD>
          </GearTR>
        )
      }
      {
        creatingTagStatus === true
          ? <LoadingOverlayComponent/>
          : <></>
      }
      <PopUp show={showPopup} message={`${list.Informations.Name} ${t('SuccessfullyAddedTo')} ${sumListAssociated}`} />
      </>
  );
}

PackListAddToSumListRowDisplay.propTypes = {
  list: listPropTypes.isRequired
};

export default PackListAddToSumListRowDisplay;
