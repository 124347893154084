// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { gearItemPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import GearMaterialRowDisplay from '../../Common/GearMaterialRowDisplay';
import DisplayHelpComponent from '../../../../../ToolTip/DisplayHelp_Component';

// Import Datas
import { singularValues } from '../../../../../../utils/Constants/Gear_Constants';

// Import Functions
import { convertIfNecessarySI } from '../../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearMattressSpecificTableDisplay ({ item }) {
  const { t } = useTranslation();
  return (
    <GearTable id={`gearMattressSpecificTableDisplay-${item.Informations.Name}`}>
      <ColGroup12/>
      <GearTableBody>
        <GearTR id={`gearMattressSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearMattressSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={4}>{`${t('Type')}`}</GearTH>
          <GearTH colSpan={4}>{`${t('RValue')}`}</GearTH>
          <GearTH colSpan={4}>
            {singularValues.includes(item.Specific.Season) ? `${t('Season')}` : `${t('Seasons')}`}
            <DisplayHelpComponent
              text1={`${t('SeasonDescription1')}`}
              text2={`${t('SeasonDescription2')}`}
              text3={`${t('SeasonDescription3')}`}
              text4={`${t('SeasonDescription4')}`}
              alignLeft={true}
            />
          </GearTH>
        </GearTR>
        <GearTR id={`gearMattressSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTD colSpan={4}>
            {item.Specific.Type !== null ? `${t(item.Specific.Type)}` : ''}
            </GearTD>
          <GearTD colSpan={4}>
            {item.Specific.RValue !== null ? item.Specific.RValue : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {item.Specific.Season !== null ? item.Specific.Season : ''}
          </GearTD>
        </GearTR>
        <GearMaterialRowDisplay specificCharacteristic={item.Specific} gearName={item.Informations.Name}/>
        <GearTR id={`gearMattressSpecificRowDisplay-${item.Informations.Name}`}>
        <GearTH colSpan={12}>{`${t('DeployedDimension')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearMattressSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearMattressSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTD colSpan={12}>
            {item.Specific.Deployed.Shape !== null ? item.Specific.Deployed.Shape : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearMattressSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={4}>
            {item.Specific.Deployed.Length !== null
              ? `${t('Length')} [${convertIfNecessarySI('Distance', item.Specific.Deployed.Length).convertedUnit}]`
              : `${t('Length')}`
            }
          </GearTH>
          <GearTH colSpan={4}>
            {item.Specific.Deployed.Width !== null
              ? `${t('Width')} [${convertIfNecessarySI('Distance', item.Specific.Deployed.Width).convertedUnit}]`
              : `${t('Width')}`
            }
          </GearTH>
          <GearTH colSpan={4}>
            {item.Specific.Deployed.Thickness !== null
              ? `${t('Thickness')} [${convertIfNecessarySI('Distance', item.Specific.Deployed.Thickness).convertedUnit}]`
              : `${t('Thickness')}`
            }
            </GearTH>
        </GearTR>
        <GearTR id={`gearMattressSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTD colSpan={4}>
            {item.Specific.Deployed.Length !== null
              ? convertIfNecessarySI('Length', item.Specific.Deployed.Length).convertedValue
              : ''
            }
          </GearTD>
          <GearTD colSpan={4}>
            {item.Specific.Deployed.Width !== null
              ? convertIfNecessarySI('Width', item.Specific.Deployed.Width).convertedValue
              : ''
            }
            </GearTD>
          <GearTD colSpan={4}>
            {item.Specific.Deployed.Thickness !== null
              ? convertIfNecessarySI('Width', item.Specific.Deployed.Thickness).convertedValue
              : ''
            }
            </GearTD>
        </GearTR>
      </GearTableBody>
    </GearTable>
  );
}

GearMattressSpecificTableDisplay.propTypes = {
  item: gearItemPropTypes.isRequired
};

export default GearMattressSpecificTableDisplay;
