// Import React Libraries
import styled from 'styled-components';
import colors from '../../../Colors/colors';

export const UserDataTable = styled.table`
  width: 100%;
  border-spacing: 4px;
  margin: 20px 0;
  font-family: sans-serif;
`;

export const UserDataTableBody = styled.tbody`

`;

export const UserDataTR = styled.tr`
`;

export const UserDataTH = styled.th`
  border-radius: 3px;
  background: ${colors.characteristicTH};
  height: 20px;
`;

export const UserDataTD = styled.td`
  border-radius: 3px;
  background: ${colors.characteristicTD};
  text-align: center;
  height: 20px;
`;

export const UserAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${20 * 7}px;
`;

export const UserAvatar = styled.img`
  height: 100%;
`;
