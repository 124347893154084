// SleepingBag
// Insulator Nature
export const insulatorNatureChoiceArray = ['Synthetic', 'Down'];
// Insulator Quality
export const insulatorQualityChoiceArray = ['600', '650', '700', '750', '800', '850', '900'];

// Mattress
// Type
export const mattressTypeChoiceArray = ['Inflatable', 'SelfInflatable', 'Foam'];

// Hammock
// Amount of person
export const hammockPersonChoiceArray = ['1', '2'];
// Type
export const hammockTypeChoiceArray = ['Symetrical', 'Asymetrical'];
