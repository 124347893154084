// Import React Libraries
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { errorMessagesProptypes } from '../../../../../datas/PropTypes/errorMessagesPropTypes';
import { listPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Component
import ColGroup12 from '../../../../Gear/Tab/ColGroup12';
import { GearSelectDropDown } from '../../../../Gear/SelectDropDown/GearSelectDropDown';

// Import Datas
import { listCategoryChoiceArray } from '../../../../../datas/ChoiceArrays/Lists/lists_ChoiceArrays';

// Import Functions
import { listPackListFormValidTest } from '../../../../../utils/Functions/Lists/FillDataObject/ListFormCheck_Functions';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../../utils/Style/js/Account/UserDataTableDisplay_Style';

// Import Constants

function PackListSpecificTableEdit ({ listCharacteristics, onSubmit, onError, temporaryListData, errorMessages }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [category, setCategory] = useState(temporaryListData !== null ? temporaryListData.Informations.Category : listCharacteristics.Informations.Category);

  const [formDataErrorMessages, setFormDataErrorMessages] = useState({
    Category: ''
  });

  const handleSubmit = (event) => {
    console.log('handleSubmit : PackListCategoryTableAdd');
    event.preventDefault();
    const formData = new FormData(event.target);
    console.log('formData : ', formData);
    const isPackListFormValid = listPackListFormValidTest(setFormDataErrorMessages, formData);
    console.log('isPackListFormValid : ', isPackListFormValid);

    if (isPackListFormValid) {
      const data = Object.fromEntries(formData.entries());
      if (onSubmit) {
        onSubmit(data);
      }
    } else {
      if (onError) {
        onError(formDataErrorMessages);
      }
    }
  };

  useEffect(() => {
    console.log('formDataErrorMessages : ', formDataErrorMessages);
  }, [formDataErrorMessages, errorMessages]);

  useEffect(() => {
    console.log('useEffect');
    console.log('temporaryListData : ', temporaryListData);
  }, [temporaryListData]);

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <UserDataTable>
        <ColGroup12/>
        <UserDataTableBody>
          <UserDataTR>
            <UserDataTH colSpan={12}>{`${t('ListCategory')}`}</UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={12}>
              <GearSelectDropDown options={listCategoryChoiceArray} choice={category} setChoice={setCategory} noChoice={`${t('Select')}`}/>
              <input type="hidden" name="Category" value={category} />
            </UserDataTD>
          </UserDataTR>
          {formDataErrorMessages.Category === 'MissingCategory'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={12} style={{ color: 'red' }}>{`${t('MissingListCategory')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
        </UserDataTableBody>
      </UserDataTable>
    </form>
  );
}

PackListSpecificTableEdit.propTypes = {
  listCharacteristics: listPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  temporaryListData: listPropTypes,
  errorMessages: errorMessagesProptypes
};

export default forwardRef(PackListSpecificTableEdit);
