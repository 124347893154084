// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
// import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../datas/PropTypes/gearPropTypes';

// Import Functions
import { getGearSubCategoryList } from '../../utils/Functions/gearSubcategoryFunction';
import { scrollToTop } from '../../utils/Functions/general_Functions';

// Import Style
import { StyledTitleH2 } from '../../utils/Style/GlobalStyle';
import { ProductSubCategorySelectorContainer, ProductPreviewContainer } from '../../utils/Style/ItemSubCategorySelectorStyle';
import { PictureContainerSelector, ArticlePicturePreviewSelection } from '../../utils/Style/PreviewStyle';

function ItemSubcategorySelectorComponent ({ gearData }) {
  /*
  console.log('ItemSubcategorySelectorComponent');
  console.log('gearData : ', gearData);
  */

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const itemSubcategoryList = getGearSubCategoryList(gearData.Category);

  const handleSubCategorySelection = (subCategory) => {
    console.log('handleSubCategorySelection');
    console.log(`subCategory : ${subCategory}`);

    const subCategoryPath = `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(subCategory)}`;
    navigate(subCategoryPath);
    scrollToTop();
  };

  return (
    <ProductSubCategorySelectorContainer id='productSubCategorySelectorContainer'>
      {
        itemSubcategoryList.map((item, index) => (
          <ProductPreviewContainer
            key={index} id='productPreviewContainer'
            onClick={() => handleSubCategorySelection(itemSubcategoryList[index].subCategory)}
          >
            <PictureContainerSelector id='pictureContainerSelector'>
              <ArticlePicturePreviewSelection src={item.illustration} alt='picture1'/>
            </PictureContainerSelector>
            <StyledTitleH2>{t(item.text)}</StyledTitleH2>
          </ProductPreviewContainer>
        ))
      }
    </ProductSubCategorySelectorContainer>
  );
}

ItemSubcategorySelectorComponent.propTypes = {
  gearData: gearDataPropTypes.isRequired
};

export default ItemSubcategorySelectorComponent;
