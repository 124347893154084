// Import React Libraries
import React, { useEffect, useState } from 'react';

// Import PropTypes
import PropTypes from 'prop-types';
import { TooltipWrapper, TooltipContainer, HelpIcon, ToolTipIllustrationContainer, ToolTipIllustration, ToolTipP } from '../../utils/Style/js/ToolTip/DisplayHelp_Style';

const DisplayHelpComponent = ({ text1, text2, text3, text4, illustration, alignLeft }) => {
  /*
  console.log('DisplayHelpComponent');
  console.log(`text1 : ${text1}`);
  console.log(`text2 : ${text2}`);
  console.log(`text3 : ${text3}`);
  console.log(`text4 : ${text4}`);
  console.log(`alignLeft : ${alignLeft}`);
  */

  const [showTip, setShowTip] = useState(false);

  const handleMouseEnter = () => {
    setShowTip(true);
  };

  const handleMouseLeave = () => {
    setShowTip(false);
  };

  const handleClick = () => {
    setShowTip(!showTip);
  };

  useEffect(() => {
    // console.log(`showTip : ${showTip}`);
  }, [showTip]);

  return (
    <TooltipWrapper>
        <HelpIcon
            data-tip
            data-for="helpTip"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            style={{ cursor: 'pointer', fontWeight: 'bold', margin: '0 5px' }}
        >
            ?
        </HelpIcon>
        <TooltipContainer
          show={showTip}
          alignLeft={alignLeft === undefined ? false : alignLeft}
        >
          {
            (text1 === undefined) &&
            <>
            </>
          }
          {
            (text1 !== undefined && text2 === undefined) &&
            <>
              <ToolTipP>{text1}</ToolTipP>
            </>
          }
          {
            (text2 !== undefined && text3 === undefined) &&
            <>
              <ToolTipP>{text1}</ToolTipP>
              <ToolTipP>{text2}</ToolTipP>
            </>
          }
          {
            (text3 !== undefined && text4 === undefined) &&
            <>
              <ToolTipP>{text1}</ToolTipP>
              <ToolTipP>{text2}</ToolTipP>
              <ToolTipP>{text3}</ToolTipP>
            </>
          }
          {
            (text4 !== undefined) &&
            <>
              <ToolTipP>{text1}</ToolTipP>
              <ToolTipP>{text2}</ToolTipP>
              <ToolTipP>{text3}</ToolTipP>
              <ToolTipP>{text4}</ToolTipP>
            </>
          }
          {
            illustration &&
              <ToolTipIllustrationContainer>
                <ToolTipIllustration src={illustration}/>
              </ToolTipIllustrationContainer>
          }
        </TooltipContainer>
    </TooltipWrapper>
  );
};

DisplayHelpComponent.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  illustration: PropTypes.string,
  alignLeft: PropTypes.bool
};

export default DisplayHelpComponent;
