// Import React Libraries
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
// import PropTypes from 'prop-types';
import { gearDataPropTypes, gearItemPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';
import GearMosquitoNetRow from '../Common/GearMosquitoNetRowDisplay';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearAccessoryTableDisplay ({ gearData, gearItem }) {
  /*
  console.log('GearAccessoryTableDisplay');
  console.log('gearData : ', gearData);
  console.log('gearItem : ', gearItem);
  */
  const { t } = useTranslation();

  return (
    <GearTable id={`gearAccessoryTableDisplay-${gearData.AccessoryCategory}`}>
      <ColGroup12/>
      <GearTableBody>
        <GearTR id={`gearAccessoryRowTHDisplay-${gearData.AccessoryCategory}`}>
          <GearTH colSpan={12}>{`${t('AccessoryCategory')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearAccessoryRowTDDisplay-${gearData.AccessoryCategory}`}>
          <GearTD colSpan={12}>{`${t(gearItem.Informations.AccessoryCategory)}`}</GearTD>
        </GearTR>
        {
          gearItem.Informations.AccessoryCategory === 'MosquitoNet' &&
          <GearMosquitoNetRow
            gearData={gearData}
            specificCharacteristic={gearItem.Specific}
          />
        }
      </GearTableBody>
    </GearTable>
  );
}

GearAccessoryTableDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  gearItem: gearItemPropTypes.isRequired
};

export default forwardRef(GearAccessoryTableDisplay);
