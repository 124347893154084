// Import Style
import styled from 'styled-components';
import { commonHeaderFooter } from '../Styles';

// Import Colors
import colors from '../Colors/colors';

// Import Constants
import { footerHeight } from '../Constant';

export const StyledFooter = styled.div`
  ${commonHeaderFooter};
  height: ${footerHeight}px;
  width: 100%;

  flex-direction: row;
  align-items:center;

  justify-content: space-between;

  color: white;
  background-color: ${colors.headerFooterBackGround};
`;

export const StyledText = styled.div`
  display: flex;
  justify-content: center;
  font-family: sans-serif;
  font-weight: bold;
`;

export const FooterSideContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
`;

export const StyledButton = styled.button`
  border: none;  
  background-color: transparent;
  cursor: pointer;
`;
