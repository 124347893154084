import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';

// ACCESSORY
// Get all the accessories of a SubCategory
export async function APIGetAllAccessories (gearData) {
  /*
  console.log('APIGetAllAccessories');
  console.log('gearData : ', gearData);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/GetAllAccessories/${gearData.Category}/${gearData.SubCategory}/${gearData.AccessoryCategory}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

/* Get all the accessories of a subCategory depending on their validation status */
export async function APIGetAllAccessoriesStatus (gearData, validationStatus) {
  /*
  console.log('APIGetAllAccessoriesStatus');
  console.log('gearData : ', gearData);
  console.log(`validationStatus : ${validationStatus}`);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/GetAccessoriesStatus/${gearData.Category}/${gearData.SubCategory}/${gearData.AccessoryCategory}/${validationStatus}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

// Get the user accessories pending for validation
export async function APIUserAllAccessoriesPendingValidation (gearData, userID) {
  console.log('APIUserAllAccessoriesPendingValidation');
  console.log('gearData : ', gearData);
  console.log(`userID : ${userID}`);

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/GetUserPendingValidationAccessories/${gearData.Category}/${gearData.SubCategory}/${gearData.AccessoryCategory}/${userID}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    // console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

/* Check if the current user has any pending validation gear in a subCategory */
export async function APICheckIfUserHasPendingValidationAccessories (gearData, userID) {
  console.log('APICheckIfUserHasPendingValidationAccessories');
  console.log('gearData : ', gearData);
  console.log(`userID : ${userID}`);

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/UserHavePendingValidationAccessories/${gearData.Category}/${gearData.SubCategory}/${gearData.AccessoryCategory}/${userID}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    console.log('RESPONSE : ', response);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};

/* Check if there is any pending validation gear in a subCategory */
export async function APICheckIfPendingValidationAccessories (gearData) {
  console.log('APICheckIfUserHasPendingValidationAccessories');
  console.log('gearData : ', gearData);

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/${gearData.TypeCategory}/PendingValidationAccessories/${gearData.Category}/${gearData.SubCategory}/${gearData.AccessoryCategory}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      }
    });

    console.log('RESPONSE : ', response);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    console.log('Server Response:', responseData);
    return responseData;
  } catch (error) {
    console.log('error:', error);
    throw error;
  }
};
