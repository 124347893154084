// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
import { gearDataPropTypes } from '../../datas/PropTypes/gearPropTypes';

// Import Functions
import { scrollToTop } from '../../utils/Functions/general_Functions';
import { getGearTypeCategoryList } from '../../utils/Functions/Gear/gearTypeCategory_Functions';

// Import Style
import { StyledTitleH2 } from '../../utils/Style/GlobalStyle';
import { ProductSubCategorySelectorContainer, ProductPreviewContainer } from '../../utils/Style/ItemSubCategorySelectorStyle';
import { PictureContainerSelector, ArticlePicturePreviewSelection } from '../../utils/Style/PreviewStyle';

function GearCategorySelectorComponent ({ gearData }) {
  /*
  console.log('GearCategorySelectorComponent');
  console.log('gearData : ', gearData);
  */

  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const gearTypeCategoryList = getGearTypeCategoryList(gearData.TypeCategory);

  const handleCategorySelection = (Category) => {
    console.log('handleCategorySelection');
    console.log(`Category : ${Category}`);

    const subCategoryPath = `/${i18n.language}/${t(gearData.TypeCategory)}/${t(Category)}`;
    navigate(subCategoryPath);
    scrollToTop();
  };

  return (
    <ProductSubCategorySelectorContainer id='productSubCategorySelectorContainer'>
      {
        gearTypeCategoryList.map((gear) => (
          <ProductPreviewContainer
            key={gear.gearCategory}
            id='productPreviewContainer'
            onClick={() => handleCategorySelection(gear.gearCategory)}
          >
            <PictureContainerSelector id='pictureContainerSelector'>
              <ArticlePicturePreviewSelection src={gear.illustration} alt='picture1'/>
            </PictureContainerSelector>
            <StyledTitleH2>{t(gear.text)}</StyledTitleH2>
          </ProductPreviewContainer>
        ))
      }
    </ProductSubCategorySelectorContainer>
  );
}

GearCategorySelectorComponent.propTypes = {
  gearData: gearDataPropTypes.isRequired
};

export default GearCategorySelectorComponent;
