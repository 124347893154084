// Import React Libraries
import React from 'react';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import ItemDetailAdd from '../../components/Gear/itemDetailAdd';

// Import Style
// import { PageWrapper } from '../../utils/Styles';

function ItemDetailAddPage ({ gearTypeCategory, gearCategory, gearSubCategory, gearAccessoryCategory }) {
  console.log('ItemDetailAddPage');
  console.log(`gearTypeCategory : ${gearTypeCategory}`);
  console.log(`gearCategory : ${gearCategory}`);
  console.log(`gearSubCategory : ${gearSubCategory}`);
  console.log(`gearAccessoryCategory : ${gearAccessoryCategory}`);

  const gearData = {
    TypeCategory: gearTypeCategory,
    Category: gearCategory,
    SubCategory: gearSubCategory,
    AccessoryCategory: gearAccessoryCategory
  };

  return (
    <>
      <ItemDetailAdd gearData={gearData} />
    </>
  );
}

ItemDetailAddPage.propTypes = {
  gearTypeCategory: PropTypes.string.isRequired,
  gearCategory: PropTypes.string.isRequired,
  gearSubCategory: PropTypes.string.isRequired,
  gearAccessoryCategory: PropTypes.string
};

export default ItemDetailAddPage;
