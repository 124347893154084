import {
  // Shelter
  deployedShapeTarp,
  deployedShapeTent,
  deployedShapeTentAwning,
  // Bedding
  deployedShapeSleepingBag,
  deployedShapeMattress
} from '../../../../datas/ChoiceArrays/Gear/deployedShape_ChoiceArrays';

export function getShapeArray (desiredShapeArray) {
  let shapeArray;
  const functionMap = {
    // Shelter
    Awning: deployedShapeTentAwning,
    Tent: deployedShapeTent,
    Tarp: deployedShapeTarp,
    // Bedding
    SleepingBag: deployedShapeSleepingBag,
    Mattress: deployedShapeMattress
  };

  if (functionMap[desiredShapeArray]) {
    shapeArray = functionMap[desiredShapeArray];
    // console.log('itemList : ', itemList);
    return shapeArray;
  } else {
    console.error(`Unknown itemCategory: ${shapeArray}`);
  }
};
