export function calculDensity (volume, weight, volumeUnity, weightUnity, densityUnity) {
  let volumeInLiters, weightInG;

  if (volume !== null) {
    if (volumeUnity === 'L') {
      volumeInLiters = volume;
    } else {
      console.error('Unsupported volume unit');
      return null;
    }

    if (weightUnity === 'g') {
      weightInG = weight;
    } else if (weightUnity === 'kg') {
      weightInG = weight * 1000;
    } else {
      console.error('Unsupported weight unit');
      return null;
    }

    if (densityUnity === 'g/L') {
      const densityInGPerLiter = parseFloat((weightInG / volumeInLiters).toFixed(1));
      console.log(`densityInGPerLiter : ${densityInGPerLiter}`);
      return densityInGPerLiter;
    } else {
      console.error('Unsupported density unit');
      return null;
    }
  } else {
    return null;
  }
};
