// Import Function
import { convertCentimeterToMeter } from '../UnitConversion/convertDistance_Functions';

export function calculRectangularVolume (length, width, height, distanceUnity, volumeUnity) {
  let lengthInMeters, widthInMeters, heightInMeters;
  console.log(`length : ${length}`);
  console.log(`width : ${width}`);
  console.log(`height : ${height}`);

  if (distanceUnity === 'cm') {
    lengthInMeters = convertCentimeterToMeter(length);
    widthInMeters = convertCentimeterToMeter(width);
    heightInMeters = convertCentimeterToMeter(height);
  } else if (distanceUnity === 'm') {
    lengthInMeters = length;
    widthInMeters = width;
    heightInMeters = height;
  } else {
    console.error('Unsupported distance unit');
    return null;
  }

  if (volumeUnity === 'L') {
    const volumeInLiters = parseFloat((lengthInMeters * widthInMeters * heightInMeters * 1000).toFixed(1));
    console.log(`volumeInLiters : ${volumeInLiters}`);
    if (volumeInLiters !== 0) {
      return volumeInLiters;
    } else {
      return null;
    }
  } else {
    console.error('Unsupported volume unit');
    return null;
  }
};

export function calculCylindricVolume (length, diameter, distanceUnity, diameterUnity, volumeUnity) {
  let lengthInMeters, diameterInMeters;

  if (distanceUnity === 'cm') {
    lengthInMeters = convertCentimeterToMeter(length);
  } else if (distanceUnity === 'm') {
    lengthInMeters = length;
  } else {
    console.error('Unsupported distance unit');
    return null;
  }

  if (diameterUnity === 'cm') {
    diameterInMeters = convertCentimeterToMeter(diameter);
  } else if (diameterUnity === 'm') {
    diameterInMeters = diameter;
  } else {
    console.error('Unsupported distance unit');
    return null;
  }

  if (volumeUnity === 'L') {
    const volumeInLiters = parseFloat(((Math.PI * Math.pow(diameterInMeters / 2, 2) * lengthInMeters) * 1000).toFixed(1));
    console.log(`volumeInLiters : ${volumeInLiters}`);
    if (volumeInLiters !== 0) {
      return volumeInLiters;
    } else {
      return null;
    }
  } else {
    console.error('Unsupported volume unit');
    return null;
  }
};
