// Import React Libraries
import React from 'react';

// Import PropTypes
import { gearDataPropTypes, gearItemPropTypes } from '../../../../datas/PropTypes/gearPropTypes';

// Import Component

// Specific Table
// Accessory
import GearAccessoryTableDisplay from './Accessory/GearAccessoryTableDisplay';
// Shelter
import GearTarpSpecificTableDisplay from './Shelter/Tarp/GearTarpSpecificTableDisplay';
import GearTentSpecificTableDisplay from './Shelter/Tent/GearTentSpecificTableDisplay';
// Bedding
import GearHammockSpecificTableDisplay from './Bedding/Hammock/GearHammockSpecificTableDisplay';
import GearMattressSpecificTableDisplay from './Bedding/Mattress/GearMattressSpecificTableDisplay';
import GearSleepingBagSpecificTableDisplay from './Bedding/SleepingBag/GearSleepingBagSpecificTableDisplay';

function GearSpecificTableHandlerDisplay ({ gearData, gearItem }) {
  /*
  console.log('GearSpecificTableHandlerDisplay');
  console.log('gearItem : ', gearItem);
  */

  return (
    <>
      { /* Accessory */ }
      {gearItem.Informations.SubCategory === 'Accessory' && (
        <GearAccessoryTableDisplay
          gearData={gearData}
          gearItem={gearItem}
        />
      )}
      { /* Shelter */ }
      {gearItem.Informations.SubCategory === 'Tarp' && (
        <GearTarpSpecificTableDisplay
          gearData={gearData}
          specificCharacteristic={gearItem.Specific}
          gearName={gearItem.Informations.Name}
        />
      )}
      {gearItem.Informations.SubCategory === 'Tent' && (
        <GearTentSpecificTableDisplay
          gearData={gearData}
          specificCharacteristic={gearItem.Specific}
          gearName={gearItem.Informations.Name}
        />
      )}
      { /* Bedding */ }
      {gearItem.Informations.SubCategory === 'Hammock' && (
        <GearHammockSpecificTableDisplay
          gearData={gearData}
          specificCharacteristic={gearItem.Specific}
          gearName={gearItem.Informations.Name}
        />
      )}
      {gearItem.Informations.SubCategory === 'Mattress' && (
        <GearMattressSpecificTableDisplay
          item={gearItem}
        />
      )}
      {gearItem.Informations.SubCategory === 'SleepingBag' && (
        <GearSleepingBagSpecificTableDisplay
          item={gearItem}
        />
      )}
    </>
  );
}

GearSpecificTableHandlerDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  gearItem: gearItemPropTypes.isRequired
};

export default GearSpecificTableHandlerDisplay;
