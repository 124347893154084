// Import React Libraries
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import Component
import ColGroup12 from '../Gear/Tab/ColGroup12';
import LoadingOverlayComponent from '../LoadingComponent/LoadingOverlay_Component';

// Import Function
import { sleep } from '../../utils/Functions/general_Functions';
import { authenticationLoginAPI } from '../../api/User/Authentication_API';

// Import Style
import {
  AuthenticationTableContainer,
  AuthenticationTable, AuthenticationTableBody,
  AuthenticationTR, AuthenticationTH, AuthenticationTD
} from '../../utils/Style/js/Authentication/AuthenticationTable_Style';
import { AuthenticationInput } from '../../utils/Style/js/Authentication/AuthenticationForm_Style';
import { GeneralButton } from '../../utils/Style/js/General/Button_Style';

// Import Constants

function AuthenticationForm () {
  const { i18n, t } = useTranslation();

  const [isAuthInProgress, setIsAuthInProgress] = useState(false);
  const [invalidConnexion, setInvalidConnexion] = useState(false);
  const [userIDs, setUserIDs] = useState({
    User_ID: '',
    User_Password: ''
  });

  const handleSubmit = async (event) => {
    // console.log('handleSubmit');
    event.preventDefault();
    console.log(userIDs);

    if (!isAuthInProgress) {
      setIsAuthInProgress(true);
      try {
        const serverAnswer = await authenticationLoginAPI(userIDs);
        // console.log(`serverAnswer : ${serverAnswer}`);
        if (serverAnswer === true) {
          // Change the language
          const userData = JSON.parse(sessionStorage.getItem('userData'));
          const langCode = userData.userGeneralPreference.User_Language;
          // console.log('langCode : ', langCode);
          i18n.changeLanguage(langCode);
          setInvalidConnexion(false);
        } else {
          await sleep(2000);
          setInvalidConnexion(true);
        }
      } finally {
        setIsAuthInProgress(false);
      }
    }
  };

  const handleChange = (e) => {
    setUserIDs({
      ...userIDs,
      [e.target.name]: e.target.value
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };

  return (
    <>
      <AuthenticationTableContainer id='AuthenticationTableContainer'>
        <form onSubmit={handleSubmit}>
          <AuthenticationTable id={'authenticationTable'}>
            <ColGroup12/>
            <AuthenticationTableBody>
              <AuthenticationTR id={'authenticationTable-ID-TR-TH'}>
                <AuthenticationTH colSpan={12}>
                  {`${t('Identifier')}`}
                </AuthenticationTH>
              </AuthenticationTR>
              <AuthenticationTR id={'authenticationTable-ID-TR-TD'}>
                <AuthenticationTD colSpan={12}>
                  <AuthenticationInput
                    type="text"
                    name="User_ID"
                    value={userIDs.User_ID}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                  />
                </AuthenticationTD>
              </AuthenticationTR>
              <AuthenticationTR id={'authenticationTable-Password-TR-TH'}>
                <AuthenticationTH colSpan={12}>
                  {`${t('Password')}`}
                </AuthenticationTH>
              </AuthenticationTR>
              <AuthenticationTR id={'authenticationTable-Password-TR-TD'}>
                <AuthenticationTD colSpan={12}>
                  <AuthenticationInput
                    type="password"
                    name="User_Password"
                    value={userIDs.User_Password}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                  />
                </AuthenticationTD>
              </AuthenticationTR>
              {
                invalidConnexion &&
                <AuthenticationTR id={'authenticationTable-InvalidConnexion-TR-TD'}>
                <AuthenticationTD style={{ color: 'red' }}colSpan={12}>
                  {`${t('ConnectionFailed')}`}
                </AuthenticationTD>
              </AuthenticationTR>
              }
            </AuthenticationTableBody>
          </AuthenticationTable>
        </form>
        <GeneralButton onClick={handleSubmit}>
          {t('Connexion')}
        </GeneralButton>
      </AuthenticationTableContainer>
      {
        isAuthInProgress &&
        <LoadingOverlayComponent/>
      }
    </>
  );
};

export default AuthenticationForm;
