// Popup.js
import React from 'react';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Style
import { PopUpContainer } from '../../utils/Style/js/PopUp/PopUp_Style';
import { StyledP } from '../../utils/Style/js/General/Font_Style';

const PopUp = ({ show, message }) => {
  return (
    <PopUpContainer show={show}>
      <StyledP style={{ fontWeight: 'bold' }}>
        {message}
      </StyledP>
    </PopUpContainer>
  );
};

PopUp.propTypes = {
  message: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired
};

export default PopUp;
