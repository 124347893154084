// Import React Libraries
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes, gearItemPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';
import GearMaterialTableEditRow from '../../Common/GearMaterialTableEdit';
import GearMosquitoNetEditRow from '../../Common/GearMosquitoNetEditRow';
import GearTentAwningAmountRowEdit from './GearTentAwningAmountRowEdit';
import GearAwningDeployedDimensionRowEdit from './GearTentAwningDeployedDimensionRowEdit';
import GearDeployedDimensionTableComponentEdit from '../../Common/GearDeployedDimensionEdit';
import DisplayHelpComponent from '../../../../../ToolTip/DisplayHelp_Component';

// Import Datas
import {
  tentPersonChoiceArray, tentDoorChoiceArray,
  tentTypeChoiceArray, tentWallChoiceArray,
  tentVentilationChoiceArray,
  tentIngressProtectionChoiceArray,
  impermeabilityChoiceArray,
  sewingChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Shelter/SpecificShelter_ChoiceArrays';
import {
  yesNoChoiceArray,
  UPFChoiceArray,
  seasonChoiceArray
} from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Functions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../../utils/Style/js/GearForm/GearForm_Style';

// Import Constants

function GearTentSpecificTableEdit ({ gearData, item, onSubmit }, ref) {
  /*
  console.log('GearTentSpecificTableEdit');
  console.log('gearData : ', gearData);
  console.log('item : ', item);
  */

  const { t } = useTranslation();
  const formRef = useRef();

  const [person, setPerson] = useState(item.Specific.Person);
  const [door, setDoor] = useState(item.Specific.Door);
  const [height, setHeight] = useState(item.Specific.Deployed.ChamberHeight);
  const [selfSupporting, setSelfSupporting] = useState(item.Specific.SelfSupporting);
  const [type, setType] = useState(item.Specific.Type);
  const [wall, setWall] = useState(item.Specific.Wall);
  const [season, setSeason] = useState(item.Specific.Season);
  const [ventilation, setVentilation] = useState(item.Specific.Ventilation);
  const [upf, setUpf] = useState(item.Specific.Upf);
  const [hoop, setHoop] = useState(item.Specific.Hoop);
  const [peg, setPeg] = useState(item.Specific.Peg);
  const [guyWire, setGuyWire] = useState(item.Specific.GuyWire);
  const [mosquitoNet, setMosquitoNet] = useState(item.Specific.MosquitoNet);
  const [meshSize, setMeshSize] = useState(item.Specific.MeshSize);
  const [awning, setAwning] = useState(item.Specific.Awning);
  const [awningIdentical, setAwningIdentical] = useState(item.Specific.AwningIdentical);
  const [sewing, setSewing] = useState(item.Specific.Sewing);
  const [ingressProtection, setIngressProtection] = useState(item.Specific.IngressProtection);
  const [impermeabilityExtern, setImpermeabilityExtern] = useState(item.Specific.ImpermeabilityExtern);
  const [impermeabilityFloor, setImpermeabilityFloor] = useState(item.Specific.ImpermeabilityFloor);

  const [materials, setMaterials] = useState(
    [item.Specific.Materials[0], item.Specific.Materials[1], item.Specific.Materials[2]]);

  const setFirstMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[0] = value;
      return newMaterials;
    });
  };

  const setSecondMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[1] = value;
      return newMaterials;
    });
  };

  const setThirdMaterial = (value) => {
    setMaterials((prevMaterials) => {
      const newMaterials = [...prevMaterials];
      newMaterials[2] = value;
      return newMaterials;
    });
  };

  const materialsSetter = [setFirstMaterial, setSecondMaterial, setThirdMaterial];

  useEffect(() => {
    if (awning === null || awning === 0 || awning === 1) {
      setAwningIdentical(null);
    }
  }, [awning]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearCommonTable-${gearData.gearSubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={4}>{`${t('Person(s)')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Door(s)')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Height')} [${getUnit('Distance')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTD-${gearData.gearSubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={tentPersonChoiceArray}
                choice={person}
                setChoice={setPerson}
                noChoice='Select'
              />
              <input type="hidden" name="Person" value={person} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
              options={tentDoorChoiceArray}
              choice={door}
              setChoice={setDoor}
              noChoice='Select'
              />
              <input type="hidden" name="Door" value={door} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
                type="number"
                name="ChamberHeight"
                placeholder={height === null ? `${t('Brand')}` : height}
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
            </GearTD>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={4}>{`${t('SelfSupporting')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Type')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Wall(s)')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTD-${gearData.gearSubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={yesNoChoiceArray}
                choice={selfSupporting}
                setChoice={setSelfSupporting}
                noChoice='Select'
              />
              <input type="hidden" name="SelfSupporting" value={selfSupporting} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
              options={tentTypeChoiceArray}
              choice={type}
              setChoice={setType}
              noChoice='Select'/>
              <input type="hidden" name="Type" value={type} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={tentWallChoiceArray}
                choice={wall}
                setChoice={setWall}
                noChoice='Select'
              />
              <input type="hidden" name="Wall" value={wall} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={4}>
              {`${t('Season(s)')}`}
              <DisplayHelpComponent
                text1={`${t('SeasonDescription1')}`}
                text2={`${t('SeasonDescription2')}`}
                text3={`${t('SeasonDescription3')}`}
                text4={`${t('SeasonDescription4')}`}
                alignLeft={true}
              />
            </GearTH>
            <GearTH colSpan={4}>{`${t('Ventilation(s)')}`}</GearTH>
            <GearTH colSpan={4}>
              {`${t('UPF')}`}
              <DisplayHelpComponent text1={`${t('UVProtectionFactor')}`}/>
            </GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={seasonChoiceArray}
                choice={season}
                setChoice={setSeason}
                noChoice='Select'
              />
              <input type="hidden" name="Season" value={season} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={tentVentilationChoiceArray}
                choice={ventilation}
                setChoice={setVentilation}
                noChoice='Select'
              />
              <input type="hidden" name="Ventilation" value={ventilation} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={UPFChoiceArray}
                choice={upf}
                setChoice={setUpf}
                noChoice='Select'
              />
              <input type="hidden" name="Upf" value={upf} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={4}>{`${t('Hoop(s)')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('Peg(s)')}`}</GearTH>
            <GearTH colSpan={4}>{`${t('GuyWire(s)')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={tentVentilationChoiceArray}
                choice={hoop}
                setChoice={setHoop}
                noChoice='Select'
              />
              <input type="hidden" name="Hoop" value={hoop} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={tentVentilationChoiceArray}
                choice={peg}
                setChoice={setPeg}
                noChoice='Select'
              />
              <input type="hidden" name="Peg" value={peg} />
            </GearTD>
            <GearTD colSpan={4}>
              <GearSelectDropDown
                options={tentVentilationChoiceArray}
                choice={guyWire}
                setChoice={setGuyWire}
                noChoice='Select'
              />
              <input type="hidden" name="GuyWire" value={guyWire} />
            </GearTD>
          </GearTR>
          <GearMosquitoNetEditRow gearData={gearData} mosquitoNet={mosquitoNet} setMosquitoNet={setMosquitoNet} meshSize={meshSize} setMeshSize={setMeshSize}/>
          <GearTentAwningAmountRowEdit
            gearData={gearData}
            awning={awning}
            setAwning={setAwning}
            awningIdentical={awningIdentical}
            setAwningIdentical={setAwningIdentical}
          />
          <GearMaterialTableEditRow
            gearData={gearData}
            setMaterials={materialsSetter}
            materials={materials}
          />
          <input type="hidden" name="Materials" value={materials} />
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={6}>{`${t('Sewing')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('IngressProtection')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTD-${gearData.gearSubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown
                options={sewingChoiceArray}
                choice={sewing}
                setChoice={setSewing}
                noChoice='Select'
              />
              <input type="hidden" name="Sewing" value={sewing} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown
                options={tentIngressProtectionChoiceArray}
                choice={ingressProtection}
                setChoice={setIngressProtection}
                noChoice='Select'/>
              <input type="hidden" name="IngressProtection" value={ingressProtection} />
            </GearTD>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={6}>{`${t('ImpermeabilityExtern')} [${getUnit('Impermeability')}]`}</GearTH>
            <GearTH colSpan={6}>{`${t('ImpermeabilityFloor')} [${getUnit('Impermeability')}]`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTD-${gearData.gearSubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown
                options={impermeabilityChoiceArray}
                choice={impermeabilityExtern}
                setChoice={setImpermeabilityExtern}
                noChoice='Select'
              />
              <input type="hidden" name="ImpermeabilityExtern" value={impermeabilityExtern} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown
                options={impermeabilityChoiceArray}
                choice={impermeabilityFloor}
                setChoice={setImpermeabilityFloor}
                noChoice='Select'
              />
              <input type="hidden" name="ImpermeabilityFloor" value={impermeabilityFloor} />
            </GearTD>
          </GearTR>
          <GearDeployedDimensionTableComponentEdit gearData={gearData} specific={item.Specific}/>
          <GearAwningDeployedDimensionRowEdit gearData={gearData} gearSpecificCharacteristic={item.Specific} awning={awning}/>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearTentSpecificTableEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearItemPropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearTentSpecificTableEdit);
