export function getUserIDFromUrlAccountDisplay (url) {
  // console.log('getUserIDFromUrlAccountDisplay');
  // Split the URL by slashes and filter out empty strings
  const parts = url.split('/').filter(part => part.length > 0);

  // Return the last element in the array
  const parsedUserID = parts[parts.length - 1];
  // console.log(`parsedUserID : ${parsedUserID}`);
  return parsedUserID;
};

export function getUserIDFromUrlAccountEdit (url) {
  // console.log('getUserIDFromUrlAccountEdit');
  const positionFromEnd = 2;
  // Split the URL by slashes and filter out empty strings
  const parts = url.split('/').filter(part => part.length > 0);

  // Ensure the positionFromEnd is within the bounds of the array
  if (positionFromEnd > parts.length) {
    return null;
  }

  // Return the specific element from the end
  const parsedUserID = parts[parts.length - positionFromEnd];
  // console.log(`parsedUserID : ${parsedUserID}`);
  return parsedUserID;
};
