// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import Components
import UserProfileAdd from './UserProfile_Add';
import UserGeneralPreferenceAdd from './UserGeneralPreference_Add';
import UserUnitPreferenceAdd from './UserUnitPreference_Add';
import LoadingComponent from '../../../LoadingComponent/Loading_Component';

// Import Functions
import { generateUserObjectFromForms } from '../../../../utils/Functions/User/FillUserObject';
import { APICreateUser } from '../../../../api/User/User_API';
import { StyledH1, TitleContainer } from '../../../../utils/Style/js/General/Font_Style';
// Usefull if the data persistance is researched
// import { resetTemporaryUserData, storeTemporaryUserData } from '../../../../utils/Functions/Storage_Functions/SessionStorage_Functions';

function UserTableAdd () {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();

  const [loaderState, setLoaderState] = useState(false);
  const [temporaryUserData, setTemporaryUserData] = useState(null);
  const [errorMessages, setErrorMessages] = useState([]);

  const formRef1 = useRef();
  const formRef2 = useRef();
  const formRef3 = useRef();

  let form1 = null;
  let form2 = null;
  let form3 = null;

  const handleFormSubmit1 = (data) => {
    form1 = data;
    console.log('Form 1 data:', form1);
    // Handle form 1 submission here
  };
  const handleFormError1 = (errors) => {
  };

  const handleFormSubmit2 = (data) => {
    form2 = data;
    console.log('Form 2 data:', form2);
    // Handle form 2 submission here
  };
  const handleFormSubmit3 = (data) => {
    form3 = data;
    console.log('Form 3 data:', form3);
    // Handle form 2 submission here
  };

  const triggerSubmit = async (e) => {
    e.preventDefault();

    if (formRef1.current) {
      formRef1.current.submit();
    }
    if (formRef2.current) {
      formRef2.current.submit();
    }
    if (formRef3.current) {
      formRef3.current.submit();
    }

    if (form1 !== null && form2 !== null && form3 !== null) {
      setLoaderState(true);

      const objectFromForms = generateUserObjectFromForms(form1, form2, form3);
      console.log('objectFromForms : ', objectFromForms);

      try {
        const serverResponse = await APICreateUser(objectFromForms);
        // console.log('server Response : ', serverResponse);
        setLoaderState(false);

        if (serverResponse.message === 'User Created') {
          setTemporaryUserData(null);
          navigate(`/${i18n.language}/${t('Account')}/${form1.User_ID}`);
        } else if (serverResponse.error) {
          console.log('server Response Error : ', serverResponse.error.error);
          setTemporaryUserData(objectFromForms);
          setErrorMessages(serverResponse.error.error);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    } else {
      setErrorMessages([]);
    }
  };

  useEffect(() => {
  }, [temporaryUserData]);

  return (
    <>
      {
        loaderState
          ? <LoadingComponent/>
          : <>
              <TitleContainer>
                <StyledH1>{`${t('Profile')} :`}</StyledH1>
              </TitleContainer>
              <UserProfileAdd
                ref={formRef1}
                onSubmit={handleFormSubmit1}
                onError={handleFormError1}
                temporaryUserProfile={temporaryUserData !== null ? temporaryUserData.userProfile : null}
                errorMessages={errorMessages}
              />
              <TitleContainer>
                <StyledH1>{`${t('GeneralPreference')} :`}</StyledH1>
              </TitleContainer>
              <UserGeneralPreferenceAdd
                ref={formRef2}
                onSubmit={handleFormSubmit2}
              />
              <TitleContainer>
                <StyledH1>{`${t('UnitPreference')} :`}</StyledH1>
              </TitleContainer>
              <UserUnitPreferenceAdd
                ref={formRef3}
                onSubmit={handleFormSubmit3}
              />
              <button onClick={triggerSubmit}>Submit All Forms</button>
            </>
      }

    </>
  );
}

export default UserTableAdd;
