// Import React Libraries
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Context

// Import Components
import ItemSubcategorySelectorComponent from '../../components/Gear/ItemSubCategorySelector_Component';
// import TrendingSlider from '../../components/Slider/TrendingSlider/TrendingSlider';

// Import Functions
import { displayOutletSubCategory } from '../../utils/Functions/PathFunctions/gearPath_Functions';

// Import Style
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

function ItemSubcategorySelectorPage ({ TypeCategory, Category }) {
  /*
  console.log('ItemSubcategorySelectorPage');
  console.log(`TypeCategory : ${TypeCategory}`);
  console.log(`Category : ${Category}`);
  */

  const { t, i18n } = useTranslation();
  const location = useLocation();
  const currentUrl = location.pathname;
  const shouldDisplayPageContainer = displayOutletSubCategory(TypeCategory, Category, currentUrl, t, i18n);

  const gearData = {
    TypeCategory,
    Category
  };

  return (
    <>
      {shouldDisplayPageContainer
        ? (
      <PageContainer>
        <ItemSubcategorySelectorComponent gearData={gearData} />
        { /* <TrendingSlider gearData={gearData}/> */ }
      </PageContainer>
          )
        : (
      <Outlet/>
          )}
    </>
  );
}

ItemSubcategorySelectorPage.propTypes = {
  TypeCategory: PropTypes.string.isRequired,
  Category: PropTypes.string.isRequired
};

export default ItemSubcategorySelectorPage;
