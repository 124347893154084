// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import DisplayHelpComponent from '../../../../ToolTip/DisplayHelp_Component';
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';

// Import Functions
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Functions
import { calculSurfaceDependingOnShape } from '../../../../../utils/Functions/Gear/Calcul/gearCalculSurfaceData_Function';
import { getShapeArray } from '../../../../../utils/Functions/Gear/GatherShapes/gatherShapes_Functions';

// Import Illustration
// Triangle
import TriangleLengthAen from '../../../../../assets/Schemas/Triangle/TriangleLengthA_en.jpg';
import TriangleLengthBen from '../../../../../assets/Schemas/Triangle/TriangleLengthB_en.jpg';
import TriangleLengthCen from '../../../../../assets/Schemas/Triangle/TriangleLengthC_en.jpg';

// Trapezium
import TrapeziumLengthAfr from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthA_fr.jpg';
import TrapeziumLengthAen from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthA_en.jpg';
import TrapeziumLengthBfr from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthB_fr.jpg';
import TrapeziumLengthBen from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthB_en.jpg';
import TrapeziumLengthCfr from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthC_fr.jpg';
import TrapeziumLengthCen from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthC_en.jpg';
// Hexagon
import HexagonalLengthAfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthA_fr.jpg';
import HexagonalLengthAen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthA_en.jpg';
import HexagonalLengthBfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthB_fr.jpg';
import HexagonalLengthBen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthB_en.jpg';
import HexagonalLengthCfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthC_fr.jpg';
import HexagonalLengthCen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthC_en.jpg';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';

// Import Constants

function GearDeployedDimensionRowAdd ({ gearData, nameComplement }) {
  /*
  console.log('GearDeployedDimensionRowAdd');
  console.log('gearData : ', gearData);
  console.log(`nameComplement : ${nameComplement}`);
  */

  const { i18n, t } = useTranslation();
  const [deployedShape, setDeployedShape] = useState(null);
  // Square & Rectangular
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  // Circle
  const [diameter, setDiameter] = useState('');
  // Hexagonal
  const [lengthA, setLengthA] = useState('');
  const [lengthB, setLengthB] = useState('');
  const [lengthC, setLengthC] = useState('');
  const [surface, setSurface] = useState(0);

  // Mummy
  const [widthShoulder, setWidthShoulder] = useState(null);
  const [widthFoot, setWidthFoot] = useState(null);
  console.log(`shoulderWidth : ${widthShoulder}`);
  console.log(`footWidth : ${widthFoot}`);

  useEffect(() => {
    setSurface(calculSurfaceDependingOnShape(length, width, lengthA, lengthB, lengthC, diameter, deployedShape));
  }, [length, width, deployedShape, lengthA, lengthB, lengthC, diameter]);

  useEffect(() => {
    console.log('Reset Values');
    setLength('');
    setLengthA('');
    setLengthB('');
    setLengthC('');
    setWidth('');
    setWidthShoulder('');
    setWidthFoot('');
    setSurface(0);
  }, [deployedShape]);

  return (
    <>
      <GearTR id={`GearDeployedDimensionRowTHAdd-ShapeSelection-${gearData.SubCategory}`}>
        <GearTH colSpan={12}>{`${t('DeployedDimension')}`}</GearTH>
      </GearTR>
      <GearTR id={`GearDeployedDimensionRowTHAdd-ShapeSelection-${gearData.SubCategory}`}>
        <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
      </GearTR>
      <GearTR id={`GearDeployedDimensionRowTDAdd-ShapeSelection-${gearData.SubCategory}`}>
        <GearTD colSpan={12}>
          <GearSelectDropDown options={getShapeArray(gearData.SubCategory)} choice={deployedShape} setChoice={setDeployedShape} noChoice='Shape'/>
          <input
            type="hidden"
            name={nameComplement !== undefined ? `${nameComplement}Shape` : 'Shape'}
            value={deployedShape}
          />
        </GearTD>
      </GearTR>
      {deployedShape === 'Square' && (
        <>
          <GearTR id={`GearDeployedDimensionRowTHAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTH colSpan={6}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
            <GearTH colSpan={6}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
          </GearTR>
          <GearTR id={`GearDeployedDimensionRowTDAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTD colSpan={6}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}Length` : 'Length'}
                placeholder={`${t('Length')}`}
                onChange={(e) => setLength(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={6}>{surface !== 0 ? surface : `${t('Surface')}`}
              <input
              type="hidden"
              name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
              value={surface}
              />
            </GearTD>
          </GearTR>
        </>
      )}
      {deployedShape === 'Rectangle' && (
        <>
          <GearTR id={`GearDeployedDimensionRowTHAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('Width')} [${getUnit('Distance')}]`}</GearTH>
            <GearTH colSpan={4}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
          </GearTR>
          <GearTR id={`GearDeployedDimensionRowTDAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}Length` : 'Length'}
                placeholder={`${t('Length')}`}
                onChange={(e) => setLength(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}Width` : 'Width'}
                placeholder={`${t('Width')}`}
                onChange={(e) => setWidth(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={4}>{surface !== 0 ? surface : `${t('Surface')}`}
              <input
                type="hidden"
                name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                value={surface}
              />
            </GearTD>
          </GearTR>
        </>
      )}
      {deployedShape === 'Circle' && (
        <>
          <GearTR id={`GearDeployedDimensionRowTHAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTH colSpan={6}>{`${t('Diameter')} [${getUnit('Distance')}]`}</GearTH>
            <GearTH colSpan={6}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
          </GearTR>
          <GearTR id={`GearDeployedDimensionRowTDAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTD colSpan={6}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}Diameter` : 'Diameter'}
                placeholder={`${t('Diameter')}`}
                onChange={(e) => setDiameter(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={6}>{surface !== 0 ? surface : `${t('Surface')}`}
              <input
              type="hidden"
              name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
              value={surface}
              />
            </GearTD>
          </GearTR>
        </>
      )}
      {deployedShape === 'Triangle' && (
        <>
          <GearTR id={`GearDeployedDimensionRowTHAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTH colSpan={3}>
              {`${t('Length')} A [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? TriangleLengthAen : TriangleLengthAen}/>
            </GearTH>
            <GearTH colSpan={3}>
              {`${t('Length')} B [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? TriangleLengthBen : TriangleLengthBen}/>
            </GearTH>
            <GearTH colSpan={3}>
              {`${t('Length')} C [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? TriangleLengthCen : TriangleLengthCen}/>
            </GearTH>
            <GearTH colSpan={3}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
          </GearTR>
          <GearTR id={`GearDeployedDimensionRowTDAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthA` : 'LengthA'}
                placeholder={`${t('Length')} A`}
                onChange={(e) => setLengthA(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthB` : 'LengthB'}
                placeholder={`${t('Length')} B`}
                onChange={(e) => setLengthB(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthC` : 'LengthC'}
                placeholder={`${t('Length')} C`}
                onChange={(e) => setLengthC(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>{surface !== 0 ? surface : `${t('Surface')}`}
              <input
                type="hidden"
                name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                value={surface}
              />
            </GearTD>
          </GearTR>
        </>
      )}
      {deployedShape === 'Trapezium' && (
        <>
          <GearTR id={`GearDeployedDimensionRowTHAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTH colSpan={3}>
              {`${t('Length')} A [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? TrapeziumLengthAfr : TrapeziumLengthAen}/>
            </GearTH>
            <GearTH colSpan={3}>
              {`${t('Length')} B [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? TrapeziumLengthBfr : TrapeziumLengthBen}/>
            </GearTH>
            <GearTH colSpan={3}>
              {`${t('Length')} C [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? TrapeziumLengthCfr : TrapeziumLengthCen}/>
            </GearTH>
            <GearTH colSpan={3}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
          </GearTR>
          <GearTR id={`GearDeployedDimensionRowTDAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthA` : 'LengthA'}
                placeholder={`${t('Length')} A`}
                onChange={(e) => setLengthA(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthB` : 'LengthB'}
                placeholder={`${t('Length')} B`}
                onChange={(e) => setLengthB(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthC` : 'LengthC'}
                placeholder={`${t('Length')} C`}
                onChange={(e) => setLengthC(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>{surface !== 0 ? surface : `${t('Surface')}`}
              <input
                type="hidden"
                name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                value={surface}
              />
            </GearTD>
          </GearTR>
        </>
      )}
      {deployedShape === 'Hexagon' && (
        <>
          <GearTR id={`GearDeployedDimensionRowTHAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTH colSpan={3}>
              {`${t('Length')} A [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthAfr : HexagonalLengthAen}/>
            </GearTH>
            <GearTH colSpan={3}>
              {`${t('Length')} B [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthBfr : HexagonalLengthBen}/>
            </GearTH>
            <GearTH colSpan={3}>
              {`${t('Length')} C [${getUnit('Distance')}]`}
              <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthCfr : HexagonalLengthCen}/>
            </GearTH>
            <GearTH colSpan={3}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
          </GearTR>
          <GearTR id={`GearDeployedDimensionRowTDAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthA` : 'LengthA'}
                placeholder={`${t('Length')} A`}
                onChange={(e) => setLengthA(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthB` : 'LengthB'}
                placeholder={`${t('Length')} B`}
                onChange={(e) => setLengthB(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}LengthC` : 'LengthC'}
                placeholder={`${t('Length')} C`}
                onChange={(e) => setLengthC(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={3}>{surface !== 0 ? surface : `${t('Surface')}`}
              <input
                type="hidden"
                name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                value={surface}
              />
            </GearTD>
          </GearTR>
        </>
      )}
      {deployedShape === 'Mummy' && (
        <>
          <GearTR id={`GearDeployedDimensionRowTHAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTH colSpan={4}>
              {`${t('Length')} [${getUnit('Distance')}]`}
            </GearTH>
            <GearTH colSpan={4}>
              {`${t('WidthShoulder')} [${getUnit('Distance')}]`}
            </GearTH>
            <GearTH colSpan={4}>
              {`${t('WidthFoot')} [${getUnit('Distance')}]`}
            </GearTH>
          </GearTR>
          <GearTR id={`GearDeployedDimensionRowTDAdd-${deployedShape}-${gearData.SubCategory}`}>
            <GearTD colSpan={4}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}Length` : 'Length'}
                placeholder={`${t('Length')}`}
                onChange={(e) => setLength(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}WidthShoulder` : 'WidthShoulder'}
                placeholder={`${t('WidthShoulder')}`}
                onChange={(e) => setWidthShoulder(e.target.value)}
              />
            </GearTD>
            <GearTD colSpan={4}>
              <GearFormInput
                type="number"
                name={nameComplement !== undefined ? `${nameComplement}WidthFoot` : 'WidthFoot'}
                placeholder={`${t('WidthFoot')}`}
                onChange={(e) => setWidthFoot(e.target.value)}
              />
            </GearTD>
          </GearTR>
        </>
      )}
    </>
  );
}

GearDeployedDimensionRowAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  nameComplement: PropTypes.string
};

export default GearDeployedDimensionRowAdd;
