function isValidListName (listNameToTest) {
  // console.log(`userIDToTest : ${userIDToTest}`);
  if (listNameToTest === '' || listNameToTest === null) {
    return ('MissingListName');
  } else {
    // Regular expression pattern for email validation
    const userIDPattern = /^[a-zA-Z0-9]+$/;
    // Test the email address against the pattern
    return userIDPattern.test(listNameToTest);
  }
}

export function listCommonFormValidTest (setFormDataErrorMessages, formData) {
  const isListNameValid = isValidListName(formData.get('Name'));

  // console.log(`isUserIDValid : ${isUserIDValid}`);

  if (isListNameValid === true) {
    // console.log('User_ID Valid');
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      ListName: ''
    }));
  } else {
    if (isListNameValid === 'MissingListName') {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        ListName: 'MissingListName'
      }));
    } else if (isListNameValid === false) {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        ListName: 'FormatListName'
      }));
    }
  }

  // console.log('formDataErrorMessages : ', formDataErrorMessages);

  // if (isEmailValid === true && isPhoneValid === true && isNameValid === true) {
  if (isListNameValid === true) {
    return true;
  } else {
    return false;
  }
};

function isValidListCategory (categoryToTest) {
  console.log('isValidListCategory');
  console.log(`categoryToTest : ${categoryToTest}`);
  if (categoryToTest === '' || categoryToTest === null) {
    return ('MissingCategory');
  } else {
    return true;
  }
}

export function listPackListFormValidTest (setFormDataErrorMessages, formData) {
  console.log('listPackListFormValidTest');
  const isPackListFormValid = isValidListCategory(formData.get('Category'));

  // console.log(`isUserIDValid : ${isUserIDValid}`);

  if (isPackListFormValid === true) {
    // console.log('User_ID Valid');
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      Category: ''
    }));
  } else {
    if (isPackListFormValid === 'MissingCategory') {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        Category: 'MissingCategory'
      }));
    }
  }

  if (isPackListFormValid === true) {
    return true;
  } else {
    return false;
  }
};

function isValidListEnvironment (environmentToTest) {
  console.log('isValidListCategory');
  console.log(`categoryToTest : ${environmentToTest}`);
  if (environmentToTest === '' || environmentToTest === null) {
    return ('MissingEnvironment');
  } else {
    return true;
  }
}

export function listSumListFormValidTest (setFormDataErrorMessages, formData) {
  console.log('listPackListFormValidTest');
  const isPackListFormValid = isValidListEnvironment(formData.get('Environment'));

  // console.log(`isUserIDValid : ${isUserIDValid}`);

  if (isPackListFormValid === true) {
    // console.log('User_ID Valid');
    setFormDataErrorMessages(prevState => ({
      ...prevState,
      Environment: ''
    }));
  } else {
    if (isPackListFormValid === 'MissingEnvironment') {
      setFormDataErrorMessages(prevState => ({
        ...prevState,
        Environment: 'MissingEnvironment'
      }));
    }
  }

  if (isPackListFormValid === true) {
    return true;
  } else {
    return false;
  }
};
