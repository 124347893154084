// Import React Libraries
import styled from 'styled-components';
import { responsiveWidthMobile } from '../../../Constant';

export const ContactTable = styled.table`
  width: 100%;
  border-spacing: 4px;
`;

export const ContactTableBody = styled.tbody`

`;

export const ContactTR = styled.tr`
`;

export const ContactTD = styled.td`
  border-radius: 3px;
  border: 1px solid black;
  text-align: center;
  height: 21px;

  @media (max-width: ${responsiveWidthMobile}px){
    height: 35px;
  }
`;
