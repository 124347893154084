// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes, specificCharacteristicPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import GearMaterialRowDisplay from '../../Common/GearMaterialRowDisplay';
import GearDeployedDimensionRowDisplay from '../../Common/GearDeployedDimensionRowDisplay';
import DisplayHelpComponent from '../../../../../ToolTip/DisplayHelp_Component';

// Import Datas
import { singularValues } from '../../../../../../utils/Constants/Gear_Constants';

// Import Function
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearTarpSpecificTableDisplay ({ gearData, specificCharacteristic, gearName }) {
  const { t } = useTranslation();

  return (
    <GearTable id={`gearTarpSpecificTable-${gearName}`}>
      <ColGroup12/>
      <GearTableBody>
      <GearTR id={`gearCommonRowTH-${gearName}`}>
        <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
      </GearTR>
      <GearTR id={`gearCommonRowTH-${gearName}`}>
        <GearTH colSpan={12}>
          {singularValues.includes(specificCharacteristic.Attach) ? `${t('Attach')}` : `${t('Attachs')}`}
        </GearTH>
      </GearTR>
      <GearTR id={`gearCommonRowTD-${gearName}`}>
        <GearTD colSpan={12}>{specificCharacteristic.Attach || ''}</GearTD>
      </GearTR>
      <GearMaterialRowDisplay specificCharacteristic={specificCharacteristic} gearName={gearName}/>
      <GearTR id={`gearCommonRowTH-${gearName}`}>
        <GearTH colSpan={4}>{`${t('TissuDensity')} [${getUnit('TissuDensity')}]`}</GearTH>
        <GearTH colSpan={4}>{`${t('ThreadCount')} [${getUnit('ThreadCount')}]`}</GearTH>
        <GearTH colSpan={4}>{`${t('Sewing')}`}</GearTH>
      </GearTR>
      <GearTR id={`gearCommonRowTH-${gearName}`}>
        <GearTD colSpan={4}>{specificCharacteristic.TissuDensity || ''}</GearTD>
        <GearTD colSpan={4}>{specificCharacteristic.ThreadCount || ''}</GearTD>
        <GearTD colSpan={4}>{specificCharacteristic.Sewing || ''}</GearTD>
      </GearTR>
      <GearTR id={`gearCommonRowTH-${gearName}`}>
        <GearTH colSpan={4}>{`${t('Impermeability')} [${getUnit('Impermeability')}]`}</GearTH>
        <GearTH colSpan={4}>{`${t('Breathability')} [${getUnit('Breathability')}]`}</GearTH>
        <GearTH colSpan={4}>{`${t('UPF')}`} <DisplayHelpComponent text1={`${t('UVProtectionFactor')}`}/></GearTH>
      </GearTR>
      <GearTR id={`gearCommonRowTD-${gearName}`}>
        <GearTD colSpan={4}>{specificCharacteristic.Impermeability || ''}</GearTD>
        <GearTD colSpan={4}>{specificCharacteristic.Breathability || ''}</GearTD>
        <GearTD colSpan={4}>{specificCharacteristic.Upf || ''}</GearTD>
      </GearTR>
      <GearTR id={`gearCommonRowTH-${gearName}`}>
        <GearTH colSpan={12}>{`${t('DeployedDimension')}`}</GearTH>
      </GearTR>
      <GearDeployedDimensionRowDisplay specific={specificCharacteristic} gearName={gearName}/>
      </GearTableBody>
    </GearTable>
  );
}

GearTarpSpecificTableDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  specificCharacteristic: specificCharacteristicPropTypes.isRequired,
  gearName: PropTypes.string.isRequired
};

export default GearTarpSpecificTableDisplay;
