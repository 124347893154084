// Import Constants
import { WebServerIPAddress } from '../../utils/Constants/IP_Constants';

export async function APIPostListTag (data) {
  console.log('APIPostListTag');
  console.log('data : ', data);

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/ListTag/`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'POST',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      console.log('responseBody : ', responseBody.message);
      throw error;
    }
    console.log('responseBody : ', responseBody);
    if (responseBody.message === 'ListTag created') {
      return true;
    } else if (responseBody.message === 'ListTag already existing') {
      console.log('ListTag already existing');
      return false;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error:', error);
  }
};

export async function APIGetListTags (listMongoDBID, listType) {
  /*
  console.log('APIGetListTags');
  console.log('listMongoDBID : ', listMongoDBID);
  console.log('listType : ', listType);
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/ListTag/${listMongoDBID}/${listType}`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'GET',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    console.log('responseBody : ', responseBody);
    return responseBody;
  } catch (error) {
    console.log('error:', error);
  }
};

export async function APIEditListTagQuantity (data) {
  // console.log('APIEditListTagQuantity');
  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/ListTag/`;

  try {
    const response = await fetch(URLToFetch, {
      method: 'PUT',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    console.log('responseBody : ', responseBody);
    if (responseBody.message === 'ListTag updated') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error:', error);
  }
};

export async function APIDeleteUserListTag (listTagMongoDBID) {
  /*
  console.log('APIDeleteUserListTag');
  console.log(`listTagMongoDBID : ${listTagMongoDBID}`)
  */

  const authToken = sessionStorage.getItem('authenticationToken');
  const URLToFetch = `https://${WebServerIPAddress}/ListTag/${listTagMongoDBID}`;
  try {
    const response = await fetch(URLToFetch, {
      method: 'DELETE',
      // Do not set 'Content-Type' header manually, let fetch handle it automatically for FormData
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    // Read the response body
    const responseBody = await response.json();

    if (!response.ok) {
      const error = new Error('Network response was not ok');
      error.response = responseBody;
      throw error;
    }
    console.log('responseBody : ', responseBody);
    if (responseBody.message === 'ListTag Deleted') {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('error:', error);
  }
};
