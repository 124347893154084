// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { gearItemPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import GearDeployedDimensionRowDisplay from '../../Common/GearDeployedDimensionRowDisplay';
import GearSleepingBagInsulatorNatureRowDisplay from './GearSleepingBagInsulatorNatureRowDisplay';

// Import Functions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Datas
import { singularValues } from '../../../../../../utils/Constants/Gear_Constants';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearSleepingBagSpecificTableDisplay ({ item }) {
  const { t } = useTranslation();
  return (
    <GearTable id={`gearSleepingBagSpecificTableDisplay-${item.Informations.Name}`}>
      <ColGroup12/>
      <GearTableBody>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={6}>{`${t('Hood')}`}</GearTH>
          <GearTH colSpan={6}>
            {singularValues.includes(item.Specific.Season) ? `${t('Season')}` : `${t('Seasons')}`}
            </GearTH>
        </GearTR>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTD colSpan={6}>
            {item.Specific.Hood !== null ? `${t(item.Specific.Hood)}` : ''}
            </GearTD>
          <GearTD colSpan={6}>
            {item.Specific.Season !== null ? `${item.Specific.Season}` : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
            <GearTH colSpan={6}>{`${t('ZipSide')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('Pairable')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTD colSpan={6}>
            {item.Specific.ZipSide !== null ? `${t(item.Specific.ZipSide)}` : ''}
          </GearTD>
          <GearTD colSpan={6}>
            {item.Specific.Pairable !== null ? `${t(item.Specific.Pairable)}` : ''}
          </GearTD>
        </GearTR>
        <GearSleepingBagInsulatorNatureRowDisplay specificCharacteristic={item.Specific} gearName={item.Informations.Name}/>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={12}>{`${t('Temperature')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={4}>{`${t('TemperatureComfort')} [${getUnit('Temperature')}]`}</GearTH>
          <GearTH colSpan={4}>{`${t('TemperatureLimit')} [${getUnit('Temperature')}]`}</GearTH>
          <GearTH colSpan={4}>{`${t('TemperatureExtrem')} [${getUnit('Temperature')}]`}</GearTH>
        </GearTR>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTD colSpan={4}>
            {item.Specific.TemperatureComfort !== null ? `${item.Specific.TemperatureComfort}` : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {item.Specific.TemperatureLimit !== null ? `${item.Specific.TemperatureLimit}` : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {item.Specific.TemperatureExtrem !== null ? `${item.Specific.TemperatureExtrem}` : ''}
          </GearTD>
        </GearTR>
        <GearTR id={`gearSleepingBagSpecificRowDisplay-${item.Informations.Name}`}>
          <GearTH colSpan={12}>{`${t('DeployedDimension')}`}</GearTH>
        </GearTR>
        <GearDeployedDimensionRowDisplay specific={item.Specific} gearName={item.Informations.Name}/>
      </GearTableBody>
    </GearTable>
  );
}

GearSleepingBagSpecificTableDisplay.propTypes = {
  item: gearItemPropTypes.isRequired
};

export default GearSleepingBagSpecificTableDisplay;
