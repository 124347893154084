// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Import Component
import UserListSelectorComponent from '../../components/List/userListSelector_Component';

// Import Functions
import { displayListPage } from '../../utils/Functions/PathFunctions/listPath_Functions';

// Import Styles
import { DisplayContainer, PageContainer, PageWrapper } from '../../utils/Styles';
import { StyledH1, TitleContainer } from '../../utils/Style/js/General/Font_Style';

// Import Constants

function ListPage () {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const currentUrl = location.pathname;

  const [shouldDisplayListDetail, setShouldDisplayListDetail] = useState(false);

  useEffect(() => {
    setShouldDisplayListDetail(displayListPage(currentUrl, t, i18n));
  }, [navigate]);

  return (
    <PageWrapper id='pageWrapperSubCategorySelectorPage'>
      <PageContainer id='ListPageContainer'>
        <DisplayContainer>
          {shouldDisplayListDetail
            ? (
                <>
                  <TitleContainer>
                      <StyledH1>{`${t('Lists')}`}</StyledH1>
                  </TitleContainer>
                  <UserListSelectorComponent/>
                </>
              )
            : (
                <Outlet />
              )
          }
        </DisplayContainer>
      </PageContainer>
    </PageWrapper>
  );
}

export default ListPage;
