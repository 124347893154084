// Import React Libraries
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { errorMessagesProptypes } from '../../../../../datas/PropTypes/errorMessagesPropTypes';
import { listPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Component
import ColGroup12 from '../../../../Gear/Tab/ColGroup12';
import { GearSelectDropDown } from '../../../../Gear/SelectDropDown/GearSelectDropDown';
import DisplayHelpComponent from '../../../../ToolTip/DisplayHelp_Component';

// Import Datas
import { listVisibilityChoiceArray } from '../../../../../datas/ChoiceArrays/Lists/lists_ChoiceArrays';

// Import Functions
import { listCommonFormValidTest } from '../../../../../utils/Functions/Lists/FillDataObject/ListFormCheck_Functions';

// Import Styles
import {
  UserDataTable, UserDataTableBody,
  UserDataTR, UserDataTH, UserDataTD
} from '../../../../../utils/Style/js/Account/UserDataTableDisplay_Style';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';

// Import Constants

function ListCommonTableAdd ({ onSubmit, onError, temporaryListData, errorMessages, listType }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const [name, setName] = useState(temporaryListData !== null ? temporaryListData.Name : null);
  const [visibility, setVisibility] = useState(temporaryListData !== null ? temporaryListData.listVisibility : 'Everyone');

  const [formDataErrorMessages, setFormDataErrorMessages] = useState({
    ListName: ''
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const isListFormValid = listCommonFormValidTest(setFormDataErrorMessages, formData);
    console.log('isListFormValid : ');
    console.log(isListFormValid);

    if (isListFormValid) {
      const data = Object.fromEntries(formData.entries());
      console.log(`isListFormValid : ${isListFormValid}`);
      if (onSubmit) {
        onSubmit(data);
      }
    } else {
      console.log(`isListFormValid : ${isListFormValid}`);
      if (onError) {
        onError(formDataErrorMessages);
      }
    }
  };

  useEffect(() => {
    console.log('formDataErrorMessages : ', formDataErrorMessages);
  }, [formDataErrorMessages, errorMessages]);

  useEffect(() => {
    console.log('useEffect');
    console.log('temporaryListData : ', temporaryListData);
  }, [temporaryListData]);

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <UserDataTable>
        <ColGroup12/>
        <UserDataTableBody>
          <UserDataTR>
            <UserDataTH colSpan={6}>{`${t('ListCreator')}`}</UserDataTH>
            <UserDataTH colSpan={6}>
              {`${t('ListName')}`}
              <DisplayHelpComponent
                text1={`${t('NameListDescription1')}`}
                text2={`${t('NameListDescription2')}`}
              />
            </UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              {`${currentUserData.userProfile.User_ID}`}
              <input type="hidden" name="Creator" value={currentUserData.userProfile.User_ID} />
            </UserDataTD>
            <UserDataTD colSpan={6}>
              <GearFormInput
                type="text"
                name="Name"
                placeholder={`${t('Name')}`}
                value= {name}
                onChange={(e) => setName(e.target.value)}
              />
            </UserDataTD>
          </UserDataTR>
          {errorMessages && errorMessages.includes('ListName')
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6}/>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('NameUnavailable')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.ListName === 'FormatListName'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6}/>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('NameFormat')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          {formDataErrorMessages.ListName === 'MissingListName'
            ? (
            <UserDataTR>
              <UserDataTD colSpan={6}/>
              <UserDataTD colSpan={6} style={{ color: 'red' }}>{`${t('NameMissing')}`}</UserDataTD>
            </UserDataTR>
              )
            : <></>
          }
          <UserDataTR>
            <UserDataTH colSpan={6}>
              {`${t('ListType')}`}
            </UserDataTH>
            <UserDataTH colSpan={6}>
              {`${t('ListVisibility')}`}
            </UserDataTH>
          </UserDataTR>
          <UserDataTR>
            <UserDataTD colSpan={6}>
              {`${t(`${listType}Title`)}`}
              <input type="hidden" name="ListType" value={listType}/>
            </UserDataTD>
            <UserDataTD colSpan={6}>
              <GearSelectDropDown options={listVisibilityChoiceArray} choice={visibility} setChoice={setVisibility} noChoice={`${t('Select')}`}/>
              <input type="hidden" name="ListVisibility" value={visibility} />
            </UserDataTD>
          </UserDataTR>
        </UserDataTableBody>
      </UserDataTable>
    </form>
  );
}

ListCommonTableAdd.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  temporaryListData: listPropTypes,
  errorMessages: errorMessagesProptypes,
  listType: PropTypes.string.isRequired
};

export default forwardRef(ListCommonTableAdd);
