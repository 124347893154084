// Import Assets
import {
  beddingAccessoryCategoryIllustration,
  hammockSubCategoryIllustration,
  mattressSubCategoryIllustration,
  sleepingBagSubCategoryIllustration
} from '../../Illustrations/equipment_Illustrations';

export const beddingSubCategoryList = [
  {
    category: 'Bedding',
    subCategory: 'SleepingBag',
    text: 'SleepingBag-title',
    illustration: sleepingBagSubCategoryIllustration
  },
  {
    category: 'Bedding',
    subCategory: 'Mattress',
    text: 'Mattress-title',
    illustration: mattressSubCategoryIllustration
  },
  {
    category: 'Bedding',
    subCategory: 'Hammock',
    text: 'Hammock-title',
    illustration: hammockSubCategoryIllustration
  },
  {
    category: 'Bedding',
    subCategory: 'Accessory',
    text: 'Accessory',
    illustration: beddingAccessoryCategoryIllustration
  }
];
