// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Import Components
import UserTableEdit from '../../components/Account/Tab/Edit/UserTableConstruction_Edit';
import LoadingComponent from '../../components/LoadingComponent/Loading_Component';

// Import API
import { APIgetUser } from '../../api/User/User_API';

// Import Functions
import { getUserIDFromUrlAccountEdit } from '../../utils/Functions/PathFunctions/parseUrl_Functions';

// Import Style
import { PageWrapper } from '../../utils/Styles';

function AccountEditPage () {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  // const navigate = useNavigate();
  const currentUrl = location.pathname;
  const userIDFromUrl = getUserIDFromUrlAccountEdit(currentUrl);

  const [userDataToSee, setUserDataToSee] = useState(null);
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  // const userData = JSON.parse(sessionStorage.getItem('userData'));
  console.log(`currentUrl : ${currentUrl}`);
  console.log(`userIDFromUrl : ${userIDFromUrl}`);
  // console.log('userData : ', userData);

  useEffect(() => {
    const fetchUserData = async () => {
      const data = await APIgetUser(getUserIDFromUrlAccountEdit(currentUrl));
      setUserDataToSee(data);
    };
    fetchUserData();

    // handle access rights
    if (currentUserData.userProfile.User_Rank !== 'ADMIN') {
      if (currentUserData.userProfile.User_ID !== getUserIDFromUrlAccountEdit(currentUrl)) {
        console.log('redirect user when he tries to edit an other profile');
        navigate(`/${i18n.language}`);
      }
    } else if (currentUserData.userProfile.User_Rank === 'ADMIN') {
      if (currentUserData.userProfile.User_ID !== getUserIDFromUrlAccountEdit(currentUrl)) {
        fetchUserData();
      }
    }
  }, [navigate, i18n.language, t]);

  return (
      <PageWrapper id='pageWrapperAccountAdd'>
        {
          userDataToSee !== null
            ? (
                <UserTableEdit userData={userDataToSee}/>
              )
            : (
          <LoadingComponent/>
              )
        }

      </PageWrapper>
  );
}

export default AccountEditPage;
