// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import { GearSelectDropDown } from '../../../../SelectDropDown/GearSelectDropDown';

// Import Datas
import { singularValues } from '../../../../../../utils/Constants/Gear_Constants';
import { tentAwningChoiceArray } from '../../../../../../datas/ChoiceArrays/Gear/Shelter/SpecificShelter_ChoiceArrays';
import { yesNoChoiceArray } from '../../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearTentAwningAmountRowEdit ({ gearData, awning, setAwning, awningIdentical, setAwningIdentical }) {
  // console.log('GearAwningDimensionRowAdd');
  // console.log(`awning : ${awning}`);
  const { t } = useTranslation();

  return (
    <>
        {singularValues.includes(awning) && (
          <>
            <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
              <GearTH colSpan={12}>
                {singularValues.includes(awning) ? `${t('Awning')}` : `${t('Awning(s)')}`}
              </GearTH>
            </GearTR>
            <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
              <GearTD colSpan={12}>
                <GearSelectDropDown options={tentAwningChoiceArray} choice={awning} setChoice={setAwning} noChoice='Select'/>
                <input type="hidden" name="Awning" value={awning} />
              </GearTD>
            </GearTR>
          </>
        )}
        {awning === 2 && (
          <>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTH colSpan={6}>{`${t('Awnings')}`}</GearTH>
            <GearTH colSpan={6}>{`${t('AwningIdenticals')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearCommonRowTH-${gearData.gearSubCategory}`}>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={tentAwningChoiceArray} choice={awning} setChoice={setAwning} noChoice='Select'/>
              <input type="hidden" name="Awning" value={awning} />
            </GearTD>
            <GearTD colSpan={6}>
              <GearSelectDropDown options={yesNoChoiceArray} choice={awningIdentical} setChoice={setAwningIdentical} noChoice='Select'/>
              <input type="hidden" name="AwningIdentical" value={awningIdentical} />
            </GearTD>
          </GearTR>
          </>
        )}
    </>
  );
}

GearTentAwningAmountRowEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  awning: PropTypes.number.isRequired,
  setAwning: PropTypes.func.isRequired,
  awningIdentical: PropTypes.number.isRequired,
  setAwningIdentical: PropTypes.func.isRequired
};

export default GearTentAwningAmountRowEdit;
