// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes, specificCharacteristicPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Component
import ColGroup12 from '../../../ColGroup12';
import GearMaterialRowDisplay from '../../Common/GearMaterialRowDisplay';
import GearMosquitoNetRow from '../../Common/GearMosquitoNetRowDisplay';
import GearHammockPocketMattressRowDisplay from './GearHammockPocketMattressRowDisplay';
import GearDeployedDimensionRowDisplay from '../../Common/GearDeployedDimensionRowDisplay';

// Import Datas
import { singularValues } from '../../../../../../utils/Constants/Gear_Constants';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';
import { convertIfNecessarySI } from '../../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Constants

function GearHammockSpecificTableDisplay ({ gearData, specificCharacteristic, gearName }) {
  const { t } = useTranslation();

  return (
    <GearTable id={`gearCommonTable-${gearName}`}>
      <ColGroup12/>
      <GearTableBody>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={12}>{`${t('Specificity')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={4}>
            {singularValues.includes(specificCharacteristic.Person) ? `${t('Person')}` : `${t('Persons')}`}
          </GearTH>
          <GearTH colSpan={4}>{`${t('Type')}`}</GearTH>
          <GearTH colSpan={4}>
            {specificCharacteristic.MaxWeight !== null
              ? `${t('MaxWeight')} [${convertIfNecessarySI('Weight', specificCharacteristic.MaxWeight).convertedUnit}]`
              : `${t('MaxWeight')}`
            }
          </GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTD-${gearName}`}>
          <GearTD colSpan={4}>
            {specificCharacteristic.Person !== null ? specificCharacteristic.Person : ''}
          </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.Type !== null ? `${t(specificCharacteristic.Type)}` : ''}
            </GearTD>
          <GearTD colSpan={4}>
            {specificCharacteristic.MaxWeight !== null ? convertIfNecessarySI('Weight', specificCharacteristic.MaxWeight).convertedValue : ''}
            </GearTD>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={6}>{`${t('StoragePocket')}`}</GearTH>
          <GearTH colSpan={6}>{`${t('Hoops')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTD-${gearName}`}>
          <GearTD colSpan={6}>
            {specificCharacteristic.StoragePocket !== null ? `${t(specificCharacteristic.StoragePocket)}` : ''}
          </GearTD>
          <GearTD colSpan={6}>
            {specificCharacteristic.Hoop !== null ? `${t(specificCharacteristic.Hoop)}` : ''}
          </GearTD>
        </GearTR>
        <GearMosquitoNetRow specificCharacteristic={specificCharacteristic} gearName={gearName}/>
        <GearHammockPocketMattressRowDisplay specificCharacteristic={specificCharacteristic} gearName={gearName}/>
        <GearMaterialRowDisplay specificCharacteristic={specificCharacteristic} gearName={gearName}/>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={12}>{`${t('DeployedDimension')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTH colSpan={12}>
            {specificCharacteristic.Deployed.TotalLength !== null
              ? `${t('TotalLength')} [${convertIfNecessarySI('Distance', specificCharacteristic.Deployed.TotalLength).convertedUnit}]`
              : `${t('TotalLength')}`
            }
          </GearTH>
        </GearTR>
        <GearTR id={`gearCommonRowTH-${gearName}`}>
          <GearTD colSpan={12}>
            {specificCharacteristic.Deployed.TotalLength !== null ? convertIfNecessarySI('Distance', specificCharacteristic.Deployed.TotalLength).convertedValue : ''}
          </GearTD>
        </GearTR>
        <GearDeployedDimensionRowDisplay specific={specificCharacteristic} gearName={gearName}/>
      </GearTableBody>
    </GearTable>
  );
}

GearHammockSpecificTableDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  specificCharacteristic: specificCharacteristicPropTypes,
  gearName: PropTypes.string.isRequired
};

export default GearHammockSpecificTableDisplay;
