// Import React Libraries
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Import PropTypes
// import PropTypes from 'prop-types';
import { gearCommonCharacteristicPropTypes, gearDataPropTypes } from '../../../../datas/PropTypes/gearPropTypes';

// Import Component
// Common Table
import GearImageUploaderTableEdit from './Common/GearImageUploaderTableEdit';
import GearCommonTableComponentEdit from './Common/GearCommonDataTableEdit';
import GearCompactedDimensionTableComponentEdit from './Common/GearCompactedDimensionTableEdit';
import GearDataTableSpecificHandlerEdit from './GearTableSpecificHandlerEdit';
import LoadingComponent from '../../../LoadingComponent/Loading_Component';

import GearValidationDataTableEdit from './Common/GearValidationDataTableEdit';

// Import Functions
import { ObjectFromFormMapping } from '../../../../utils/Functions/Gear/FillDataObject/ObjectFromFormsMapping_Functions';
import { APIDeleteGear, APIEditGear } from '../../../../api/Gear/Gear_API';

// Import Style
import { GearInteractButtonContainer } from '../../../../utils/Style/js/GearItemSelector/GearItemCommonButtons_Style';
import { GeneralButton } from '../../../../utils/Style/js/General/Button_Style';
import { userRankGearValidationAccessArray } from '../../../../datas/User/User_AddEdit_ChoiceArrays';

function GearDataTableEdit ({ gearData, item }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  /*
  console.log('GearDataTableEdit');
  console.log(`gearTypeCategory : ${gearTypeCategory}`);
  console.log(`gearCategory : ${gearCategory}`);
  console.log(`gearSubCategory : ${gearSubCategory}`);
  console.log('gearCharacteristic : ', gearCharacteristic);
  console.log('gearCharacteristic.illustrations : ', gearCharacteristic.illustrations);
  */

  const [loaderState, setLoaderState] = useState(false);

  const formRef1 = useRef();
  const formRef2 = useRef();
  const formRef3 = useRef();
  const formRef4 = useRef();
  const formRef5 = useRef();

  let form1 = null;
  let form2 = null;
  let form3 = null;
  let form4 = null;
  let form5 = null;

  const handleFormSubmit1 = (data) => {
    // console.log('Form 1 data:', data);
    form1 = data;
    // Handle form 1 submission here
  };
  const handleFormSubmit2 = (data) => {
    // console.log('Form 2 data:', data);
    form2 = data;
    // Handle form 2 submission here
  };
  const handleFormError2 = (errors) => {
  };

  const handleFormSubmit3 = (data) => {
    // console.log('Form 3 data:', data);
    form3 = data;
    // Handle form 2 submission here
  };
  const handleFormSubmit4 = (data) => {
    // console.log('Form 3 data:', data);
    form4 = data;
    // Handle form 2 submission here
  };
  const handleFormSubmit5 = (data) => {
    // console.log('Form 3 data:', data);
    form5 = data;
    // Handle form 2 submission here
  };

  const triggerSubmit = async (e) => {
    e.preventDefault();

    if (formRef1.current) {
      formRef1.current.submit();
    }
    if (formRef2.current) {
      formRef2.current.submit();
    }

    if (formRef3.current) {
      formRef3.current.submit();
    }
    if (formRef4.current) {
      formRef4.current.submit();
    }
    if (formRef5.current) {
      formRef5.current.submit();
    }
    if (form1 !== null && form2 !== null && form3 !== null && form4 !== null && form5 !== null) {
      setLoaderState(true);
      const objectFromForms = await ObjectFromFormMapping(form1, form2, form3, form4, form5, item, gearData, 'Edit');
      console.log('objectFromForms : ', objectFromForms);

      try {
        const serverResponse = await APIEditGear(objectFromForms);
        setLoaderState(false);
        console.log('serverResponse : ', serverResponse);

        if (serverResponse.message === `${item.Informations.SubCategory} Edited`) {
          // setTemporaryUserData(null);
          const linkPath = gearData.SubCategory !== 'Accessory'
            ? `/${i18n.language}/${t(item.Informations.TypeCategory)}/${t(item.Informations.Category)}/${t(item.Informations.SubCategory)}/${item.Informations.WebAppID}`
            : `/${i18n.language}/${t(item.Informations.TypeCategory)}/${t(item.Informations.Category)}/${t(item.Informations.SubCategory)}/${t(item.Informations.AccessoryCategory)}/${t(item.Informations.WebAppID)}`;

          navigate(linkPath);
        } else if (serverResponse.error) {
          console.log('server Response Error : ', serverResponse.error.error);
          // setTemporaryUserData(objectFromForms);
          // setErrorMessages(serverResponse.error.error);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    }
  };

  const triggerDeletion = async (e) => {
    e.preventDefault();
    const deleteConfirmation = window.confirm(`${t('DeleteConfirmation')} ${t(gearData.SubCategory)}`);
    if (deleteConfirmation) {
      try {
        const serverResponse = await APIDeleteGear(gearData, item._id);
        if (serverResponse.message === `${gearData.SubCategory} Deleted`) {
          const pathAccessory = `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t(gearData.AccessoryCategory)}`;
          const pathSubCategory = `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}`;

          if (gearData.SubCategory === 'Accessory') {
            navigate(pathAccessory);
          } else {
            navigate(pathSubCategory);
          }
        } else if (serverResponse.error) {
          console.log('server Response Error : ', serverResponse.error.error);
          // setTemporaryUserData(objectFromForms);
          // setErrorMessages(serverResponse.error.error);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    }
  };

  return (
    <>
      { loaderState
        ? <LoadingComponent/>
        : <div id='GearTableConstructionEdit'>
          <GearImageUploaderTableEdit
            ref={formRef1}
            gearData={gearData}
            gearIllustrationArray = {item.Assets.Illustrations}
            gearName = {item.Informations.WebAppID}
            onSubmit={handleFormSubmit1}
          />
          <GearCommonTableComponentEdit
            ref={formRef2}
            item={item}
            onSubmit={handleFormSubmit2}
            onError={handleFormError2}
          />
          <GearDataTableSpecificHandlerEdit
            ref={formRef3}
            gearData={gearData}
            item={item}
            gearSpecificCharacteristic={item.Specific}
            onSubmit={handleFormSubmit3}
          />
          <GearCompactedDimensionTableComponentEdit
            ref={formRef4}
            item={item}
            onSubmit={handleFormSubmit4}
          />
          {userRankGearValidationAccessArray.includes(currentUserData.userProfile.User_Rank) &&
            <GearValidationDataTableEdit
              ref={formRef5}
              item={item}
              onSubmit={handleFormSubmit5}
            />
          }
          <GearInteractButtonContainer>
              <GeneralButton onClick={triggerSubmit}>{`${t('Validate')}`}</GeneralButton>
          </GearInteractButtonContainer>
          <GearInteractButtonContainer>
              <GeneralButton onClick={triggerDeletion}>{`${t('Delete')}`}</GeneralButton>
          </GearInteractButtonContainer>
        </div>
      }
</>
  );
}

GearDataTableEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearCommonCharacteristicPropTypes
};

export default GearDataTableEdit;
