// Import React Libraries
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';
import DisplayHelpComponent from '../../../../ToolTip/DisplayHelp_Component';

// Import Styles
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { ImageSelectorContainer, ImageContainer, DeleteButton, FileInputLabel } from '../../../../../utils/Style/js/GearTable/TablePicture_Style';
// Commit
function GearImageUploaderTableAdd ({ gearData, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [selectedImages, setSelectedImages] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    // Append the selected images to the form data
    selectedImages.forEach((image, index) => {
      if (image) {
        formData.append(`image-${index}`, image);
      }
    });

    const data = Object.fromEntries(formData.entries());
    console.log('PICTURES');
    console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  const handleImageChange = (event, index) => {
    const files = Array.from(event.target.files);
    const newSelectedImages = [...selectedImages];
    newSelectedImages[index] = files[0]; // Store the selected file at the corresponding index
    setSelectedImages(newSelectedImages);
  };

  const deleteImage = (index) => {
    const newSelectedImages = [...selectedImages];
    newSelectedImages.splice(index, 1);
    setSelectedImages(newSelectedImages);
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearImageUploaderTableAdd-${gearData.TypeCategory}`}>
        <ColGroup12 />
        <GearTableBody>
          <GearTR id={`gearImageUploaderRowTHAdd-${gearData.TypeCategory}`}>
            <GearTH colSpan={12}>
              {t('Pictures')}
              <DisplayHelpComponent
                text1={`${t('PictureDescription')}`}
              />
            </GearTH>
          </GearTR>
          {selectedImages.length < 6 && (
            <GearTR id={`gearImageUploaderRowTDAdd-${gearData.TypeCategory}`}>
              <GearTD colSpan={12}>
                {/* Top input for new image */}
                <div>
                  {/* Hidden file input */}
                  <input
                    type="file"
                    accept="image/jpeg, image/jpg"
                    onChange={(e) => handleImageChange(e, selectedImages.length)}
                    style={{ display: 'none' }} // Hide the default file input
                    id="fileInput-new"
                  />
                  {/* Custom label to trigger file input */}
                  <FileInputLabel htmlFor="fileInput-new">
                    {t('AddPicture')}
                  </FileInputLabel>
                </div>
              </GearTD>
            </GearTR>
          )}
          <GearTR id={`gearImageUploaderRowTDAdd-${gearData.TypeCategory}`}>
            {selectedImages.slice(0, 3).map((image, index) => (
                <GearTD colSpan={4} key={index} id={`gearCommonRow-${gearData.TypeCategory}-${index}`}>
                  <ImageSelectorContainer>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      accept="image/jpeg, image/jpg"
                      onChange={(e) => handleImageChange(e, index)}
                      style={{ display: 'none' }} // Hide the default file input
                      id={`fileInput-${index}`}
                    />
                    {/* Custom label to trigger file input */}
                    {image && (
                      <ImageContainer>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`preview ${index}`}
                          style={{ width: '100px', height: '100px' }}
                        />
                        <DeleteButton onClick={() => deleteImage(index)}>
                          &times;
                        </DeleteButton>
                      </ImageContainer>
                    )}
                    <FileInputLabel htmlFor={`fileInput-${index}`}>
                      {image ? image.name : 'Select File'}
                    </FileInputLabel>
                  </ImageSelectorContainer>
                </GearTD>
            ))}
          </GearTR>
          {selectedImages.length > 3 && (
            <GearTR id={`gearImageUploaderRowTDAdd-${gearData.TypeCategory}`}>
              {selectedImages.slice(3).map((image, index) => (
                <GearTD colSpan={4} key={index + 3} id={`gearCommonRow-${gearData.TypeCategory}-${index + 3}`}>
                  <ImageSelectorContainer>
                    {/* Hidden file input */}
                    <input
                      type="file"
                      accept="image/jpeg, image/jpg"
                      onChange={(e) => handleImageChange(e, index + 3)}
                      style={{ display: 'none' }} // Hide the default file input
                      id={`fileInput-${index + 3}`}
                    />
                    {/* Custom label to trigger file input */}
                    {image && (
                      <ImageContainer>
                        <img
                          src={URL.createObjectURL(image)}
                          alt={`preview ${index + 3}`}
                          style={{ width: '100px', height: '100px' }}
                        />
                        <DeleteButton onClick={() => deleteImage(index + 3)}>
                          &times;
                        </DeleteButton>
                      </ImageContainer>
                    )}
                    <FileInputLabel htmlFor={`fileInput-${index + 3}`}>
                      {image ? image.name : 'Select File'}
                    </FileInputLabel>
                  </ImageSelectorContainer>
                </GearTD>
              ))}
            </GearTR>
          )}
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearImageUploaderTableAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearImageUploaderTableAdd);
