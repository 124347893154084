// Import React Libraries
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Import PropTypes
// import PropTypes from 'prop-types';
import { gearDataPropTypes } from '../../../../datas/PropTypes/gearPropTypes';

// Import Component
import LoadingComponent from '../../../LoadingComponent/Loading_Component';
// Common Table
import GearImageUploaderTableAdd from './Common/GearImageUploaderTableAdd';
import GearCommonDataTableAdd from './Common/GearCommonDataTableAdd';
import GearCompactedDimensionTableAdd from './Common/GearCompactedDimensionTableAdd';
import ValidationDataTableAdd from './ValidationDataTableAdd';
import GearDataTableSpecificHandlerAdd from './GearTableSpecificHandlerAdd';

// Import Functions
import { APIPostGear } from '../../../../api/Gear/Gear_API';
import { ObjectFromFormMapping } from '../../../../utils/Functions/Gear/FillDataObject/ObjectFromFormsMapping_Functions';

// Import Style
import { GearInteractButtonContainer } from '../../../../utils/Style/js/GearItemSelector/GearItemCommonButtons_Style';
import { GeneralButton } from '../../../../utils/Style/js/General/Button_Style';
import { userRankGearValidationAccessArray } from '../../../../datas/User/User_AddEdit_ChoiceArrays';

// Import Constants

function GearDataTableAdd ({ gearData }) {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  const [loaderState, setLoaderState] = useState(false);
  // const [temporaryGearObject, setTemporaryGearObject] = useState(null);

  const item = {};

  const formRef1 = useRef();
  const formRef2 = useRef();
  const formRef3 = useRef();
  const formRef4 = useRef();
  const formRef5 = useRef();

  let form1 = null;
  let form2 = null;
  let form3 = null;
  let form4 = null;
  let form5 = null;

  const handleFormSubmit1 = (data) => {
    console.log('Form 1 data:', data);
    form1 = data;
    // Handle form 1 submission here
  };

  const handleFormSubmit2 = (data) => {
    console.log('Form 2 data:', data);
    form2 = data;
    // Handle form 2 submission here
  };
  const handleFormError2 = (errors) => {
  };

  const handleFormSubmit3 = (data) => {
    console.log('Form 3 data:', data);
    form3 = data;
    // Handle form 2 submission here
  };
  const handleFormSubmit4 = (data) => {
    console.log('Form 4 data:', data);
    form4 = data;
    // Handle form 2 submission here
  };
  const handleFormSubmit5 = (data) => {
    console.log('Form 5 data:', data);
    form5 = data;
    // Handle form 2 submission here
  };

  const triggerSubmit = async (e) => {
    e.preventDefault();

    if (formRef1.current) {
      formRef1.current.submit();
    }
    if (formRef2.current) {
      formRef2.current.submit();
    }
    if (formRef3.current) {
      formRef3.current.submit();
    }
    if (formRef4.current) {
      formRef4.current.submit();
    }
    if (formRef5.current) {
      formRef5.current.submit();
    }

    if (form1 !== null && form2 !== null && form3 !== null && form4 !== null && form5 !== null) {
      setLoaderState(true);

      // Implémenter fonction qui utilise la fonction correspondant a la sous catégorie d'objet
      const objectFromForms = await ObjectFromFormMapping(form1, form2, form3, form4, form5, item, gearData, 'Add');
      console.log('objectFromForms : ', objectFromForms);

      try {
        const serverResponse = await APIPostGear(objectFromForms);
        setLoaderState(false);
        // console.log('serverResponse : ', serverResponse);

        if (serverResponse.message === `${gearData.SubCategory} Created`) {
        // setTemporaryGearObject(null);
          const gearId = objectFromForms.Informations.WebAppID;
          const linkPath = gearData.SubCategory !== 'Accessory'
            ? `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${gearId}`
            : `/${i18n.language}/${t(gearData.TypeCategory)}/${t(gearData.Category)}/${t(gearData.SubCategory)}/${t(gearData.AccessoryCategory)}/${t(gearId)}`;

          navigate(linkPath);
        } else if (serverResponse.error) {
          console.error('server Response Error : ', serverResponse.error.error);
        // setTemporaryGearObject(objectFromForms);
        // setErrorMessages(serverResponse.error.error);
        }
      } catch (error) {
        console.error('Error during form submission:', error);
      }
    }
  };

  return (
    <>
      { loaderState
        ? <LoadingComponent/>
        : <div id='GearTableConstructionDisplay'>
            <GearImageUploaderTableAdd
              ref={formRef1}
              gearData={gearData}
              onSubmit={handleFormSubmit1}
            />
            <GearCommonDataTableAdd
              ref={formRef2}
              gearData={gearData}
              onSubmit={handleFormSubmit2}
              onError={handleFormError2}
            />
            <GearDataTableSpecificHandlerAdd
              ref={formRef3}
              gearData={gearData}
              onSubmit={handleFormSubmit3}
            />
            <GearCompactedDimensionTableAdd
              ref={formRef4}
              gearData={gearData}
              onSubmit={handleFormSubmit4}
            />
            {userRankGearValidationAccessArray.includes(currentUserData.userProfile.User_Rank) &&
              <ValidationDataTableAdd
                ref={formRef5}
                onSubmit={handleFormSubmit5}
              />
            }
            <GearInteractButtonContainer>
              <GeneralButton onClick={triggerSubmit}>{`${t('Validate')}`}</GeneralButton>
          </GearInteractButtonContainer>
          </div>
      }
    </>
  );
}

GearDataTableAdd.propTypes = {
  gearData: gearDataPropTypes.isRequired
};

export default GearDataTableAdd;
