// Import React Libraries
import React, { forwardRef } from 'react';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearCommonCharacteristicPropTypes, gearDataPropTypes, gearItemPropTypes } from '../../../../datas/PropTypes/gearPropTypes';

// Import Component

// Specific Table
// Accessory
import GearAccessoryTableEdit from './Accessory/GearAccessoryTableEdit';
// Shelter
import GearTarpSpecificTableEdit from './Shelter/Tarp/GearTarpSpecificTableEdit';
import GearTentSpecificTableEdit from './Shelter/Tent/GearTentSpecificTableEdit';
// Bedding
import GearSleepingBagSpecificTableEdit from './Bedding/SleepingBag/GearSleepingBagSpecificTableEdit';
import GearHammockSpecificTableEdit from './Bedding/Hammock/GearHammockSpecificTableEdit';
import GearMattressSpecificTableEdit from './Bedding/Mattress/GearMattressSpecificTableEdit';

function GearDataTableSpecificHandlerEdit ({ gearData, gearSpecificCharacteristic, item, onSubmit }, ref) {
  return (
    <>
      { /* Accessory */ }
      {gearData.SubCategory === 'Accessory' && (
        <GearAccessoryTableEdit
          ref={ref}
          gearData={gearData}
          gearSpecificCharacteristic={gearSpecificCharacteristic}
          onSubmit={onSubmit}
        />
      )}
      { /* Shelter */ }
      {gearData.SubCategory === 'Tarp' && (
        <GearTarpSpecificTableEdit
          ref={ref}
          gearData={gearData}
          item={item}
          onSubmit={onSubmit}
        />
      )}
      {gearData.SubCategory === 'Tent' && (
        <GearTentSpecificTableEdit
          ref={ref}
          gearData={gearData}
          item={item}
          onSubmit={onSubmit}
        />
      )}
      { /* Bedding */ }
      {gearData.SubCategory === 'SleepingBag' && (
        <GearSleepingBagSpecificTableEdit
          ref={ref}
          gearData={gearData}
          item={item}
          onSubmit={onSubmit}
        />
      )}
      {gearData.SubCategory === 'Mattress' && (
        <GearMattressSpecificTableEdit
          ref={ref}
          gearData={gearData}
          item={item}
          onSubmit={onSubmit}
        />
      )}
      {gearData.SubCategory === 'Hammock' && (
        <GearHammockSpecificTableEdit
          ref={ref}
          gearData={gearData}
          item={item}
          onSubmit={onSubmit}
        />
      )}
    </>
  );
}

GearDataTableSpecificHandlerEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  item: gearItemPropTypes,
  gearSpecificCharacteristic: gearCommonCharacteristicPropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearDataTableSpecificHandlerEdit);
