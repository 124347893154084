// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { specificCharacteristicPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Constants

function GearHammockPocketMattressRowDisplay ({ specificCharacteristic, gearName }) {
  const { t } = useTranslation();

  return (
    <>
      {specificCharacteristic.PocketMattress === 'Yes'
        ? (
          <>
            <GearTR id={`gearCommonRowTH-${gearName}`}>
              <GearTH colSpan={12}>{`${t('PocketMattress')}`}</GearTH>
            </GearTR>
            <GearTR id={`gearCommonRowTH-${gearName}`}>
              <GearTD colSpan={12}>
                {specificCharacteristic.PocketMattress !== null ? `${t(specificCharacteristic.PocketMattress)}` : ''}
              </GearTD>
            </GearTR>
            <GearTR id={`gearCommonRowTH-${gearName}`}>
              <GearTH colSpan={4}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
              <GearTH colSpan={4}>{`${t('Width')} [${getUnit('Distance')}]`}</GearTH>
              <GearTH colSpan={4}>{`${t('Thickness')} [${getUnit('Distance')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearCommonRowTH-${gearName}`}>
              <GearTD colSpan={4}>
                {specificCharacteristic.PocketLength !== null ? specificCharacteristic.PocketLength : ''}
                </GearTD>
              <GearTD colSpan={4}>
                {specificCharacteristic.PocketWidth !== null ? specificCharacteristic.PocketWidth : ''}
              </GearTD>
              <GearTD colSpan={4}>
                {specificCharacteristic.PocketThickness !== null ? specificCharacteristic.PocketThickness : ''}
              </GearTD>
            </GearTR>
          </>
          )
        : (
            <>
              <GearTR id={`gearCommonRowTH-${gearName}`}>
                <GearTH colSpan={12}>{`${t('PocketMattress')}`}</GearTH>
              </GearTR>
              <GearTR id={`gearCommonRowTH-${gearName}`}>
                <GearTD colSpan={12}>
                  {specificCharacteristic.PocketMattress !== null ? `${t(specificCharacteristic.PocketMattress)}` : ''}
                </GearTD>
              </GearTR>
            </>
          )
      }
    </>
  );
}

GearHammockPocketMattressRowDisplay.propTypes = {
  specificCharacteristic: specificCharacteristicPropTypes,
  gearName: PropTypes.string.isRequiredn
};

export default GearHammockPocketMattressRowDisplay;
