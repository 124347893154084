// Import Functions
// Accessory
import { generateAccessoryObjectFromForms } from './Common/GenerateAccessoryObjectFromForm_Function';
// Shelter
import { generateTarpObjectFromForms } from './Shelter/GenerateTarpObjectFromForm_Function';
import { generateTentObjectFromForms } from './Shelter/GenerateTentObjectFromForm_Function';
// Bedding
import { generateSleepingBagObjectFromForms } from './Bedding/GenerateSleepingBagObjectFromForm_Function';
import { generateMattressObjectFromForms } from './Bedding/GenerateMattressObjectFromForm_Function';
import {
  generateHammockObjectFromForms
} from './Bedding/GenerateHammockObjectFromForm_Function';

export async function ObjectFromFormMapping (form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit) {
  console.log('ObjectFromFormMapping');
  console.log('gearData : ', gearData);

  const functionMap = {
    Shelter: getObjectFromFormsShelter,
    Bedding: getObjectFromFormsBedding
  };

  const selectedFunction = functionMap[gearData.Category];
  if (selectedFunction) {
    console.log('selectedFunction : ', selectedFunction);
    return await selectedFunction(form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit);
    // return selectedFunction(gearTypeCategory, gearCategory, gearSubCategory);
  } else {
    console.error(`Unknown itemCategory: ${gearData.Category}`);
  }
};

async function getObjectFromFormsShelter (form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit) {
  console.log('getObjectFromFormsShelter');
  console.log('gearData : ', gearData);

  const functionMap = {
    // Accessory
    Accessory: generateAccessoryObjectFromForms(form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit),
    // Shelter
    Tarp: generateTarpObjectFromForms(form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit),
    Tent: generateTentObjectFromForms(form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit)
  };

  const selectedFunction = functionMap[gearData.SubCategory];
  if (selectedFunction) {
    console.log('selectedFunction : ', selectedFunction);
    return selectedFunction;
  } else {
    console.error(`Shelter : Unknown SubCategory: ${gearData.SubCategory}`);
  }
};

async function getObjectFromFormsBedding (form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit) {
  console.log('getObjectFromFormsBedding');
  console.log('gearData : ', gearData);

  const functionMap = {
    // Accessory
    Accessory: generateAccessoryObjectFromForms(form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit),
    // Bedding
    Mattress: generateMattressObjectFromForms(form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit),
    SleepingBag: generateSleepingBagObjectFromForms(form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit),
    Hammock: generateHammockObjectFromForms(form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit)
  };

  const selectedFunction = functionMap[gearData.SubCategory];
  if (selectedFunction) {
    console.log('selectedFunction : ', selectedFunction);
    return selectedFunction;
  } else {
    console.error(`Bedding : Unknown SubCategory: ${gearData.SubCategory}`);
  }
};
