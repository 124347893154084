import PropTypes from 'prop-types';

export const listPropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
    PropTypes.object,
    PropTypes.oneOf([null])
  ])
);

export const arrayOfListPropTypes = PropTypes.arrayOf(listPropTypes);
