// EN
import AccountEn from './../../i18n_translations/en/Account_en.json';
import BeddingEn from './../../i18n_translations/en/Bedding_en.json';
import ChoiceArraysEn from './../../i18n_translations/en/ChoiceArrays_en.json';
import ColorsEn from './../../i18n_translations/en/Colors_en.json';
import ComparatorEn from './../../i18n_translations/en/Comparator_en.json';
import ContactEn from './../../i18n_translations/en/Contact_en.json';
import FooterEn from './../../i18n_translations/en/Footer_en.json';
import FormEn from './../../i18n_translations/en/Form_en.json';
import FormErrorEn from './../../i18n_translations/en/FormError_en.json';
import GearCommonEn from './../../i18n_translations/en/GearCommon_en.json';
import HeaderEn from './../../i18n_translations/en/Header_en.json';
import ListsEn from './../../i18n_translations/en/Lists_en.json';
import ShelterEn from './../../i18n_translations/en/Shelter_en.json';

// FR
import AccountFr from './../../i18n_translations/fr/Account_fr.json';
import BeddingFr from './../../i18n_translations/fr/Bedding_fr.json';
import ChoiceArraysFr from './../../i18n_translations/fr/ChoiceArrays_fr.json';
import ColorsFr from './../../i18n_translations/fr/Colors_fr.json';
import ComparatorFr from './../../i18n_translations/fr/Comparator_fr.json';
import ContactFr from './../../i18n_translations/fr/Contact_fr.json';
import FooterFr from './../../i18n_translations/fr/Footer_fr.json';
import FormFr from './../../i18n_translations/fr/Form_fr.json';
import FormErrorFr from './../../i18n_translations/fr/FormError_fr.json';
import GearCommonFr from './../../i18n_translations/fr/GearCommon_fr.json';
import HeaderFr from './../../i18n_translations/fr/Header_fr.json';
import ListsFr from './../../i18n_translations/fr/Lists_fr.json';
import ShelterFr from './../../i18n_translations/fr/Shelter_fr.json';

export const translationsJsons = {
  en: {
    Account: AccountEn,
    Bedding: BeddingEn,
    ChoiceArrays: ChoiceArraysEn,
    Colors: ColorsEn,
    Comparator: ComparatorEn,
    Contact: ContactEn,
    Footer: FooterEn,
    Form: FormEn,
    FormError: FormErrorEn,
    GearCommon: GearCommonEn,
    Header: HeaderEn,
    Lists: ListsEn,
    Shelter: ShelterEn
  },
  fr: {
    Account: AccountFr,
    Bedding: BeddingFr,
    ChoiceArrays: ChoiceArraysFr,
    Colors: ColorsFr,
    Comparator: ComparatorFr,
    Contact: ContactFr,
    Footer: FooterFr,
    Form: FormFr,
    FormError: FormErrorFr,
    GearCommon: GearCommonFr,
    Header: HeaderFr,
    Lists: ListsFr,
    Shelter: ShelterFr
  }
};
