// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Components
import ListsTableConstructorEdit from '../../components/List/Tab/Edit/ListTableConstruction_Edit';
import LoadingComponent from '../../components/LoadingComponent/Loading_Component';

// Import Functions
import { APIgetUserIDFromPseudo } from '../../api/User/User_API';
import { APIGetOneUserTypeList } from '../../api/List/List_API';

// Import Style
// import { PageWrapper } from '../../utils/Styles';

function ListEditPage ({ userConcerned, listType }) {
  const { userPseudo, listID } = useParams();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));

  console.log('ListEditPage');
  console.log(`userPseudo : ${userPseudo}`);
  console.log(`listID : ${listID}`);

  const [listCharacteristics, setListCharacteristics] = useState(null);

  useEffect(() => {
    const fetchListData = async () => {
      setListCharacteristics(null);
      const userID = userConcerned === 'Me' ? currentUserData.userProfile.User_ID : await APIgetUserIDFromPseudo(userPseudo);
      const listData = await APIGetOneUserTypeList(userID, listID, listType);
      setListCharacteristics(listData);
    };

    fetchListData();
  }, []);

  return (
    <>
      {
        listCharacteristics !== null
          ? (
            <ListsTableConstructorEdit
              listCharacteristics={listCharacteristics}
            />
            )
          : (
            <LoadingComponent/>
            )

      }
    </>
  );
}

ListEditPage.propTypes = {
  userConcerned: PropTypes.string.isRequired,
  listType: PropTypes.string.isRequired
};

export default ListEditPage;
