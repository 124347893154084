// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

// Import Components
import UserTableDisplay from '../../components/Account/Tab/Display/UserTableConstruction_Display';
import LoadingComponent from '../../components/LoadingComponent/Loading_Component';

// Import API
import { APIgetUser } from '../../api/User/User_API';

// Import Functions
import {
  shouldDisplayAccount
} from '../../utils/Functions/PathFunctions/accountPath_Functions';
import { getUserIDFromUrlAccountDisplay } from '../../utils/Functions/PathFunctions/parseUrl_Functions';

// Import Style
import { PageWrapper } from '../../utils/Styles';

function AccountDisplayPage () {
  // console.log('AccountDisplayPage');
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const currentUrl = location.pathname;
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  // const [userDataToSee, setUserDataToSee] = useState(JSON.parse(sessionStorage.getItem('userData')));
  const [userDataToSee, setUserDataToSee] = useState(null);
  const shouldDisplayAccountPage = shouldDisplayAccount(currentUrl, t, i18n);
  /*
  console.log(`currentUrl : ${currentUrl}`);
  console.log('currentUserData : ', currentUserData);
  console.log(`shouldDisplayAccountPage : ${shouldDisplayAccountPage}`);
  console.log('userDataToSee : ', userDataToSee);
  */

  useEffect(() => {
    const fetchUserData = async () => {
      const userToSee = getUserIDFromUrlAccountDisplay(currentUrl);
      const data = await APIgetUser(userToSee);
      setUserDataToSee(data);
      /*
      console.log('fetchUserData');
      console.log(`currentUrl : ${currentUrl}`);
      console.log(`userToSee : ${userToSee}`);
      */
    };

    if (shouldDisplayAccountPage) {
      fetchUserData(); // ?
      // handle access rights
      if (currentUserData.userProfile.User_Rank !== 'ADMIN') {
        if (currentUserData.userProfile.User_ID !== getUserIDFromUrlAccountDisplay(currentUrl)) {
          // console.log('redirect user when he tries to display an other profile');
          navigate(`/${i18n.language}`);
        }
      } else if (currentUserData.userProfile.User_Rank === 'ADMIN') {
        fetchUserData();
      }
    }
  }, [navigate, i18n.language, t]);

  return (
      <PageWrapper id='pageWrapperAccount'>
        {shouldDisplayAccountPage
          ? (
              userDataToSee !== null
                ? (
                  <UserTableDisplay userData={userDataToSee}/>
                  )
                : (
                  <LoadingComponent/>
                  )

            )
          : (
              <Outlet/>
            )
        }
      </PageWrapper>
  );
}

export default AccountDisplayPage;
