// Import Style
import styled from 'styled-components';

// Import Constants
import { responsiveWidthMobile } from '../../../Constant';

export const AccountActionSelectorWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

  width: 100%;

  @media (max-width: ${responsiveWidthMobile}px){
    flex-direction: column;
    align-items: center;
  }
`;
