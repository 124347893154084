// Import Datas

// Shelter
import { shelterSubCategoryList } from '../../datas/Equipment/Shelter/ShelterSubCategory';
// Bedding
import { beddingSubCategoryList } from '../../datas/Equipment/Bedding/BeddingSubCategory';
// Cooking
import { cookingSubCategoryList } from '../../datas/Equipment/Cooking/CookingSubCategory';
// Portage
import { portageSubCategoryList } from '../../datas/Equipment/Portage/PortageSubCategory';

export function getGearSubCategoryList (gearCategory) {
  const functionMap = {
    Shelter: shelterSubCategoryList,
    Bedding: beddingSubCategoryList,
    Cooking: cookingSubCategoryList,
    Portage: portageSubCategoryList
  };

  const selectedItemList = functionMap[gearCategory];
  if (selectedItemList) {
    /*
    console.log('selectedItemList');
    console.log(selectedItemList);
    */
    return selectedItemList;
  } else {
    console.error(`Unknown itemCategory: ${gearCategory}`);
  }
};
