// Import React Libraries
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';

// Import Style
import {
  GearTR, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearCommonErrorRow ({ formDataErrorMessages }) {
  console.log('GearCommonErrorRow');
  console.log('formDataErrorMessages : ', formDataErrorMessages);

  const { t } = useTranslation();

  useEffect(() => {
    console.log('formDataErrorMessages : ', formDataErrorMessages);
  }, []);
  return (
    <>
    {(formDataErrorMessages.Name !== '' || formDataErrorMessages.Brand !== '' || formDataErrorMessages.Price !== '') &&
      <GearTR>
        {formDataErrorMessages.Name === ''
          ? <GearTD colSpan={4}/>
          : <>
              {formDataErrorMessages.Name === 'NameMissing' &&
                <GearTD colSpan={4} style={{ color: 'red' }}>{`${t('NameMissing')}`}</GearTD>
              }
              {formDataErrorMessages.Name === 'NameFormat' &&
                <GearTD colSpan={4} style={{ color: 'red' }}>{`${t('NameFormat')}`}</GearTD>
              }
            </>
        }
        {formDataErrorMessages.Brand === ''
          ? <GearTD colSpan={4}/>
          : <>
              {formDataErrorMessages.Brand === 'BrandMissing' &&
                <GearTD colSpan={4} style={{ color: 'red' }}>{`${t('BrandMissing')}`}</GearTD>
              }
              {formDataErrorMessages.Brand === 'BrandFormat' &&
                <GearTD colSpan={4} style={{ color: 'red' }}>{`${t('BrandFormat')}`}</GearTD>
              }
            </>
        }
        {formDataErrorMessages.Price === ''
          ? <GearTD colSpan={4}/>
          : <>
              {formDataErrorMessages.Price === 'PriceNegative' &&
                <GearTD colSpan={4} style={{ color: 'red' }}>{`${t('PriceNegative')}`}</GearTD>
              }
            </>
        }
      </GearTR>
    }
    </>
  );
}

GearCommonErrorRow.propTypes = {
  formDataErrorMessages: PropTypes.objectOf(
    PropTypes.arrayOf(PropTypes.string)
  ).isRequired
};

export default GearCommonErrorRow;
