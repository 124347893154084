import colorsPalette from './colors_palette';

const colors = {
  headerFooterBackGround: colorsPalette.oxfordBlue,
  underlineEffect: colorsPalette.aliceBlue,
  titleUnderline: colorsPalette.robbinEggBlue,

  sideBarShelterCategory: colorsPalette.darkCyan,
  sideBarShelterSubCategory: colorsPalette.tiffanyBlue,

  sideBarBeddingCategory: colorsPalette.lavender,
  sideBarBeddingSubCategory: colorsPalette.thistle,

  characteristicTH: colorsPalette.babyBlue,
  characteristicTD: colorsPalette.columniaBlue,

  buttonBackGround: colorsPalette.airSUperiorityBlue,
  buttonShadow: colorsPalette.uranianBlue
};
export default colors;

/*

  link: '#ffffff',
  linkResponsive: '#000000',
  burgerDiv: '#ffffff',
  burgerDivOpen: '#000000',

  creme: '#F9F9FA',
  bluePastel: '#5B89B4',
  marineBlue: '#253046',
  lightGrey: '#d6d1c3',

  orangeCustom: '#E95D12',
  greenCustom: '#37A746'

  */
