import colors from '../../Colors/colors';

export function getSideBarGearTypeCategoryColorFunction (gearTypeCategory, gearCategory) {
  const functionMap = {
    Equipment: getSideBarEquipmentCategoryColor
  };

  const selectedFunction = functionMap[gearTypeCategory];
  if (selectedFunction) {
    return selectedFunction(gearCategory);
  } else {
    console.error(`Unknown itemCategory: ${gearTypeCategory}`);
  }
};

export function getSideBarEquipmentCategoryColor (gearCategory) {
  const functionMap = {
    Shelter: colors.sideBarShelterCategory,
    Bedding: colors.sideBarBeddingCategory
  };

  const selectedColor = functionMap[gearCategory];
  // console.log(`selectedColor : ${selectedColor}`);
  if (selectedColor) {
    return selectedColor;
  } else {
    console.error(`Unknown itemCategory: ${gearCategory}`);
  }
};

export function getSideBarGearSubCategoryColorFunction (gearTypeCategory, gearCategory) {
  const functionMap = {
    Equipment: getSideBarEquipmentSubCategoryColor
  };

  const selectedFunction = functionMap[gearTypeCategory];
  if (selectedFunction) {
    return selectedFunction(gearCategory);
  } else {
    console.error(`Unknown itemCategory: ${gearTypeCategory}`);
  }
};

export function getSideBarEquipmentSubCategoryColor (gearCategory) {
  const functionMap = {
    Shelter: colors.sideBarShelterSubCategory,
    Bedding: colors.sideBarBeddingSubCategory
  };

  const selectedColor = functionMap[gearCategory];
  // console.log(`selectedColor : ${selectedColor}`);
  if (selectedColor) {
    return selectedColor;
  } else {
    console.error(`Unknown itemCategory: ${gearCategory}`);
  }
};
