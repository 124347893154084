// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { listPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Components

// Import Style
import { GearTR, GearTH, GearTD } from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function PackListSpecificRowDisplay ({ listCharacteristics }) {
  console.log('ListCommonTableDisplay');

  const { t } = useTranslation();

  return (
    <>
          <GearTR id={'listRowTH-listCreator'}>
            <GearTH colSpan={12}>
              {`${t('ListCategory')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listRowTD-listCreator'}>
            <GearTD colSpan={12}>
              {`${t(listCharacteristics.Informations.Category)}`}
            </GearTD>
          </GearTR>
    </>
  );
}

PackListSpecificRowDisplay.propTypes = {
  listCharacteristics: listPropTypes.isRequired
};

export default PackListSpecificRowDisplay;
