// Import Functions
import {
  generateInformationsObjectFromForms,
  generateAssetsObjectFromForms,
  generateStatusObjectFromForms,
  generateMetricsObjectFromForms,
  generateCompactedObjectFromForms,
  generateStatisticsObjectFromForms
} from './CommonPropertiesFromForms_Functions';

export function generateAccessoryObjectFromForms (form1Data, form2Data, form3Data, form4Data, form5Data, item, gearData, fromAddOrEdit) {
  console.log('generateAccessoryObjectFromForms');
  console.log('form1Data : ', form1Data);
  console.log('form2Data : ', form2Data);
  console.log('form3Data : ', form3Data);
  console.log('form4Data : ', form4Data);
  console.log('form5Data : ', form5Data);

  const informationObject = generateInformationsObjectFromForms(form2Data, gearData);
  const assetsObject = generateAssetsObjectFromForms(form1Data);
  const statusObject = generateStatusObjectFromForms(form5Data, item.Status, fromAddOrEdit);
  const metricsObject = generateMetricsObjectFromForms(form2Data, form4Data);
  const compactedObject = generateCompactedObjectFromForms(form4Data);
  const statisticsObject = generateStatisticsObjectFromForms(item.Statistics, fromAddOrEdit);

  return {
    _id: item._id,
    Informations: informationObject,
    Assets: assetsObject,
    Status: statusObject,
    Metrics: metricsObject,
    Compacted: compactedObject,
    Specific: {
      MosquitoNet: form3Data.MosquitoNet || null,
      MeshSize: form3Data.MeshSize || null
    },
    Statistics: statisticsObject
  };
}
