// Import Illustrations

import {
  // Equipment Shelter
  tarpDefaultIllustration,
  tentDefaultIllustration,
  // Accessory
  mastDefaultIllustration,
  pegDefaultIllustration,
  ropeDefaultIllustration,
  stripDefaultIllustration,
  // Equipment Bedding
  sleepingBagDefaultIllustration,
  matrressDefaultIllustration,
  hammockDefaulIllustation,
  // Accessory
  mosquitoNetDefaultIllustration
} from '../../../datas/Illustrations/equipmentDefault_Illustration';

export function getGearTypeCategoryDefaultIllustrationFunction (gearData) {
  /*
  console.log('getGearTypeCategoryDefaultIllustrationFunction');
  console.log('gearData : ', gearData);
  */

  const functionMap = {
    Equipment: getEquipmentDefaultIllustration
  };

  const selectedFunction = functionMap[gearData.TypeCategory];
  if (selectedFunction) {
    return selectedFunction(gearData);
  } else {
    console.error(`Unknown gearTypeCategory: ${gearData.TypeCategory}`);
  }
};

export function getEquipmentDefaultIllustration (gearData) {
  const functionMap = {
    Shelter: getShelterDefaultIllustration,
    Bedding: getBeddingDefaultIllustration
  };

  const selectedFunction = functionMap[gearData.Category];
  if (selectedFunction) {
    return selectedFunction(gearData);
  } else {
    console.error(`Unknown gearCategory: ${gearData.Category}`);
  }
};

export function getAccessoryDefaultIllustration (gearData) {
  const functionMap = {
    // Shelter
    Mast: mastDefaultIllustration,
    Peg: pegDefaultIllustration,
    Rope: ropeDefaultIllustration,
    Strip: stripDefaultIllustration,
    // Bedding
    MosquitoNet: mosquitoNetDefaultIllustration
  };

  const defaultIllustration = functionMap[gearData.AccessoryCategory];
  // console.log(`selectedColor : ${selectedColor}`);
  if (defaultIllustration) {
    return defaultIllustration;
  } else {
    console.error(`Unknown gearAccessoryCategory: ${gearData.AccessoryCategory}`);
  }
};

export function getShelterDefaultIllustration (gearData) {
  const functionMap = {
    Tarp: tarpDefaultIllustration,
    Tent: tentDefaultIllustration,
    Accessory: getAccessoryDefaultIllustration(gearData)
  };

  const defaultIllustration = functionMap[gearData.SubCategory];
  // console.log(`selectedColor : ${selectedColor}`);
  if (defaultIllustration) {
    return defaultIllustration;
  } else {
    console.error(`Unknown gearSubCategory: ${gearData.SubCategory}`);
  }
};

export function getBeddingDefaultIllustration (gearData) {
  const functionMap = {
    SleepingBag: sleepingBagDefaultIllustration,
    Mattress: matrressDefaultIllustration,
    Hammock: hammockDefaulIllustation,
    Accessory: getAccessoryDefaultIllustration(gearData)
  };

  const defaultIllustration = functionMap[gearData.SubCategory];
  // console.log(`selectedColor : ${selectedColor}`);
  if (defaultIllustration) {
    return defaultIllustration;
  } else {
    console.error(`Unknown gearSubCategory: ${gearData.SubCategory}`);
  }
};
