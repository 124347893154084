// Import React Libraries
import React, { useState, useImperativeHandle, useRef, forwardRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearCommonCharacteristicPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';

// Import Datas
import { compactedShapeChoiceArray } from '../../../../../datas/ChoiceArrays/Gear/Common_ChoiceArrays';

// Import Function
import { calculCylindricVolume, calculRectangularVolume } from '../../../../../utils/Functions/Gear/Calcul/gearCalculVolumeData_Function';
import { calculDensity } from '../../../../../utils/Functions/Gear/Calcul/gearCalculDensityData_Function';

// Import Style
import {
  GearTable, GearTableBody,
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';

function GearCompactedDimensionTableComponentEdit ({ item, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [compactedShape, setCompactedShape] = useState(item.Compacted.Shape === null ? '' : item.Compacted.Shape);

  // Rectangular
  const [rectangularLength, setRectangularLength] = useState(item.Compacted.RectangularLength);
  const [width, setWidth] = useState(item.Compacted.Width);
  const [height, setHeight] = useState(item.Compacted.Height);
  // Cylindric
  const [cylindricLength, setCylindricLength] = useState(item.Compacted.CylindricLength);
  const [diameter, setDiameter] = useState(item.Compacted.Diameter);

  const [weight, setWeight] = useState(item.Metrics.Weight);

  const [volume, setVolume] = useState(item.Metrics.Volume);
  const [density, setDensity] = useState(item.Metrics.Density);

  // Ref to track the initial mount
  const prevCompactedShapeRef = useRef(compactedShape);

  useEffect(() => {
    let calculatedVolume;
    if (compactedShape === 'Rectangular') {
      console.log('Retangular');
      calculatedVolume = calculRectangularVolume(rectangularLength, width, height, getUnit('Distance'), getUnit('Volume'));
    }
    if (compactedShape === 'Cylindric') {
      console.log('Retangular');
      calculatedVolume = calculCylindricVolume(cylindricLength, diameter, getUnit('Distance'), getUnit('Distance'), getUnit('Volume'));
    }
    setVolume(calculatedVolume);
  }, [cylindricLength, rectangularLength, width, height, compactedShape, diameter]);

  useEffect(() => {
    const calculatedDensity = calculDensity(volume, weight, getUnit('Volume'), getUnit('Weight'), getUnit('Density'));

    setDensity(calculatedDensity);
  }, [volume, weight]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    const prevCompactedShape = prevCompactedShapeRef.current;

    if (prevCompactedShape !== compactedShape) {
      console.log('Reset Values');
      setRectangularLength(null);
      setCylindricLength(null);
      setWidth(null);
      setHeight(null);
      setDiameter(null);
      setVolume(null);
      setDensity(null);
      prevCompactedShapeRef.current = compactedShape;
    }
  }, [compactedShape]);

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
    <GearTable id={`gearCompactedDimensionTableEdit-${item.Informations.SubCategory}`}>
      <ColGroup12/>
      <GearTableBody>
        <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
          <GearTH colSpan={12}>{`${t('CompactedDimension')}`}</GearTH>
        </GearTR>
          {compactedShape === '' && (
              <>
                <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
                  <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
                </GearTR>
                <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
                  <GearTD colSpan={12}>
                    <GearSelectDropDown options={compactedShapeChoiceArray} choice={compactedShape} setChoice={setCompactedShape} noChoice='Shape'/>
                    <input type="hidden" name="CompactedShape" value={compactedShape} />
                  </GearTD>
                </GearTR>
              </>
          )}
          {compactedShape === 'Cylindric' && (
            <>
              <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
                <GearTH colSpan={4}>{`${t('Shape')}`}</GearTH>
                <GearTH colSpan={4}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
                <GearTH colSpan={4}>{`${t('Diameter')} Ø [${getUnit('Distance')}]`}</GearTH>
              </GearTR>
              <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
                <GearTD colSpan={4}>
                  <GearSelectDropDown options={compactedShapeChoiceArray} choice={'Cylindric'} setChoice={setCompactedShape} noChoice='Shape'/>
                  <input type="hidden" name="CompactedShape" value={compactedShape} />
                </GearTD>
                <GearTD colSpan={4}>
                  <GearFormInput
                    type="number"
                    name="CylindricLength"
                    placeholder={cylindricLength === null ? `${t('Length')}` : cylindricLength}
                    value={cylindricLength}
                    onChange={(e) => setCylindricLength(e.target.value)}
                  />
                </GearTD>
                <GearTD colSpan={4}>
                  <GearFormInput
                    type="number"
                    name="Diameter"
                    placeholder={diameter === null ? `${t('Diameter')}` : diameter}
                    value={diameter}
                    onChange={(e) => setDiameter(e.target.value)}
                  />
                </GearTD>
              </GearTR>
            </>
          )}
          {compactedShape === 'Rectangular' && (
            <>
            <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
              <GearTH colSpan={3}>{`${t('Shape')}`}</GearTH>
              <GearTH colSpan={3}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
              <GearTH colSpan={3}>{`${t('Width')} [${getUnit('Distance')}]`}</GearTH>
              <GearTH colSpan={3}>{`${t('Height')} [${getUnit('Distance')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
              <GearTD colSpan={3}>
                <GearSelectDropDown options={compactedShapeChoiceArray} choice={'Rectangular'} setChoice={setCompactedShape} noChoice='Shape'/>
                <input type="hidden" name="CompactedShape" value={compactedShape} />
              </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name="RectangularLength"
                  placeholder={rectangularLength === null ? `${t('Length')}` : rectangularLength}
                  value={rectangularLength}
                  onChange={(e) => setRectangularLength(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name="Width"
                  placeholder={width === null ? `${t('Width')}` : width}
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name="Height"
                  placeholder={height === null ? `${t('Height')}` : height}
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </GearTD>
            </GearTR>
            </>
          )}
        <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
          <GearTH colSpan={4}>{`${t('Weight')} [${getUnit('Weight')}]`}</GearTH>
          <GearTH colSpan={4}>{`${t('Volume')} [${getUnit('Volume')}]`}</GearTH>
          <GearTH colSpan={4}>{`${t('Density')} [${getUnit('Density')}]`}</GearTH>
        </GearTR>
        <GearTR id={`gearCompactedDimensiontRowEditTH-${item.Informations.SubCategory}`}>
          <GearTD colSpan={4}>
            <GearFormInput
            type="number"
            name="Weight"
            placeholder={weight === null ? `${t('Weight')}` : weight}
            value={weight}
            onChange={(e) => setWeight(e.target.value)}/>
          </GearTD>
          <GearTD colSpan={4}>
            {volume !== null ? volume : `${t('Volume')}`}
            <input type="hidden" name="Volume" value={volume}/>
          </GearTD>
          <GearTD colSpan={4}>
            { density !== null ? density : `${t('Density')}` }
            { /* typeof density === 'number' ? density : `${t('Density')}` */ }
            <input type="hidden" name="Density" value={density}/>
          </GearTD>
        </GearTR>
      </GearTableBody>
    </GearTable>
    </form>
  );
}

GearCompactedDimensionTableComponentEdit.propTypes = {
  item: gearCommonCharacteristicPropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearCompactedDimensionTableComponentEdit);
