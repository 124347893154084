// Import React Libraries
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { gearCommonCharacteristicPropTypes } from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import ColGroup12 from '../../ColGroup12';
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';

// Import Datas
import { gearValidationChoiceArray } from '../../../../../datas/ChoiceArrays/Gear/gearStatistics_ChoiceArrays';

// Import Style
import {
  GearTR, GearTH, GearTD,
  GearTable,
  GearTableBody
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearValidationDataTableEdit ({ item, onSubmit }, ref) {
  const { t } = useTranslation();
  const formRef = useRef();

  const [validationStatus, setValidationStatus] = useState(item.Status.Validated === true ? 'Validated' : 'PendingValidation');

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());
    // console.log(data);
    if (onSubmit) {
      onSubmit(data);
    }
  };

  useImperativeHandle(ref, () => ({
    submit () {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }
  }));

  return (
    <form ref={formRef} onSubmit={handleSubmit}>
      <GearTable id={`gearValidationDataTableEdit-${item.Informations.SubCategory}`}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={`gearValidationDataRowEdit-${item.Informations.SubCategory}`}>
            <GearTH colSpan={12}>{`${t('ValidationStatus')}`}</GearTH>
          </GearTR>
          <GearTR id={`gearValidationDataRowEdit-${item.Informations.SubCategory}`}>
            <GearTD colSpan={12}>
              <GearSelectDropDown options={gearValidationChoiceArray} choice={validationStatus} setChoice={setValidationStatus} noChoice={validationStatus}/>
              <input type="hidden" name="Validated" value={validationStatus} />
            </GearTD>
          </GearTR>
        </GearTableBody>
      </GearTable>
    </form>
  );
}

GearValidationDataTableEdit.propTypes = {
  item: gearCommonCharacteristicPropTypes,
  onSubmit: PropTypes.func.isRequired
};

export default forwardRef(GearValidationDataTableEdit);
