// Import React Libraries
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

// Import PropTypes
import { gearDataPropTypes, gearItemPropTypes } from '../../../../datas/PropTypes/gearPropTypes';

// Import Component
// Common Table
import GearCompactedDimensionTableDisplay from './Common/GearCompactedDimensionTableDisplay';

// Specific Table
import GearSpecificTableHandlerDisplay from './GearSpecificTableHandlerDisplay';

// Import Style
import { GearInteractButtonContainer } from '../../../../utils/Style/js/GearItemSelector/GearItemCommonButtons_Style';
import { GeneralButton } from '../../../../utils/Style/js/General/Button_Style';

function GearTableConstructionDisplay ({ gearData, gearItem }) {
  const { i18n, t } = useTranslation();
  const currentUserData = JSON.parse(sessionStorage.getItem('userData'));
  const [shouldDisplayEditButton, setShouldDisplayEditButton] = useState(false);

  /*
  console.log('GearTableConstructionDisplay');
  console.log('gearItem : ', gearItem);
  */

  const linkPath = gearItem.Informations.SubCategory !== 'Accessory'
    ? `/${i18n.language}/${t(gearItem.Informations.TypeCategory)}/${t(gearItem.Informations.Category)}/${t(gearItem.Informations.SubCategory)}/${gearItem.Informations.WebAppID}/${t('Edition')}`
    : `/${i18n.language}/${t(gearItem.Informations.TypeCategory)}/${t(gearItem.Informations.Category)}/${t(gearItem.Informations.SubCategory)}/${t(gearItem.Informations.AccessoryCategory)}/${gearItem.Informations.WebAppID}/${t('Edition')}`;

  // console.log(`linkPath : ${linkPath}`);
  useEffect(() => {
    if (currentUserData.userProfile.User_Rank === 'ADMIN') {
      setShouldDisplayEditButton(true);
    } else if (currentUserData.userProfile.User_ID === gearItem.Statistics.AddedBy) {
      setShouldDisplayEditButton(true);
    }
  }, []);

  return (
    <div id='GearTableConstructionDisplay'>
      <GearSpecificTableHandlerDisplay gearData={gearData} gearItem={gearItem}/>
      <GearCompactedDimensionTableDisplay
        item={gearItem}
        gearName={gearItem.Informations.WebAppID}
      />
      {shouldDisplayEditButton
        ? <GearInteractButtonContainer>
            <Link to={linkPath} style={{ textDecoration: 'none' }}>
                <GeneralButton>{`${t('EditThe')} ${t(gearItem.Informations.SubCategory)}`}</GeneralButton>
            </Link>
          </GearInteractButtonContainer>
        : <></>
      }
    </div>
  );
}

GearTableConstructionDisplay.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  gearItem: gearItemPropTypes.isRequired
};

export default GearTableConstructionDisplay;
