import PropTypes from 'prop-types';

export const userProfilePropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
);

export const userGeneralPreferencePropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
);

export const userUnitPreferencePropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
);

export const userStatisticPropTypes = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
);

export const userDataPropTypes = PropTypes.shape({
  userProfile: userProfilePropTypes.isRequired,
  userGeneralPreference: userGeneralPreferencePropTypes.isRequired,
  userUnitPreference: userUnitPreferencePropTypes.isRequired,
  userStatistic: userStatisticPropTypes
});
