// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import { specificCharacteristicPropTypes } from '../../../../../../datas/PropTypes/gearPropTypes';

// Import Functions
import { getUnit } from '../../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../../utils/Style/js/GearTable/GearTable_Style';

// Import Constants

function GearSleepingBagInsulatorNatureRowDisplay ({ specificCharacteristic, gearName }) {
  const { t } = useTranslation();
  return (
    <>
        {specificCharacteristic.InsulatorNature === 'Down'
          ? (
            <>
              <GearTR id={`gearCommonRowTH-${gearName}`}>
                <GearTH colSpan={6}>{`${t('InsulatorNature')}`}</GearTH>
                <GearTH colSpan={6}>{`${t('InsulatorQuality')} [${getUnit('InsulatorQuality')}]`}</GearTH>
              </GearTR>
              <GearTR id={`gearCommonRowTH-${gearName}`}>
                <GearTD colSpan={6}>
                  {specificCharacteristic.InsulatorNature !== null ? `${t(specificCharacteristic.InsulatorNature)}` : ''}
                </GearTD>
                <GearTD colSpan={6}>
                  {specificCharacteristic.InsulatorQuality || ''}
                </GearTD>
              </GearTR>
            </>
            )
          : (
        <>
              <GearTR id={`gearCommonRowTH-${gearName}`}>
                <GearTH colSpan={12}>{`${t('InsulatorNature')}`}</GearTH>
              </GearTR>
              <GearTR id={`gearCommonRowTH-${gearName}`}>
                <GearTD colSpan={12}>
                  {specificCharacteristic.InsulatorNature !== null ? `${t(specificCharacteristic.InsulatorNature)}` : ''}
                </GearTD>
              </GearTR>
            </>
            )}
    </>
  );
}

GearSleepingBagInsulatorNatureRowDisplay.propTypes = {
  specificCharacteristic: specificCharacteristicPropTypes,
  gearName: PropTypes.string.isRequiredn
};

export default GearSleepingBagInsulatorNatureRowDisplay;
