// Import React Libraries
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import PropTypes from 'prop-types';
import {
  gearDataPropTypes,
  specificCharacteristicPropTypes
} from '../../../../../datas/PropTypes/gearPropTypes';

// Import Components
import DisplayHelpComponent from '../../../../ToolTip/DisplayHelp_Component';
import { GearSelectDropDown } from '../../../SelectDropDown/GearSelectDropDown';

// Import Functions
import { getUnit } from '../../../../../utils/Functions/Gear/Unit/unit_Functions';

// Import Functions
import { calculSurfaceDependingOnShape } from '../../../../../utils/Functions/Gear/Calcul/gearCalculSurfaceData_Function';
import { getShapeArray } from '../../../../../utils/Functions/Gear/GatherShapes/gatherShapes_Functions';

// Import Illustration
// Triangle
import TriangleLengthAen from '../../../../../assets/Schemas/Triangle/TriangleLengthA_en.jpg';
import TriangleLengthBen from '../../../../../assets/Schemas/Triangle/TriangleLengthB_en.jpg';
import TriangleLengthCen from '../../../../../assets/Schemas/Triangle/TriangleLengthC_en.jpg';

// Trapezium
import TrapeziumLengthAfr from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthA_fr.jpg';
import TrapeziumLengthAen from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthA_en.jpg';
import TrapeziumLengthBfr from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthB_fr.jpg';
import TrapeziumLengthBen from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthB_en.jpg';
import TrapeziumLengthCfr from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthC_fr.jpg';
import TrapeziumLengthCen from '../../../../../assets/Schemas/Trapezium/TrapeziumLengthC_en.jpg';

// Hexagon
import HexagonalLengthAfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthA_fr.jpg';
import HexagonalLengthAen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthA_en.jpg';
import HexagonalLengthBfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthB_fr.jpg';
import HexagonalLengthBen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthB_en.jpg';
import HexagonalLengthCfr from '../../../../../assets/Schemas/Hexagon/HexagonalLengthC_fr.jpg';
import HexagonalLengthCen from '../../../../../assets/Schemas/Hexagon/HexagonalLengthC_en.jpg';

// Import Style
import {
  GearTR, GearTH, GearTD
} from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import { GearFormInput } from '../../../../../utils/Style/js/GearForm/GearForm_Style';

// Import Constants

function GearDeployedDimensionTableComponentEdit ({ gearData, specific, nameComplement }) {
  const { i18n, t } = useTranslation();
  // const [deployedShape, setDeployedShape] = useState(specific.Deployed.Shape);
  const [deployedShape, setDeployedShape] = useState(
    nameComplement !== undefined
      ? (specific.Deployed[`${nameComplement}Shape`])
      : (specific.Deployed.Shape)
  );

  // Square & Rectangular
  const [length, setLength] = useState(
    nameComplement !== undefined
      ? (specific.Deployed[`${nameComplement}Length`])
      : (specific.Deployed.Length)
  );
  const [width, setWidth] = useState(
    nameComplement !== undefined
      ? (specific.Deployed[`${nameComplement}Width`])
      : (specific.Deployed.Width)
  );
  // Circle
  const [diameter, setDiameter] = useState(
    nameComplement !== undefined
      ? (specific.Deployed[`${nameComplement}Diameter`])
      : (specific.Deployed.Diameter)
  );
  // Hexagonal
  const [lengthA, setLengthA] = useState(
    nameComplement !== undefined
      ? (specific.Deployed[`${nameComplement}LengthA`])
      : (specific.Deployed.LengthA)
  );
  const [lengthB, setLengthB] = useState(
    nameComplement !== undefined
      ? (specific.Deployed[`${nameComplement}LengthB`])
      : (specific.Deployed.LengthB)
  );
  const [lengthC, setLengthC] = useState(
    nameComplement !== undefined
      ? (specific.Deployed[`${nameComplement}LengthC`])
      : (specific.Deployed.LengthC)
  );
  const [surface, setSurface] = useState(
    nameComplement !== undefined
      ? (specific.Deployed[`${nameComplement}Surface`])
      : (specific.Deployed.Surface)
  );
  // Mummy
  const [widthShoulder, setWidthShoulder] = useState(specific.Deployed.WidthShoulder);
  const [widthFoot, setWidthFoot] = useState(specific.Deployed.WidthFoot);

  // Ref to track the initial mount
  const prevDeployedShapeRef = useRef(deployedShape);

  useEffect(() => {
    setSurface(calculSurfaceDependingOnShape(length, width, lengthA, lengthB, lengthC, diameter, deployedShape));
  }, [length, width, deployedShape, lengthA, lengthB, lengthC, diameter]);

  useEffect(() => {
    const prevDeployedShape = prevDeployedShapeRef.current;

    if (prevDeployedShape !== deployedShape) {
      // console.log('Reset Values');
      setLength(null);
      setLengthA(null);
      setLengthB(null);
      setLengthC(null);
      setWidth(null);
      setSurface(null);
      prevDeployedShapeRef.current = deployedShape;
    }
  }, [deployedShape]);

  return (
    <>
      <GearTR id={`gearDeployedDimensionRowTHEdit-${gearData.SubCategory}`}>
        <GearTH colSpan={12}>{`${t('DeployedDimension')}`}</GearTH>
      </GearTR>
        <GearTR id={`gearDeployedDimensionRowTHEdit-${gearData.SubCategory}`}>
          <GearTH colSpan={12}>{`${t('Shape')}`}</GearTH>
        </GearTR>
        <GearTR id={`gearDeployedDimensionRowTDEdit-${gearData.SubCategory}`}>
          <GearTD colSpan={12}>
            <GearSelectDropDown options={getShapeArray(gearData.SubCategory)} choice={deployedShape} setChoice={setDeployedShape} noChoice='Shape'/>
            <input
              type="hidden"
              name={nameComplement !== undefined ? `${nameComplement}Shape` : 'Shape'}
              value={deployedShape}
            />
          </GearTD>
        </GearTR>
        {deployedShape === 'Square' && (
          <>
            <GearTR id={`gearDeployedDimensionRowTHEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTH colSpan={6}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
              <GearTH colSpan={6}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearDeployedDimensionRowTDEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTD colSpan={6}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}Length` : 'Length'}
                  placeholder={length === null ? `${t('Length')}` : length}
                  value= {length}
                  onChange={(e) => setLength(e.target.value)}/>
              </GearTD>
              <GearTD colSpan={6}>{surface !== 0 ? surface : `${t('Surface')}`}
                <input
                  type="hidden"
                  name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                  value={surface}
                />
              </GearTD>
            </GearTR>
          </>
        )}
        {deployedShape === 'Rectangle' && (
          <>
            <GearTR id={`gearDeployedDimensionRowTHEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTH colSpan={4}>{`${t('Length')} [${getUnit('Distance')}]`}</GearTH>
              <GearTH colSpan={4}>{`${t('Width')} [${getUnit('Distance')}]`}</GearTH>
              <GearTH colSpan={4}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearDeployedDimensionRowTDEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTD colSpan={4}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}Length` : 'Length'}
                  placeholder={length === null ? `${t('Length')}` : length}
                  value= {length}
                  onChange={(e) => setLength(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={4}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}Width` : 'Width'}
                  placeholder={width === null ? `${t('Width')}` : width}
                  value= {width}
                  onChange={(e) => setWidth(e.target.value)}
                />
                </GearTD>
              <GearTD colSpan={4}>{surface !== 0 ? surface : `${t('Surface')}`}
                <input
                  type="hidden"
                  name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                  value={surface}
                />
              </GearTD>
            </GearTR>
          </>
        )}
        {deployedShape === 'Circle' && (
          <>
            <GearTR id={`gearDeployedDimensionRowTHEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTH colSpan={6}>{`${t('Diameter')} [${getUnit('Distance')}]`}</GearTH>
              <GearTH colSpan={6}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearDeployedDimensionRowTDEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTD colSpan={6}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}Diameter` : 'Diameter'}
                  placeholder={diameter === null ? `${t('Diameter')}` : diameter}
                  value= {diameter}
                  onChange={(e) => setDiameter(e.target.value)}/>
              </GearTD>
              <GearTD colSpan={6}>{surface !== 0 ? surface : `${t('Surface')}`}
                <input
                  type="hidden"
                  name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                  value={surface}
                />
              </GearTD>
            </GearTR>
          </>
        )}
        {deployedShape === 'Triangle' && (
          <>
            <GearTR id={`gearDeployedDimensionRowTHEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTH colSpan={3}>
                {`${t('Length')} A [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? TriangleLengthAen : TriangleLengthAen}/>
              </GearTH>
              <GearTH colSpan={3}>
                {`${t('Length')} B [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? TriangleLengthBen : TriangleLengthBen}/>
              </GearTH>
              <GearTH colSpan={3}>
                {`${t('Length')} C [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? TriangleLengthCen : TriangleLengthCen}/>
              </GearTH>
              <GearTH colSpan={3}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearDeployedDimensionRowTDEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthA` : 'LengthA'}
                  placeholder={lengthA === null ? `${t('Length')} A` : lengthA}
                  value= {lengthA}
                  onChange={(e) => setLengthA(e.target.value)}
                />
                </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthB` : 'LengthB'}
                  placeholder={lengthB === null ? `${t('Length')} B` : lengthB}
                  value= {lengthB}
                  onChange={(e) => setLengthB(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthC` : 'LengthC'}
                  placeholder={lengthC === null ? `${t('Length')} C` : lengthC}
                  value= {lengthC}
                  onChange={(e) => setLengthC(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>{surface !== 0 ? surface : `${t('Surface')}`}
                <input
                  type="hidden"
                  name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                  value={surface}
                />
              </GearTD>
            </GearTR>
          </>
        )}
        {deployedShape === 'Trapezium' && (
          <>
            <GearTR id={`gearDeployedDimensionRowTHEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTH colSpan={3}>
                {`${t('Length')} A [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? TrapeziumLengthAfr : TrapeziumLengthAen}/>
              </GearTH>
              <GearTH colSpan={3}>
                {`${t('Length')} B [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? TrapeziumLengthBfr : TrapeziumLengthBen}/>
              </GearTH>
              <GearTH colSpan={3}>
                {`${t('Length')} C [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? TrapeziumLengthCfr : TrapeziumLengthCen}/>
              </GearTH>
              <GearTH colSpan={3}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearDeployedDimensionRowTDEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthA` : 'LengthA'}
                  placeholder={lengthA === null ? `${t('Length')} A` : lengthA}
                  value= {lengthA}
                  onChange={(e) => setLengthA(e.target.value)}
                />
                </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthB` : 'LengthB'}
                  placeholder={lengthB === null ? `${t('Length')} B` : lengthB}
                  value= {lengthB}
                  onChange={(e) => setLengthB(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthC` : 'LengthC'}
                  placeholder={lengthC === null ? `${t('Length')} C` : lengthC}
                  value= {lengthC}
                  onChange={(e) => setLengthC(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>{surface !== 0 ? surface : `${t('Surface')}`}
                <input
                  type="hidden"
                  name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                  value={surface}
                />
              </GearTD>
            </GearTR>
          </>
        )}
        {deployedShape === 'Hexagonal' && (
          <>
            <GearTR id={`gearDeployedDimensionRowTHEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTH colSpan={3}>
                {`${t('Length')} A [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthAfr : HexagonalLengthAen}/>
              </GearTH>
              <GearTH colSpan={3}>
                {`${t('Length')} B [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthBfr : HexagonalLengthBen}/>
              </GearTH>
              <GearTH colSpan={3}>
                {`${t('Length')} C [${getUnit('Distance')}]`}
                <DisplayHelpComponent illustration={i18n.language === 'fr' ? HexagonalLengthCfr : HexagonalLengthCen}/>
              </GearTH>
              <GearTH colSpan={3}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearDeployedDimensionRowTDEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthA` : 'LengthA'}
                  placeholder={lengthA === null ? `${t('Length')} A` : lengthA}
                  value= {lengthA}
                  onChange={(e) => setLengthA(e.target.value)}
                />
                </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthB` : 'LengthB'}
                  placeholder={lengthB === null ? `${t('Length')} B` : lengthB}
                  value= {lengthB}
                  onChange={(e) => setLengthB(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}LengthC` : 'LengthC'}
                  placeholder={lengthC === null ? `${t('Length')} C` : lengthC}
                  value= {lengthC}
                  onChange={(e) => setLengthC(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>{surface !== 0 ? surface : `${t('Surface')}`}
                <input
                  type="hidden"
                  name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                  value={surface}
                />
              </GearTD>
            </GearTR>
          </>
        )}
        {deployedShape === 'Mummy' && (
          <>
            <GearTR id={`gearDeployedDimensionRowTHEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTH colSpan={3}>
                {`${t('Length')} [${getUnit('Distance')}]`}
              </GearTH>
              <GearTH colSpan={3}>
                {`${t('WidthShoulder')} [${getUnit('Distance')}]`}
              </GearTH>
              <GearTH colSpan={3}>
                {`${t('WidthFoot')} [${getUnit('Distance')}]`}
              </GearTH>
              <GearTH colSpan={3}>{`${t('Surface')} [${getUnit('Surface')}]`}</GearTH>
            </GearTR>
            <GearTR id={`gearDeployedDimensionRowTDEdit-${deployedShape}-${gearData.SubCategory}`}>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}Length` : 'Length'}
                  placeholder={length === null ? `${t('Length')}` : length}
                  value= {length}
                  onChange={(e) => setLength(e.target.value)}
                />
                </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}WidthShoulder` : 'WidthShoulder'}
                  placeholder={widthShoulder === null ? `${t('WidthShoulder')}` : widthShoulder}
                  value= {widthShoulder}
                  onChange={(e) => setWidthShoulder(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>
                <GearFormInput
                  type="number"
                  name={nameComplement !== undefined ? `${nameComplement}WidthFoot` : 'WidthFoot'}
                  placeholder={widthFoot === null ? `${t('WidthFoot')}` : widthFoot}
                  value= {widthFoot}
                  onChange={(e) => setWidthFoot(e.target.value)}
                />
              </GearTD>
              <GearTD colSpan={3}>{surface !== 0 ? surface : `${t('Surface')}`}
                <input
                  type="hidden"
                  name={nameComplement !== undefined ? `${nameComplement}Surface` : 'Surface'}
                  value={surface}
                />
              </GearTD>
            </GearTR>
          </>
        )}
    </>
  );
}

GearDeployedDimensionTableComponentEdit.propTypes = {
  gearData: gearDataPropTypes.isRequired,
  deployedShape: PropTypes.string.isRequired,
  nameComplement: PropTypes.string,
  specific: specificCharacteristicPropTypes.isRequired
};

export default GearDeployedDimensionTableComponentEdit;
