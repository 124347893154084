// Import React Libraries
import React from 'react';
import { useTranslation } from 'react-i18next';

// Import PropTypes
import { listPropTypes } from '../../../../../datas/PropTypes/listPropTypes';

// Import Components
import ColGroup12 from '../../../../Gear/Tab/ColGroup12';
import PackListSpecificRowDisplay from '../PackList/packListSpecificRowDisplay';
import SumListSpecificRowDisplay from '../SumList/sumListSpecificRowDisplay';

// Import Functions
import { convertIfNecessarySI } from '../../../../../utils/Functions/Gear/UnitConversion/convertionHandler_Function';

// Import Style
import { GearTable, GearTableBody, GearTR, GearTH, GearTD } from '../../../../../utils/Style/js/GearTable/GearTable_Style';
import PackListAddToSumListRowDisplay from '../PackList/packListAddToSumListRowDisplay';

// Import Constants

function ListCommonTableDisplay ({ listCharacteristics }) {
  console.log('ListCommonTableDisplay');

  const { t } = useTranslation();

  return (
    <>
      <GearTable id={'listTable-preview'}>
        <ColGroup12/>
        <GearTableBody>
          <GearTR id={'listRowTH-listCreator'}>
            <GearTH colSpan={6}>
              {`${t('ListCreator')}`}
            </GearTH>
            <GearTH colSpan={6}>
              {`${t('ListName')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listRowTD-listCreator'}>
            <GearTD colSpan={6}>
              {listCharacteristics.Informations.Creator}
            </GearTD>
            <GearTD colSpan={6}>
              {listCharacteristics.Informations.Name}
            </GearTD>
          </GearTR>
          <GearTR id={'listRowTH-listType'}>
            <GearTH colSpan={6}>
              {`${t('ListType')}`}
            </GearTH>
            <GearTH colSpan={6}>
              {`${t('ListVisibility')}`}
            </GearTH>
          </GearTR>
          <GearTR id={'listRowTH-listType'}>
            <GearTD colSpan={6}>
              {`${t(`${listCharacteristics.Informations.Type}Title`)}`}
            </GearTD>
            <GearTD colSpan={6}>
              {`${t(listCharacteristics.Status.Visibility)}`}
            </GearTD>
          </GearTR>
          {
            listCharacteristics.Informations.Type === 'PackList' &&
            <>
              <PackListSpecificRowDisplay listCharacteristics={listCharacteristics}/>
              <PackListAddToSumListRowDisplay list={listCharacteristics}/>
            </>
          }
          {
            listCharacteristics.Informations.Type === 'SumList' &&
            <SumListSpecificRowDisplay listCharacteristics={listCharacteristics}/>
          }
          <GearTR id={'listRowTH-listCharacteristics'}>
            <GearTH colSpan={6}>
              {listCharacteristics.Metrics.Weight !== null
                ? `${t('TotalWeight')} [${convertIfNecessarySI('Weight', listCharacteristics.Metrics.Weight).convertedUnit}]`
                : `${t('TotalWeight')}`
              }
            </GearTH>
            <GearTH colSpan={6}>
              {listCharacteristics.Metrics.Volume !== null
                ? `${t('TotalVolume')} [${convertIfNecessarySI('Volume', listCharacteristics.Metrics.Volume).convertedUnit}]`
                : `${t('TotalVolume')}`
              }
            </GearTH>
          </GearTR>
          <GearTR id={'listRowTH-listCharacteristics'}>
            <GearTD colSpan={6}>
              {listCharacteristics.Metrics.Weight !== null
                ? convertIfNecessarySI('Weight', listCharacteristics.Metrics.Weight).convertedValue
                : ''
              }
            </GearTD>
            <GearTD colSpan={6}>
              {listCharacteristics.Metrics.Volume !== null
                ? convertIfNecessarySI('Volume', listCharacteristics.Metrics.Volume).convertedValue
                : ''
              }
            </GearTD>
          </GearTR>
        </GearTableBody>
      </GearTable>
    </>
  );
}

ListCommonTableDisplay.propTypes = {
  listCharacteristics: listPropTypes.isRequired
};

export default ListCommonTableDisplay;
