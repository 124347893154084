// Import Assets
import {
  pegAccessoryCategoryIllustration,
  ropeAccessoryCategoryIllustration
} from '../../Illustrations/equipment_Illustrations';

export const shelterAccessoryCategoryList = [
  {
    AccessoryCategory: 'Peg',
    text: 'Peg-title',
    illustration: pegAccessoryCategoryIllustration
  },
  {
    AccessoryCategory: 'Rope',
    text: 'Rope-title',
    illustration: ropeAccessoryCategoryIllustration
  }
];
